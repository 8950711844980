$records-image-height: 60px;
$records-image-width: 100%;
$upload-image-height: 72px;

.thumbnail-container {
  &.thumbnail-container--no-effects .small-img-window {
    cursor: initial;

    &:hover {
      box-shadow: none;
    }
  }

  .small-img-window {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    margin-bottom: $general-spacing;
    margin-right: $general-spacing;
    border-radius: $border-radius;
    cursor: pointer;
    height: $records-image-height;

    &:hover,
    &.active {
      box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.4);
    }

    .small-img {
      height: $records-image-height;
      border-radius: $border-radius;
      object-fit: cover;
    }
  }

  &--fit {
    .small-img-window {
      margin-right: 3%;
      width: 30%;

      &--btn {
        color: $pearl;
        background-color: $jade;
        padding: 0px $general-spacing * 0.5;
        vertical-align: top;
        line-height: 17px;
        font-weight: 500;
        min-height: 60px;

        svg {
          margin-top: -30px;
        }
      }
    }
    .small-img {
      width: $records-image-width;
    }
  }
}

.image-box {
  height: $upload-image-height;
  padding: 0;
  position: relative;
  width: fit-content;
  border-radius: $border-radius;
  cursor: pointer;

  &:hover {
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.4);
  }

  .remove-location {
    position: absolute;
    top: 12px;
    right: -10px;
  }

  img {
    object-fit: cover;
    height: $upload-image-height;
    border-radius: $border-radius;
  }
}
