.pointer {
  cursor: pointer;
}
.pointer2 {
  cursor: pointer;
  margin-left: 22px;
}

.table-boxed,
.table-boxed th {
  border: 1px solid black;
}

.table-boxed td {
  border-right: 1px solid #000000;
  border-left: 1px solid #000000;
}

.table-inbrace-dark-blue {
  background-color: #018abe;
  color: #fff;
  border-bottom: 1px solid #000000;
}

.table.table-condensed.table-striped.table-boxed {
  border-radius: 20px;
}

.table-inbrace-dark-blue thead {
  border-radius: 20px 20px 0px 0px;
}

.table-inbrace-dark-blue tr,
.table-inbrace-dark-blue tr th {
  border-bottom: 1px solid #000000 !important;
}

.table-industry.accounts {
  table-layout: fixed;
}

.table-inbrace-dark-industry.accounts td {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.margin-top {
  margin-top: 15px !important;
}

.float-right {
  float: right;
  margin-right: 10px;
}

.fa-badge-color {
  color: #c9e2fa;
}

.main-content.main-content-solo {
  padding-top: 30px;
}

#addaccountbell {
  margin-left: 5px;
}

.warning-creation {
  margin-left: 20px;
  color: #d43f3a;
}

.api-create {
  // margin-top: 5px;
  // margin-bottom: 5px;
}

.dso-name-width {
  width: 21%;
}

.account-name-width {
  width: 15%;
}
