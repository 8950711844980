$light-gray: #394042;
$dark-gray: #303235;

.manual-wire-locket {
    display: flex;
    flex-direction: row;

    .first-item {
        min-width: 130px;
        border: 1px solid $light-gray;
        color: #fff;
        padding: 10px;
        background-color: $dark-gray;
    }

    .manual-wire-locket-element {
        min-width: 130px;
        border: 1px solid $light-gray;
        color: #fff;
        background-color: $light-gray;
    
        .select-manual-wire-locket {
            &.error {
                border: 1px solid red;
            }

            width: 100%;
            background-color: $dark-gray;
            height: 100%;
            padding: 10px;
            color: #fff;
            font-size: 1.3rem;

            svg {
                color: #fff;
            }
        }
    }

    .menu-paper {
        background-color: $dark-gray;
        color: #fff;
    }
}
