.submission-width {
  width: 10%;
}

.name-width {
  width: 17%;
}

.type-width {
  width: 10% !important;
}
