.submission-confirmation-container {
  align-items: center;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  margin-top: 16px;
  row-gap: 10px;
  width: 100%;

  .content__action {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 16px;
  }
}

.rating-form-container {
  margin: 10px auto;
  max-width: 600px;
  padding: 56px;

  * {
    box-sizing: border-box;
    margin: 0;
  }

  .rating-form {
    display: flex;
    flex-direction: column;
    row-gap: 10px;

    .rating-form__title {
      color: #707070;
      font-size: 18px;
      font-weight: 500;
      line-height: 25px;

      &--center {
        text-align: center;
      }
    }

    .rating-form__subtitle {
      color: #404041;

      &--center {
        text-align: center;
      }
    }

    .tooth-rating {
      .tooth-rating__label {
        color: #404041;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
      }
    }

    .rating-form__comment {
      display: flex;
      flex-direction: column;
      position: relative;
      row-gap: 4px;

      .comment__label {
        color: #404041;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
      }

      .text-area__count--bottom {
        top: 100%;
      }
    }

    .rating-form__button {
      display: flex;
      justify-content: center;
      margin-top: 16px;
    }
  }
}

@media (max-width: 768px) {
  .rating-form-container {
    padding: 24px;

    .rating-form {
      .tooth-rating {
        .tooth-rating__teeth {
          svg {
            width: 40px;
          }
        }
      }

      .rating-form__comment {
        .text-area__count {
          right: 10px;
        }
      }
    }
  }
}
