.ipp-light {
  // abstracts
  @import './abstracts/mixins';

  // base
  @import './base/variables';
  @import './base/hyperlinks';
  @import './base/modifiers';
  @import './base/spacing';
  @import './base/typography';

  // component
  @import './components/alert-container';
  @import './components/alert-message';
  @import './components/auto-complete';
  @import './components/auto-suggest';
  @import './components/badge';
  @import './components/breadcrumb';
  @import './components/border';
  @import './components/button-toggle';
  @import './components/button';
  @import './components/card-container';
  @import './components/clinical-conditions';
  @import './components/collapsible-container';
  @import './components/collapsible-triangle-container';
  @import './components/div-table';
  @import './components/dropdown';
  @import './components/edit-table';
  @import './components/error-message';
  @import './components/feature-announcement';
  @import './components/feedback-form';
  @import './components/file-upload';
  @import './components/filter';
  @import './components/hamburger-menu';
  @import './components/icon';
  @import './components/image';
  @import './components/line-division';
  @import './components/modal';
  @import './components/navbar';
  @import './components/note-container';
  @import './components/pagination';
  @import './components/patient-notes';
  @import './components/pdf-modal';
  @import './components/progress-ring';
  @import './components/progress-bar';
  @import './components/record-viewer';
  @import './components/scrollbar';
  @import './components/selection-area';
  @import './components/sidebar';
  @import './components/simple-modal';
  @import './components/smile-design-preferences';
  @import './components/smile-simulation-submission';
  @import './components/stepper';
  @import './components/submission-summary';
  @import './components/teeth-selector';
  @import './components/text-input';
  @import './components/tooltip';
  @import './components/wall';
  @import './components/help-sidebar';

  // layout
  @import './layout/content';
  @import './layout/_content-header';

  // Background
  @import './backgrounds/background';

  // Mobile
  @import './mobile/small-devices';

  // page
  @import './page/case-details';
  @import './page/case-submisson';
  @import './page/dashboard';
  @import './page/forms';
  @import './page/ir-submission';
  @import './page/login';
  @import './page/not-found';
  @import './page/prospect-details';
  @import './page/provider-tier-details';
  @import './page/tx-plan';

  // CSS
  font-size: 16px;
  background-color: $eggshell;
  overflow-x: hidden;
}
