&.background,
.background {
  background-color: $turquoise;

  &--wires {
    background-image: url('../../../../public/static/img/wires.png');
    background-position: top right;
    background-repeat: no-repeat;
  }
}
