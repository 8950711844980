.bpp-pagination-container {
  .pagination-dark {
    min-height: 58px;
  }

  .pagination-dark > .pagination > .active > a {
    background-color: #323539;
    border-color: #323539;
    color: #ffffff;
  }

  .pagination-dark > .pagination > li:first-child > a,
  .pagination-dark > .pagination > li:first-child > span {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }

  .pagination-dark > .pagination > li:last-child > a,
  .pagination-dark > .pagination > li:last-child > span {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  .pagination-dark > .pagination > li > a,
  .pagination-dark > .pagination > li > span {
    color: #323539;
  }
}
