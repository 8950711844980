$tooltip-color: $almostBlack;

.tooltip {
  &.top > .tooltip-arrow {
    border-top-color: $tooltip-color;
  }
  &.right > .tooltip-arrow {
    border-right-color: $tooltip-color;
  }
  &.bottom > .tooltip-arrow {
    border-bottom-color: $tooltip-color;
  }
  &.left > .tooltip-arrow {
    border-left-color: $tooltip-color;
  }

  > div.tooltip-inner {
    font-size: 0.9em;
    max-width: 300px;
    padding: 2px $general-spacing;
    white-space: initial;
    background-color: $tooltip-color;
    color: $pearl;
  }
}
