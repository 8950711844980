.case-card-container.card {
  padding: $general-spacing * 3;
  min-height: calc(100vh - 360px);
  position: relative;

  > .col-md-8 {
    padding: 0;
  }

  > .alert {
    margin-bottom: $general-spacing * 3;
  }
}

.form-control.patient-field {
  font-size: 1em;

  &.field-error {
    border-color: $ruby;
  }
}

label.field-error {
  color: $ruby;
}

label.emphasis {
  font-weight: 500;
}

.case-form-container {
  .alert {
    margin-bottom: $general-spacing * 3;
  }

  label {
    .input-container {
      display: inline-block;
    }
    input[type='radio'],
    input[type='checkbox'] {
      margin-right: 8px;
      width: auto;
    }
  }

  .fileUpload-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: $general-spacing * 3;

    .fileUpload-content-left {
      padding: 0;
    }
    .fileUpload-previews {
      display: flex;
      flex-wrap: wrap;

      .image-box {
        margin: $general-spacing * 2;
      }
    }
  }
}

.patient-field {
  width: 300px;
}

.tooth-viewer {
  border-radius: $border-radius;
}

.error-message-container {
  position: fixed;
  bottom: $general-spacing;
  right: $general-spacing;
  max-width: 300px;
  z-index: 2;

  #warning-submit {
    padding-top: 0;
  }

  .wizard-error-text {
    color: inherit;
  }
}

.case-form-controls {
  margin-top: $general-spacing;
  text-align: center;

  [data-position="prev"]{
    float: left;
    margin: 0;
  }

  [data-position="next"]{
    float: right;
    margin: 0;
  }

  .btn--primary + .btn--revise {
    margin-left: 120px;
  }

  .btn--secondary + .btn--primary + .btn--revise {
    margin-left: 0px;
  }
}

[data-position="save"]{
  float: right;
  margin: 0 !important;
}

.title-height {
  min-height: 48px;
  h1 {
    line-height: 40px;
  }
}

.question-box {
  margin-bottom: $general-spacing * 2;
  .title {
    padding-left: 0;

    &--warning {
      color: $ruby;
    }
  }

  .emphasis {
    font-weight: 500;
  }

  .btn-bar {
    margin-left: $general-spacing;
    .btn-ib {
      margin: 0;
    }
  }

  .alert--default {
    margin-top: $general-spacing;

    div {
      font-weight: 500;
    }
  }

  .de2-date {
    width: 70%;
    margin-right: 10px;
  }

  .de3-date {
    width: 160px;
  }
}

.address-container {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: $general-spacing;
  margin-top: $general-spacing;

  > div {
    margin-bottom: $general-spacing;

    &.emphasis {
      font-weight: 500;
    }
  }

  .address-list {
    label {
      clear: both;
      display: block;

      input {
        float: left;
        display: block;
      }
      .address-text {
        position: relative;
        margin-left: $general-spacing * 3;

        div.emphasis {
          font-weight: 500;
        }
      }
    }
  }
}

.padding-top{
  margin-top: $general-spacing*2;
}

@include for-size(desktop) {
  .case-form-controls {
    grid-template-columns: 1fr;
    .case-form-step {
      grid-column-start: 1;
    }

    .case-form-edit {
      justify-self: unset;
    }
  }
}

@include for-size(tablet) {
  .case-card-container.card {
    padding: $general-spacing;
  }

  .address-container {
    display: unset;
  }

  .case-form-container .fileUpload-container {
    display: unset;
  }

  .form-control.patient-field{
    width: 100%;
  }
}

@include for-size(phone) {
  .btn{
    min-width: 75px;
  }

  .btn--primary + .btn--revise {
    margin-left: 80px !important;
  }

  .btn:not(:last-child){
    margin-right: 0px ;
  }
}


