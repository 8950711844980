.simple-modal {
  transition: all 0.3s ease;

  .modal-dialog {
    transition: all 0.3s ease;

    &.size-lg {
      width: auto;
      @media (min-width: 768px) {
        width: 60%;
      }
    }

    .modal-content {
      margin: 205px 10px 0 10px;
      padding: 16px;

      .modal-body {
        padding: 0;
        overflow-x: visible;
        overflow-y: visible;

        .modal-header {
          padding: 0 0 16px 0;

          .modal-title {
            color: $sonicSilver;
            font-size: 19.2px;
            font-weight: 500;
            width: 95%;
          }

          .close {
            float: none;
            position: absolute;
            right: 0;
            top: 4px;
            font-size: 16px;
            color: $sonicSilver;
          }
        }

        .modal-content-body {
          display: flex;
          flex-direction: column;
          row-gap: 16px;
        }

        .modal-footer {
          padding: 16px 0 0 0;

          #modal_confirm_btn {
            margin-top: 0;
          }

          #modal_close_btn {
            margin-top: 0;
          }
        }
      }

      &.primary-theme {
        border-top: 5px solid $turquoise;

        .modal-body {
          .modal-footer {
            #modal_confirm_btn {
              background-color: $jade;
              border-color: $jade;

              &:hover {
                background-color: #1a9b9b;
                border-color: #1a9b9b;
              }
            }

            #modal_close_btn {
              background-color: #ffffff;
              color: #57b6bd;
              border: 1px $platinum solid;

              &:hover {
                border: 1px $jade solid;
              }
            }
          }
        }
      }

      &.error-theme {
        border-top: 5px solid $vermillion;

        .modal-body {
          .modal-footer {
            #modal_confirm_btn {
              background-color: $vermillion;
              border-color: $vermillion;

              &:hover {
                background-color: #b82637;
                border-color: #b82637;
              }
            }

            #modal_close_btn {
              background-color: #ffffff;
              color: $vermillion;
              border: 1px $platinum solid;

              &:hover {
                border: 1px $vermillion solid;
              }
            }
          }
        }
      }
    }
  }
}
