.account-activation {
  label {
    vertical-align: top;
    margin-left: 10px;
    width: 70%;
  }

  .address-option {
    text-align: left;
    padding-left: 150px;
  }
}
