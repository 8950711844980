/*
* BPP Sass Variables
*/

// Modal Button Colors
$bpp-btn-negative-border-color: #e0e0e0;
$bpp-btn-negative-text-color: #787e85;
$bpp-btn-negative-background-color: #ffffff;

$bpp-btn-negative-hover-border-color: #828488;

// Font sizes
$bpp-font-size-reg: 14px;
$bpp-font-size-md: 20px;

// Error Colors
$bpp-subtle-warning: #e74b3ae3;
$bpp-subtle-warning-new: #d43f3a;

// Disabled colors
$bpp-text-input-disabled-color: #afb2b5;
