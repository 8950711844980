.ipp_case_cancel_dropdown .select-container {
  margin: 0px;
  text-align: left;

  select {
    float: left;
  }
  select:focus,
  select:hover {
    border: 1px solid #74b9be;
  }

  .dropdown-option:hover {
    box-shadow: 0 0 10px 100px #1882a8 inset;
  }

  .case_cancel_text_margin {
    margin-top: 32px;
    margin-bottom: 4px;
  }

  .case_cancel_text_margin_bottom {
    margin-bottom: 32px;
  }
}

.ipp_case_cancel_dropdown {
  text-align: left;
  textarea {
    float: left;
    width: 95%;
  }
  label.margin-left-49 {
    margin-top: 10px;
    margin-left: 0px;
    font-weight: 500;
  }
}

@media screen and (max-width: 1024px) {
  .ipp_case_cancel_dropdown{
    .select-container{
      select {
        width: 100%;
      }

      .case_cancel_text_margin{
        input {
          display: inline-block;
        }
        label {
          display: inline;
        }
      }
    }
  }
  
}