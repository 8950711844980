@import '../../../src/bpp-variables';

.bpp-modal {
  .btn-negative {
    border-color: $bpp-btn-negative-border-color;
    color: $bpp-btn-negative-text-color;
    background-color: $bpp-btn-negative-background-color;

    &:active,
    &:hover,
    &:focus,
    &:focus-within {
      background-color: $bpp-btn-negative-background-color;
      border-color: $bpp-btn-negative-hover-border-color;
      color: $bpp-btn-negative-text-color;
    }

    &:active {
      box-shadow: inset 0 2px 5px rgba(0, 0, 0, 0.125);
    }
  }

  .ip-review-loader p {
    margin-top: 20px;
    font-size: 17px;
  }

  .select-container {
    width: 100%;
  }

  .select-container > select {
    width: 65%;
    float: right;
    margin-right: 25px;
    height: 34px;
    padding: 7px 7px 7px 9px;
    border-radius: 4px;
    border: 1px solid #ccc;
    color: #303235;
  }

  .modal-textarea-label {
    margin-top: 5px;
  }

  .modal-message-text {
    color: #999;
  }

  select option {
    padding: 5px;
  }

  .margin-top-15 {
    margin-top: 15px;
  }

  .margin-left-40 {
    margin-left: 40px;
  }

  .margin-left-49 {
    margin-left: 49px;
  }

  .margin-left-57 {
    margin-left: 57px;
  }

  .margin-right-9 {
    margin-right: 9px;
  }

  .dropdown-error {
    border: 1px solid $bpp-subtle-warning !important;
  }

  .clinical-review {
    .text-editor {
      width: 90%;
      margin: 0 auto;
      margin-top: 15px;
      display: inline-block;
      margin-left: 10px;

      &__error {
        border: 1px solid $bpp-subtle-warning;
      }

      .quill {
        .ql-editor {
          min-height: 170px;

          p {
            color: #303235;
          }

          &.ql-blank::before {
            font-style: normal;
            color: #999999;
          }
        }
      }
    }

    .character-count {
      text-align: right;
      margin-right: 25px;

      &__error {
        color: $bpp-subtle-warning;
      }
    }
  }

  .modal-header button.close[disabled] {
    cursor: not-allowed;
  }

  &.overflow-visible .modal-body{
    overflow: visible;
  }
}

.modal-header button.close[disabled] {
  cursor: not-allowed;
}

.table.table-striped.table-bordered.table-hover > tbody > tr > td {
  vertical-align: top;
}

.ql-editor.ql-table {
  padding: 0px;
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-all;
  word-break: break-word;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}

.ql-editor.ql-table.rich-logs {
  background-color: #ededed;
  border-radius: 10px;
  padding: 10px;

  &--teal{
    background-color: #2DA4AD;
    color: #FFF;
    margin: 20px;
    border-radius: 3px;

    p {
      color: #FFF !important;
      margin-bottom: 4px;
    }

    svg {
      fill: #FFF;
    }
  }
}

.ql-editor.ql-table.rich-logs p {
  color: #000;
}

.no-margin-bottom {
  margin-bottom: 0px;
}
.credit-hold {
  .form-group {
    margin-top: 15px;
    text-align: left;
    padding: 0 10px;
    overflow: visible;
  }

  .credit-hold-table {
    max-height: 270px;
    overflow-y: auto;
  }

  table {
    width: 100%;
    margin-top: 15px;
    border-collapse: separate;

    th {
      border-top: 1px solid #ddd;
      padding-top: 5px;
      padding-bottom: 5px;
      background-color: #f1f1f1;
      cursor: default;
    }

    th:first-child {
      border-left: 1px solid #ddd;
      border-top-left-radius: 10px;
      padding-left: 10px;
    }

    th:last-child {
      border-right: 1px solid #ddd;
      border-top-right-radius: 10px;
    }

    td {
      padding-top: 10px;
      padding-bottom: 10px;

      input {
        margin-left: 33px;
      }

      &:first-child {
        border-left: 1px solid #ddd;
        padding-left: 10px;
        width: 100px;
        vertical-align: top;
        padding-top: 15px;
        min-width: 140px;
      }

      &:last-child {
        border-right: 1px solid #ddd;
      }
    }

    tr:last-child td:first-child {
      border-bottom-left-radius: 10px;
    }

    tr:last-child td:last-child {
      border-bottom-right-radius: 10px;
    }

    tr:last-child td {
      border-bottom: 1px solid #ddd;
    }
  }
}
