$footer-text-color: #fcf8f8;

.footer {
  max-height: 100px;
  overflow: hidden;
  height: 100px;
  width: 100vw !important;
  max-width: 100%;
  padding-top: 10px;
  margin-top: 10vh;
  display: flex;
  font-size: 14px;
  flex-direction: row;
  background-color: #303235;

  .right {
    font-size: 12px;
    color: $footer-text-color;
  }

  .footer_logo {
    max-width: 140px;
  }

  .collection_1 {
    padding-left: 17%;
    width: 55%;
    height: 100%;
    justify-content: space-evenly;
  }

  .collection_2 {
    padding-right: 10%;
    margin-top: 5px;
    justify-content: start;
    .footer_item:hover {
      text-decoration: underline;
    }
    .footer_item:active,
    .footer_item:visited,
    .footer_item:focus {
      text-decoration: none;
    }
  }

  .collection_3 {
    margin-top: 5px;
    justify-content: start;
  }

  .footer_collections {
    display: flex;
    flex-direction: column;
    color: $footer-text-color;
    font-size: 14px;

    .footer_item {
      color: $footer-text-color;
      text-align: left;
      margin-bottom: 0px;
    }

    .header {
      font-weight: bold;
      margin: 0px;
    }
  }
}

@media screen and (max-width: 1000px) {
  .footer {
    justify-content: space-evenly;

    .footer_collections {
      width: auto;
    }

    .collection_1 {
      padding-left: 0px;
    }

    .collection_2 {
      padding-right: 0px;
    }
  }
}

@media screen and (max-width: 750px) {
  .footer {
    position: relative;
    padding-top: 20px;
    width: 100vw !important;
    max-height: 150px;
    margin-top: 100px;
    height: 150px;

    .footer_logo {
      display: none;
    }

    .collection_1 {
      position: absolute;
      height: auto;
      bottom: 15px;

      .right {
        margin-bottom: 0px;
      }
    }

    .collection_2 {
      margin-top: 0px;
    }

    .collection_3 {
      justify-content: start;
    }
  }
}

@media screen and (max-width: 300px) {
  .footer {
    max-height: 200px;
    width: 100% !important;
    margin-top: 50px;
    height: 200px;

    .collections {
      text-align: center;
    }

    .collection_2 {
      text-align: center;
      width: 60%;
    }
  }
}
