.label-validate-referral-code{
  display: inline-block;  
}

.div-validate-referral-code{
  padding-top: 20px;
  text-align: left;
}

.input-validate-referral-code{
  display: inline-block;  
  margin-left: 20px;
  width: 280px;
}