.dashboard {
  &-container {
    display: grid;
    grid-template-columns: 7fr 3fr;
    grid-template-rows: min-content 1fr;
    grid-gap: $general-spacing;
    grid-template-areas:
      'A B'
      'C B'
      'C B';
  }

  &__stats {
    grid-area: A;
  }

  &-side {
    grid-area: B;
  }

  &__stats {
    display: flex;
    border-top: 5px solid #2da4ad;

    &__horizontal-divider {
      display: none;
      width: 100%;
      margin-top: 8px;
      margin-bottom: 8px;
      border-top: 1px solid $platinum;
    }

    &__col {
      padding: 0 $general-spacing;
      text-align: center;
      width: 30%;
      border-right: 1px solid $platinum;
      cursor: pointer;

      > .stats__title {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 4em;

        > h3 {
          white-space: break-spaces;
        }
      }

      > .stats__title:hover {
        text-decoration: underline;
      }

      &:nth-child(5) {
        border-right: none;
      }
    }
  }
}

.provider-tier-container {
  position: relative;
  z-index: 1;

  &::before {
    content: '';
    z-index: -1;
    width: 100%;
    height: 50px;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #2da4ad;
    border-radius: $border-radius $border-radius 0 0;
  }

  .provider-tier-logo {
    height: 50px;
    display: flex;
    margin-top: -$general-spacing * 2;
    padding: $general-spacing 0;

    img {
      max-height: 100%;
      margin-right: $general-spacing;
    }
    span {
      display: flex;
      align-items: center;
      color: $pearl;
      line-height: 1.2em;
    }
  }

  .profile-image {
    float: right;
    canvas {
      margin-left: 10px;
    }
  }

  .provider-tier-progress {
    font-size: 0.75em;
    display: flex;
    align-items: center;
    margin-top: $general-spacing;

    progress {
      flex: 1;
    }

    img {
      margin-left: $general-spacing;
      display: inline-block;
      height: 2em;
    }
  }

  .provider-tier-note {
    font-size: 0.75em;
  }

  .provider-tier-footer {
    clear: both;
    font-size: 0.75em;
  }
}

.dashboard-side {
  display: grid;
  height: fit-content;
  grid-template-rows: min-content minmax(auto, max-content) minmax(auto, max-content);
}

.card.dashboard-list-container {
  padding: $general-spacing * 2 0;
  overflow: hidden;
}

#dashboard-announcement {
  aspect-ratio: 1 / 1;
  padding: 15px;

  iframe {
    width: 100%;
    height: 100%;
    border: none;
  }
}

.dashboard-list {
  list-style: none;
  padding: 0;
  margin: 0;
  overflow: auto;
  height: 715px;

  .no-records {
    display: none;
  }

  &__title {
    padding-left: $general-spacing * 2;
    .alert--icon {
      float: none;
      vertical-align: bottom;
    }
  }

  .dashboard-list__item {
    padding: $general-spacing * 0.5;
    padding-left: $general-spacing * 2;
    cursor: pointer;

    &__status {
      display: flex;
      justify-content: space-between;

      > span {
        font-weight: bold;
      }
    }

    &__date {
      margin-left: $general-spacing * 0.5;
    }

    &__info {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &:nth-child(odd) {
      background-color: $eggshell;
    }

    &:hover {
      background-color: $platinum;
    }

    &:focus-within {
      background-color: $soot;
    }
  }

  .dashboard-list__message {
    padding-left: $general-spacing * 2;
  }
}

.dashboard-container {
  .content__header {
    display: none;
  }
  .hide-on-mobile--top-border {
    min-block-size: auto;
  }
  .card.card--top-border {
    border: 1px solid white;
  }
  .div-table--content {
    display: block;
    padding-top: 10px;
  }
  .pagination__container {
    display: none;
  }
  span.arrow.dsc {
    display: none;
  }
  .div-table--content:nth-last-child(-n + 6):last-child {
    border-bottom: 1px solid #e1e2e2;
  }

  .div-table--heading-container {
    position: sticky;
    top: 57px;
    background-color: white;
    z-index: 1;
    margin-top: -5px;
  }

  .dashboard-title {
    position: sticky;
    top: 0px;
    background: white;
    z-index: 1;
  }
}

.dashboard-stats {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  font-size: 0.9em;
  white-space: nowrap;

  &__stats {
    &__col {
      text-align: center;
      width: auto;

      > .stats__title {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 2em;
        font-weight: bold;
      }
    }
  }
}

.dashboard-case-container {
  border: 1px solid #e1e2e2;
  border-radius: 3px;
  background: white;
}

.dashboard-activity {
  max-height: 164px;
}
.empty-dashboar-list-msg {
  font-family: 'Sofia Pro Regular', sans-serif;
  font-size: 24px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  margin-top: 25%;
}
.dashboard-carousel {
  height: min-content;

  p {
    text-align: left;
  }

  h3 {
    text-align: left;
    margin: 16px 0px;
  }

  .carousel-root {
    padding: 0px 16px;
    position: relative;

    .control-arrow {
      width: 36px;
      height: 36px;
      border-radius: 3px;
      background: rgba(0, 0, 0, 0.4) !important;
      top: 3em;
      opacity: 0.6;
    }

    .control-arrow:hover {
      opacity: 1;
    }

    .read-or-hide {
      color: #2da4ad;
      font-weight: bold;
    }

    .read-or-hide:hover {
      text-decoration: underline;
    }

    .carousel-slider {
      position: unset;

      .slider-wrapper {
        padding: 1px;
        margin-left: -1px;
        margin-bottom: 33px;
        width: 101%;

        .announcement-container {
          a {
            display: block;

            img {
              border-radius: 6px;
            }
          }
        }
      }

      .control-dots {
        width: 92%;

        .dot {
          width: 12px;
          height: 12px;
          box-shadow: none;
          background: #d0cece;

          &.selected {
            background: #4f4f4f;
          }
        }
      }
    }
  }
}

@include for-size(desktop) {
  .dashboard__stats__col > .stats__title {
    height: 4em;
  }
}

@include for-size(tablet) {
  #dashboard-announcement {
    aspect-ratio: unset;
    max-height: unset;
    height: 400px;
  }
  .dashboard-container {
    display: flex;
    flex-direction: column;

    .case-gen2-text {
      white-space: nowrap !important;
    }
  }
  .card.dashboard-list-container {
    max-height: 50vh;
  }
  .dashboard-side {
    order: 3;
  }
  .dashboard-case-container {
    order: 2;
    height: auto;

    &:has(.empty-dashboar-list-msg) {
      height: 200px;
    }
  }
}

@include for-size(phone) {
  .statuses-result-container {
    width: 100%;
    overflow: hidden;
  }

  .div-table--item-2 .clinical--icon {
    margin: unset;
    margin-top: 1em;
  }

  .pagination__container {
    flex-direction: column;
  }
  .div-table--content {
    padding: 10px 10px 10px 0px;
    margin: auto;
    width: 100%;
    border: none;
    border-bottom: 1px solid #e1e2e2;
  }

  .empty-dashboar-list-msg {
    font-size: 1em;
    margin-top: 7%;
  }

  .dashboard__stats {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .dashboard__stats__col {
    padding: 0px 10px 0px 0px;
    flex-basis: calc(50% - 10px);
    margin: 5px;

    .stats__title h3 {
      font-size: 1em;
    }
  }

  .dashboard__stats__col:nth-child(4) {
    order: 1;
  }
  .dashboard__stats__col:nth-child(5) {
    order: 2;
    border-right: 1px solid $platinum;
  }
  .dashboard__stats__col:nth-child(3) {
    order: 3;
    border-right: none;
  }
  .dashboard__stats__horizontal-divider {
    display: block;
    order: 4;
  }
  .dashboard__stats__col:nth-child(1), .dashboard__stats__col:nth-child(2) {
    order: 5;
  }
  .dashboard__stats__col:nth-child(2) {
    border-right: none;
  }

  .dashboard__stats__col:nth-child(n + 3) {
    flex-basis: calc(33.33% - 10px);
  }

  .footer {
    margin-top: 10px;
  }
}
