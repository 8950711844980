.multiple_checkboxes {
  padding-left: 1px;
  display: grid;
  grid-template-rows: 1fr 1fr 1fr;
  grid-auto-flow: column;

  p {
    margin: 0px;
  }

  label {
    color: #707070;
  }
}

@media screen and (max-width: 450px) {
  .multiple_checkboxes {
    grid-auto-flow: unset;
  }
}
