.tooth-rating {
  display: flex;
  flex-direction: column;
  row-gap: 4px;

  &__teeth {
    display: flex;

    &--centered {
      justify-content: center;
      column-gap: 24px;
    }

    &--show-labels {
      padding: 16px 0;
    }

    & .teeth__tooth-icon {
      cursor: pointer;
      text-align: center;
      max-width: 100px;

      &.fixed-width {
        width: 100px;

        @media screen and (max-width: 719px) {
          width: 73px;
        }

        @media screen and (max-width: 600px) {
          width: 50px;
        }
      }

      & .tooth-icon__label {
        cursor: pointer;
        text-align: center;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;

        &--selected {
          color: #2da4ad;
        }

        &--disabled {
          cursor: not-allowed;
        }


        @media screen and (max-width: 719px) {
          font-size: 12px;
          line-height: 20px;
        }

        @media screen and (max-width: 600px) {
          font-size: 10px;
          line-height: 12px;
        }
      }
    }
  }
}
