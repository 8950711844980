.fileupload-progress {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-basis: max-content;
  flex-wrap: wrap;
  padding: 8px 12px;

  > div {
    margin: 4px;
  }

  &__main {
    flex: 1;
    display: flex;
    justify-content: space-between;
  }

  .progress {
    flex-basis: 100%;
  }

  &:nth-child(even) {
    background-color: #f8f8f8;
  }

  .fileupload-abort {
    margin-left: 4px;
    cursor: pointer;
    color: #e1e2e2;

    &:hover {
      color: #707070;
    }

    &:active {
      color: #c4c4c4;
    }
  }
}
