.modal-condense {
  padding-left: 100px;
  padding-right: 100px;
}

.modal-condense-top {
  padding-left: 100px;
  padding-right: 10px;
}

.no-notes {
  padding-bottom: 20px;
}

.text-notes {
  width: 100%;
  border-radius: 5px;
  padding: 5px 10px;
  margin-bottom: 20px;
}

.tr-anchor {
  position: relative;
}

.tr-holder {
  position: absolute;
  right: -58px;
  font-size: 20px;
  cursor: pointer;
}

i.fa-pencil {
  margin-right: 5px;
}

.tr-holder i.fa-pencil:hover {
  color: #32a1d9;
}

.tr-holder i.fa-trash-o:hover {
  color: red;
}

.edit-text {
  color: #32a1d9;
}

.delete-text {
  color: red;
}

.text-message {
  padding: 15px 0px;
}

.btn.btn-space-general {
  margin: 0px 10px;
}

.table-action {
  font-size: 20px;
  background-color: transparent;
  border: none !important;
  width: 73px;
}

.modal-condense-top {
  max-height: 480px;
  overflow-y: scroll;
  margin-bottom: 15px;
  padding-bottom: 0px;
}

.table.table-note > thead > tr > th.table-comments,
.table.table-note > thead > tr > th.table-date-time,
.table.table-note > thead > tr > th.table-created-by {
  border: 1px solid #ddd;
  border-bottom: 2px solid #ddd;
}

.table.table-note > tbody > tr > td {
  border: 1px solid #ddd;
  vertical-align: top;
}

.table.table-note > tbody > tr:nth-child(odd) > td:nth-child(odd),
.table.table-note > tbody > tr:nth-child(odd) > td:nth-child(2) {
  background-color: #f9f9f9;
}

.table-date-time {
  width: 160px;
}

td.table-action > span > .fa.fa-pencil:hover {
  color: #32a1d9;
  cursor: pointer;
}

td.table-action > span > .fa.fa-trash-o:hover {
  color: red;
  cursor: pointer;
}

.wrap-text {
  word-wrap: break-word;
}

.table-note {
  .ql-editor {
    padding: 0;
  }
}
