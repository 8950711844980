.icon {
  &--primary {
    color: $jade;
  }

  &--warning {
    color: $revise-default;

    &:hover {
      color: $revise-hover;
    }
    &:active {
      color: $revise-active;
    }
  }
}

.fa-circle-xmark.white-bg {
  background-color: #fff;
  border-radius: 50%;
}