@import '../../../../bpp-variables';

.template {
  display: inline-block;
  padding-left: 8px;
  padding-right: 8px;
  vertical-align: top;
  margin-top: 0px;

  &-text {
    margin-left: 6px;
  }

  &-checkbox {
    cursor: pointer;
    display: inline-block;
  }

  &-radio {
    text-align: left;
    margin-top: 8px;
    margin-left: auto;
    margin-right: auto;
    width: 100px;

    .radio-spacing {
      display: block;
      width: 100px;
      margin-bottom: 8px;
    }
  }
}

.cad-container .dxf-error {
  color: $bpp-subtle-warning;
  margin-bottom: 5px;
  text-align: center;
}
