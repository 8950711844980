.selection-area {
  display: flex;
  align-items: center;
  margin-left: -$general-spacing * 2;
  padding: $general-spacing;
  cursor: pointer;

  &--primary {
    background-color: $primary-active;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.05), 0 4px 4px 0 rgba(0, 0, 0, 0.05);

    p {
      color: $pearl;
    }

    i {
      color: $pearl;
    }

    &:hover {
      background-color: $primary-hover;
    }
    &:active {
      background-color: $primary-active;
    }
    &:disabled {
      background-color: $primary-disabled;
    }
  }

  &--content {
      padding-left: $general-spacing;
    flex: 85%;

    p {
      margin: 0px;
    }
  }

  &--icon {
    flex: 15%;
    text-align: right;
    padding: $general-spacing;
  }
}
