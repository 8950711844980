// For removing bootstrap 3's padding
.no-gutter > [class*='col-'] {
  padding-left: 0;
  padding-right: 0;
}

.content-body {
  display: flex;

  .case-summary-container {
    align-self: flex-start;
    position: sticky;
    top: 65px;
    min-width: 250px;
    max-width: 350px;
    max-height: calc(100vh - 65px);
    overflow-y: auto;
    overflow-x: hidden;
    margin-right: $general-spacing;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .case-detail-container {
    width: 100%;

    .card:first-child {
      margin-top: 0;
    }

    .case-gen2 {
      float: none;
    }
  }

  .account-summary-container {
    align-self: flex-start;
    position: sticky;
    top: 65px;
    min-width: 250px;
    max-width: 350px;
    max-height: calc(100vh - 65px);
    overflow-y: auto;
    overflow-x: hidden;
    margin-right: $general-spacing;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }

    img {
      display: inline-block;
      height: 1em;
      margin-right: 8px;
    }
    @media (max-width: 760px) {
      margin-bottom: 10px;
    }
  }

  .account-detail-container {
    width: 100%;

    .card:first-child {
      margin-top: 0;
    }
  }

  .fa.fa-exclamation {
    color: $ruby;
  }
}

.patient {
  &-info__personal-info {
    display: flex;
    padding-bottom: $general-spacing;
    margin-bottom: $general-spacing;
    border-bottom: 1px solid $platinum;

    > .profile-icon-container {
      margin-right: $general-spacing;
    }
    .patient-info__detail {
      overflow: hidden;
      :first-child {
        font-weight: bold;
        color: $almostBlack;
        font-size: 1.2em;
      }
    }
  }

  &__initials {
    width: 80px;
    height: 80px;
    font-size: 2.3em;
    line-height: 80px;
    color: $pearl;
    text-align: center;
    background-color: $sonicSilver;
    border-radius: $border-radius;
  }

  &__silhouette {
    font-size: 4em;
  }
}

.patient-action-container {
  .patient-action {
    display: block;
    text-align: left;
    background: none;
    border: none;
    padding-left: 0px;
    padding-right: 0px;
    color: $almostBlack;

    &:hover {
      color: $primary-hover;
    }

    &:active {
      color: $primary-active;
    }

    &:disabled {
      color: $dark-disabled;
      cursor: not-allowed;
    }

    > i {
      margin-right: $general-spacing;
    }

    > span {
      color: inherit;
    }
  }
}

.case-tab {
  &__item-container:not(:last-child) {
    margin-bottom: $general-spacing;
  }

  &__item {
    cursor: pointer;
    display: flex;
    position: relative;
    justify-items: center;
    align-items: center;
    color: $almostBlack;

    &:hover {
      color: $primary-hover;
    }

    &--active {
      color: $primary-default;
      font-weight: bold;

      &:hover {
        color: $primary-default;
      }
    }

    &:active {
      color: $primary-active;
    }

    svg {
      width: 1em;
      margin-right: $general-spacing;
      pointer-events: none;
    }
  }

  &__sub-item {
    margin-left: 20px + $general-spacing * 2;
  }
}

.case-detail {
  &__header {
    display: inline;
    margin-right: $general-spacing;
  }

  &__status-header {
    & > div,
    & > span {
      font-size: 0.8em;
    }
  }

  &__address {
    display: flex;
    > span {
      margin-right: $general-spacing * 0.5;
    }
  }

  &__id {
    margin-right: 5px;
  }

  &__row {
    border-top: 1px solid $platinum;
    padding: $general-spacing * 2 0;
    margin: 0 $general-spacing * 3;

    &:first-child {
      margin-top: $general-spacing;
      border-top: none;
    }

    &--flex {
      display: flex;
      justify-content: space-between;
    }

    &.case-detail__row--inner {
      border-top: 1px solid $platinum;
      display: flex;
      justify-content: space-between;
      margin: 0;
    }

    &.case-detail__row--inneredit {
      border-top: 1px solid $platinum;
      justify-content: space-between;
      margin: 0;
    }

    &.case-detail__row--nopadd {
      border-top: 1px solid $platinum;
      justify-content: space-between;
      margin-bottom: 0 0 10px 0 !important;
      padding: 0;
    }

    &.case-detail__row--border-top {
      border-top: 1px solid $platinum;
    }

    &.case-detail__row--no-border {
      border-top: none;
    }

    .clinical-tag {
      background: rgba(216, 250, 101, 1);
      border-radius: 6px;
      color: $almostBlack;
      padding: 4px 12px 4px 12px;
      margin-left: 12px;
    }
  }

  &__col {
    flex: 1 1;
    border-right: 1px solid $platinum;
    padding: 0 $general-spacing;

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      border-right: none;
      padding-right: 0;
    }

    .mg-bottom {
      margin-bottom: $general-spacing * 2;
    }
  }

  &__document-link {
    display: inline-block;
    width: fit-content;
    text-decoration: underline;
    cursor: pointer;
    padding: $general-spacing * 0.5 0;
    border: none;
    background-color: $pearl;

    > i {
      pointer-events: none;
      margin-right: $general-spacing;
    }

    &:active {
      color: $jade;
    }

    &:hover {
      color: $wave;
    }

    &.case-detail__document-two-lines {
      text-align: left;
      padding-left: 20px;
      text-indent: -20px;
    }
  }

  &__summary {
    margin: 0 $general-spacing * 3;
    .case-detail__row {
      min-height: 200px;
    }
  }

  &__status {
    flex: 1 1;
    text-align: center;
    margin-right: 0;

    &-container {
      display: flex;
      justify-content: space-between;
    }

    &__title {
      height: 3em;
      font-weight: bold;
    }

    &__icon {
      width: 5em;

      .all {
        fill: none;

        &.st0 {
          fill: #fff;
          stroke: none;
        }
      }

      .icon_detail {
        fill: $turquoise;
      }

      &--primary {
        .st1 {
          stroke: $turquoise;
        }
      }

      &--inbrace {
        .cls-1 {
          fill: $turquoise;
        }
      }

      &--active {
        stroke: $turquoise;
      }

      &--warning {
        color: $orange;
        .icon_status {
          stroke: $orange;
        }
      }

      &--alert {
        color: $ruby;

        .icon_status {
          stroke: $ruby;
        }
      }

      color: $turquoise;
      &--inactive {
        color: $soot;

        .icon_status {
          stroke: $soot;
        }
      }
    }

    &__text--alert > div {
      color: $ruby;
    }
  }

  .progress {
    height: $general-spacing;
    border-radius: $border-radius;
    margin-bottom: $general-spacing * 2;

    .progress-bar {
      background-color: $turquoise;
    }
  }

  .approve-revise-buttons {
    margin-top: $general-spacing;
  }
}

.icon_status {
  stroke-width: 4;
}

.icon_detail {
  stroke-width: 2;
}

.case-history__item {
  display: grid;
  grid-template-columns: max-content auto;
  margin-bottom: $general-spacing;

  > span {
    margin-right: $general-spacing;
  }

  &:last-child {
    margin-bottom: 0;
  }

  &__comment {
    grid-column-start: 2;
  }
}

.comment-container {
  padding: $general-spacing * 2;
  background-color: #ecf5f5;
  border-radius: $border-radius;
  margin: $general-spacing 0;
  word-wrap: break-word;
  word-break: break-all;

  .comment__title {
    margin-bottom: $general-spacing;
    font-weight: bold;
  }

  .ql-editor {
    padding: 0;
    a {
      color: #337ab7;
    }
    a:hover {
      color: #2da4ad;
    }
  }
}

@include for-size(desktop) {
  .label-dropdown-container {
    margin-bottom: $general-spacing;
  }

  .case-detail__row.case-detail__row--inner,
  .case-detail__row--flex {
    display: block;
  }

  .case-detail__col {
    border-right: none;
    padding: 0;
    margin-bottom: $general-spacing;
  }

  .case-progress {
    .progress {
      display: none;
    }
  }

  .case-detail__status {
    display: none;

    &--active {
      display: block;
    }

    &__title {
      height: auto;
    }
  }

  .case-history__item {
    display: block;
  }
}

.modal-error {
  p {
    color: $ruby;
  }
}

@include for-size(tablet) {
  .content-body {
    display: unset;
    .case-summary-container {
      position: unset;
      max-height: unset;
      max-width: unset;
      margin-right: 0;
    }
    .account-summary-container {
      position: unset;
      max-height: unset;
      max-width: unset;
      margin-right: 0;
    }
    .case-detail-container {
      width: 100%;
      margin-top: $general-spacing * 2;
    }

    .case-gen2 {
      font-size: 0.9em;
      padding: 2px 4px;
      display: table;
      margin-left: 0px;
    }
  }
}

@include for-size(phone) {
  .case-detail {
    &__row {
      margin: 0 $general-spacing * 2;
    }

    &__summary {
      margin: 0 $general-spacing * 2;
    }
  }
}
