.page__backdrop--white {
  background-color: #fff;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
}

.page__backdrop--torquise-gradient {
  background-image: linear-gradient(to bottom, #7fcfce, #3ea8d8 100%);
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 10000;
}

.page__backdrop--torquise-gradient + .privacy__footer {
  color: #fff;
}

.page__backdrop--dim-black {
  background-color: rgba(0, 0, 0, 0.3);
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
}

.modal__container {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: hsla(0, 0%, 100%, 0.93);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  padding: 20px 40px;
  border-radius: 20px;
  color: #3c3a3a;
  max-width: 650px;
}

.modal__heading--spacing {
  margin-bottom: 10px;
}

.ipp-light .card.card--message.card--customer-agreement {
  max-width: 600px;
  p {
    margin: 1em 0 1em 0;
  }
}
