@import "../../../bpp-variables";

.wire-selection-container {
  .process_tracker ul.workflow li {
    p {
      left: -65px;
      width: 145px;
    }
    &:first-child p {
      margin-top: 16px;
    }
  }

  &__unassigned {
    height: 520px;
  }
}

.wire-selection-body {
  max-height: 65vh;
  overflow-y: auto;
}

.wire-selection-process {
  padding: 20px 40px;

  &.process-box {
    margin: 30px 60px;
    display: block;
    width: auto;
  }

  .tooltip-inner {
    max-width: 300px;
    white-space: normal;
    word-break: break-all;
    word-wrap: break-word;
  }

  .wire-selection-section {
    width: 80%;
    margin: 0 auto;

    .item-details-quantity-alt {
      display: inline-block;
    }

    &__error {
      color: $bpp-subtle-warning;
    }
  }

  .wire-selection-review-item {
    padding: 20px 0;
    border-bottom: 1px solid #9ea3a8;

    &:last-child {
      border-bottom: none;
    }

    &__program {
      color: #303235;
      margin-left: 20px;
    }
  }
  .wire-selection-review-item__radios {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-gap: 8px;
    input {
      margin-right: 8px;
    }
    label {
      display: block;
      max-width: fit-content;
    }
    .dxf-error {
      color: $bpp-subtle-warning;
      margin-left: 20px;
    }
    i {
      cursor: pointer;
      &.fa-trash {
        color: $bpp-subtle-warning;
      }
    }
  }

  .wire-selection-review__assignment {
    max-width: 500px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    .css-2b097c-container {
      flex-grow: 1;
      margin-left: 10px;
    }
  }
  .item-details-part-id {
    width: 15%;
  }
  .item-details-description {
    width: 70%;
  }
  .item-details-quantity {
    width: 10%;
  }

  .wo-upload {
    width: 100%;
    text-align: center;

    * {
      display: block;
    }

    .btn {
      margin: 0 auto;
      background-color: transparent;
      width: 200px;
      color: #333;
      border: 1px solid #333;
      margin-top: 10px;
    }

    .wo-file {
      display: flex;
      margin: 0 auto;
      align-items: center;
      justify-content: center;
      margin-top: 5px;

      .wo-link {
        cursor: pointer;
        text-decoration: underline;
      }

      i {
        margin-left: 5px;
        cursor: pointer;

        &.fa-trash {
          color: $bpp-subtle-warning;
        }
      }
    }
    .custom-wo-error {
      color: $bpp-subtle-warning;
      margin-top: 5px;
    }
  }

  .fa-file-pdf-o {
    padding-left: 5px;
  }
}

.wire-selection-failure {
  text-align: center;
  width: 80%;
  margin: 0 auto;

  &__steps {
    margin-top: 10px !important;
    margin-left: 30px !important;
    text-align: left !important;
  }
}

.wire-selection-links {
  margin-top: 10px;
  margin-bottom: 20px;
}

.bmf-link {
  width: 100%;
  text-align: center;

  span:last-child {
    padding-left: 5px;
  }
}
