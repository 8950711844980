.btn-ib {
  font-size: $body-font-size;
  color: $sonicSilver;
  border-radius: $border-radius;
  border: 1px solid $platinum;
  background-color: $pearl;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);
  margin: 4px;
  padding: 8px;

  &:hover {
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.4);
  }

  &.btn-ib--active {
    color: $pearl;
    background-color: $turquoise;
    border-color: $turquoise;
    box-shadow: none;
  }

  &.btn-ib--disabled {
    color: $pearl;
    background-color: $platinum;
    border-color: $platinum;
    box-shadow: none;
    cursor: not-allowed;
  }

  &.btn-ib--active.btn-ib--disabled {
    color: $pearl;
    background-color: $primary-disabled;
    border-color: $primary-disabled;
    box-shadow: none;
  }

  &:active {
    left: 1px;
    top: 1px;
    z-index: 1;
  }
}
