.line-division {
    margin: 0px $general_spacing*2 ;
    min-height: 1px;

    &--primary{
        border-bottom: 1px solid $platinum;
    }

    &--provider{
        border-bottom: 1px solid $platinum;
        margin: $general_spacing*2 0px $general_spacing*2 0px  ;
    }

    &--limited{
        display: inline-block;
        border-bottom: $general-spacing*0.5 solid $primary-active;
        margin-bottom: $general-spacing;
    }
}
