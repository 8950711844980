.gradient-progress {
  appearance: none;
  height: 5px;
  position: relative;

  &::-webkit-progress-bar {
    background-color: $platinum;
    border-radius: $border-radius;
  }

  &::-webkit-progress-value {
    background-image: linear-gradient(to right, $turquoise 0%, $electricGreen 100%);
    border-radius: $border-radius;
    transition: width 0.25s ease-out;

    &:after {
      content: '';
      background: red;
      position: absolute;
      top: 0;
      width: 5px;
      height: 5px;
    }
  }
}
