@keyframes colorCycle {
  0% {
    color: red;
  }

  20% {
    color: orange;
  }

  40% {
    color: yellow;
  }

  60% {
    color: green;
  }

  80% {
    color: blue;
  }

  100% {
    color: purple;
  }
}

.new-icon {
  animation: colorCycle 8s infinite linear;

  text-decoration: none;
}

.csv-icon-file {
  margin-left: 5px;
}
