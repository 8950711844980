$window-width: 400px;

.note {
  &-window {
    position: fixed;
    right: 20px;
    bottom: 20px;
    width: $window-width;
    z-index: 120;

    .card {
      display: flex;
      flex-flow: column;
      height: 100%;
      box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.4);

      >div {
        flex: 0 1 auto;

        &.note-react-quill {
          flex: 1 1 auto;
        }
      }
    }
  }

  &-window--max {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    backdrop-filter: blur(4px);

    .card {
      position: absolute;
      width: 90vw;
      height: 90vh;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    .ql-editor {
      height: calc(80vh - 250px);
      max-height: unset;
      min-height: unset;
    }
  }

  &-window--min {
    .note {

      &-subject,
      &-react-quill,
      &-footer {
        display: none;
      }
    }
  }

  &-subject,
  &-react-quill {
    margin: 0 $general-spacing * 2 0 $general-spacing * 2;

    >h5 {
      text-align: left;
      color: #707070;
      margin: 10px 0;
      font-size: 1em;
      font-weight: 500;
    }
  }

  &-heading {
    margin: $general-spacing * 2;

    >h3 {
      display: inline;
    }
  }

  &-container-controls {
    float: right;

    i {
      padding: 0 $general-spacing;
      cursor: pointer;
    }
  }

  &-subject {
    .input-container {
      max-width: none;

      input {
        border-radius: 4px;
        color: #707070;
      }
    }
  }

  &-react-quill {
    .text-editor {
      border-radius: 4px;
      border: 1px solid #e1e2e2;

      .quill {
        .ql-toolbar.ql-snow {
          border: none;
        }

        .ql-container.ql-snow {
          font-size: 1em;
          border: none;
          border-top: 1px solid $platinum;
        }

        .ql-editor.ql-blank::before {
          color: $soot;
          font-style: normal;
          font-size: 1em;
        }


        .ql-editor {

          ul li,
          ol li {
            color: $sonicSilver;
          }

          a {
            color: #337ab7;

            &:hover {
              color: #2da4ad;
            }
          }
        }

      }
    }
  }

  &-footer {
    display: flex;
    justify-content: flex-end;
    margin: $general-spacing;

    >button {
      margin: 0;
    }
  }

  &-counter {
    margin-right: $general-spacing * 2;
    text-align: right;
  }
}

@include for-size(tablet) {
  .note-window {
    width: 90%;
  }

  .note-window--max {
    width: 100%;
  }
}

.patient-notes-modal {
  cursor: default;

  .modal-header {
    padding: 32px 0 0 0;
  }

  .modal-body {
    padding-bottom: 0;
    overflow: visible;
  }

  .note-subject,
  .note-react-quill {
    margin: 0;
  }
}

.case-view-note-modal .ql-editor {
  height: 145px;
}