.sidebar {
  color: $pearl;
  max-height: 100%;
  overflow-y: auto;
  padding-bottom: 51px;
  scrollbar-width: none;
  text-align: center;

  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  &__menu {
    padding-bottom: 64px;

    &.has-message-info,
    &.has-credit-hold-message {
      padding-bottom: 200px;

      @media screen and (max-width: 600px) {
        padding-bottom: 300px;
      }
    }

    &.has-message-info.has-credit-hold-message {
      padding-bottom: 300px;

      @media screen and (max-width: 600px) {
        padding-bottom: 500px;
      }
    }
  }

  &__menu__item {
    cursor: pointer;
    padding: $general-spacing;
    font-size: 1.5em;
    display: flex;
    align-items: center;
    color: $almostBlack;
    line-height: 1.2em;
    transition: none;

    &__label {
      color: inherit;
    }

    &:first-child {
      margin-bottom: $general-spacing;
    }

    &:hover {
      background-color: $wave;
    }

    &:active {
      background-color: $jade;
    }

    &--active {
      background-color: $jade;
      &:hover {
        background-color: $jade;
      }
    }

    svg {
      margin: 0 $general-spacing * 2 0 $general-spacing;
      width: 35px;
      height: 35px;

      .cls-1 {
        fill: $almostBlack;
      }
    }

    &:hover,
    &:active,
    &--active {
      color: $pearl;
      svg .cls-1 {
        fill: $pearl;
      }
    }
  }
}
