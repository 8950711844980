a {
  color: $hyperlink-dark-active;
  
  &:hover {
    color: $hyperlink-dark-hover;
  }
  &:active {
    color: $hyperlink-dark-active;
  }
  &:disabled {
    color: $hyperlink-dark-disabled;
  }

  &.white {
    color: $hyperlink-white-active;
    &:hover {
      color: $hyperlink-white-hover;
    }
    &:active {
      color: $hyperlink-white-active;
    }
    &:disabled {
      color: $hyperlink-white-disabled;
    }
  }

  &.mail, .blue {
    color: $hyperlink-blue-active;
    &:hover {
      color: $hyperlink-blue-hover;
    }
    &:active {
      color: $hyperlink-blue-active;
    }
    &:disabled {
      color: $hyperlink-blue-disabled;
    }
  }

  &.midnight {
    color: $hyperlink-nightblue-active;
    font-weight: 700;
    
    &:hover {
      color: $hyperlink-nightblue-hover;
    }
    &:active {
      color: $hyperlink-nightblue-active;
    }
    &:disabled {
      color: $hyperlink-nightblue-disabled;
    }
  }
}

.midnight {
  color: $hyperlink-nightblue-active;
  font-weight: 700;
  
  &:hover {
    color: $hyperlink-nightblue-hover;
  }
  &:active {
    color: $hyperlink-nightblue-active;
  }
  &:disabled {
    color: $hyperlink-nightblue-disabled;
  }
}
