.process-box-row {
  margin-top: 20px;
}

.link-button {
  background: none !important;
  border: none;
  padding: 0 !important;
  /*optional*/
  font-family: arial, sans-serif;
  /*input has OS specific font-family*/
  color: #069;
  text-decoration: underline;
  cursor: pointer;
}

.ip-files {
  width: 50%;
}

.file-span {
  color: #303235;
  cursor: pointer;
  display: inline-block;
}

.file-span a:focus,
a:focus {
  text-decoration: none;
  color: #303235;
}

.process-box-intro {
  .button-panel.center-text {
    width: 510px;
    margin: 0 auto;
    text-align: center;

    button {
      width: 150px;
      margin: 10px 10px 0 10px;
    }
  }
}