.provider-tier-logo {
  &--sm {
    max-height: 30px;
  }

  &--lg {
    max-height: 90px;
    margin-right: $general-spacing * 2;
    margin-bottom: $general-spacing;
  }
}

.provider-cards-container {
  display: flex;
  padding: 0px !important;
  margin-top: 40px !important;
  position: relative;
  background-color: #f8f8f8;
}

.title-info {

  h2 {
    display: inline-block;
    margin-right: $general-spacing;
  }

  span {
    font-style: italic;
    font-weight: 500;
  }
}

.provider-tier-full-progress .provider-tier-item {
  padding-top: $general-spacing * 2;
  margin-top: $general-spacing * 2;
  border-top: 1px solid $platinum;
}

.provider-tier-progress-bar {
  position: relative;
  margin-top: $general-spacing;
  margin-bottom: $general-spacing * 4;

  progress {
    width: 100%;
    height: 8px;
  }
  &__item {
    position: absolute;
    top: 0;
    transform: translateX(-50%);
    text-align: center;
    div {
      font-size: 0.9em;
      font-weight: 500;
    }
  }
}

.progress-divider {
  width: 5px;
  height: 8px;
  background-color: #707070;
  margin: 9px 2px 13px;
}

.provider-tier-logo-placeholder {
  margin: 30px 8px;
}

.provider-tier-item {
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  flex: 1 1 auto;
  border-right: 1px solid $platinum;
  position: relative;
  border-radius: 5px;
  background-color: #fff;
  
  .tier-banner {
    width: calc(100% + 2px);
    background-color: $turquoise;
    position: absolute;
    left: -1px;
    top: 0;
    color: #fff;
    text-align: center;
    padding: 5px;
    border-radius: 5px 5px 0 0;
    font-weight: bold;
  }

  .padding-wrapper {
    padding: 30px 20px;
  }
  
  &:last-child {
    border-right: none;
  }
  
  &.current {
    box-shadow: 0 4px 4px 1px $soot;
    margin: -30px -20px;
    z-index: 5;
    background-color: #fff;
    border-left: 1px solid $platinum;
    border-right: 1px solid $platinum;
  }


  .provider-tier-item__inner {
    text-align: center;
    border-right: none;
    padding: 16px;
  }

  img {
    display: block;
    margin: 10px auto;
    width: 50px;
  }

  ul {
    padding-left: 2px;

    li {
      list-style-position: outside;
      margin-left: 1em;
    }
  }
}

.provider-tier-progress-bar__item {
  &:nth-child(2) {
    left: 3%;
  }
  &:nth-child(3) {
    left: 34%;
  }
  &:nth-child(4) {
    left: 65%;
  }
  &:nth-child(5) {
    left: 97%;
  }
 }

.progress-approval-count {
  margin: 10px 0 5px;
}

@include for-size(tablet) {
  .provider-tier-current-status {
    flex-direction: column;
  }

  .provider-cards-container {
    border: none;
  }

  .provider-tier-item {
    flex-direction: column;
    border: 1px solid $platinum;
    margin-top: 5px;

    &.current {
      background-color: #fff;
      margin: 5px 0 0 0;
      border-left: 1px solid $platinum;
      border-right: 1px solid $platinum;
    }
  }

  .provider-cards-container {
    flex-wrap: wrap;
  }

  .provider-tier-item {
    flex: 1 0 100%;
  }

  .padding-wrapper {
    padding: 0;
  }
}