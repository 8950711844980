.small-gap {
  margin-bottom: 20px;
}

.prospect-title {
  font-size: 15.4px;
  margin-right: 20px;
  margin-bottom: 16px;
  border-top: 1px solid #e1e2e2;
  font-weight: bold;
  margin-top: 5px;
  padding-top: 16px;
  margin-top: 20px !important;

  &--no-border {
    border-top: none;
  }

  &--fake-section {
    margin-top: 0px !important;
    padding-top: 0px !important;
  }
}

.prospect-hr {
  border-bottom: 1px gray solid;
  padding-bottom: 10px;
  padding-top: 10px;
}

.prospect-datestamp {
  color: #ccc;
  font-style: italic;
  margin-left: 8px;
}

.prospect-contact-information .col-md-4 .emphasis {
  padding-top: 15px;
}
.prospect-contact-information .prospect-edit-button {
  padding-top: 15px;
}

.prospect-detail {
  &-window {
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.08), 0 2px 20px 0 rgba(0, 0, 0, 0.02);
    color: #575e65;
    background-color: #ffffff;
    border-radius: 25px;
  }

  &-heading {
    background-color: #43a7b3;
    color: #ffffff;
    height: 40px;
    border-radius: 25px 25px 0px 0px;
    padding: 0px 10px 0px 10px;

    &-item {
      display: block;

      > span {
        margin-left: 8px;
      }

      &:last-child {
        text-align: right;
      }

      &:first-child {
        text-align: left;
      }
    }
  }

  &-content {
    margin-top: 10px;

    hr {
      margin: 12px 20px 12px 20px;
      border-top: 1px solid #ccc;
    }
  }

  &-footer {
    text-align: right !important;
    margin-top: 10px;
    padding: 10px;
    position: sticky;
    background-color: #f8f8f8;
    bottom: 0;
    &-edit {
      text-align: right !important;
      margin-top: 10px;
      padding: 10px;
      position: sticky;
      bottom: 0;
      background-color: white;
    }
  }

  &-hyperlink {
    text-decoration: underline;
    cursor: pointer;
  }
}

.prospect-display {
  display: block;
  word-wrap: break-word;
  overflow-x: hidden;
  max-height: 100px;
}

.prospect-input {
  margin-left: 5px;
  margin-right: 5px;
  min-height: 60px;
  margin-bottom: 2px;

  &--30-min-height {
    min-height: 40px !important;
  }

  &__next-question {
    margin-top: 10px;
  }

  &-other {
    display: inline;
    padding-left: 18px;

    & + .prospect-input-field-textarea {
      margin-left: 18px;
      margin-right: -28px;
      max-width: 382px;
      margin-bottom: 5px;
      font-size: 1em;
    }
  }

  &--textarea {
    min-height: 135px;
    vertical-align: top;
    margin-top: 2px;
    font-size: 1em;

    .prospect-input-label {
      font-weight: 500;
      vertical-align: top;
    }

    .prospect-input-field {
      vertical-align: top;
    }
  }

  &--checkbox {
    .prospect-input-label {
      font-weight: 500;
      display: inline-block;
      cursor: pointer;
    }

    .prospect-input-field {
      display: inline-block;
      margin-right: 5px;
    }
  }

  &-label {
    display: block;
    font-weight: 500 !important;
    min-height: 22px;
    margin-top: 4px;

    &--addon {
      font-weight: normal;
    }

    &-error {
      color: #e34558;

      &--small {
        color: #e34558;
        margin-top: 2px;
        font-size: 12px;
        margin-left: 12px;
      }
    }
  }

  &-field {
    display: block;

    &-text {
      max-width: 400px;
      border-radius: 3px;
      font-size: 1em;

      &:hover {
        border: 1px solid #2DA4AD;
      }

      &:focus {
        border: 1px solid #2DA4AD;
      }
    }

    &-date {
      max-width: 400px;
      border-radius: 3px;
      font-size: 1em;

      &:hover {
        border: 1px solid #2DA4AD;
      }

      &:focus {
        border: 1px solid #2DA4AD;
      }
    }

    &-checkbox {
      cursor: pointer;
    }

    &-textarea {
      height: 100px !important;
      max-width: 400px;
      font-size: 1em;

      &:hover {
        border: 1px solid #2DA4AD;
      }

      &:focus {
        border: 1px solid #2DA4AD;
      }
    }

    &-error {
      border-color: #d43f3a;
    }

    &-radio {
      display: inline-block;
      margin-right: 10px;

      &-button {
        display: inline-block;
      }

      &-label {
        display: inline-block;
        margin-left: 5px;
        cursor: pointer;
      }
    }

    &-radioedit {
      margin-right: 10px;

      &-button {
        display: inline-block;
      }

      &-label {
        display: inline-block;
        margin-left: 5px;
        cursor: pointer;

        &-disabled {
          cursor: not-allowed;
          margin-left: 5px;
        }

        &-error {
          color: #d43f3a;
          margin-top: 8px;
          margin-left: 5px;
        }
      }
    }

    &-dropdown {
      max-width: 400px;
      select {
        width: 100%;
        height: 34px;
        border: 1px solid #ccc;
        border-radius: 4px;
        padding-left: 9px;
        font-size: 1em;

        &:hover {
          border: 1px solid #2DA4AD;
          outline: none;
        }

        &:focus {
          border: 1px solid #2DA4AD;
          outline: none;
        }
      }
    }
  }
}

.prospect-scheduling-header {
  margin-bottom: 15px;
}

.prospect-sub-question {
  padding-left: 20px;
}

.prospect-note-button {
  margin-right: 10px;
  .fa {
    cursor: pointer;
  }
  .fa-pencil {
    padding-right: 5px;
  }

  .fa-pencil:hover {
    color: #32a1d9;
  }

  .fa-trash:hover {
    color: red;
  }
}

.ipp-prospect-caseid-link {
  cursor: pointer;
  color: purple !important;
}

.ipp-prospect-caseid-link:hover {
  color: #43a7b3 !important;
}

.display-prospect-questions {
  @media screen and (min-width: 600px) {
    // setting default row and cols values for non mobile screens
    .row {
      margin-left: -15px;
      margin-right: -15px;
    }

    .row [class*='col'] {
      padding-left: 15px;
      padding-right: 15px;
    }
  }

  .row {
    // setting rows margin and cols padding to 0 for mobile screens
    margin-left: 0;
    margin-right: 0;
  }

  .row [class*='col'] {
    padding-left: 0;
    padding-right: 0;
  }
}

//TODO: Move this up to a more global/reusable location
.react-rainbow {
  input,input:focus {
     border: none;
     border-radius: unset;
     box-shadow: unset;
     background: unset;
     padding: 0;
     height: 1.5em;
     line-height: 1.5em;
   }
 }
 section[role=dialog]{
   tr > td:first-child {
     width: 38px;
     min-width: unset;
   }
 }

 .prospect-details h4.modal-title {
  font-size: 18px;
  font-weight: 500;
 }