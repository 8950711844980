.inloader {
  &-container {
    position: relative;
    height: 100px;
    width: 100px;
    margin: auto;
  }

  &-fill {
    path.cls-1 {
      fill: #2DA4AD;
    }
  }

  &-background {
    position: absolute;
    top: 0;
    left: 0;
    height: auto;
    width: 100%;
    transition: max-height 0.3s ease-out;
    overflow: hidden;

    &--primary {
      path.cls-1 {
        fill: #303235;
      }
    }
    &--secondary {
      path.cls-1 {
        fill: #ffffff;
      }
    }
  }

  &-animate .inloader-background {
    animation: animate 1s infinite alternate ease-in-out;
  }
}

@keyframes animate {
  from {
    max-height: 0%;
  }
  to {
    max-height: 100%;
  }
}
