$background_color: #f9f9f9;
$border_color: #ddd;

.checklist {
  margin-top: 20px;
  overflow-y: auto;
  max-height: 500px;

  textarea {
    margin: 0px 8px 8px 8px;
    width: calc(100% - 16px);
  }

  input {
    cursor: pointer;
    margin-top: 21px;
  }

  label {
    width: 100%;
    height: 57px;
    margin-bottom: 0px;
    cursor: pointer;
  }

  .checklist-tabs {
    text-align: left;

    .treatment-tab {
      width: auto;
      display: inline-block;
      padding-left: 30px;
      padding-right: 30px;
    }
  }
}

.fa.fa-check-circle {
  color: #1b8e60;
}

.fa.fa-exclamation-circle--colored {
  color: #e36666;
}

.fa-exclamation-next-btn{
  font-size: 22px;
  margin-left: 8px;
  line-height: 30px;
  vertical-align: top;
}

table.checklist-table {
  width: 100%;
  border-collapse: collapse;
  border: 1px solid $border_color;

  tr {
    border: 1px solid $border_color;

    th {
      text-align: center;
      border: 1px solid $border_color;
      height: 30px;

      div {
        margin: 8px;
      }
    }

    td {
      text-align: left;
      border: 1px solid $border_color;

      &.center-text {
        text-align: center;
      }

      div {
        margin: 8px;
        line-height: 20px;
      }
    }
  }

  tbody tr {
    &:nth-child(odd) {
      background-color: $background_color;
    }
  }
}
