$min-opacity: .6;
$max-opacity: 1;

.save-icon {
  position: relative;
}

.save-icon-state {
  position: absolute;
  z-index: 1;
  right: 0;
  bottom: 5px;
  width: 15px;
  height: 16px;

  svg {
    width: 15px !important;
    height: 16px !important;
  }
}

.loading-state {
  opacity: $min-opacity;
  -webkit-animation: loading .9s infinite;
  -moz-animation: loading .9s infinite;
  -o-animation: loading .9s infinite;
  animation: loading .9s infinite;
}

@keyframes loading {
  0% {
    opacity: $min-opacity;
  }

  50% {
    opacity: $max-opacity;
  }

  100% {
    opacity: $min-opacity;
  }
}

@-o-keyframes loading {
  0% {
    opacity: $min-opacity;
  }

  50% {
    opacity: $max-opacity;
  }

  100% {
    opacity: $min-opacity;
  }
}

@-moz-keyframes loading {
  0% {
    opacity: $min-opacity;
  }

  50% {
    opacity: $max-opacity;
  }

  100% {
    opacity: $min-opacity;
  }
}

@-webkit-keyframes loading {
  0% {
    opacity: $min-opacity;
  }

  50% {
    opacity: $max-opacity;
  }

  100% {
    opacity: $min-opacity;
  }
}
