.question-box {
  margin-bottom: 20px;
}
.question-box .title {
  padding-left: 15px;
}
.question {
  background-color: rgb(242, 249, 251);
  border-radius: 10px;
  padding: 15px;
}

.opposing-arch {
  margin-left: 1.5em;
  display: inline-block;
}
.opposing-arch div {
  display: inline-block;
}
.btn-ib {
  position: relative;
  margin: 5px;
  padding: 5px;
  padding-top: 7px;
  padding-bottom: 7px;
  border-radius: 10px;
  border-style: solid;
  border-width: 0px;
  border-color: rgb(220, 220, 220);
  background-color: white;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);
  z-index: 2;
}
.btn-ib:hover {
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.4);
}
.btn-ib:active {
  left: 1px;
  top: 1px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  z-index: 1;
}
.btn-ib.active {
  background-color: rgb(144, 204, 206);
  border-color: rgb(144, 204, 206);
  color: white;
}
.btn-ib.disabled {
  opacity: 0.4;
  pointer-events: none;
}
.teeth-selector {
  display: block;
}
.teeth-selector .btn-ib {
  font-size: 9pt;
  width: 35px;
  margin-left: 2px;
  margin-right: 2px;
}
#bracket-presets button {
  width: 75px;
}

textarea {
  border-radius: 15px;
}
#restrict-reason {
  margin-top: 10px;
}
label {
  font-weight: normal;
}
.ib-radio,
.ib-checkbox {
  padding-top: 5px;
  padding-bottom: 5px;
  margin-left: 0px;
}


h3 {
  color: rgb(144, 204, 206);
  font-size: 20px;
  margin-top: 25px;
  margin-bottom: 5px;
}

#improve-crowding .btn-ib {
  padding: 5px;
  width: 100px;
}
.label-right {
  text-align: right;
}
#spacing-box .mm-size {
  width: 500px;
}

.ib-warning {
  padding: 10px;
  border-radius: 10px;
  background-color: rgb(255, 238, 232);
}

.overjet .ib-checkbox {
  padding-left: 2em;
}
.form-check-label.disabled {
  color: rgb(150, 150, 150);
}

.tx_heading {
  color: #43a7b3;
}
