@import '../../../bpp-variables';

.teeth-container {
  margin-bottom: 20px;

  .title {
    padding-left: 15px;
  }

  .selection {
    border-radius: 10px;
    padding: 15px;
  }
  .selection.awb {
    padding: 0px;
  }

  .arch-to-treat {
    &__selection {
      margin-left: 15px;
      margin-bottom: 10px;

      &__step {
        margin-left: 20px;
        margin-top: 5px;
      }
    }
  }

  .less-margin {
    margin-bottom: -20px;
  }

  &__note {
    width: 86%;
    margin-bottom: 10px;

    &__error {
      border-color: $bpp-subtle-warning-new;
    }
  }

  &__row {
    margin-left: 105px;
    text-align: left;
    margin-top: 20px;
  }

  &__presets {
    margin-bottom: -15px;
    margin-top: 25px;

    .btn-ib {
      font-size: 13px;
      width: 60px;
      padding: 2px;
    }
  }

  &__error {
    color: $bpp-subtle-warning-new !important;

    &__message {
      width: 65%;
      text-align: center;
      margin-left: 140px;
    }
  }
}

.missing-teeth-measurements-label {
  text-align: left;
  margin-left: 88px;
}

.margin-bottom-50 {
  margin-bottom: 50px;
}
.margin-bottom-11 {
  margin-bottom: 11px;
}
