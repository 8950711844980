.alert {
  background-color: $mint;
  border-radius: $border-radius;
  border: 1px solid $turquoise;
  padding: $general-spacing $general-spacing * 2;
  text-align: center;
  font-size: $alert-font-size;
  font-weight: $alert-font-weight;
  color: $alert-font-color;
  margin: 0;

  &.alert--secondary {
    padding: $general-spacing * 0.5 $general-spacing;
    width: fit-content;
  }

  &.alert--success {
    background-color: $mint;
    border: 1px solid $turquoise;
  }

  &.alert--error {
    background-color: $licorice;
    border: 1px solid $vermillion;
    color: white;
  }

  .emphasis {
    font-weight: bold;
  }
}
