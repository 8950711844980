.manual-wire-container {
    padding: 50px 200px;
    width: 100%;
    height: 100%;
    background-color: #383c3f;
    color: #fff;
    max-width: none !important;

    .manual-wire-heading,
    .manual-wire-option,
    .manual-wire-subheading {
        margin-bottom: 20px;
    }

    .manual-wire-option-label {
        margin-right: 10px;
    }

    .custom-select {
        height: 30px;
    }

    .generation-in-progress {
        display: flex;
        justify-content: center;
        margin-top: 10px;
        font-size: 17px;
    }

    .process-tracker {
        margin-top: 10px;
        margin-left: 49.5%;
    }

    .manual-wire-option > * {
        margin-right: 10px;
    }

    .manual-wire-option {
        display: flex;
        width: 300px;
        justify-content: flex-start;

        select {
            width: 100%;
            margin-left: 10px;
        }

        input[type="checkbox"] {
            margin-left: 32px;
        }

        label {
            display: none;
        }
    }

    .manual-wire-subheading {
        background-color: #2da4ad;
        color: #000;
        text-align: center;
        border-radius: 5px;
    }

    .button-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 20px;
    }

    .buttons-row {
        display: flex;
        justify-content: center;
        width: 600px;
        margin: 8px;
        :hover {
            background-color: #42adb5;
        }
    }

    .generate-container {
        display: flex;
    }

    .generate-button {
        background-color: #2da4ad;
        color: white;
        width: 200px;
        margin: auto 5px;

        &.Mui-disabled {
            background-color: #d3d3d3;
        }
    }

    .retry-button {
        background-color: #2da4ad;
        color: white;
        width: 100px;
        margin: auto 5px;
    }

    .preview-button {
        background-color: #2da4ad;
        color: white;
        width: 200px;
        margin: auto 5px;
    }
}
