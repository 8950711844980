.account-info {
  margin-top: 20px;
}

.form-horizontal .form-group {
  margin: 0;
}

.form-horizontal .form-inline:first-of-type div {
  margin-bottom: 1rem !important;
}

.form-control:focus {
  border-color: #cccccc;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.edit-provider-locator-info{
  .account__camera{
    top: 115px;
  }

  .row{
    padding-bottom: 8px;
    &.row--no-space {
      padding-bottom: 0px;
    }

    .provider-bio-row{
      min-height: 270px;

      .input-container {
        padding-top: 10px;
        max-width: 100%;

        & textarea {
          resize: none;
          height: 196px;
        }
      }
    }

    .provider-association-row {
      min-height: 175px;

      .input-container {
        padding-top: 10px;

        & textarea {
          resize: none;
          height: 106px;
        }

        .text-area__count{
          top: 88px;
          right: 15px;
        }
      }

      .col-md-4.active {
        z-index: 2;
      }
    }

    .provider-education-row {
      min-height: 105px;

      .input-container {
        padding-top: 10px;
      }
    }

    input {
      font-size: 1em;
    }
  }
}

.modal {
  display: block;
}

.modal-footer {
  text-align: center;
}

table.info {
  border: 1px solid #e3e3e3;
  border-right: none;
}

table.table-bordered {
  border-right: none;
  border-bottom: none;
}

tr > td:first-child {
  width: 22%;
  min-width: 120px;
}

table.info tr > td:last-child {
  background-color: white;
  border: none;
}

.btn-primary {
  background-color: #32a1d9;
  border: none;
  border-radius: 20px;
}

.btn-primary + .btn-secondary {
  border-radius: 20px;
}

.btn-secondary {
  border-radius: 20px;
}

.action-icon {
  cursor: pointer;
  height: 24px;
  margin-right: 0.6rem;
  transform: scale(0.9);
  transition: all 0.1s linear;
}

.action-icon:hover {
  transform: scale(1);
}

.margin-top-1 {
  margin-top: 1rem;
}

.account-view {
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.08), 0 2px 20px 0 rgba(0, 0, 0, 0.02);
}

.thead-teal {
  background: #43a7b3;
  color: #ffffff;
  border: none;
}

.table > thead.thead-teal > tr > th:first-child {
  padding-left: 15px;
  border-radius: 20px 0px 0px 0px;
  width: 375px;
}

.table > thead.thead-teal > tr > th:last-child {
  border-radius: 0px 20px 0px 0px;
  width: 375px;
}

.table > tbody.tbody-teal > tr > td:first-child {
  width: 375px;
  border-radius: 0px 0px 0px 20px;
  padding-left: 15px;
}

.table > tbody.tbody-teal > tr > td:last-child {
  background-color: #fff;
  color: #7a7e80;
}

.table > tbody.tbody-teal > tr > td:nth-child(2) {
  border-radius: 0px 0px 20px 0px;
}

#new-password,
#current-password,
#confirm-new-password {
  width: 100%;
}

.heading-teal {
  display: inline-block;
  color: #43a7b3;
  /* font-weight: bold; */
}

.heading-type {
  width: 100px;
}

.heading-value {
  width: 320px;
}

.heading-enable {
  width: 100px;
}

.heading-item-container {
  border: 1px solid #ccc;
  margin: 10px 10px 10px -10px;
  border-radius: 20px;
  display: inline-block;
  padding: 5px 10px;
  cursor: pointer;
  line-height: 20px;
}

.heading-item-container:hover {
  border: 1px solid #43a7b3;
}

.heading-add-button {
  display: inline-block;
  position: relative;
  top: 30px;
  right: 45px;
  text-decoration: underline;
  cursor: pointer;
}

.heading-add-button {
  color: #43a7b3;
}

.heading-add-button:hover {
  color: #7a7e80;
}

.heading-item {
  display: inline-block;
}

.heading-item-action {
  display: block;
  font-size: 20px;
  text-align: right;
  margin-top: 8px;
  margin-bottom: 8px;
}

@media only screen and (min-width: 768px) {
  .heading-item-action {
    position: absolute;
    right: -258px;
    top: 0;
    display: block;
    font-size: 20px;
    text-align: right;
    margin-top: 25px;

    & + .dso-role {
      margin-top: 8px;
    }
  }
}

#send_reset_link {
  display: inline-block;
}

.heading-button {
  width: 25px;
  text-align: center;
}

.heading-button:hover {
  color: #43a7b3;
}

.heading-action {
  display: inline-block;
  cursor: pointer;
}

.heading-value-dropdown {
  list-style: None;
  display: inline-block;
}

.dropdown-menu.heading-value-menu-round {
  color: #43a7b3;
  border-color: #9cd7df;
  border-radius: 0px 0px 10px 10px;
  padding-bottom: 0px;
  padding-top: 0px;
  right: 95px;
  left: 0px;
  top: 24px;
  opacity: 0.96;
  min-width: 100px;
}

.heading-item-container-selected {
  border-color: #43a7b3;
}

.heading-value-menu-round > li:last-child > a:hover {
  border-radius: 0px 0px 10px 10px;
}

input.heading-value {
  border: None;
  padding: 0px;
}

input.heading-value::placeholder {
  color: #ccc;
}

.heading-item-inline {
  display: inline-block;
}

.heading-item-warning {
  color: #e74b3a !important;
  display: inline-block;

  &--margin {
    margin-left: 16px;
  }
}

.account__content {
  color: #7a7e80;
  margin: 0px 15px 10px 15px;
}

.account__data {
  padding-top: 20px;
  padding-bottom: 20px;
  position: relative;
}

.account__initials {
  background-color: #707070;
  border-radius: 3px;
  color: #ffffff !important;
  display: inline-block;
  font-size: 3.5em !important;
  height: 100px;
  line-height: 100px !important;
  text-align: center;
  width: 100px;
  vertical-align: middle;
  margin-left: 16px;
  margin-top: 10px;
  z-index: 13;
  svg {
    width: 1em;
  }
}

.account__info {
  display: inline-block;
  margin: 0px 16px;
  vertical-align: top;
}

.account__email {
  word-break: break-word;
  @media (max-width: 760px) {
    width: 100%;
  }
}

.account__name {
  font-size: 1.6em;
}

.account__section {
  padding: 16px;
  .account__setting {
    .edit-table {
      @media (min-width: 760px) {
        width: 70%;
      }
      @media (max-width: 760px) {
        width: 100%;
      }
      .svg-button {
        .delete-svg-button {
          span {
            svg {
              &:hover {
                color: #de5548;
              }
            }
          }
        }
        .save-svg-button {
          span {
            &:hover {
              color: #2DA4AD;
            }
            svg {
              &:hover {
                color: #2DA4AD;
              }
            }
          }
        }
      }

      .action-buttons {
        display: inline-block;
        cursor: pointer;
        margin: 5px;
      }

    
      .input-container {
        width: 100% !important;
      }
    }
  }
}



.account__section h4 {
  font-size: 18px;
  font-weight: normal;
}

.account__section a {
  color: #43a7b3;
}

.account__section a:hover {
  color: #656b6d;
}

.account__section:last-child {
  border-bottom: None;
}

.address {
  padding-top: 10px;
  display: inline-block;
  padding-bottom: 15px;
  vertical-align: top;
  width: 375px;
}

.account__line-divison {
  border-bottom: 1px solid #d4d4d4;
  margin: 0px 16px;
}

.account__camera {
  height: 28px;
  width: 28px;
  border: 1px solid #ccc;
  border-radius: 15px;
  padding-top: 2px;
  padding-left: 1px;
  text-align: center;
  color: #2DA4AD !important;
  background-color: #ffffff;
  position: absolute;
  top: 100px;
  left: 100px;
  font-size: 16px;
  cursor: pointer;
}

.profile-picture-menu {
  position: absolute;
  top: 100px;
  left: 130px;
  z-index: 1;

  ul {
    width: 120px;
    height: 98px;
    color: #43a7b3;
    font-size: 14px;
    list-style: none;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
    padding-left: 0px;
    margin-bottom: 0px;

    li {
      padding: 4px 4px 4px 8px;
      cursor: pointer;

      &:hover {
        background-color: #e8f6f6;
      }

      i {
        width: 25px;
        text-align: center;
      }
    }
  }
}

canvas {
  border-radius: 3px;
}

.account__data canvas {
  margin-left: 17px;
}

.case-header-anchor {
  font-size: 0.75em !important;
  float: right;
}

.top-margin-16 {
  margin-top: 16px;
}

.bottom-margin-16 {
  margin-bottom: 16px;
}

.bottom-margin-10 {
  margin-bottom: 10px;
  @media only screen and (max-width: 768px) {
    .input-container {
      .patient-field {
        width: 100%;
      }
      
    }
  }
  
}

@media only screen and (max-width: 768px) {
  .case-header-anchor {
    float: initial;
  }

  .provider-association-row{
    margin-bottom: 35px;
  }

  .provider-education-row--large{
    height: 135px !important;
  }
}

.word-wrap-content {
  word-wrap: break-word;
}

@media only screen and (min-width: 1537px) {
  .word-wrap-content {
    max-width: 400px;
  }
}

@media only screen and (max-width: 1537px) {
  .word-wrap-content {
    max-width: 400px;
  }
}

.account-setting-input-field-textarea:hover {
  border: 1px solid #2DA4AD;
}

.account-setting-input-field-textarea {
  height: 300px !important;
  max-width: 400px;
  font-size: 1em;
}

.day-label {
  display: inline-block;
  width: 85px;
}

.day-label-edit {
  display: inline-block;
  width: 85px;
}

.open-time-label {
  display: inline-block;
  text-align: center;
  width: 70px;
}

.open-time-label-edit {
  display: inline-block;
  width: 135px;

  .dropdown-input__single-value {
    text-overflow: initial;
  }
}

.to-time-label {
  display: inline-block;
  padding-left: 10px;
  padding-right: 10px;
}

.close-time-label {
  display: inline-block;
  text-align: center;
  width: 70px;
}

.practice-name {
  margin: 16px;
  font-size: larger !important;
  display: block;
}

.practice-image {
  margin: 16px;
  img {
    max-width: 100%;
    height: auto;
    width: auto\9; /* ie8 */
    // background: #50a2a8b3;
    padding: 20px;
    border-radius: 3px;
    margin-top: 10px;
  }
  
}

.find-a-provider-label {
  margin-right: 8px;
}

.hours-field {
  width: 135px;
  @media only screen and (max-width: 370px) {
    .open-time-label-edit {
      margin-top: 5px;
    }
  }
  @media only screen and (max-width: 768px) {
    dropdown-input__control {
      padding: 0px 13px;
    }
  }
}

.practice-logo-camera {
  height: 28px;
  width: 28px;
  border: 1px solid #ccc;
  border-radius: 15px;
  padding-top: 1.5px;
  text-align: center;
  color: #2DA4AD !important;
  background-color: #ffffff;
  font-size: 16px;
  cursor: pointer;
  display: inline-block;
}

.practice-logo-title {
  margin-right: 10px;
  margin-bottom: 10px;

}

.practice-logo-menu {
  z-index: 100;
  position: absolute;

  ul {
    width: 95px;
    height: 75px;
    color: #43a7b3;
    font-size: 14px;
    list-style: none;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
    padding-left: 0px;
    margin-bottom: 0px;

    li {
      padding: 4px 4px 4px 8px;
      cursor: pointer;

      &:hover {
        background-color: #e8f6f6;
      }

      i {
        width: 25px;
        text-align: center;
      }
    }
  }
}

.address-practice-error-label {
  color: #e34558;

}

.practice-input-field-error {
  border: 1px solid #d43f3a !important;
  border-radius: 3px;
}

.edit-provider-locator-info {
  .account__initials {
    margin-left: 0px;
  }
}

.provider-bio-text {
  max-height: 300px !important;
  max-width: 400px;
  overflow-y: auto;
  overflow-x: hidden;
}


@media only screen and (max-width: 575px) {
  .day-label-edit {
    display: block;
  }
  .open-time-label-edit {
    display: inline-block;
    width: 127px;
    margin-top: 10px;
  }
  .hours-field {
    width: 127px;
  }

  .prospect-edit-button {
    .btn:not(:last-child) {
      margin-right: 10px !important;
    }
  }
}

