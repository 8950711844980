.tr-viewer-container-parent {
  position: sticky !important;
  top: 12em;
}

.teeth-viewer-btn-bar {
  width: 100%;

  > .btn-ib {
    width: 50%;
    margin: 0%;
  }

  :first-child {
    border-radius: $border-radius 0 0 $border-radius;
  }
  :last-child {
    border-radius: 0 $border-radius $border-radius 0;
  }
}
