.filter-menu {
  position: relative;
  margin: 8px 0px;
}

.tend-submenu > li {
  padding: 6px 5px 3px 15px;
  color: #707070;
  cursor: pointer;

  &:hover {
    color: #fff;
    background-color: #42adb5;
    transition: all 0s;

    span,
    .fa.fa-chevron-right.pull-right {
      color: #fff;
    }
  }
}
