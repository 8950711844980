$navbar-height: 55px;

.navbar {
  background-color: $turquoise;
  height: $navbar-height;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  color: $pearl;
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 $general-spacing;
  margin-bottom: 0px;

  &.sticky {
    position: sticky;
  }

  &__content {
    flex: auto;
    display: flex;
    height: 100%;
    align-items: center;

    &--right {
      justify-content: flex-end;
      & .navbar__menu__item-help-sidebar {
        & .help-sidebar-icon {
          margin: 0;
        }
      }
      & .navbar__menu-item-account {
        margin-left: 0;
      }
    }
  }

  &__menu {
    display: flex;
    height: 100%;

    &__image {
      margin-left: $general-spacing;
      color: $pearl;
      display: flex;
    }

    &__item {
      display: flex;
      align-items: center;
      margin: 0;
      padding: $general-spacing $general-spacing * 2;
      height: 100%;
      line-height: 1.2em;
      color: $pearl;
      transition: none;

      &__label {
        color: inherit;
      }

      &:hover {
        color: $pearl;
        background-color: $wave;
      }

      &:active {
        color: $pearl;
        background-color: $jade;
      }

      &--active {
        &:hover {
          background-color: $jade;
        }
        background-color: $jade;
      }

      svg {
        width: 25px;
        height: 25px;
        .cls-1 {
          fill: $pearl;
        }
      }

      > svg {
        margin-right: $general-spacing;
      }
    }
  }

  &__menu__toggle {
    display: none;
  }

  &__logo {
    width: 175px;
    color: $pearl;
    cursor: pointer;
    margin: 0 $general-spacing;
    height: 100%;
    display: flex;
    transition: none;

    &--small {
      width: 34px;
      display: none;
    }

    .cls-1 {
      fill: $pearl;
    }
  }

  .dropdown {
    height: 100%;

    &.open .navbar__menu__item {
      background-color: $primary-active;
    }

    &-menu-right {

      & li a {
        color: #404041;
      }

      & .dropdown-header {
        color: $sonicSilver;
        font-family: sofia-pro, sans-serif;;
        font-style: normal;
        font-weight: 139;
        font-size: 12px;
        line-height: 24px;
      }
    }

    &-menu {
      margin-top: -$general-spacing;
      font-size: 1em;
      border-radius: $border-radius;

      li a {
        &:hover,
        &:focus {
          color: $pearl;
          background-color: $primary-hover;
        }

        &:active {
          color: $pearl;
          background-color: $primary-active;
        }
      }
    }
  }
}

.sidebar {
  &-container {
    display: none;
    position: fixed;
    transform: translateX(-100%);
    width: 100vw;
    height: 100vh;
    background-color: rgba($pearl, 0.5);
    backdrop-filter: blur(4px);
    z-index: 100;
    transition: transform 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

    &.sidebar--open {
      transform: translateX(0);
    }
  }
  &__logo {
    display: none;
  }
}

main {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.content {
  transition: none;
  width: 100%;
  flex: 1;
  max-width: 1300px;
  margin-top: $navbar-height;
  margin-left: auto;
  margin-right: auto;

  &.no-margin-top {
    margin-top: 0;
  }
}

@include for-size(desktop-lg) {
  .content {
    margin-left: $general-spacing * 2;
    margin-right: $general-spacing * 2;
    width: unset;
  }

  .navbar__logo {
    display: none;
    &--small {
      display: flex;
    }
  }

  .navbar__content--right {

    & .dropdown.navbar__menu-item-account {
      margin-left: 0;
    }

    .navbar__menu__item__label {
      display: none;
    }
    .navbar__menu__image {
      margin-left: 0;
    }
  }
}

@include for-size(tablet) {
  .navbar {
    justify-content: flex-start;

    &__logo {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    &__menu,
    &__content:last-child {
      display: none;
    }

    &__menu__toggle {
      display: block;
      color: $pearl;
      cursor: pointer;
      width: fit-content;
      margin-left: $general-spacing;
    }
  }
  .sidebar-container {
    display: block;
  }
}

@media only screen and (max-width: 820px) {
  .navbar__menu__item__label {
    display: contents;
  }
}
