$font-family: 'sofia-pro', 'Roboto', 'sans-serif';

.wasm-bolton-loading {
  color: #50a3a9;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 30px;

  & .fa-spinner:before {
    font-size: 50px;
  }
}

.wasm-bolton-values-container {
  display: flex;
  gap: 8px;
  padding: 32px 10px 16px;
}

.wasm-bolton-value-container {
  width: 100%;
  font-size: 12px;
}

.wasm-bolton-value {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 32px;
  line-height: 37.5px;
  border: 1px solid #465356;
  margin-bottom: 8px;
}

.wasm-bolton-overall,
.wasm-bolton-anterior {
  text-align: center;
  font-weight: 700;
}

.wasm-bolton-tables-container {
  display: flex;
  flex-wrap: wrap;
  padding: 0 6px;

  @media (max-height: 900px) {
    height: calc(100% - 150px);
    overflow: auto;
  }
}

.wasm-bolton-table-title {
  color: #fff;
  font-family: $font-family;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
}

.wasm-bolton-table-container {
  width: 50%;
  flex-shrink: 0;
  text-align: center;
  padding: 16px 4px 0;
}

.wasm-bolton-table-row {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  padding: 5px 8px;
  border: 0.5px solid #465356;
}

.wasm-bolton-table-row:nth-child(even) {
  background-color: #383c3f;
}

.wasm-bolton-table-key,
.wasm-bolton-table-value {
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  color: #fff;
}

.wasm-bolton-table-value {
  opacity: 0.7;
  font-weight: 400;
}
