.search-bar {
  padding: 5px 10px 6px 10px;
  width: 100%;
}

.search-bar-curve {
  border-radius: 20px !important;
}

.search-bar-curve-left {
  border-radius: 20px 0px 0px 20px;
}

.search-bar-curve-right {
  border-radius: 0px 20px 20px 0px;
}

.breadcrumb-link,
.breadcrumb-link:hover {
  color: #ffffff;
  cursor: pointer;
}

.breadcrumb-link:active {
  color: #dddddd;
}

.fullview {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 100000;
}

.navbar-default .navbar-nav > li > a.banner-text {
  color: #ffffff;
}

.navbar-default .navbar-nav > li > a.banner-text:hover {
  color: #777777;
}

.dropdown-menu.inbrace-color > li > a:focus,
.dropdown-menu.inbrace-color > li > a:hover {
  color: #262626;
  text-decoration: none;
  background-color: #f5f5f5;
}

@media (min-width: 768px) {
  .dropdown-menu.inbrace-color li a {
    background: #ffffff;
  }
  .dropdown-menu.inbrace-color {
    top: 105%;
  }
}

#topnav ul li ul.dropdown-menu.inbrace-color li {
  padding: 0px;
}

.dropdown-menu.inbrace-color > li > a {
  width: 215px;
}

.navbar.navbar-simple {
  margin-bottom: 0px;
}

.nav li.show-sm {
  display: none;
}

.nav li.hide-sm {
  display: block;
}

.add-prospect{
  display: inline-block;
  color: #379eab;
  cursor: pointer;
  line-height: 45px;
  vertical-align: top;
  padding: 0px 10px 0 10px;
  margin-top: -5px;

  i {
    margin-right: 5px;
  }

  &:hover{
    color: #575e65;
  }
  &:active{
    color: #656b6d;
  }
}

@media screen and (max-width: 768px) {
  .nav li.show-sm {
    display: block;
  }

  .nav li.hide-sm {
    display: none;
  }
}

#topnav ul li ul li {
  padding-left: 45px;
}

.dropdown-submenu {
  position: relative;
}

.dropdown-submenu > .dropdown-menu {
  top: 0;
  left: 50%;
  margin-top: -6px;
  margin-left: -1px;
  -webkit-border-radius: 0 6px 6px 6px;
  -moz-border-radius: 0 6px 6px;
  border-radius: 0 6px 6px 6px;
}

.dropdown-submenu:hover > .dropdown-menu {
  display: block;
}

.dropdown-submenu > a:after {
  display: block;
  content: ' ';
  float: right;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
  border-width: 5px 0 5px 5px;
  border-left-color: #ccc;
  margin-top: 5px;
  margin-right: -10px;
}

.dropdown-submenu:hover > a:after {
  border-left-color: #fff;
}

.dropdown-submenu.pull-left {
  float: none;
}

.dropdown-submenu.pull-left > .dropdown-menu {
  left: -100%;
  margin-left: 10px;
  -webkit-border-radius: 6px 0 6px 6px;
  -moz-border-radius: 6px 0 6px 6px;
  border-radius: 6px 0 6px 6px;
}

.dropdown-menu.dropdown-menu-round {
  color: #43a7b3;
  border-color: #9cd7df;
  border-radius: 0px 0px 10px 10px;
  padding-bottom: 0px;
  padding-top: 0px;
  right: 95px;
  left: 38px;
  top: 54px;
  width: 202px;
  opacity: 0.96;
}

.version-tag {
  display: inline;
  position: absolute;
  font-weight: bold;
  font-size: 10px;
  color: #32a1d9;
  left: 332px;
  margin-top: 39px;
}

.inline-search {
  width: 30%;
  display: inline-block;
  margin-top: 10px;
  float: left;
}

nav#sidebar {
  /* padding-top: 70px !important; */
  position: sticky !important;
  top: 0;
  z-index: 12;
}

.fixed-header {
  position: fixed;
  padding-top: 68px;
  width: 100%;
  z-index: 11;
}

.white-header {
  position: fixed;
  padding-top: 176px;
  background-color: white;
  width: 100%;
  z-index: 10;
  height: 20px;
}

.submit-spacing {
  margin-top: 100px;
}

.prospect_name{
  margin-left: 12px;

  &--limited{
    max-width: 250px;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
