.account_heading--start {
  padding-bottom: 10px;
}

.form-control.select-menu {
  line-height: 20px;
  padding: 0px 8px;
  width: 200px;
  box-shadow: none;
}

.form-control.field-error {
  border-color: #e74c3c;
}

.btn.btn-primary.btn-spacing.btn-create {
  margin-left: 0px;
}

.heading-item-warning-spacing {
  margin-top: 10px;
}
