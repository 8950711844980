.dso-account-profile {
  .account__section:last-child {
    padding-bottom: 40px;
  }

  .profile-header {
    display: inline-block;
    width: 100%;
  }

  .heading-item-warning {
    margin-left: 16px;
  }

  .account__data {
    display: inline-block;
  }

  .profile-heading {
    font-weight: normal;
    padding: 5px 0px 0px 50px;
  }

  input:checked + .slider {
    background-color: #2DA4AD;

    &:hover{
      background-color: #74b9be;
    }
  }

  .dso-info {
    padding-left: 20px;

    span {
      padding-right: 20px;
    }

    input,
    select {
      padding: 3px 10px;
      width: 220px;
    }

    input[type='text'] {
      padding-left: 14px;
    }

    .cursor-unset {
      cursor: unset;
    }

    .dso-role {
      padding-left: 41px;
      margin-top: -13px;
    }

    .dso-reset-link {
      padding-left: 83px;
      padding-bottom: 10px;
    }
  }
}
