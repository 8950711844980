.feedback-rating-post-smile-design-modal {
  color: white;

  & .modal-title {
    font-size: 18px;
    font-weight: 500;
    line-height: 25px;
  }

  & .rating-form {
    & .submitted-message {
      color: white;
      margin: 0;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
    }
    & .tooth-rating {
      & .tooth-rating__label {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
      }
    }

    & .rating-form__comment {
      & .comment__label {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
      }

      & .item-reason-comment {
        border-radius: 3px;
      }

      & .text-area__count--bottom {
        text-align: right;

        &.text-input__count--error {
          color: red;
        }
      }

      & .text-input.has-error {
        border: 2px solid red;
      }
    }

    & .modal-button-group {
      margin-top: 16px;

      & .do-not-show-again {
        cursor: pointer;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        margin-right: 10px;
        text-decoration: underline;
        &.disabled {
          cursor: not-allowed;
          color: #707070;
          text-decoration: none;
        }
      }

      & .button--success {
        background-color: #489599;
      }
    }

    & .rating-form__alert-container {
      margin-top: 16px;
      display: flex;
      flex-direction: column;
      row-gap: 10px;
    }
  }
}
