@import "../../bpp-variables";

.production-tx-guide {
  .question-container {
    width: 100%;

    .process-box {
      margin-left: auto;
      margin-right: auto;
      width: 80%;
      display: block;
      padding: 10px 30px 10px 30px;
    }

    .process-box-title {
      text-align: left;
      margin-top: 10px;
      margin-bottom: 3px;
    }

    .category {
      margin-top: 15px;
    }

    input {
      margin: 0px 8px 0px 0px;
    }

    .location {
      padding-left: 20px;

      &__incomplete {
        color: $bpp-subtle-warning-new !important;
      }

      &__disabled {
        pointer-events: none;
      }
    }

    .bracket-link {
      display: inline-block;
      text-decoration: underline;
      color: #303235;
      cursor: pointer;
      margin-right: 5px;
    }

    .question-row {
      margin-top: 5px;
      display: flex;
      flex-wrap: wrap;

      &__disabled {
        pointer-events: none;
      }

      textarea {
        border-color: #9ea3a8;
      }
    }

    .padding-left {
      padding-left: 15px;
    }

    .design {
      width: 88%;
      margin: 0 auto;

      textarea {
        margin-top: 10px;
      }

      input[type="text"] {
        display: inline-block;
        width: 160px;
        border-radius: 10px;
        margin-left: 10px;
      }

      .question-row {
        margin-top: 10px;

        .treatment-override {
          margin-left: 10px;
          color: #d43f3a;
        }
      }

      .modal-text-body {
        margin-top: 10px;
        margin-bottom: 10px;
      }

      .region-error {
        color: $bpp-subtle-warning-new;
        border-color: $bpp-subtle-warning-new;
      }

      .guide-item-row {
        margin-top: 5px;
        word-wrap: break-word;

        .wire-label {
          margin-right: 10px;
        }

        .wire-options {
          display: inline-block;
          vertical-align: top;
        }

        &__override {
          text-decoration: line-through;
        }
      }
    }

    .treatment-tab-container {
      width: 100%;
    }
  }
}

.gen-2-wires {
  .wire-label {
    float: left;
    margin-top: 5px;
    margin-right: 10px;
  }

  fieldset {
    width: 58%;

    .select-disabled {
      .datalist-wires {
        width: 350px !important;
      }

      > div > button .filter-option-inner-inner {
        color: #303235;
      }
    }
  }

  .datalist-wires {
    border-radius: 10px !important;
    height: 37px !important;
    width: 350px !important;  

    button {
      margin-left: -0.5px;
      width: 350px !important;
    }

    input {
      width: 100% !important;
      margin-left: 0px !important;
      border-radius: 4px !important;
    }

    div.dropdown-menu.open {
      min-height: 110px !important;
    }
  }
}

.inbrace_ifs-input {
  height: 160px !important;
  border: solid 1px #cccccc !important;
  border-radius: 10px;
  float: left;
  margin-left: 10px;
  margin-bottom: 10px;
  @media (min-width: 1452px) {
    width: 80% !important;
  }
}
