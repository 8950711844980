.copy-to-clipboard {
  display: inline;
  padding: 2px;
  margin: 5px;
  cursor: pointer;
  font-size: 16px;
  border-radius: 5px;
  &.dark {
    &:hover {
      background-color: #9ea3a8;
    }
  }
  &.light {
    &:hover {
      background-color: #ddd;
    }
  }
}
