.info--alert {
  display: flow-root;
  padding-top: 5px;
  padding-bottom: 5px;

  .info--text {
    float: left;
    margin-left: 5px;
    margin-right: 5px;
    vertical-align: bottom;
    max-width: 92%;
  }

  .info--close {
    float: right;
    text-decoration: underline;
    color: #489599;
    &:hover {
      cursor: pointer;
    }
  }

  .info--icon {
    width: 20px;
    height: 20px;
    float: left;
  }
}

.error--alert {
  display: flow-root;
  padding-top: 5px;
  padding-bottom: 5px;

  .error--text {
    float: left;
    margin-left: 5px;
    margin-right: 5px;
    vertical-align: bottom;
    max-width: 92%;
    color: white;
  }

  .error--close {
    float: right;
    text-decoration: underline;
    color: white;
    &:hover {
      cursor: pointer;
    }
  }
}

.success--alert {
  display: flow-root;
  padding-top: 5px;
  padding-bottom: 5px;

  .success--text {
    float: left;
    margin-left: 5px;
    margin-right: 5px;
    vertical-align: bottom;
    max-width: 92%;
  }

  .success--close {
    float: right;
    text-decoration: underline;
    &:hover {
      cursor: pointer;
    }
  }
}
