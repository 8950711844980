$nested-padding: 20px;

.dropdown {
  margin: 10px;

  &.open .fa.fa-chevron-down {
    transform: rotate(180deg);
  }

  .dropdown-menu.bpp-dropdown-menu-round {
    cursor: pointer;
    color: #303235;
    border-color: #000000;
    border-radius: 5px;
    padding: 8px 0px;
    width: 100%;
    opacity: 0.95;
    text-align: center;
    left: 0;
    right: 0;

    li {
      padding: 4px 15px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      &:hover {
        color: #444;
        background-color: #d5d5d5;
      }
    }
  }
}

.sidebar__top-container {
  display: flex;
  justify-content: center;
  align-items: center;

  .sidebar__case_select {
    cursor: pointer;
    display: inline-block;
    height: 35px;
    position: relative;
    width: 225px;
    text-align: center;
    border-radius: 8px;
    background-color: #000000;
    font-size: 18px;
    padding: 5px;
    transition: background-color 0.3s ease;
    margin: 0;

    &:hover {
      background-color: #111111;
    }

    .fa.fa-chevron-down {
      position: absolute;
      top: 0;
      right: 10px;
      height: 100%;
      display: flex;
      align-items: center;
    }
  }

  .case-list-settings {
    font-size: 16px;
    margin: 0 5px;
    cursor: pointer;

    &:hover {
      color: #f1f1f1;
    }
  }
}

.sidebar__filter-container {
  height: calc(100vh - 55px);
  overflow: auto;

  &::-webkit-scrollbar {
    background: transparent;
    background-color: transparent;
    width: 12px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgba(#7e7e7e, 0.8);
  }
  &::-webkit-scrollbar-thumb:hover {
    background-color: rgba(#7e7e7e, 1);
  }

  .case-filter-item {
    position: relative;
    cursor: pointer;
    font-size: 13px;
    padding: 7px;

    &:hover,
    &.active {
      background-color: #7a8895;
      color: #202125;
    }
  }

  .collapse-caret {
    position: absolute;
    height: 100%;
    top: 0;
    right: 20px;
    display: flex;
    align-items: center;

    &:hover {
      background-color: #dee1e5;
    }
  }

  .badge-container {
    display: inline-block;
    width: 4em;
    text-align: right;
    margin-right: 10px;
  }
}

.case-filter-submenu {
  .case-filter-item {
    padding-left: $nested-padding;
  }
  & & .case-filter-item {
    padding-left: $nested-padding * 2;
  }
  & & & .case-filter-item {
    padding-left: $nested-padding * 3;
  }
}
