.active-cases {
  .case-table {
    overflow-y: auto;
    max-height: 250px !important;
    margin-top: 10px;
    border: 1px solid #e1e2e2;
    border-radius: 10px;
  }

  table {
    width: 100%;
    border-collapse: separate;

    th {
      font-weight: bold;
      padding: 15px;
      border-bottom: 1px solid #e1e2e2;
      background-color: #f1f1f1;

      &:first-child {
        padding-left: 35px;
        padding-right: 0px;
      }

      &:last-child {
        width: 30%;
      }

      &:nth-child(2) {
        width: 30%;
      }
    }

    td {
      text-align: left;
      padding: 15px;
      margin-bottom: 20px;

      .alert--icon {
        margin-left: 5px;
        width: 20px;

        .icon_status {
          stroke-width: 1.5px;
          stroke: #e34558;
        }
      }

      .tooltip-inner {
        max-width: 240px;
        white-space: normal;
        word-wrap: break-word;
        background-color: #000000db;
      }

      &:first-child {
        padding-left: 35px;
        padding-right: 0px;
      }

      span:first-child {
        display: inline-flex;
      }
    }
  }
}
