//------------------------------------------------------------------------------
// Wasm Viewer
//------------------------------------------------------------------------------
$background-color: #383c3f;
$foreground-color: #303235;

$lime: #c9e63f;

$turquoise: #50a3a9;
$turquoise-50: #406b6f;
$turquoise-75: #48878c;
$turquoise-100: #406a6e;

$sidebar-size: 300px;

$transition-duration: 0.3s;
$transition-func: ease;

$history-item-bg-color: rgba($turquoise, 0.1);
$action-required-text-color: #e87d07;
$revised-bg-color: #707070;

$scrollbar-color: rgba(white, 0.1);
$scrollbar-thumb-color: rgba(white, 0.2);
$scrollbar-hover-color: rgba(white, 0.5);
$scrollbar-border-radius: 10px;

$gray-10: #404041;

$font-family: 'sofia-pro', 'Roboto', 'sans-serif';

.background--dark.wasm-root {
  display: flex;
  font-size: 16px;
  flex-direction: column;
  color: white;

  .wasm-topbar {
    width: 100vw;
    height: 64px;
    display: flex;
    background: $foreground-color;
  }

  .wasm-content {
    height: calc(100vh - 64px);
    position: relative;
  }

  .wasm-sidebar-container {
    position: absolute;
    height: 100%;
    left: 0;
    z-index: 10;
  }

  .wasm-logo-container {
    margin: auto 20px;
  }

  .logo-container {
    cursor: pointer;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .horizontal-divider {
    background: #383c3f;
    height: 2px;
    margin: 8px -8px;
  }

  .header {
    font-family: $font-family;
    font-style: normal;
    line-height: 24px;

    &--bold {
      font-weight: 140;
    }

    &--status {
      font-size: 0.875em;
      line-height: 16px;
    }
  }

  img::before {
    content: ' ';
    display: block;
    width: 50px;
    background-image: url('../../../public/static/img/no-image-white.png');
    background-size: contain;
  }

  canvas.emscripten {
    width: 100%;
    height: 100%;

    &.hidden {
      display: none;
    }
  }

  .abs-center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .editing-mode-controls {
    position: absolute;
    right: 20px;
    bottom: 20px;
  }
}

.wasm-sidebar {
  background-color: $foreground-color;
  color: white;
  transition: all $transition-duration $transition-func;
  padding: 8px;
  width: 100%;
  height: 100%;
  border-top: 2px solid $background-color;

  .wasm-sidebar-patient-info {
    margin-bottom: 8px;
    font-weight: bold;

    > div {
      overflow: hidden;
      text-overflow: ellipsis;
      > span {
        font-weight: initial;
        font-size: 0.875em;
      }
    }
  }

  .wasm-sidebar-buttons {
    display: flex;
    gap: 8px;

    > i {
      font-size: 1em;
      color: $turquoise;
      cursor: pointer;
      padding: 4px;
      border-radius: 3px;
      min-width: 30px;
      min-height: 24px;
      text-align: center;
      align-self: center;
      border: 1px $turquoise solid;

      &:hover {
        background: $turquoise-50;
        color: white;
      }

      &:active {
        background: $turquoise-75;
        color: white;
      }

      &.active {
        background: $turquoise;
        color: white;
      }
    }
  }

  .wasm-sidebar-name--vertical {
    display: none;
    line-height: 1.5em;
    font-size: 1.5em;
    font-weight: bold;
    writing-mode: vertical-lr;
    transform: rotate(180deg);
    margin: 16px auto;
    max-height: calc(100% - 200px);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .wasm-sidebar-approval {
    .wasm-sidebar-approval-buttons {
      width: 100%;
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      button {
        margin: 4px 0;
        width: 100%;
      }

      .tooltip {
        width: 320px;
        font-size: 1em;
        .tooltip-inner {
          white-space: break-spaces;
        }
      }
    }

    .wasm-sidebar-revision-modified-date {
      text-align: right;
      width: 100%;
      font-size: 0.75em;
      font-weight: 300;
      margin-top: 0.75em;
    }

    .wasm-sidebar-revision-title {
      display: flex;
      justify-content: space-between;
      margin-bottom: 4px;

      div {
        font-weight: bold;
      }
      i {
        cursor: pointer;
        color: white;
      }
    }

    textarea {
      padding: 8px;
      height: 8em;
      font-size: 1em;
      background-color: inherit;
      width: 100%;
      border-radius: 3px;
      margin-bottom: -4px;
      outline: none;
    }
  }
}

.wasm-no-changes-modal .setup-viewer-modal {
  width: auto;
}

.wasm-view-changes-modal .setup-viewer-modal {
  height: 680px;
  width: 75%;
  overflow: auto;

  @media screen and (min-width: 768px) {
    height: auto;
    width: 1200px;
  }
}

.wasm-sidebar.wasm-sidebar--collapse {
  .wasm-sidebar-patient-info,
  .wasm-sidebar-main-content {
    display: none;
  }
  .wasm-sidebar-name--vertical {
    display: block;
  }
  .wasm-sidebar-buttons {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

.wasm-sidebar-main-content {
  height: calc(100vh - 250px);

  .wasm-sidebar-detail-title {
    font-weight: bold;
    margin-bottom: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    > i {
      cursor: pointer;
      margin-right: 8px;

      &:hover {
        color: #2da4ad;
      }
    }
  }

  .wasm-sidebar-detail-list {
    overflow-x: hidden;
    overflow-y: auto;
    height: 100%;
    padding-right: 2px;
  }

  @keyframes newSection {
    from {
      opacity: 0;
      // background-color: $turquoise;
    }
  }

  .wasm-sidebar-history-item.wasm-sidebar-history-item--review {
    .wasm-sidebar-heading {
      animation: newSection 0.7s;
    }
    .wasm-sidebar-history-comment-item {
      animation: newSection 0.7s;
    }
  }

  .wasm-sidebar-history-item--no-collapse .wasm-sidebar-heading {
    pointer-events: none;
  }

  .wasm-sidebar-history-item:not(:last-child) {
    margin-bottom: 16px;
  }

  .wasm-sidebar-history-item:nth-last-child(2n):not(:first-child)::before {
    content: '';
    display: block;
    position: relative;
    width: 100%;
    margin: 24px 0;
    height: 1px;
    background-color: $turquoise-50;
  }

  .wasm-sidebar-history-item--ifs {
    .wasm-sidebar-heading {
      background: #96b8e1;
      color: #303030;
      path.cls-1 {
        fill: #303030;
      }
      .wasm-minus::before,
      .wasm-plus::before,
      .wasm-plus::after {
        background: #303030;
      }
    }
    .wasm-sidebar-history-comment-item {
      background: rgba(#96b8e1, 0.5);
    }
  }

  .wasm-sidebar-history-item:first-child {
    .wasm-sidebar-history-comment--scrollbar {
      max-height: calc(100vh - 420px);
      min-height: 20px;
      overflow-x: hidden;
      overflow-y: auto;
      padding-bottom: 5px;
      padding-top: 5px;
    }
  }

  .wasm-sidebar-status {
    font-family: $font-family;
    font-size: 0.875em;
    line-height: 1em;
    border-radius: 3px;
    padding: 2px 4px;
    margin-bottom: 4px;
    text-align: center;
    color: #ffffff;
    background: #2da4ad;

    &.wasm-sidebar-status--review {
      color: #e87d07;
      background: #fdf2e6;
    }

    &.wasm-sidebar-status--revised {
      color: #ffffff;
      background: $gray-10;
    }
  }

  .wasm-sidebar-heading {
    display: flex;
    flex-wrap: wrap;
    background-color: $turquoise-50;
    align-items: center;
    justify-content: space-between;
    padding: 4px 8px;
    cursor: pointer;

    .wasm-sidebar-heading-title {
      display: flex;
      align-items: center;
      margin-right: 4px;
      gap: 4px;
    }
  }

  .wasm-sidebar-history-comment-item {
    background-color: $history-item-bg-color;
    padding: 8px 4px;

    .wasm-sidebar-history-info {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .wasm-sidebar-in-logo,
      .wasm-sidebar-dr-logo {
        background: $turquoise;
        font-size: 0.75em;
        text-align: center;
        padding: 2px 4px;
      }

      > div {
        display: flex;
        align-items: center;
      }

      .wasm-sidebar-history-name {
        margin-left: 4px;
      }

      .wasm-sidebar-history-date {
        font-size: 0.75em;
        font-weight: 300;
      }
    }
  }

  .wasm-sidebar-image-item {
    margin-bottom: 8px;
    position: relative;

    img {
      width: 100%;
    }
  }

  .wasm-sidebar-image-item:last-child {
    margin-bottom: 0;
  }
}

.wasm-sidebar-in-logo {
  width: 18px;
  height: 16px;
}

.wasm-viewer-layout {
  display: flex;
  flex-direction: column;
}

.wasm-container {
  position: relative;
  width: 100%;
  height: 100%;
  transition: all 0.3s;

  .retry-btn {
    position: absolute;
    top: 50%;
    left: 50%;
    color: white;
    transform: translate(-50%, -50%);
  }
}

//------------------------------------------------------------------------------
// Wasm Viewer Top Toolbar
//------------------------------------------------------------------------------

.wasm-toolbar {
  background-color: $foreground-color;
  display: flex;
  justify-content: left;

  .view-controls-btns {
    display: flex;
  }

  .control-btn-group {
    display: flex;
    border-left: 2px $background-color solid;
    padding: 8px 16px 0 16px;
    gap: 12px;
  }

  .control-btn {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    gap: 2px;
    align-items: center;
    cursor: pointer;
    color: white;
    text-align: center;
    font-size: 0.75em;
    border-bottom: 2px transparent solid;
    user-select: none;

    svg,
    i {
      width: 32px;
      height: 32px;
      font-size: 32px;

      &.bolton-icon {
        height: 28px;
        font-size: 26px;
      }
    }

    .wasm-icon-fill {
      fill: white;
    }

    .wasm-icon-stroke {
      stroke: white;
    }

    .wasm-icon-linear-gradient stop {
      stop-color: white;
    }
  }

  .control-btn:hover {
    color: $turquoise-75;

    .wasm-icon-fill {
      fill: $turquoise-75;
    }

    .wasm-icon-stroke {
      stroke: $turquoise-75;
    }

    .wasm-icon-linear-gradient stop {
      stop-color: $turquoise-75;
    }
  }

  .control-btn:active {
    color: $turquoise-50;

    .wasm-icon-fill {
      fill: $turquoise-50;
    }

    .wasm-icon-stroke {
      stroke: $turquoise-50;
    }

    .wasm-icon-linear-gradient stop {
      stop-color: $turquoise-50;
    }
  }

  .control-btn--active {
    color: $turquoise;
    border-bottom-color: $turquoise;

    .wasm-icon-fill {
      fill: $turquoise;
    }

    .wasm-icon-stroke {
      stroke: $turquoise;
    }

    .wasm-icon-linear-gradient stop {
      stop-color: $turquoise;
    }

    &:hover {
      color: $turquoise-75;
      border-bottom-color: $turquoise-75;
    }
    &:active {
      color: $turquoise-50;
      border-bottom-color: $turquoise-50;
    }
  }

  .control-btn.control-btn--disabled {
    color: $gray-10;
    cursor: not-allowed;

    .wasm-icon-fill {
      fill: $gray-10;
    }

    .wasm-icon-stroke {
      stroke: $gray-10;
    }

    .wasm-icon-linear-gradient stop {
      stop-color: $gray-10;
    }
  }
}

.scrollbar--dark {
  &::-webkit-scrollbar {
    background-color: $scrollbar-color;
    height: 6px;
    width: 6px;
    border-radius: $scrollbar-border-radius;
  }
  &::-webkit-scrollbar-thumb {
    background-color: $scrollbar-thumb-color;
    border-radius: $scrollbar-border-radius;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: $scrollbar-hover-color;
  }
}

.wasm-container {
  .retry-btn {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
  }

  .inloader-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
  }
}

.wasm-view-controls {
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  align-items: flex-start;
  padding: 15px;
  background-color: rgba(46, 53, 53, 0.8);
  border-radius: 20px;
}

.wasm-tooth-view-controls {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  > div {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
  }

  .wasm-tooth-view-button {
    width: 35px;
    height: 35px;
    cursor: pointer;

    &.wasm-tooth-view-button--active .wasm-icon-fill {
      fill: $turquoise;
    }
    &:hover .wasm-icon-fill {
      fill: $turquoise-75;
    }
    &:active .wasm-icon-fill {
      fill: $turquoise-50;
    }
  }

  .wasm-icon-fill {
    fill: white;
  }
}

.wasm-bottom-overlay {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .wasm-bottom-container {
    transition: all $transition-duration $transition-func;
    width: 100%;
  }

  .wasm-bottom-container-title-bar {
    height: 26px;
    background-color: $turquoise-100;
    padding: 1px 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .wasm-bottom-container-controls {
    display: flex;
    align-items: center;
    gap: 8px;

    span {
      font-size: 1.5em;
      cursor: pointer;
    }
  }

  .wasm-bottom-container-content {
    background-color: $foreground-color;
  }
}

.wasm-ipr-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  .wasm-ipr-legend-container {
    position: absolute;
    background-color: $background-color;
    top: 0;
    left: 0;
    padding: 8px 16px;
    margin: 8px;

    .wasm-ipr-legend-item {
      display: flex;
      align-items: center;
      gap: 16px;

      &:first-child {
        margin-bottom: 4px;
      }
    }

    .wasm-ipr-legend--ipr {
      display: inline-block;
      width: 1em;
      height: 1em;
      background: #e13146;
      rotate: 45deg;
    }

    .wasm-ipr-legend--spacing {
      display: inline-block;
      width: 1em;
      height: 1em;
      background: #dcef81;
    }
  }

  .wasm-ipr-grid {
    display: grid;
    grid-template-columns: repeat(16, min-content);
    padding: 60px 25px;

    .wasm-ipr-change-btn {
      border-radius: 50%;
      width: 1em;
      height: 1em;
      background-color: rgba(#ffffff, 0.25);
      display: flex;
      align-items: center;
      justify-content: center;
      color: $foreground-color;
      cursor: pointer;
      user-select: none;

      &:hover {
        background-color: rgba(#ffffff, 0.5);
      }
      &:active {
        background-color: rgba(#ffffff, 0.7);
      }
    }

    > .wasm-ipr-item {
      position: relative;
      padding: 8px 10px;
      min-width: 48px;

      &.wasm-ipr--missing {
        text-decoration: line-through;
        color: rgba(#ffffff, 0.25);
      }

      .wasm-ipr-plus {
        position: absolute;
        top: 0;
        right: 0;
        translate: 50% calc(-50% - 0.5em);
      }

      &.wasm-ipr--lower {
        border-top: 1px solid rgba(#ffffff, 0.25);

        &::after {
          right: unset;
          left: -1px;
        }
        .wasm-ipr-plus {
          left: -1px;
          bottom: 0;
          top: unset;
          right: unset;
          translate: -50% calc(50% + 0.5em);
        }
        .wasm-ipr-value-container {
          left: -1px;
          bottom: 0;
          top: unset;
          right: unset;
          translate: -50% calc(50% + 1em);
          rotate: 45deg;
        }
        .wasm-ipr-change-btn.wasm-ipr-value-inc {
          position: absolute;
          bottom: unset;
          left: calc(100% + 0.25em);
          rotate: -45deg;
        }
        .wasm-ipr-change-btn.wasm-ipr-value-dec {
          position: absolute;
          right: unset;
          top: calc(100% + 0.25em);
          rotate: -45deg;
        }
      }

      &.wasm-ipr--no-ipr::after {
        content: none;
      }

      &::after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        width: 1px;
        height: 100%;
        background-color: rgba(#ffffff, 0.25);
        color: rgba(#ffffff, 0.25);
      }

      .wasm-ipr-value-container {
        position: absolute;
        top: 0;
        right: 0;
        translate: 50% calc(-50% - 1em);
        rotate: 45deg;
        width: 2em;
        height: 2em;
        background-color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $gray-10;
        border: 2px solid $gray-10;
        .wasm-ipr-value {
          rotate: -45deg;
        }

        &.wasm-ipr-value-container--ipr {
          background-color: #eb707e;
          border-color: #e13146;
        }

        &.wasm-ipr-value-container--spacing {
          background-color: #dcef81;
          border-color: #c9e63f;
          rotate: unset;
          .wasm-ipr-value {
            rotate: unset;
          }
        }
        .wasm-ipr-value-inc {
          position: absolute;
          bottom: calc(100% + 0.25em);
          rotate: -45deg;
        }
        .wasm-ipr-value-dec {
          position: absolute;
          right: calc(100% + 0.25em);
          rotate: -45deg;
        }
      }
    }
  }
}

.wasm-tmt-container {
  display: grid;
  grid-template-columns: max-content repeat(16, 1fr);
  padding: 8px 16px;

  .wasm-tmt-arch-option {
    display: flex;
    justify-content: space-around;
    gap: 8px;
    padding: 0;
  }

  .wasm-tmt-header {
    text-align: center;

    &.wasm-tmt-header--active {
      color: #2da4ad;
      font-weight: bold;
      position: relative;
    }
  }

  :nth-child(17n + 9) {
    margin-right: 4px;
  }

  div {
    padding: 2px 4px;
  }

  .wasm-tmt-item {
    border-top: 1px solid #465356;
    border-left: 1px solid #465356;
    display: flex;
    align-items: center;

    &.heading {
      margin-right: 4px;
    }

    &.wasm-tmt-item--disabled {
      background: linear-gradient(
        to top right,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) calc(50% - 0.8px),
        #465356 50%,
        rgba(0, 0, 0, 0) calc(50% + 0.8px),
        rgba(0, 0, 0, 0) 100%
      );
    }
    &.wasm-tmt-item--odd {
      background-color: #383c3f;
    }
    &.wasm-tmt-item--active {
      background-color: $turquoise;
      input::placeholder {
        color: white;
      }
    }

    &:nth-child(17n + 1),
    &:nth-child(17n + 9),
    &:nth-child(17n) {
      border-right: 1px solid #465356;
    }
    &:nth-last-child(-n + 17) {
      border-bottom: 1px solid #465356;
    }

    input {
      width: 100%;
      height: 100%;
      outline: none;
      border: none;
      background: none;
      text-align: center;
      margin-left: 10px;

      &:focus::placeholder {
        color: transparent;
      }
      &::placeholder {
        text-align: center;
      }
    }

    &.wasm-tmt-item--more-than-two-digits input {
      margin-left: 0px;
      padding: 0px;
    }

    @media screen and (max-width: 1600px) {
      &.wasm-tmt-item--double-digit input {
        margin-left: 5px;
      }
    }

    @media screen and (max-width: 1500px) {
      &.wasm-tmt-item--double-digit input {
        margin-left: 0px;
        padding: 0px;
      }
    }
  }

  .tmt-input-sign {
    position: relative;
    top: 1px;
    width: 15px;
    text-align: center;
    flex-shrink: 0;
  }

  .wasm-tmt-arch-option,
  .wasm-tmt-header,
  .wasm-tmt-item,
  .tmt-input-sign {
    @media screen and (max-width: 1480px) {
      font-size: 14px;
    }
    @media screen and (max-width: 1380px) {
      font-size: 12px;
    }
  }
}

.wasm-animation-container {
  position: absolute;
  right: 0;
  margin: 0 auto;
  margin-bottom: 16px;
  left: 300px;
  font-size: 1.5em;
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 2px;
  transition: bottom $transition-duration $transition-func;

  .wasm-animation-disclaimer {
    font-size: 0.5em;
    font-style: italic;
    text-align: center;
  }
}

.wasm-animation-progress-bar-container {
  height: 36px;
  width: 500px;
  background-color: $foreground-color;
  padding: 6px 10px;
  border-radius: 3px;
  display: flex;
  align-items: center;

  svg {
    width: auto;
    height: 100%;
  }

  .wasm-icon-fill {
    fill: white;
  }

  .wasm-animation-progress-bar {
    overflow: hidden;
    flex: 1;
    margin: 0 10px;
    background: #595b5d;
    border-radius: 3px;
    display: flex;
  }

  .wasm-animation-progress-bar div {
    height: 8px;
    flex-grow: 0;
    transition-timing-function: linear;
    transition-property: all;
    background: $turquoise;
    border-radius: 3px;
  }
}

@keyframes progress-animation {
  to {
    transform: scaleX(1);
  }
}

.wasm-change-summary-container {
  margin-bottom: 16px;

  .wasm-view-change-header {
    font-weight: bold;
    margin-top: 8px;
  }

  .wasm-view-change--no-change {
    font-size: 0.8em;
  }

  .wasm-view-change-content {
    padding: 4px;
    background-color: #383c3f;

    &.wasm-view-change-revision-notes {
      max-height: 120px;
      overflow: auto;
      word-break: break-all;
    }
  }
}

.wasm-arch-control {
  margin: 0 10px 0 15px;
  position: relative;
  text-align: center;

  .wasm-arch-button {
    border: 2px solid white;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    cursor: pointer;

    &.wasm-arch-button--inactive {
      color: $gray-10;
      border-color: $gray-10;
    }
  }

  .wasm-arch-button:first-child {
    margin-bottom: 110px;
  }

  .wasm-arch-control-slider-indicators {
    position: absolute;
    top: 21px;
    left: 9px;

    > div {
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background: white;
      margin: 13px 0;
      position: relative;
    }

    > div::before {
      content: '';
      position: absolute;
      top: -13px;
      left: 3px;
      height: 13px;
      width: 2px;
      background: white;
    }

    > div:first-child::before {
      display: none;
    }
  }

  .wasm-arch-control-slider {
    appearance: none;
    background: none;
    height: 5px;
    width: 100px;
    transform: rotate(270deg);
    position: absolute;
    top: 78px;
    left: -37px;
    outline: none;

    &::-webkit-slider-thumb {
      appearance: none;
      width: 19px;
      height: 25px;
      border: 0;
      background: url("data:image/svg+xml,<svg width='19' height='25' fill='none' xmlns='http://www.w3.org/2000/svg'><path fill='%2346A7B2' d='M19 9.14C19 4.091 14.747 0 9.5 0S0 4.092 0 9.14v6.72C0 20.909 4.253 25 9.5 25s9.5-4.092 9.5-9.14V9.14Z'/><path stroke='%23fff' stroke-miterlimit='10' stroke-width='2' d='M5.462 8.948 9.5 5.063l4.038 3.885M13.54 15.505 9.5 19.392l-4.038-3.887'/></svg>");
      transform: rotate(90deg);
      cursor: ns-resize;
    }
  }
}

.wasm-dropdown {
  position: absolute;
  top: 36px;
  left: 50%;
  transform: translateX(-50%);
  overflow: hidden;

  &:hover {
    .wasm-dropdown-header {
      border: 1px solid $turquoise;
    }
    .wasm-dropdown-list {
      border: 1px solid $turquoise;
      border-top: none;
    }
  }

  .wasm-dropdown-header {
    display: flex;
    gap: 8px;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;

    border-radius: 50px;
    background: #303235;
    padding: 5px 20px;
    border: 1px solid transparent;

    &.wasm-dropdown-header--open {
      border-radius: 16px 16px 0 0;
      border-bottom: 1px solid #383c3f;
    }

    > i {
      font-size: 0.75em;
    }
  }

  .wasm-dropdown-list {
    border-radius: 0 0 16px 16px;
    background: #303235;
    padding: 5px 20px;
    border: 1px solid transparent;
    border-top: none;
    max-height: 8.5em;
    overflow: auto;

    .wasm-dropdown-list-item {
      cursor: pointer;
      margin-bottom: 4px;

      &.wasm-dropdown-list-item--active {
        color: $turquoise;
      }

      &:hover {
        color: $turquoise-75;
      }

      &:active {
        color: $turquoise-50;
      }
    }
  }
}

.wasm-save-draft-container {
  svg {
    width: 50px;
    height: 50px;
    margin-bottom: 8px;
  }

  .wasm-save-loading {
    animation: spin 1s infinite linear;
  }

  div {
    margin-bottom: 8px;
  }
}

.wasm-toggle-btn {
  display: flex;
  align-items: center;
  cursor: inherit;
  width: fit-content;

  > input {
    opacity: 0;
    width: 0;
    height: 0;

    &:checked + div {
      background-color: #c9e2e4;
    }

    &:checked + div:before {
      background-color: #2da4ad;
      transform: translateX(18px);
    }
  }

  > div {
    position: relative;
    width: 40px;
    height: 20px;
    background: #d9d9d9;
    border-radius: 50px;

    &:before {
      position: absolute;
      content: '';
      height: 16px;
      width: 16px;
      left: 3px;
      bottom: 2px;
      background-color: #707070;
      border-radius: 50%;
      transition: 0.3s;
    }
  }
}

.wasm-toolbar-dropdown {
  position: relative;
  display: flex;

  &.wasm-toolbar-dropdown--active::after {
    border-bottom-color: $turquoise;
  }

  &:hover::after {
    border-bottom-color: $turquoise-75;
  }

  &::after {
    content: '';
    position: absolute;
    bottom: 4px;
    right: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 0 5px 5px;
    border-color: transparent transparent white transparent;
  }

  &:hover .wasm-toolbar-dropdown-list {
    top: calc(100% + 5px);
    opacity: 1;
    visibility: visible;
    transition-delay: 100ms;
  }

  .wasm-toolbar-dropdown-list {
    position: absolute;
    top: calc(100% - 5px);
    background-color: white;
    color: black;
    z-index: 10;
    width: 200px;
    padding: 8px 12px;
    border-radius: 3px;
    cursor: default;
    font-size: 0.8em;

    opacity: 0;
    visibility: hidden;
    transition-duration: 300ms;
    transition-property: opacity, top, visibility;
    transition-delay: 500ms;
    transition-timing-function: $transition-func;
  }

  .control-btn span i {
    width: auto;
    height: auto;
    font-size: 1em;
  }

  .wasm-dropdown-list-item {
    display: flex;
    justify-content: space-between;
    margin-bottom: 4px;
    cursor: pointer;

    &.disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }

    &.no-toggle {
      user-select: none;
      &:not(.disabled) {
        &:hover {
          color: $turquoise;
        }
        &:active {
          color: $turquoise-100;
        }
      }
    }
  }
}

.wasm-yellow-text {
  color: #c9e63f;
}

.wasm-plus,
.wasm-minus {
  position: relative;
  width: 0.75em;
  height: 0.75em;

  &::before {
    content: ' ';
    position: absolute;
    display: block;
    background: #fff;
    width: 100%;
    left: 0;
    top: calc(50% - 1px);
    bottom: calc(50% - 1px);
  }
}

.wasm-plus::after {
  content: ' ';
  position: absolute;
  display: block;
  background: #fff;
  height: 100%;
  top: 0;
  left: calc(50% - 1px);
  right: calc(50% - 1px);
}

.wasm-chevron::before {
  content: '';
  position: relative;
  display: inline-block;
  vertical-align: top;
  height: 0.7em;
  width: 0.7em;
  border-style: solid;
  border-width: 0.15em 0.15em 0 0;
  transform: rotate(-45deg);
}
.wasm-chevron.wasm-chevron--right::before {
  left: -0.15em;
  transform: rotate(45deg);
}
.wasm-chevron.wasm-chevron--left::before {
  left: 0.2em;
  transform: rotate(-135deg);
}
.wasm-chevron.disabled {
  color: $gray-10;
  cursor: default;
}

.wasm-inc-movement {
  background: $foreground-color;
  border: 1px solid black;
  transition: all $transition-duration $transition-func;
  border-radius: 3px;

  &--phase-1 {
    width: 275px;
  }

  &--phase-2 {
    width: 210px;
  }

  .wasm-inc-movement-title-bar {
    width: 100%;
    height: 26px;
    background: $turquoise-100;
    display: flex;
    border-radius: 3px;
    justify-content: space-between;
    align-items: center;
    padding: 0px 8px;
    cursor: pointer;
    transition: all $transition-duration $transition-func;

    &--open {
      border-radius: 3px 3px 0 0;
    }
  }

  .wasm-inc-movement-selected-tooth {
    text-align: center;
    &--phase-2 {
      padding: 12px 8px;
      @media screen and (max-height: 880px) {
        padding-top: 6px;
        padding-bottom: 6px;
      }
      @media screen and (max-height: 780px) {
        padding-top: 3px;
        padding-bottom: 3px;
      }
    }
  }

  .wasm-inc-movement-content {
    font-size: 0.9em;
    &--phase-1 {
      padding: 8px;
    }
    &--phase-2 {
      .horizontal-divider {
        margin: 0;
      }
    }
  }

  .wasm-inc-movement-item {
    display: grid;
    width: 100%;

    &--phase-2 {
      grid-template-columns: 1fr 1fr 60px 1fr;
      align-items: center;
      text-align: center;
      padding: 8px 8px;
      cursor: pointer;

      &.selected,
      &:hover {
        background: $gray-10;
      }

      @media screen and (max-height: 880px) {
        padding-top: 4px;
        padding-bottom: 4px;
      }

      @media screen and (max-height: 780px) {
        padding-top: 2px;
        padding-bottom: 2px;
      }
    }

    &--phase-1 {
      grid-template-columns: 1fr min-content 60px min-content 1fr;
      align-items: center;
      gap: 8px;
      margin-bottom: 12px;
    }

    &.multi-plane {
      grid-template-columns: 1fr 3fr;
    }

    > span:first-child {
      display: flex;
      justify-content: center;
    }

    .wasm-inc-movement-item--left {
      text-align: right;
    }

    input {
      outline: none;
      border: none;
      text-align: center;
      background: $background-color;
    }

    .input-wrapper {
      position: relative;
      display: flex;
      align-items: center;

      input {
        width: 100%;
        &:not(.no-right-padding) {
          padding-right: 15px;
        }
      }

      .sign {
        position: absolute;
        right: 5px;
      }
    }

    .wasm-chevron {
      cursor: pointer;
      display: grid;
      align-items: center;
      justify-content: center;
      height: 100%;

      &.disabled {
        cursor: not-allowed;
      }
    }
  }

  .wasm-inc-movement-icon {
    width: 32px;
    height: 32px;

    @media screen and (max-height: 700px) {
      width: 24px;
      height: 24px;
    }
  }
}

.w100px {
  width: 100px;
  justify-content: center;
}

.MuiTooltip-tooltip {
  font-size: 12px !important;
  margin-top: 8px !important;
  font-weight: 400 !important;
}

.wasm-notifications {
  position: absolute;
  top: 88px;
  left: 0;
  right: 0;
}

.wasm-notifications {
  position: absolute;
  top: 88px;
  left: 0;
  right: 0;
}

.introjs-tooltip.custom-tooltip.introjs-floating {
  width: 450px;
  max-width: max-content;

  &.step-1.custom-tooltip .introjs-prevbutton {
    display: none;
  }
}

.introjs-tooltipReferenceLayer * {
  font-family: 'sofia-pro', 'Roboto', 'sans-serif';
}

@media (min-width: 1600px) and (max-width: 1920px) {
  a.introjs-hint[data-step='10'] {
    top: calc(100% - 480px) !important;
  }
}

.introjs-tooltiptext {
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  padding: 15px;
  h3 {
    margin-top: 10px;
  }
  ul {
    padding-inline-start: 20px;
  }
  ul.upper-greek-list {
    list-style-type: upper-greek;
  }
}

.introjs-hint-pulse {
  background-color: $lime;
  opacity: 0.85;
}

.introjs-hint:hover > .introjs-hint-pulse {
  background-color: $lime;
  opacity: 0.55;
}

.wasm-help-container {
  bottom: 10px;
  position: absolute;
  right: 10px;

  .wasm-help-menu {
    background-color: white;
    border-radius: 3px;
    bottom: 0px;
    color: black;
    cursor: default;
    font-size: 16px;
    font-weight: 400;
    list-style-type: none;
    opacity: 0;
    padding: 0;
    position: absolute;
    right: 0px;
    text-align: left;
    transition-delay: 500ms;
    transition-duration: 300ms;
    transition-property: opacity, bottom, visibility;
    transition-timing-function: $transition-func;
    visibility: hidden;
    width: 200px;
    z-index: 10;

    > :first-child {
      border-bottom: $background-color 1px solid;
    }
  }

  &:hover .wasm-help-menu {
    bottom: 40px;
    opacity: 1;
    visibility: visible;
  }

  .wasm-help-item {
    cursor: default;
    display: flex;
    justify-content: space-between;
    padding: 4px 8px;

    &:hover {
      cursor: pointer;
      opacity: 0.75;
      color: white;
      background-color: $turquoise;
    }

    &.disabled {
      cursor: not-allowed;
      opacity: 0.5;
    }
  }

  .wasm-help-icon {
    font-size: 35px;
    transition: color $transition-duration $transition-func;
    &:hover {
      color: $turquoise;
      cursor: pointer;
    }
  }
}
