.cad-window {
  max-width: 700px;
  margin: 18px auto 0px auto;
}

.button-panel {
  width: 400px;
  margin: 0 auto;
  padding-top: 5px;
  text-align: center;
}

.button-panel button {
  width: 150px;
}

.btn-disabled {
  pointer-events: none;
}

.font-size-lg {
  font-size: 30px;
}
