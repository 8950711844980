.ipp-pagination-container {
  .pagination > .active > a,
  .pagination > .active > a:hover,
  .pagination > .active > a:active,
  .pagination > .active > a:focus {
    color: #ffffff;
  }

  .pagination > li > a,
  .pagination > li > span {
    color: #43a7b3;
  }

  .pagination > li:first-child > a,
  .pagination > li:first-child > span {
    border-radius: 50% 0 0 50%;
  }

  .pagination > li:last-child > a,
  .pagination > li:last-child > span {
    border-radius: 0 50% 50% 0;
  }
}
