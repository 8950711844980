@import '../../../bpp-variables';

.setup-container {
  .business-dark-setup-window {
    width: 128px;
    display: inline-block;
    margin-right: 10px;
    line-height: 20px;
    vertical-align: top;
    position: relative;

    .center-text {
      width: 100%;
    }
  }

  .business-dark-setup-window-details {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .horizontal-ruler {
    border-top: 0.7px solid rgb(158, 163, 168);
    padding: 0 10px;
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .modal-error,
  .modal-error p {
    color: $bpp-subtle-warning;
    text-align: center;
  }

  .setup-assigned-to {
    display: inline-block;
    width: 100%;
    text-align: center;

    span.bold-text {
      padding: 0px 10px;
    }

    .assign-to-dropdown {
      width: 230px !important;
      border-radius: 20px;
      display: inline-block;
    }

    &-hrless {
      hr {
        display: none;
      }
    }
  }

  .final_design {
    border-top: 1px solid #9ea3a8;
    margin-top: 20px;
    padding-top: 20px;
  }

  .setup-text {
    text-align: center;
    span:first-child {
      white-space: nowrap;
    }
  }

  .setup_img_mask {
    width: 100%;
    height: fit-content;
    background-color: #000000b3;
    z-index: 2;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    color: #e2dfdf;
    font-size: 11.5px;
    text-align: center;
    padding: 7px;
    position: absolute;
    bottom: 0px;
    line-height: 1.5;
    cursor: pointer;
  }

  .process_tracker {
    margin-top: 10px;
    ul.workflow {
      padding-top: 40px;

      li p {
        top: -38px;
      }
    }
  }

  .process_message {
    font-size: $bpp-font-size-reg;
    width: 82%;
    line-height: 20px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 18px;
  }

  .fileUpload-content {
    padding: 15px;
  }

  .font-size-lg {
    font-size: 34px;
  }

  .accepted-text {
    color: #43a7b3;
    font-size: 13px !important;
    overflow: hidden;
    white-space: pre-wrap;
    width: 95px;
  }
}

div.css-yk16xz-control,
div.css-1pahdxg-control {
  border-radius: 10px;
}

div.css-26l3qy-menu {
  margin-top: 1px;
  border-radius: 7px;
  text-align: left;
}

span.css-1okebmr-indicatorSeparator {
  background-color: rgba(0, 0, 0, 0);
}
.download-zip {
  display: block;
  background-color: rgb(79, 80, 82);
  width: 24px;
  height: 24px;
  border-radius: 4px;
}
.download-zip-icon {
  margin-left: 6px;
  margin-top: 5px;
  font-size: 14px;
}

.upload-adjusted-setup {
  display: block;
  margin-top: 8px;
  background-color: rgb(79, 80, 82);
  width: 24px;
  height: 24px;
  border-radius: 4px;
}
.upload-adjusted-setup-icon {
  margin-left: 5.5px;
  margin-top: 5px;
  font-size: 14px;
}

.setup-upload-button {
  width: 127px;
  border: 1px solid #000 !important;
  text-align: center;
  border-radius: 10px;
  color: #000000;
  font-size: 24px;
  height: 128px;
  padding: 0px;
  margin-left: auto;
  margin-right: auto;
  cursor: pointer;
}

.setup-upload-button:hover {
  border-color: #32a1d9 !important;
  color: #32a1d9;
}

.setup-upload-button:active {
  border-color: #018abe;
  color: #018abe;
}

.btn-adjusted-setup {
  padding: 6px 25px;
}

.cancel-setup {
  color: $bpp-subtle-warning;
  font-size: 16px;
  cursor: pointer;
  position: absolute;
  top: -5px;
}

.rotate {
  animation: rotation 3s infinite linear;
}

.setup-conversion-icon {
  font-size: 35px;
  margin-top: 30px;
}

.remove-setup {
  position: absolute;
  bottom: 0;
  right: 0;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  cursor: pointer;
}

.remove-adjusted-setup {
  position: absolute;
  transform: translate(-45%, -45%);
  cursor: pointer;
}

.converting-message {
  font-size: 14px;
  margin-top: 10px;
  color: #303235;
}

.failure-conversion-message {
  color: #303235;
  font-size: 14px;
  width: 70%;
  margin: 20px auto 0 auto;
}

.conversion-error-icon {
  font-size: 28px;
}

.conversion-success-message {
  color: white;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  white-space: break-spaces;
}

.section-buttons {
  margin-top: 30px;
  text-align: center;
}

.adjusted-setup-conversion-failed {
  width: 127px;
  border: 1px solid #000 !important;
  text-align: center;
  border-radius: 10px;
  color: #000000;
  font-size: 24px;
  height: 128px;
  padding: 0px;
  margin-left: auto;
  margin-right: auto;

  .tooltip-inner {
    max-width: 300px;
    white-space: normal;
    word-wrap: break-word;
  }
}
