@use '../abstracts/mixins' as mixins;

$scrollbar-color: rgba($almostBlack, 0.1);
$scrollbar-thumb-color: rgba($almostBlack, 0.5);
$scrollbar-hover-color: rgba($almostBlack, 0.7);

.record-thumbnail-container {
  white-space: nowrap;
  overflow-x: auto;
  height: fit-content;
  padding-top: 16px;

  img {
    max-width: 80px;
    min-width: 40px;
    min-height: 40px;
  }

  &::-webkit-scrollbar {
    background-color: $scrollbar-color;
    height: 6px;
    width: 6px;
    border-radius: $border-radius;
  }
  &::-webkit-scrollbar-thumb {
    background-color: $scrollbar-thumb-color;
    border-radius: $border-radius;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: $scrollbar-hover-color;
  }

  .thumbnail_image {
    display: inline-block;
    position: relative;
    margin-right: 5px;

    &__hidden {
      display: none;
    }
  }
}

.record-viewer-container {
  position: relative;
  margin-top: $general-spacing;
  width: 100%;
  height: auto !important;
  overflow: hidden;

  &.record-viewer-container--fixed {
    height: 300px;
    max-height: 300px;
  }

  &:hover {
    .image-viewer-btn,
    .image-viewer-controls {
      transform: translateY(0);
      &.fa {
        transform: translateY(-50%);
      }
    }
  }

  .uploaded-records-header {
    margin-bottom: 40px;
  }
}

.uploaded-records-header {
  display: flex;
  flex-direction: column;

  margin-bottom: 8px;

  @include mixins.rowGap(16px);

  &.no-records {
    margin-bottom: 16px;
  }

  .title,
  .subtitle {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #707070;
  }

  .selected-itero-patient-info {
    display: flex;

    @include mixins.columnGap(16px);
    .remove-itero-files {
      color: #e34558;
      font-weight: 500;
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.image-viewer-container {
  border-radius: 3px;
  background-color: $platinum !important;
  text-align: center;
}

.image-viewer-btn {
  opacity: 0.5;
  text-shadow: none;
  padding: $general-spacing * 0.5;
  background-color: rgba($almostBlack, 0.5);
  color: $pearl;
  border-radius: $border-radius;
  height: 1.5em;
  width: 1.5em;
  opacity: 1;
  transition: transform 0.2s ease-out;
  cursor: pointer;
  text-align: center;

  &:hover {
    background-color: rgba($almostBlack, 0.8);
  }

  &.fa-angle-left {
    position: absolute;
    top: 50%;
    left: 4px;
    transform: translate(-120%, -50%);
  }

  &.fa-angle-right {
    position: absolute;
    top: 50%;
    right: 4px;
    transform: translate(120%, -50%);
  }
}

.image-viewer-controls {
  width: 100%;
  background-color: rgba($almostBlack, 0.5);
  position: absolute;
  bottom: -1px;
  display: flex;
  justify-content: center;
  padding: $general-spacing * 0.5;
  transform: translateY(100%);
  transition: 0.2s ease-out;

  &:hover {
    background-color: rgba($almostBlack, 0.8);
  }

  span {
    color: $pearl;
    margin-right: $general-spacing * 0.5;
  }

  &__btn {
    cursor: pointer;
    color: $pearl;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 25px;
    height: 25px;
    > svg {
      width: inherit;
      height: inherit;
    }

    &:hover {
      color: rgba($pearl, 0.6);
    }
    &:active,
    &:focus {
      color: rgba($pearl, 0.8);
    }
  }

  input {
    -webkit-appearance: none;
    background: $pearl;
    width: 70px;
    height: 1px;
    margin: auto 0;

    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      height: 11px;
      width: 11px;
      border-radius: 50%;
      background: $pearl;
      cursor: pointer;
    }

    &::-moz-range-thumb {
      height: 11px;
      width: 11px;
      border-radius: 50%;
      background: $pearl;
      cursor: pointer;
    }

    &::-ms-thumb {
      height: 11px;
      width: 11px;
      border-radius: 50%;
      background: $pearl;
      cursor: pointer;
    }
  }
}

.record-thumbnail-tooltip-container {
  height: inherit;
  display: grid;
  grid-gap: 10px;
  grid-template-rows: 2fr 8fr;
  max-height: 50vh;
  .record-thumbnail-container {
    .thumbnail_image:first-child {
      .tooltip {
        .tooltip-inner {
          margin-left: 12px;
        }
      }
    }
  }
}
