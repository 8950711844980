.cad-window {
  max-width: 700px;
  margin: 18px auto 0px auto;
}

.cad-window .paragraph {
  margin-bottom: 15px;
}

.ip-file-download {
  width: 180px;
  display: inline-block;
  vertical-align: top;
  margin-right: 20px;

  a {
    display: block;
  }
}

.ip-wire-selection {
  display: inline-block;
  padding-top: 5px;

  .select-label-inline {
    display: inline-block;
    margin-right: 5px;
  }

  .select-wire-readonly {
    width: 210px;
    display: inline-block;
    text-align: left;
  }
}

.ip-wire-selection > div {
  margin-bottom: 6px;
}

.ip-actions {
  margin-top: 20px;
}

.ip-actions button {
  width: 150px;
}
