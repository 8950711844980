.base-modal {
  .select-container {
    width: 100%;
  }

  .modal-body {
    overflow-x: hidden;
  }

  .modal-body.select-address-body {
    overflow-y: inherit;
    overflow-x: inherit;
  }

  .select-container > select {
    width: 65%;
    float: right;
    margin-right: 25px;
    height: 34px;
    padding: 7px 7px 7px 9px;
    border-radius: 4px;
    border: 1px solid #ccc;
    outline: none;
    font-family: Roboto, sans-serif;
    font-size: 1em;
    font-weight: 400;
    color: #707070;
    line-height: 24px;

    :focus {
      color: #707070;
    }

    &.dropdown-error {
      border: 1px solid #e13146;
    }
  }

  .modal-textarea-hold-cancel {
    &::placeholder {
      color: #c4c4c4;
    }
  }

  .modal-textarea-label {
    margin-top: 5px;
  }

  .modal-message-text {
    color: #999;
  }

  select option {
    padding: 5px;
  }

  .margin-top-15 {
    margin-top: 15px;
  }

  .margin-left-40 {
    margin-left: 40px;
  }

  .margin-left-49 {
    margin-left: 49px;
  }

  .margin-left-57 {
    margin-left: 57px;
  }

  .margin-right-9 {
    margin-right: 2px;
  }
}

.modal-error-left p {
  color: #e13146 !important;
}

.ipp-light .modal-error p {
  color: #e13146 !important;
}

.modal-textarea-error-hold-cancel {
  border: 1px solid #e13146 !important;
}

.wide_modal {
  width: 880px;
}

.datearea {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.input_description {
  font-weight: 500 !important;
  font-size: 16px;
  padding-left: 1px;
}

.completion_switch_radio_div {
  display: flex;
  align-items: center;
  .radio_btns {
    margin-right: 5px;
  }
}

.uploader_div {
  margin-top: 20px;
}

.file_upload_text {
  text-align: left;
  margin-bottom: 20px;
}

.lock_icon {
  margin-right: 5px;
}

@media screen and (max-width: 880px) {
  .wide_modal {
    margin-left: 0px;
    width: 100%;
  }
  .modal-footer {
    text-align: center !important;
  }
}
.pdf-viewer{
  height:inherit;
}
