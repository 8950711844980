.card--error {
  padding: 16px;
  background-color: $pearl;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);
  min-width: 300px;
  z-index: 1;
  width: fit-content;
  position: relative;

  &__title {
    color: $ruby;
    margin-bottom: 10px;
  }

  > i {
    cursor: pointer;
    position: absolute;
    top: 16px;
    right: 16px;
    color: $soot;

    &:hover {
      color: $almostBlack;
    }
    &:active {
      color: $titanium;
    }
  }

  ul {
    padding-left: 1em;
  }
}
