.program {
    &__name {
        padding: 5px 0;
    }

    &__levels-container {
        padding: 12px 0;
        display: flex;
    }

    &__levels {
        display: flex;
        flex-direction: column;
        margin-top: 2px;

        input {
            margin-right: 4px;
        }
    }

    &__level-input {
        display: inline-block;
        padding-right: 10px;
    }

    &__item {
        padding-right: 12px;
        margin-top: 4px;
    }

    &__opt {
        margin-top: 4px;
    }
}
