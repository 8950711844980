.select-address-modal {
  width: 355px !important;
  border-radius: 10px;
  background-color: #fff;
  z-index: 3000;
}

.select-address-m {
  text-align: left;
}

.select-address-m > label {
  text-align: right;
}

.dropdown-toggle .filter-option-inner-inner, .dropdown-menu .inner .text {
  text-wrap: wrap;
}

.inline-input > textarea {
  width: 370px;
}

.inline-input {
  position: relative;
}

.inline-input .bootstrap-select .dropdown-menu .inner .dropdown-menu.inner {
  top: 5%;
  overflow: hidden;
  overflow-y: scroll !important;
}

.inline-input .select-address-modal-1 .dropdown-menu .inner .dropdown-menu.inner {
  top: 5%;
  overflow: hidden;
  overflow-y: hidden !important;
}

.inline-input .select-address-modal-2 .dropdown-menu .inner .dropdown-menu.inner {
  top: 5%;
  overflow: hidden;
  overflow-y: hidden !important;
}

.inline-input .select-address-modal-3 .dropdown-menu .inner .dropdown-menu.inner {
  top: 5%;
  overflow: hidden;
  overflow-y: hidden !important;
}

.inline-input .bootstrap-select .dropdown-menu .inner {
  overflow: hidden !important;
}

.select-address-modal[disabled] {
  background-color: rgb(198, 201, 204);
}

.inline-select {
  display: inline-block;
  padding-left: 20px;
}

.select-address-m label.inline-input,
.expedite-details label.inline-input {
  width: 162px;
  text-align: right;
  padding-right: 21px;
}

.inline-input .select-address-modal-1 .dropdown-menu.open {
  min-height: 80% !important;
  overflow: hidden;
}

.inline-input .select-address-modal-2 .dropdown-menu.open {
  min-height: 150% !important;
  overflow: hidden;
}

.inline-input .select-address-modal-3 .dropdown-menu.open {
  min-height: 220% !important;
  overflow: hidden;
}

.inline-input .select-address-modal-4 .dropdown-menu.open {
  min-height: 220% !important;
  overflow: hidden;
  .inner {
    padding-bottom: 10px;
  }
}

.select-address-body .p-slim {
  text-align: center;
  margin-bottom: 10px;
}

.inline-input .bootstrap-select .dropdown-menu {
  position: absolute;
  top: 100%;
  min-height: 100%;
  left: 0;
  margin: -10px 0 0;
  z-index: inherit;
  border-radius: 5px;
  overflow: hidden;
}
.inline-input .bootstrap-select .btn {
  border: 1px solid #c6c9cc;
  padding: 12px 12px;
  margin-bottom: 10px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 5px;
}

.modal .select-address-body {
  overflow-y: visible;
}

.modal.in .modal-dialog {
  position: relative;
}

.select-address-m:first-child {
  margin-top: 20px;
  margin-left: 15%;
}

.select-address-m {
  margin-left: 5%;
  text-align: left;
}

.select-address-m:last-child {
  margin-bottom: 0px;
}

.select-address-m select {
  padding: 5px;
  border-radius: 4px;
  border: 1px solid #ccc;
  color: #303235;
}

.select-address-m .inline-label {
  width: 145px;
}
