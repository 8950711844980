.pagination-container {
  margin-top: 5px;

  .pagination {
    margin: 5px 0;
    float: right;
  }

  .pagination-bottom {
    position: absolute;
    bottom: 10px;
    right: 330px;
    -webkit-transition: all 1s linear; /* Safari */
    transition: all 1s linear;
  }

  @media screen and (max-width: 768px) {
    .pagination-bottom {
      position: absolute;
      bottom: 10px;
      right: 30px;
      -webkit-transition: all 1s linear; /* Safari */
      transition: all 1s linear;
    }
  }
}
