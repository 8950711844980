.item-request-container {
  width: 50%;
  padding: $general-spacing $general-spacing * 2;
}

.item-review-container .alert {
  width: 50%;
}

.item-request-table {
  tr {
    > td {
      width: 100%;
      padding: $general-spacing 0;
      border-bottom: 1px solid $platinum;
    }
    &:last-child > td {
      border-bottom: 0;
    }
    > :last-child {
      position: relative;
      white-space: nowrap;
      text-align: center;
    }

    .item-request-quantity {
      padding: 0 $general-spacing;
      margin: 0 $general-spacing;
      background-color: $eggshell;
      border-radius: $border-radius;
    }

    .item-request-control {
      color: $primary-default;
      cursor: pointer;
      &:hover {
        color: $primary-hover;
      }
      &:active {
        color: $primary-active;
      }
      &--disabled {
        color: $primary-disabled;
      }
    }

    div.alert {
      position: absolute;
      right: -50%;
      bottom: 50%;
      transform: translate(100%, 50%);
    }
  }

  &.gen2-brackets {
    width: 100%;

    th {
      float: left;
      margin-bottom: 10px;
    }
  }
}

.reason-checkbox-label {
  margin: $general-spacing;
  cursor: pointer;
}

.item-reason-container {
  margin-top: $general-spacing * 2;

  #segment-preference {
    margin-left: $general-spacing;
    .btn-ib {
      margin: 0;
      width: 225px;
    }
  }

  #reason-arch-segments {
    padding: $general-spacing;
    .btn-ib {
      width: 225px;
    }
  }

  .teeth-selector .row {
    justify-content: flex-start;
  }

  textarea {
    width: 50%;
  }
}

@include for-size(tablet) {
  .item-request-container,
  .item-review-container .alert {
    width: 100%;
  }
  .item-request-table {
    tr div.alert {
      display: none;
    }
  }
}
