.referral-code-data{
    padding-top:10px;
    padding-bottom:10px;
}

.first-child{
    padding-top: 20px;
}

.referral-code-content{
    padding-bottom: 5px;
    text-align: left;
}