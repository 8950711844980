.smile-design-preferences {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 36px 0px 10px;

  * {
    box-sizing: border-box;
  }

  &__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;

    .header__subtitle {
      display: flex;
      column-gap: 4px;
      flex-direction: row;
      align-items: center;

      .subtitle__text {
        color: #303235;
      }
    }
  }

  &__footer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 100%;

    .footer__saving-indicator {
      display: flex;
      column-gap: 4px;
      flex-direction: row;
      align-items: center;

      .saving-indicator__text {
        color: #303235;
      }

      .saving-changes-icon {
        animation-name: spin;
        animation-duration: 1000ms;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
        height: 24px;
      }
    }
  }

  &__form-container {
    align-items: flex-start;
    background: #ffffff;
    border-radius: 3px;
    border: 1px solid #e1e2e2;
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 24px;
    width: 100%;

    .loader-container {
      padding: 0;
      margin: 0 auto;
    }

    .smile-design-preferences__form {
      display: flex;
      flex-direction: column;
      row-gap: 16px;
      width: 100%;

      .info-icon {
        margin-top: 2px;

        &.desktop {
          display: inline-block;
        }

        &.mobile {
          display: none;
        }
      }

      .field-container {
        align-items: flex-start;
        display: flex;
        flex-direction: column;
        gap: 4px;
        padding: 0px;
        width: 100%;

        .field-title-container {
          display: flex;
          flex-direction: row;
          gap: 4px;

          .field-title {
            margin: 0;

            &__text {
              display: flex;
              flex-direction: row;
              column-gap: 4px;
            }

            &__index,
            &__title {
              color: #707070;
              font-size: 16px;
              font-style: normal;
              font-weight: 500;
              line-height: 24px;
            }

            &.error {
              color: #e13146;
            }
          }
        }

        .input-container {
          align-items: flex-start;
          background: #f8f8f8;
          display: flex;
          flex-direction: column;
          gap: 16px;
          padding: 16px;
          width: 100%;

          .checkbox {
            display: flex;
            gap: 10px;
          }

          .radio {
            display: flex;
            flex-direction: column;
            gap: 8px;
            width: 100%;

            label.disabled {
              cursor: not-allowed;
            }

            .radio-text-input {
              padding-left: 20px;
            }

            .text-area__count {
              bottom: -16px;
              right: 4px;
            }
          }

          .tooth-chart-selection {
            display: flex;
            flex-direction: column;
            row-gap: 16px;

            .dropdown-input {
              min-width: 100px;

              .dropdown-input__control {
                padding: 0px 8px;
              }
            }

            .teeth-label {
              font-family: sofia-pro, 'sans-serif';
              font-weight: 800;
              font-size: 12px;
              line-height: 24px;
            }

            .teeth {
              column-gap: 8px;
              display: flex;
              flex-flow: row wrap;
              row-gap: 16px;

              .tooth {
                flex: 0 0 100px;

                &__label {
                  font-weight: 400;
                  font-size: 14px;
                  line-height: 16px;

                  &.bold {
                    font-weight: 700;
                  }
                }

                .dropdown-input {
                  .dropdown-input__indicator {
                    padding: 8px 8px 8px 7px;
                  }
                }
              }
            }
            .apply-to-all {
              align-items: center;
              column-gap: 4px;
              display: flex;

              .btn,
              .dropdown-input {
                margin: 0;
              }

              .dropdown-input {
                .dropdown-input__indicator {
                  padding: 8px 8px 8px 7px;
                }
              }
            }
          }

          &--text-input {
            padding-bottom: 24px;
          }

          .text-input.error {
            border: 1px solid #e13146;
          }

          .text-area__count {
            bottom: 0;
          }

          .input-warning-container {
            width: 100%;

            .alert {
              text-align: left;
            }
          }
        }
      }
    }
  }
}

.smile-design-modal {
  .modal-dialog {
    max-width: 580px;

    .modal-content {
      .modal-body {
        .modal-content-body {
          ul {
            list-style: none;
            padding: 0;
            margin: 0;
          }
        }
      }
    }
  }
}

.MuiTooltip-tooltip.smile-design-preferences__tooltip {
  background-color: #303235;
  color: white;
  font-size: 0.9em;
  font-weight: 400;

  .MuiTooltip-arrow {
    color: #303235;
  }
}

.MuiTooltip-popper.smile-design-preferences__popper {
  z-index: 98;
}

.MuiTooltip-tooltipPlacementBottom.smile-design-preferences__tooltip {
  margin: 4px 0;
}

.MuiTooltip-tooltipPlacementTop.smile-design-preferences__tooltip {
  margin: -8px 0;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media (max-width: 768px) {
  .smile-design-preferences {
    &__header {
      flex-direction: column;
      justify-content: flex-start;
    }

    &__form-container {
      .smile-design-preferences__form {
        .info-icon {
          &.desktop {
            display: none;
          }

          &.mobile {
            display: inline-block;
            cursor: pointer;
          }
        }
        .field-container {
          .field-title-container {
            align-items: flex-start;
          }
        }
      }
    }
  }
}

@media (max-width: 500px) {
  .smile-design-preferences {
    &__form-container {
      .smile-design-preferences__form {
        .field-container {
          .input-container {
            .dropdown-input {
              min-width: 100%;
            }
          }
        }
      }
    }
  }
}
