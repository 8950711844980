@import '../../bpp-variables';

.align-top {
  vertical-align: top !important;
  padding-top: 9px;
}

.country-td {
  background-color: #d6d6d8 !important;
  .dropdown-menu {
    width: 100% !important;
  }
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 28px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #9ea3a8;
  -webkit-transition: 0.4s;
  transition: 0.4s;

  &.ipp {
    background-color: #a7d1d4;

    &.not-allowed {
      background-color: #a7d1d4;
    }
    input[type='checkbox']:not(:checked) {
      background-color: #9ea3a8 !important;
    }
  }
}

.slider:before {
  position: absolute;
  content: '';
  height: 20px;
  width: 20px;
  left: 5px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #6b7079;

  &.disabled {
    background-color: #9ea3a8;
    cursor: not-allowed;

    &.ipp {
      background-color: #a7d1d4;
    }
  }
}

input:not(:checked) + .slider {
  &.ipp {
    background-color: #e1e2e2;
  }
}

input:checked + .slider:before {
  -webkit-transform: translateX(30px);
  -ms-transform: translateX(30px);
  transform: translateX(30px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;

  &.round-box {
    border-radius: 3px;
  }
}

.ipp-light {
  .account-enabled {
    .switch-label {
      color: #fff;
    }

    .slider.round:before {
      border-radius: 3px;
    }
  }
}

.slider.round:before {
  border-radius: 50%;
}

.switch-label {
  color: #fff;

  &.disabled {
    cursor: not-allowed;
  }
}
.tooltip-inner {
  background-color: #000000db;
  white-space: nowrap;
  max-width: none;
}

.tooltip.top .tooltip-arrow {
  border-top-color: #000000db;
}

.pointer {
  word-break: break-word;
  cursor: pointer;
}

.profile-edit {
  display: inline-block;
  padding-bottom: 20px;
}

.profile-edit-window {
  border-radius: 10px;
  width: calc(100% - 230px);
  display: inline-block;
  padding-left: 13px;
  padding-right: 10px;
  padding-bottom: 6px;
  line-height: 30px;
  vertical-align: middle;
  margin-bottom: 2px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  background-color: #afb2b7;
  cursor: pointer;
  height: 38px !important;
  margin-top: 4px;
}

.profile-edit-container {
  padding-left: 15px;
}

.profile-edit-container > div.profile-edit-section:first-child {
  vertical-align: middle;
}

.user-dropdown {
  height: 43px;

  & > div:first-child {
    bottom: 14px;
  }
}

.feature-item,
.program__item,
.program-item,
.program__item {
  font-weight: bold;
  color: #303235;
}

.feature-item.mt_10 {
  margin-top: 10px;
}

//Add doctor account CSI dropdowns
.profile-edit-container > div.profile-edit-section:last-child {
  width: calc(100% - 15px);
  max-width: 400px;
  padding-top: 4px;
  padding-bottom: 4px;
  height: 44px;
}

@media only screen and (min-width: 1537px) {
  .profile-edit-container > div.profile-edit-section:last-child {
    width: calc(100% - 230px);
    padding-top: 4px;
    padding-bottom: 4px;
    height: 44px;
  }
}

.profile-edit-container > div.profile-edit-section:last-child > select {
  border-radius: 10px;
  padding: 3px 12px;
}

//Add business account dropdown
.profile-edit-container > div.profile-edit-section:last-child > fieldset > div.form-input > div.dropdown {
  width: 100% !important;
  border-radius: 10px;
}

.profile-edit-section {
  display: inline-block;
  position: relative;
}

.user-input-bottom {
  bottom: 17.5px;
}

.user-input-line-height {
  line-height: 10px;
}

.profile-edit-section-attach {
  position: absolute;
  top: 12px;
  right: -70px;
}

//CSI dropdown on edit doctor account page
.profile-edit-section > select.input {
  width: 250px !important;
  height: 30px;
  min-height: 0px;
  float: left;
  border-radius: 10px;
}

.profile-edit-section > input {
  height: 38px;
}

.profile-edit-section > input.profile-edit-input-error {
  border: 1px solid $bpp-subtle-warning;
}

.profile-edit-section > input.profile-edit-input-error::placeholder {
  color: $bpp-subtle-warning;
}

.profile-edit-section > .profile-heading {
  float: left;
  width: 215px;
  padding-left: 0;
}

.profile-heading.profile-heading-notched {
  margin-bottom: 4px;
}

.profile-heading-pull {
  margin-top: -2px;
}

.profile-edit-section > input {
  border-radius: 10px;
}

.profile-error {
  color: #d43f3a;
  padding-left: 36px;
}

.csi-display {
  display: inline-block;
  width: calc(100% - 70px);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Notification checkboxes, edit buttons */

span.notifications {
  float: right;
  margin-right: 12px;
}

input.notifications {
  float: right;
  margin-right: 7px;
  margin-top: 10px;
  height: 20px;
}

#edit-confirm.btn-next {
  margin-left: 2px !important;
}
#edit-discard.btn-next {
  margin-left: 2px !important;
}

.btn-hide {
  visibility: hidden;
}

.btn-disabled {
  pointer-events: none;
}

// Select Dropdowns

.profile-edit-window > div.css-2b097c-container {
  width: 86%;
  float: left;
}

.profile-edit-window > div.css-2b097c-container > div.css-zke0qo-control {
  border-radius: 10px;
}

.profile-edit-window .css-yk16xz-control {
  height: 18px;
  border-radius: 10px;
}

.profile-edit-window .css-1o2io3r-control {
  border-radius: 10px;
}

.profile-edit-window .css-1hwfws3 {
  height: 100%;
  position: initial;
}

.profile-edit-window .css-b8ldur-Input {
  padding-top: 0px;
  margin: 0;
  position: absolute;
  top: 0;
}

.profile-edit-section.profile-edit-window.profile-edit-window-react-select {
  padding: 0px !important;
  margin-top: 4px;
  height: 38px;
  overflow: visible;
  white-space: normal;

  .css-zke0qo-control {
    border-radius: 10px 0px 0px 10px;
    height: 38px;
    line-height: 30px;
  }

  .css-1o2io3r-control {
    border-radius: 10px 0px 0px 10px;
    height: 38px;
  }

  .css-1hwfws3 {
    padding-left: 10px;
  }

  .css-1uccc91-singleValue {
    max-width: calc(100% - 40px);
  }
}

.profile-edit-window.profile-edit-window-react-select > div.css-2b097c-container {
  width: 82%;
  height: 43px;
}

input.notifications.notifications-react-select {
  position: absolute;
  right: 10px;
  top: 0;
  bottom: 0;
}

@media only screen and (max-width: 1280px) {
  input.notifications.notifications-react-select {
    margin-left: 15px;
  }
}

@media only screen and (max-width: 1200px) {
  input.notifications.notifications-react-select {
    margin-left: 27px;
  }
}

@media only screen and (max-width: 860px) {
  input.notifications.notifications-react-select {
    margin-left: 15px;
  }
}

.profile-edit-section.profile-edit-section-user {
  margin-top: 0px;
  padding: 0px !important;

  input {
    padding-top: 4px;
    height: 45px;
  }
}

.css-26l3qy-menu > div > div {
  word-wrap: break-word;
}

.type-width-tb {
  width: 105px !important;
  min-width: 105px !important;
}

.notify-width-tb {
  width: 100px;
}

tbody > tr.force-auto-width > td:first-child,
.auto-width-tb {
  width: auto !important;
}

.address-width-tb {
  width: 155px;
}

.city-width-tb {
  width: 150px;
}

.state-width-tb {
  width: 100px;
}

.zip-width-tb {
  width: 80px;
}

.small-width-tb {
  width: 71px;
}

.edit-width-tb {
  width: 100px;
  cursor: default;
}

.icon-width-tb {
  width: 30px !important;
  min-width: 30px !important;
  text-align: right;
}

.erp-icon {
  font-size: 1.3rem !important;
}

.hyperlink-dark {
  text-decoration: underline;
  cursor: pointer;

  &-bottom {
    margin-bottom: 8px;
    display: inline-block;
  }
}

.hyperlink-black {
  margin-left: 20px;
  text-decoration: underline;
  cursor: pointer;
}

.hyperlink-black:hover {
  color: #6b7079;
}

.hyperlink-disabled {
  margin-left: 20px;
  color: #6b7079;
}

fieldset.datalist.account-state {
  margin-bottom: -12px;
}

fieldset.input-error > div > div > button.dropdown-toggle {
  border: 1px solid #e74b3ae3;
}

.not-allowed,
.not-allowed:hover {
  cursor: not-allowed;
}

.update-name-error {
  color: $bpp-subtle-warning;
}

.feature-table .table-inbrace-dark-industry-2 .opted_in_tr {
  height: 88px;
  width: 100%;

  td {
    vertical-align: initial;
    padding-top: 10px;
  }
}

.feature-table .table-inbrace-dark-industry-2 .opted_in_email_tr {
  position: absolute;
  top: 75px;
  width: calc(100% - 145px);
  td {
    padding-left: 15px;
    background: none;
  }
}

.feature-table .table-inbrace-dark-industry-2 .opted_in_email_tr_with_icon {
  position: absolute;
  top: 70px;
  width: 50%;
  #referral_email {
    position: absolute;
    line-height: 20px;
  }
  td {
    padding-left: 15px;
    background: none;
  }
}

.referral_email_input_td {
  position: absolute;
  right: 0px;
  display: inline-block;
  width: 65% !important;
}

.opted_in_email_td {
  display: inline-table;
}

.referral_email_input {
  font-weight: normal;
}

.feature-table .table-inbrace-dark-industry-2 .opted_in_email_tr_with_icon #referral_email {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.feature-item.mt_10.email {
  min-width: 48px;
}

.referral-email-input-field {
  margin-top: 25px;
  width: 300px;
}

.referral-email-outer-div {
  display: flex;
  align-items: center;
  margin-top: -15px;
  flex-direction: row;
  align-content: stretch;
  justify-content: space-between;
}

@media screen and (max-width: 1024px) {
  .type-width-tb {
    width: 70% !important;
  }
  .feature-table .table-inbrace-dark-industry-2 .opted_in_tr {
    height: 100px !important;
    margin-bottom: 25px;

    td {
      padding-bottom: 40px;
    }
  }
  .feature-table .table-inbrace-dark-industry-2 .opted_in_email_tr_with_icon #referral_email {
    width: 50%;
  }
}

input[type='checkbox'].feature__checkbox {
  margin-top: 0px;
  margin-left: 5px;
  vertical-align: middle;
}

// edit-account multiselect fields

.multi-selected-fields-container {
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.practice-checkboxes-tr {
  display: table-row;
  .practice-checkboxes-div {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    width: 100%;
    margin-left: 10px;
    .practice-checkbox-div {
      display: flex;
      align-items: center;
      flex-grow: 1;
      min-width: max-content;
      input[type='checkbox'] {
        margin-top: 0px;
        margin-left: 5px;
      }
    }
  }
}

.state-datalist {
	.dropdown-menu {
		.open {
			width: 100px;
		}
	}
}
