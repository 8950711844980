.edit-table {
  position: relative;
  display: inline-block;
  width: 50%;

  &--parent {
    position: relative;

    &:last-child {
      .edit-table--content {
        border-bottom: none;
      }
    }
  }

  &--action {
    position: absolute;
    display: inline-block;
    top: 2px;
    left: calc(100% + #{$general-spacing * 3.5});
    width: 100%;

    .svg-button {
      float: none;
    }
  }

  &--heading {
    display: inline-block;
    border-bottom: 1px solid $platinum;

    span {
      font-family: sofia-pro, sans-serif;
    }
  }

  &--content {
    max-height: $general-spacing * 6;
    height: $general-spacing * 6;
    line-height: $general-spacing * 6;
    vertical-align: middle;
    position: relative;
    border-bottom: 1px solid #e1e2e2;

    &:hover {
      color: $turquoise;

      & div,
      span,
      a {
        color: $turquoise;
      }
    }

    &:last-child {
      border-bottom: none;
    }
  }

  &--item {
    display: inline-block;
    cursor: pointer;
    padding: calc(#{$general-spacing}/ 2) 0px;
    line-height: 1.5em;
    vertical-align: middle;
    height: $general-spacing * 5;

    .dropdown-menu {
      font-size: 1em;
      min-width: 100px;

      li {
        a {
          &:hover,
          &:focus {
            color: $pearl;
            background-color: $primary-hover;
          }

          &:active {
            color: $pearl;
            background-color: $primary-active;
          }
        }
      }
    }
  }

  &--group {
    display: block;
  }

  &--mobile {
    width: 100%;
    border-bottom: 1px solid #e1e2e2;
    padding-bottom: $general-spacing;
    margin-bottom: $general-spacing;
    cursor: pointer;

    &:last-child {
      border-bottom: none;
    }

    &-action {
      margin-top: $general-spacing;
    }

    &-item {
      display: flex;
      justify-content: flex-start;

      li,
      div {
        flex: 4;
        height: $general-spacing * 4;
        vertical-align: middle;
        margin-bottom: $general-spacing;
      }

      .dropdown-menu {
        font-size: 1em;
        min-width: 100px;

        li {
          margin-bottom: 0px;

          a {
            &:hover,
            &:focus {
              color: $pearl;
              background-color: $primary-hover;
            }

            &:active {
              color: $pearl;
              background-color: $primary-active;
            }
          }
        }
      }
    }

    &-label {
      flex: 1;
      min-width: 100px;
      text-align: right;
      height: $general-spacing * 4;
      vertical-align: middle;
      padding-right: $general-spacing;
      margin-bottom: $general-spacing;
    }
  }
}

@include for-size(tablet) {
  .edit-table {
    width: 100%;

    .show-on-mobile {
      display: block;
    }
  }
}
