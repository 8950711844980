$success: #2DA4AD;
$success_hover: #74B9BE;
$success_active: #489599;
$success_disabled: #3c5558;

$danger: #E34558;
$danger_hover: #E75F6F;
$danger_active: #E13146;
$danger_disabled: #613d44;

$secondary:#2a3033;
$secondary_hover: #404548;
$secondary_active: #5f6466;
$secondary_disabled: #E1E2E2;

.button {
  border-radius: 3px;
  color: white;
  min-width: 80px;
  line-height: 30px;
  font-size: 14px;
  margin-right: 10px;
  border: none;
  transition: color 0.25s ease, background-color 0.25s ease;

  &:disabled {
    color: rgba(white, 0.25);
    cursor: not-allowed;
  }

  &--success {
    background-color: $success;

    &:focus{
      outline: none;
    }

    &:hover {
      background-color: $success_hover;
      outline: none;
    }

    &:active {
      background-color: $success_active;
      outline: none;
    }

    &:disabled {
      background-color: $success_disabled;
      outline: none;
    }
  }

  &--danger {
    background-color: $danger;

    &:focus{
      outline: none;
    }

    &:hover {
      background-color: $danger_hover;
      outline: none;
    }

    &:active {
      background-color: $danger_active;
      outline: none;
    }

    &:disabled {
      background-color: $danger_disabled;
      outline: none;
    }
  }

  &--secondary {
    background-color: $secondary;
    border: 1px solid rgba(white, 0.5);
    
    &:focus{
      outline: none;
    }

    &:hover {
      background-color: $secondary_hover;
      outline: none;
    }

    &:active {
      background-color: $secondary_active;
      outline: none;
    }

    &:disabled {
      background-color: $secondary_disabled;
      outline: none;
    }
  }

  &:last-child {
    margin-right: 0px;
  }
}
