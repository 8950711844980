@import './bpp-variables';

.bpp-dark {
  .teeth-group-inline {
    display: inline-block;
    margin: 0px 8px;
  }

  .hold-length {
    display: inline-block;
    margin: 5px 0px -5px 0px;
    max-width: 175px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .hold-length-2 {
    display: inline-block;
    margin: 0px 0px -5px 0px;
    max-width: 175px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .beige-table {
    background-color: #ffffff;
    padding: 2px;
  }

  .notso-beige-table {
    background-color: #f7f7f7;
  }

  .left-round-table {
    /* border-radius: 25px 0px 0px 25px; */
    padding-left: 10px;
  }

  .right-round-table {
    /* border-radius: 0px 25px 25px 0px; */
    padding-right: 10px;
  }

  th {
    cursor: pointer;
  }

  .search-bar {
    padding: 5px 10px 6px 10px;
    width: 100%;
  }

  .search-bar-curve {
    border-radius: 20px !important;
  }

  .search-bar-curve-left {
    border-radius: 20px 0px 0px 20px;
  }

  .search-bar-curve-right {
    border-radius: 0px 20px 20px 0px;
  }

  .wrapper {
    display: flex;
    align-items: stretch;
  }

  .content {
    min-height: 100vh;
    width: 100%;
    transition: all 0.3s;
  }

  .contentAction {
    width: 100%;
    transition: all 0.3s;
  }

  .loader-container {
    text-align: center;
    margin-bottom: -10px;
  }

  .loader-container-2 {
    margin-bottom: -150px;
    text-align: center;
    overflow: hidden;
    z-index: 14;
  }

  .loader-header {
    min-height: calc(100vh - 100px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    color: white;

    p {
      margin-top: 8px;
    }
  }

  .page__number_position {
    font-size: 12px;
    /* position: absolute; */
    width: 100%;
    margin-bottom: 10px;
  }

  //Login.css
  .background-tranquil {
    background-color: rgba(255, 255, 255, 0.7);
    border-radius: 30px;
  }

  .tranquil-spacing-1 {
    padding: 30px;
  }

  .tranquil-spacing-2 {
    padding: 30px 0px 5px 0px;
    max-width: 668px;
  }

  .tranquil-spacing-3 {
    padding: 10px 0px 5px 0px;
    max-width: 388px;
  }

  .login-logo {
    width: 290px;
    margin-bottom: 10px;
  }

  /* Buttons */
  .btn.btn-round {
    padding: 10px 50px;
    border-radius: 20px;
  }

  .btn.btn-round:hover {
    background-color: #67d4d1;
    border-color: #67d4d1;
    -webkit-transition: all 500ms;
    -moz-transition: all 500ms;
    -o-transition: all 500ms;
    transition: all 500ms;
  }

  .btn.btn-round:nth-child(2) {
    margin-left: 20px;
  }

  /* Text */
  .light-text {
    color: #ffffff;
  }

  .grey-text {
    color: #999999;
  }

  .black-text {
    color: #303235;
    font-size: 16px;
  }

  .font-size-md {
    font-size: 16px;
  }

  /* Input Styles */
  input.input-style-1 {
    /* border-color: #bbb; */
    border: 1px rgba(255, 255, 255, 0.7) solid;
    border-radius: 20px;
    background-color: rgba(255, 255, 255, 0.7);
  }

  input.input-style-1:focus {
    /* border-color: #bbb; */
    /* border-color: #32a1d9 !important; */
    border: 1px #32a1d9 solid;
  }

  /* Paddings */
  .paragraph-padding-b1 {
    padding-bottom: 15px;
  }

  .input-padding-b1 {
    padding-bottom: 25px;
  }

  .input-spacing {
    padding-left: 10px;
    padding-right: 10px;
  }

  .div-padding-b1 {
    padding-bottom: 15px;
  }

  .text-padding-rl {
    padding-right: 10px;
    padding-left: 10px;
  }

  /* Element */
  .inline-ele {
    display: inline-block;
  }
  .page-container {
    position: relative;
    text-align: center;
    color: white;
  }

  //password_recovery
  body {
    font-family: sans-serif;
  }

  input:focus,
  select:focus,
  textarea:focus,
  button:focus {
    outline: none !important;
  }

  input:active,
  select:active,
  textarea:active,
  button:active {
    outline: none !important;
  }

  .btn:focus:active {
    outline: none !important;
  }

  .btn-invisible {
    background-color: #fff;
    color: #32a1d9;
    display: inline-block;
    margin-top: 19px;
    cursor: pointer;
  }

  .btn-invisible:hover {
    color: #018abe;
  }

  .btn-invisible:active {
    color: #018abe;
  }

  .pdfobject-container {
    height: 62rem;
    border: 1rem solid rgba(0, 0, 0, 0.1);
  }

  .modal-pdf {
    min-height: 450px;
  }

  /* Reduce z-index to 2 to fix modal overlay issue */
  .affix {
    top: 0px;
    z-index: 2 !important;
    width: 100%;
  }

  .backgound-inbrace-1 {
    background: #d2edf4;
    background-image: linear-gradient(to bottom, #7fcfce, #3ea8d8 100%);
    padding: 20px 15px 15px 15px;
    position: relative;
    height: 100vh;
  }

  .backgound-inbrace-2 {
    background: #32a1da;
    background-image: linear-gradient(to bottom, #32a1da, #f8c9dd 100%);
    padding: 20px 15px 15px 15px;
    position: relative;
    height: 100vh;
  }

  .recovery-window {
    max-width: 1077px;
    padding: 50px 70px;
  }

  .button-window {
    padding: 25px;
  }

  .input-search-window {
    padding-bottom: 25px;
    padding-left: 15px;
    padding-right: 15px;
    max-width: 850px;
  }

  .input-search-border {
    padding-bottom: 5px;
    border-bottom: 1px solid #dddddd;
    width: 85%;
  }

  .inline-input {
    display: inline-block;
    padding-left: 20px;
  }

  .inline-label {
    display: inline-block;
    width: 130px;
  }

  .background-tranquil {
    background-color: rgba(255, 255, 255, 0.7);
    border-radius: 30px;
  }

  .tranquil-spacing-2 {
    padding: 30px 0px 5px 0px;
    max-width: 668px;
  }

  .logo-brand {
    width: 150px;
  }

  .version-tag {
    display: inline;
    position: absolute;
    font-weight: bold;
    font-size: 10px;
    color: #32a1d9;
    left: 332px;
    margin-top: -13px;
  }

  // thumbnail
  .small-img-window {
    position: relative;
    height: 72px;
    overflow: hidden;
    text-align: center;
    display: inline-block;
    margin-right: 10px;
    cursor: pointer;
    border-radius: 10px;
  }

  .small-img-window:hover {
    -webkit-box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.35);
    -moz-box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.35);
    box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.35);
  }
  .small-img-window.active {
    border-width: 5px;
    border-style: solid;
    border-color: white;
  }

  .small-img {
    /* margin-top: 2px; */
    height: 72px;
    /* width: 128px; */
    /* height: 72px; */
    /* object-fit: fill; */
    /* min-width: 70px; */
    /* border-radius: 10px; */
    object-fit: cover;
  }

  .image-fixed-size {
    width: auto !important;
    /* border-radius: 5px; */
    width: 128px;
    margin-bottom: 10px;
  }

  #segment-preference button {
    width: 205px;
    height: 30px;
  }

  #reason-arch-segments {
    width: 100%;
    padding: 10px;
  }

  #reason-arch-segments .btn-ib {
    padding: 10px;
    width: 250px;
  }

  .table > thead > tr > th {
    border-bottom: none;
  }

  .table > tbody > tr > td,
  .table > tbody > tr > th,
  .table > tfoot > tr > td,
  .table > tfoot > tr > th,
  .table > thead > tr > td,
  .table > thead > tr > th {
    border-top: none;
  }

  .patients-edit-info {
    margin-left: 15%;
  }

  .fileUpload-content {
    color: #000;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    white-space: break-spaces;
  }

  .placeholder-block {
    background-color: #1a1a1a !important;
    min-width: 72px;

    &::before {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      background-color: #ffffff;
      width: 100%;
      height: 100%;
      opacity: 0.1;
    }
  }

  @keyframes fading {
    0% {
      opacity: 0.1;
    }

    50% {
      opacity: 0.2;
    }

    100% {
      opacity: 0.1;
    }
  }
}


// Errors
.subtle-error {
  color: $bpp-subtle-warning;
}


// Breakpoints
.mobile-only {
  @media (min-width: 768px) {
    display: none;
  }
}

.tablet-up {
  @media (max-width: 767px) {
    display: none;
  }
  @media (min-width: 768px) {
    display: block;
  }
}