.dxtx-comment {
  border-radius: 10px;
  border: 1px solid #999999;
  padding: 10px 20px;
}

.upper-set{
  font-size: 1em;
}

.mm-size {
  width: 60px;
  font-size: 1em;
}

.mm-label {
  padding-right: 15px;
}

.mm-class {
  border-radius: 10px;
  background-color: #e8f6f6;
  color: #32a1d9;
  padding: 5px 10px;
  margin: -10px;
}

.dxtx-padding-l1 {
  padding-left: 16px;
}

.dxtx-padding-l2 {
  padding-left: 34px;
}

.row-padding-t1 {
  padding-top: 10px;
}

.checkbox {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.dxtx-heading {
  font-size: 18px;
  padding-bottom: 10px;
  color: #575e65;
}

.dxtx-group-box {
  background-color: #f7f7f7;
  border-radius: 25px;
  padding: 15px;
  margin: 0px;
  color: #575e65;
}

.btn.active-selection {
  background-color: #43a7b3;
  color: #ffffff;
}

.btn.active-selection:hover {
  background-color: #43a7b3;
  color: #ffffff;
}

.btn.active-selection:focus {
  background-color: #43a7b3;
  color: #ffffff;
}
