@media screen and (max-width: 768px) {
  .logo-brand {
    width: 150px;
  }

  .version-tag {
    display: inline;
    position: absolute;
    font-weight: bold;
    font-size: 10px;
    color: #32a1d9;
    left: 232px;
    margin-top: -13px;
  }
}

.logo-nav {
  margin-left: 50px;
}

.welcome-nav {
  float: right;
  margin-right: 50px;
}

.max-logo-width {
  text-align: left;
  margin: 20px 0px;
  max-width: 385px;
}

/* Badge */
.badge.badge-warning {
  background-color: rgba(255, 255, 255, 0.9);
  border: 1px solid #aadaea;
  margin-right: 5px;
  color: #43a7b3;
  margin-top: -2px;
  margin-bottom: 2px;
}

.badge.badge-hollow {
  background-color: initial;
  border: 1px solid #aadaea;
  margin-right: 5px;
  color: inherit;
  border-color: #aadaea;
  margin-top: -2px;
  margin-bottom: 2px;
}

.badge.badge-double {
  margin-left: -6px;
}

.badge.badge-no-border {
  border: none;
}

/* Images */
.profile-img {
  width: 40px;
  border-radius: 20px;
  background-color: #fff;
  margin-left: 15px;
}

.patient-img {
  width: 40px;
  border-radius: 20px;
  background-color: #fff;
  margin-right: 5px;
}

/* Buttons */
.btn.btn-round {
  padding: 10px 50px;
  border-radius: 20px;
}

.btn.btn-round-2 {
  padding: 10px 15px;
  border-radius: 50px;
}

.btn.btn-round-3 {
  padding: 10px 30px;
  border-radius: 20px;
}

.btn.btn-round-4 {
  border-radius: 20px;
}

.btn.btn-round-5 {
  margin: 5px;
  border-radius: 20px;
}

.btn.btn-round-6,
.btn.btn-round-6.view-plan-button {
  margin: 15px 0px 0px 0px;
  border-radius: 20px;
}

.btn.btn-round-8 {
  border-radius: 20px;
  margin-bottom: 3px;
}

.btn.btn-primary.btn-inbrace-blue.btn-round-8:hover {
  background-color: #018abe;
}

.btn.btn-primary.btn-inbrace-blue.btn-round-8:active {
  background-color: #046d94;
}

.btn.btn-primary,
.btn.btn-primary.btn-round-6,
.btn.btn-primary.btn-round-6.view-plan-button {
  background-color: #7bd1d0;
}

.btn.btn-primary:hover,
.btn.btn-primary.btn-round-6:hover,
.btn.btn-primary.btn-round-6.view-plan-button:hover {
  background-color: #4fb1b0;
}

.btn.btn-primary:active,
.btn.btn-primary.btn-round-6:active,
.btn.btn-primary.btn-round-6.view-plan-button:active {
  background-color: #3caaa8;
}

.btn.btn-primary.btn-inbrace-blue {
  background-color: #32a1d9;
  border-color: #32a1d9;
}

.btn.btn-disabled {
  background-color: #f2f2f2;
  border-radius: 25px;
  color: #c9c8cf;
}

.btn.btn-teal {
  background-color: #7bd1d0;
  border-radius: 25px;
}

.btn.btn-teal:active {
  background-color: #3caaa8;
  border-radius: 25px;
}

.btn.btn-teal:hover {
  color: #fff;
}

.btn-spacing {
  margin-left: 12px;
  margin-right: 12px;
}

.btn-spacing-b1 {
  margin-bottom: 18px;
}

.btn-spacing-b2 {
  margin: 20px 0px;
}

.btn-margin-l1,
.btn.btn-margin-l1 {
  margin-left: 10px;
}

.inbrace-btn-adjacent {
  display: inline-block;
  padding: 0px 20px;
}

.button-top-spacing {
  margin-top: 20px;
}

.btn.btn-right {
  float: right;
}

.btn.btn-left {
  float: right;
}
/* Text */
.light-text {
  color: #ffffff;
}

.grey-text {
  color: #999999;
}

.p-slim {
  margin-bottom: 0px;
}

.background-blank,
.background-blank > td {
  background-color: #ffffff;
}

.no_records {
  width: 400px;
  height: 400px;
  color: #ffffff;
  background-color: #7ececf; /* For browsers that do not support gradients */
  background-image: linear-gradient(to top right, #7ececf, #32a1d9); /* Standard syntax (must be last) */
  border-radius: 50%;
  text-align: center;
  font-size: 18px;
  vertical-align: middle;
  text-align: center;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.08), 0 6px 20px 0 rgba(0, 0, 0, 0.08);
  display: table;
  margin: 0;
  position: relative;
  margin: 10% auto 10% auto;
}

.no_records__container {
  width: 100%;
  background-color: #ffffff;
}

.no_records_content {
  height: 100%;
}

.no_records__image {
  margin-top: 40px;
  width: 250px;
}

.no_records__gradient--circle {
  border-radius: 50%;
}

.no_records__text {
  margin-top: 20px;
  font-size: 1.35em;
}

.bold-text, .bolder {
  font-weight: bold;
}

.italic-text {
  font-style: italic;
}

.underline-text {
  text-decoration: underline;
}

.underline-text:hover {
  text-decoration: underline;
}

.underline-text:focus {
  text-decoration: underline;
}

.dark-text {
  color: #3c3a3a;
}

.dark-text li {
  font-size: 15.4px;
}

.center-text {
  text-align: center;
}

.left-text {
  text-align: left;
}

.top-text {
  vertical-align: top;
}

.bottom-text {
  vertical-align: bottom;
}

.reject-text {
  color: #ff6666;
}

.cozy-text {
  padding: 0;
  margin: 0;
  color: #575e65;
}

.plan-height {
  color: #575e65;
}

.cozy-text.cozy-log {
  padding-left: 16px;
  text-indent: -16px;
}

.btn.btn-light-3 > .fa-search {
  color: #fff;
}

.cozy-paragraph {
  line-height: 1.3;
  margin-right: 50px;
}

.rejected-text.font-size-lg {
  color: #ff6666;
  font-size: 20px;
}

.rejected-text.font-size-xlg {
  color: #ff6666;
  font-size: 36px;
}

.accepted-text {
  color: #00cc66;
}

.accepted-text.font-size-lg {
  color: #43a7b3;
  font-size: 20px;
}

.accepted-text.font-size-xlg {
  color: #32a1da;
  font-size: 36px;
}

.terms-heading {
  color: #32a1d9;
  font-size: 18px;
  font-weight: bold;
}

.font-size-s {
  font-size: 10px;
}

.font-size-reg {
  font-size: 14px;
}

.font-size-md {
  font-size: 16px;
}

.font-size-lg {
  font-size: 20px;
}

.font-size-xlg {
  font-size: 26px;
}

.heading-style-main {
  font-size: 18px;
  margin-left: 18px;
  padding: 11px 5px 7px 5px;
}

.placeholder-italic::-webkit-input-placeholder {
  font-style: italic;
}

.placeholder-italic:-moz-placeholder {
  font-style: italic;
}

.placeholder-italic::-moz-placeholder {
  font-style: italic;
}

.placeholder-italic:-ms-input-placeholder {
  font-style: italic;
}

.heading-nav {
  background-color: #32a1d9;
}

.table-inbrace-heading {
  background-color: #43a7b3;
  color: #ffffff;
  height: 42px;
  border-radius: 25px;
}

.inbrace-text-default {
  color: #999999;
}

.inbrace-text-primary {
  color: #32a1d9;
}

.inbrace-text-danger {
  color: #c0392b;
}

.txplan-heading,
.txplan-heading-dark {
  color: #ffffff;
  border-radius: 20px;
  height: 25px;
  padding: 2.5px 10px;
  font-weight: bold;
}

.txplan-heading {
  background-color: #43a7b3;
}

.txplan-heading-dark {
  background-color: #3c3e42;
}

.txplan-table {
  width: 100%;
}

.txplan-table > tr > td.txplan-ignore-bottom-padding,
.txplan-table > tbody > tr > td.txplan-ignore-bottom-padding {
  padding-bottom: 0px;
}

.txplan-table > tr > td.txplan-ignore-top-padding,
.txplan-table > tbody > tr > td.txplan-ignore-top-padding {
  padding-top: 0px;
}

.txplan-table > tr > td.txplan-table-ignore,
.txplan-table > tbody > tr > td.txplan-table-ignore {
  padding: 5px 0px;
}

.txplan-table > tr > td,
.txplan-table > tbody > tr > td {
  padding: 5px 10px;
}

.txplan-border-bottom {
  border-bottom: 1px solid #000000;
}

.txplan-border-bottom-spacing {
  border-bottom: 1px solid #000000;
  height: 40px;
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.txplan-border-bottom-right {
  border-bottom: 1px solid #000000;
  border-right: 1px solid #000000;
}

.txplan-top {
  vertical-align: top;
}

.txplan-bottom {
  vertical-align: bottom;
}

.txplan-left {
  padding-left: 10px;
  padding-right: 10px;
}

.txplan-half {
  width: 50% !important;
}

.txplan-halfspan {
  width: 50% !important;
  display: inline-block;
}

.txplan-lesshalf {
  width: 30% !important;
}

.txplan-sixth-1 {
  width: 15%;
}

.txplan-sixth-2 {
  width: 19%;
}

.txplan-sixth-3 {
  width: 15.5%;
}

.txplan-sixth {
  width: 16.5%;
}

.txplan-max-1 {
  display: inline-block;
  width: 235px;
}

.txplan-left-space {
  padding-right: 10px;
}

.no-bullet {
  list-style: none;
  padding-left: 0px;
}

.no-bullet-2 {
  list-style: none;
  padding-left: 10px;
}

.txplan-height-1 {
  height: 20px;
}
.txplan-height-2 {
  height: 40px;
}
.txplan-height-3 {
  height: 60px;
}

.txplan-height-4 {
  height: 80px;
}

.txplan-height-5 {
  height: 100px;
}

.txplan-table > tr > td.txplan-width-case {
  width: 22%;
}

.txplan-padding-b1 {
  margin-bottom: 2px;
}

.box {
  display: inline-block;
  height: 20px;
  width: 20px;
  border: 1px solid #000000;
  margin-right: 5px;
  margin-top: 3px;
  margin-bottom: -3px;
  padding-bottom: 5px;
}

.box-text {
  display: inline-block;
  padding-bottom: 10px;
  height: 20px;
}

.txplan-tooth {
  width: 35px;
  display: inline-block;
  text-align: center;
}

.left-circle {
  border-top: 1px solid #000000;
  border-left: 1px solid #000000;
  border-bottom: 1px solid #000000;
  border-radius: 5px 0px 0px 5px;
}

.right-circle {
  border-top: 1px solid #000000;
  border-right: 1px solid #000000;
  border-bottom: 1px solid #000000;
  border-radius: 0px 5px 5px 0px;
}

.middle-circle {
  border-top: 1px solid #000000;
  border-bottom: 1px solid #000000;
}

.full-circle {
  border: 1px solid #000000;
  border-radius: 5px;
}

/* Input */
.inbrace-lg-btn {
  width: 80px;
  height: 80px;
  background-color: #32a1d9;
  color: #ffffff;
  border-radius: 40px;
  border: 1px solid #32a1d9;
  font-size: 40px;
}

.inbrace-lg-btn:active {
  background-color: #0089be;
  border: 1px solid #0089be;
}

.inbrace-lg-btn.inbrace-danger {
  background-color: #dd514c;
  border: 1px solid #dd514c;
}

.inbrace-lg-btn.inbrace-danger:active {
  background-color: #c0392b;
  border: 1px solid #c0392b;
}

/* Input Styles */
.input-style-1 {
  border-color: #585858;
  border-radius: 20px;
  background-color: rgba(255, 255, 255, 0.7);
}

input.input-style-2 {
  border-color: #585858;
  border-radius: 20px;
  background-color: rgba(255, 255, 255, 0.7);
  height: 42px;
}

.input-search {
  border: none;
  padding-left: 10px;
  width: 90%;
}

.input-search-addon {
  border: none;
  border-right: 2px solid #999999;
  background-color: none;
  padding-right: 10px;
}

.search-navbar {
  display: inline-block;
  width: 900px;
  vertical-align: bottom;
}

.search-nav-search {
  display: inline-block;
  width: 50%;
}

.search-nav-filter {
  display: inline-block;
  height: 45px;
  vertical-align: top;
}

.search-nav-filter .select-container {
  margin: 0px 0px 0px 20px;
}

.search-nav-filter .select-container .select-status {
  border-radius: 20px;
  color: #7a7e80;
}

.input-padding-b1 {
  padding-bottom: 25px;
}

.input-padding-b2 {
  padding-bottom: 10px;
}

.div-padding-b1 {
  padding-bottom: 15px;
}

.div-padding-b2 {
  padding-bottom: 5px;
}

.div-padding-l1 {
  padding-left: 40px;
}

.div-margin-b1 {
  margin-bottom: 15px;
}

.text-padding-rl {
  padding-right: 10px;
  padding-left: 10px;
}

.text-padding-r2 {
  padding-right: 10px;
}

.text-padding-r3 {
  width: 20px;
  display: inline-block;
}

.text-padding-r4 {
  padding-right: 40px;
  font-size: 15.4px;
  display: inline-block;
}

.text-padding-r5 {
  padding-right: 26px;
}

.text-padding-t1 {
  padding-top: 10px;
}

.text-padding-t2 {
  padding-top: 20px;
}

.text-padding-l1 {
  padding-left: 10px;
}

.teal-text {
  color: #43a7b3;
}

.fa-teal {
  background: #43a7b3;
  background: linear-gradient(to right, #7fcfce, #3ea8d8);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 1.2em;
}

.table > thead > tr > th.text-padding-l2 {
  padding-left: 26px;
}

.date-left {
  width: 16px;
  display: inline-block;
  margin-right: 5px;
  margin-left: -5px;
}

.ir-date-left {
  width: 16px;
  display: inline-block;
  margin-right: 5px;
}

.portal-view {
  padding: 20px 65px 30px 65px;
}
.ir-portal-view {
  padding: 10px 65px 30px 65px;
}

.portal-view-wide {
  padding: 20px 30px 30px 30px;
}

.portal-view.portal-view-upload {
  padding: 6px 65px 50px 65px;
}

.portal-view.portal-view-1 {
  padding: 6px 65px 50px 65px;
}

.portal-subView {
  padding: 0px 65px;
}

.portal-center {
  text-align: center;
}

.checkbox-padding-l {
  padding-left: 5px;
}

/* Element */
.inline-ele {
  display: inline-block;
}

.font-size-small {
  font-size: 11px;
}

.agreement__checkbox {
  display: inline-block;
}

.agreement__text {
  display: inline-block;
  vertical-align: top;
  padding-top: 4px;
}

@media only screen and (max-width: 768px) {
  .sm-t-padding {
    padding-top: 20px;
  }

  .recovery-window {
    padding: 20px 28px;
  }
}

@media only screen and (max-width: 1536px) {
  /* .heading-style-main {
    font-size: 18px;
    margin-left: 20px;
    padding: 9px 5px 13px 5px;
  } */
}

/* Navigation */
.container-nav {
  padding: 30px;
}

.fa-r-padding {
  padding-right: 5px;
}

.fa-float-left {
  float: left;
  padding-top: 26px;
  margin-left: -10px;
  margin-right: -50px;
}

.fa-float-left-2 {
  margin-left: -5px;
  margin-right: 5px;
}

/* Table Coloring */
.beige-table {
  background-color: #ffffff;
  padding: 2px;
}

.notso-beige-table {
  background-color: #f7f7f7;
}

.left-round-table {
  /* border-radius: 25px 0px 0px 25px; */
  padding-left: 10px;
}

.right-round-table {
  /* border-radius: 0px 25px 25px 0px; */
  padding-right: 10px;
}

tr:last-child .left-round-table {
  border-radius: 0px 0px 0px 25px;
}

tr:last-child .right-round-table {
  border-radius: 0px 0px 25px 0px;
}

.beige-dark-table:hover td,
.beige-dark-table:hover th {
  background-color: #d9d9d9;
  cursor: pointer;
}

.beige-dark-table:active td,
.beige-dark-table:active th {
  background-color: #bbbbbb;
  cursor: pointer;
}

/* Side Bar Code */
p {
  font-family: 'Poppins', sans-serif;
  font-size: 1.1em;
  font-weight: 300;
  line-height: 1.7em;
  color: #999;
}

.nav-icon {
  width: 40px;
  font-size: 1.3em;
}

.nav-icon.phone-icon {
  font-size: 22px;
  padding-left: 23px;
}

.nav-icon.help-icon {
  font-size: 17.5px;
  width: 45px;
}

a i.fa-question-circle {
  font-size: 17px;
}

a i.fa-phone {
  font-size: 17px;
}

.nav-icon.user-icon {
  font-size: 15.5px;
}

a,
a:hover,
a:focus {
  text-decoration: none;
  transition: all 0.3s;
  cursor: pointer;
}

.navbar {
  padding: 10px 10px;
  background: #fff;
  border: none;
  border-radius: 0;
  // margin-bottom: 40px;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
}

.navbar-btn {
  box-shadow: none;
  outline: none !important;
  border: none;
}

.line {
  width: 100%;
  height: 1px;
  border-bottom: 1px dashed #ddd;
  margin: 40px 0;
}

.iloader {
  width: 45px;
}

.heading-password {
  font-weight: bold;
  padding-top: 10px;
  padding-bottom: 3px;
  padding-left: 15px;
}

/* ---------------------------------------------------
    SIDEBAR STYLE
----------------------------------------------------- */
.sub-menu {
  border-bottom: 1px solid #ffffff;
}

hr.sub-divider {
  border-top: 1px solid #aadaea;
}

.sub-divider {
  margin: 0px 20px;
}

.sub-divider.inbrace-blue {
  border-top: 1px solid #e8f6f6;
  margin: 0px 0px;
}

.sub-divider.inbrace-light-green {
  border-top: 1px solid #e8f6f6;
  margin: 0px 0px;
}

#sidebar ul li a.sub-item,
#sidebar ul li span.sub-item {
  padding-left: 36px !important;
}

#sidebar ul li a.sub-item:hover,
#sidebar ul li span.sub-item:hover {
  color: #43a7b3;
}

#sidebar ul li a.sub-item-2 {
  padding-left: 50px !important;
}

#sidebar ul li a.sub-item-0 {
  padding-left: 42px !important;
}

ul li a.sub-item i,
ul li a.sub-item-0 i {
  width: 26px;
}

.first-li {
  padding: 5px 0px 3px 0px;
}

.sub-item-divider {
  padding-bottom: 1px solid #ffffff;
}

.sub-item-inline {
  display: inline-block;
}

#sidebar {
  min-width: 260px;
  max-width: 260px;
  /* background: #32a1d9; */
  color: #fff;
  transition: all 0.3s;
}

#sidebar a,
#sidebar a:focus {
  color: inherit;
}

#sidebar a:hover {
  color: #43a7b3;
}

#sidebar.active {
  /*margin-left: -300px; left nav */
}

#sidebar .sidebar-header {
  padding: 20px;
  /* background: #32a1d9; */
}

#sidebar ul.components {
  padding: 0;
}

#sidebar ul p {
  color: #fff;
  padding: 10px;
}

#sidebar ul li a {
  padding: 8px 10px;
  font-size: 0.95em;
  display: block;
}

#sidebar ul li a.sub-item-inline {
  display: inline-block;
  padding: 0px !important;
  margin: 0px;
}

#sidebar ul li a.nav-header {
  /* background-color: #32a1d9; */
  font-size: 0.95em !important;
}

#sidebar ul li a.nav-header.not-a-link:hover {
  /* background-color: #32a1d9; */
  color: #ffffff;
  cursor: default;
  background: initial;
}

#sidebar ul li a.not-a-link:hover {
  /* background-color: #32a1d9; */
  color: #ffffff;
  cursor: default;
  background: initial;
}

#sidebar ul li a.support-link:hover {
  cursor: pointer;
  /* background-color: #32a1d9; */
  text-decoration: underline;
  color: #43a7b3;
}

#sidebar ul li a:hover {
  background-color: #e8f6f6;
}

#sidebar ul li a.sub-item-selected {
  background-color: #e8f6f6;
  color: #43a7b3;
}

#sidebar ul li a:active {
  background-color: #c8eaea;
}

#sidebar ul li.active > a,
a[aria-expanded='true'] {
  color: #fff;
  /* background: #32a1d9; */
}

#sidebar {
  display: block;
}

.sidebar__logged_in {
  background-color: rgba(0, 0, 0, 0.3);
  /* border-radius: 10px; */
  color: #ffffff;
  position: absolute;
  bottom: 0px;
  left: 0px;
  margin: 0px 0px;
  padding: 10px 20px;
  width: calc(100% - 0px);
}

.sidebar__logged_in-heading {
  font-weight: bold;
}

.btn-primary--agreement {
  margin-top: 15px;
}

.logo-sidebar {
  margin: 0 auto;
  width: 220px;
  padding: 20px;
}

@media screen and (max-width: 768px) {
  #sidebar {
    /* display: none; */
  }

  div.inline-search {
    display: none;
  }
}

.heading-offset {
  margin-top: 70px;
}

a[data-toggle='collapse'] {
  position: relative;
}

#sidebar ul ul a {
  font-size: 0.95em !important;
  padding-left: 30px !important;
  /* background: #32a1d9; */
}

.logout--round:hover {
  border-radius: 0px 0px 10px 10px;
}

ul.CTAs {
  padding: 20px;
}

ul.CTAs a {
  text-align: center;
  font-size: 0.9em !important;
  display: block;
  border-radius: 5px;
  margin-bottom: 5px;
}

a.download {
  background: #fff;
  color: #7386d5;
}

a.article,
a.article:hover {
  background: #6d7fcc !important;
  color: #fff !important;
}

/* ---------------------------------------------------
    CONTENT STYLE
----------------------------------------------------- */

#content {
  padding: 0px 0px 30px 0px;
  min-height: 100vh;
  width: 100%;
  max-width: calc(100% - 260px);
  transition: all 0.3s;
  position: relative;
  background-color: #f8f8f8;
}

#content-alt {
  padding: 0px 0px 30px 0px;
  min-height: 100vh;
  width: 100%;
  transition: all 0.3s;
}

/* ---------------------------------------------------
    MEDIAQUERIES
----------------------------------------------------- */

@media (max-width: 768px) {
  #sidebar {
    /* margin-left: -300px; */
  }
  #sidebar.active {
    margin-left: 0;
  }
  #sidebarCollapse span {
    /* display: none; */
  }
}

.pull-right {
  float: right;
}

.fa.fa-chevron-down.pull-right {
  position: relative;
  top: 12px;
}

.pull-left {
  float: left;
}

.inline-block {
  display: inline-block;
}

/* ---------------------------------------------------
    MISC
----------------------------------------------------- */
.submit-case-button {
  margin-top: 20px;
}

.button--bell {
  position: relative;
  display: inline-block;
  left: -242px;
  top: 8px;
}

.resume-progress-record .button--bell {
  left: -212px;
}


.fa-spin-mod {
  color: #79cccf;
  font-size: 25px;
}
