.show-on-mobile {
  display: none;
}

@include for-size(tablet) {
  .hide-on-mobile {
    display: none !important;

    &--top-border {
      border: 0px solid #e1e2e2 !important;
      background-color: initial;
      padding: 0px;
    }
  }

  .show-on-mobile {
    display: initial;
  }
}

@include for-size(desktop) {
  .show-on-small-desktop {
    display: initial;
  }
}