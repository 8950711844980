.resizer {
  cursor: ew-resize;
  height: 100%;
  position: absolute;
  top: 0;
  right: -4px;
  width: 16px;
  background-color: #303235;
  transition: all 0.3s;
  transform: scaleX(0.5);
  opacity: 0;
  z-index: 2;

  &:hover {
    transform: scaleX(1);
    background-color: #a2a3a5;
    opacity: 1;
  }
}
