.pagination__container {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  .pagination__number {
    float: none;
    display: inline-block;
    margin-right: 16px;
    font-size: $body-font-size;
    color: $sonicSilver;
  }

  .pagination {
    float: none;
    display: inline-block;
    margin: 0;

    li {
      > a {
        border-radius: $border-radius;
        margin-right: 4px;
        background-color: $pearl;
        border: 1px solid $platinum;
        color: $sonicSilver;
        cursor: pointer;

        &:hover {
          color: $turquoise;
        }
      }
      &.active > a {
        color: $turquoise;
        font-weight: bold;
      }
      &.disalbled > a {
        color: $platinum;
      }
      &:last-child > a {
        margin-right: 0;
      }
    }
  }
}

@include for-size(tablet) {
  .pagination__container{
      justify-content: center;
  }

  .pagination__number{
      display: none !important;
  }
}
