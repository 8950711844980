.div-table {
  &--alert {
    float: right;
    margin-top: 2px;
  }

  &--heading {
    padding: $general-spacing $general-spacing 0 $general-spacing;
    display: inline-block;
    cursor: pointer;
    border-bottom: 1px solid $platinum;

    span {
      font-family: $badge-font-family;

      span.arrow.dsc {
        border-top: 4px solid $sonicSilver;
      }

      span.arrow.asc {
        border-bottom: 4px solid $sonicSilver;
      }
    }

    &-container {
      margin: $general-spacing $general-spacing * 2 0px $general-spacing * 2;
    }

    &--empty {
      margin-bottom: -8px;
    }
  }

  &--content {
    display: flex !important;
    flex-flow: wrap;
    align-items: center;
    margin: 0px $general-spacing * 2;
    border-bottom: 1px solid #e1e2e2;
    max-height: 100px;
    height: 100px;

    &--no-notes {
      height: 80px;
    }

    &--accounts {
      height: 42px;
    }

    .action-required {
      border: 1px solid #f3b777;
      transition: transform 0.2s;
      box-shadow: 3px 4px 4px rgba(0, 0, 0, 0.2);
    }

    .action-required:hover {
      opacity: 0.6;
    }

    .action-required:active {
      opacity: 0.4;
      box-shadow: none;
    }
    &:hover {
      color: $turquoise;

      .additional-actions i {
        color: $sonicSilver;
      }

      .badge--primary,
      .badge--primary span,
      .badge--primary a {
        color: $tangerine;
      }

      .close span,
      .note-counter {
        color: $sonicSilver;
      }

      & div,
      span,
      a {
        color: $turquoise;
      }

      .ql-tooltip,
      .ql-action {
        color: #444;
      }

      .modal-content {
        cursor: default;
        .case-view-note-modal {
          span,
          .note__subject {
            color: #707070 !important;
          }
        }
      }
    }

    .disabled {
      cursor: not-allowed;
      opacity: 0.5;
    }

    .disabled:hover {
      cursor: not-allowed;
      opacity: 0.5;
    }

    &:last-child {
      border-bottom: none;
    }
  }

  &--item {
    display: inline-block;
    cursor: pointer;
    padding-left: $general-spacing * 1.5;
    line-height: 1.5em;
    vertical-align: bottom;
    width: 90%;

    &-icon {
      display: inline-flex;
      width: 100%;
      > span:first-child {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        flex: auto;
      }
    }

    &-restrict-text {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      border-radius: 4px;
      &:has(.action-required) {
        padding: 10px 0px;
      }

      &--default {
        color: $turquoise;
      }
      &--primary {
        color: $tangerine;
      }
    }

    .case-id-label {
      margin-right: 5px;
      @media (max-width: 878px) {
        display: inline-block;
        width: 100%;
      }
    }

    .add-tooltip-class {
      display: flex;
    }

    .hold-length {
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .row-label {
      font-weight: bold;
      font-size: 0.81em;
    }

    .clinical--icon {
      width: 2em;
      height: 1.9em;
      background-color: $electricGreen;
      border-radius: 4px;
      margin-top: 1em;
      font-family: $badge-font-family;
      padding: 0px 0px 6px 4px;
      font-size: 0.81em;
    }

    &-notes {
      display: flex;

      &-content {
        width: 90%;

        &--not-applicable {
          padding-top: 24px;
        }

        .tooltip-inner {
          word-wrap: break-word;
          word-break: break-word;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 6;
          line-clamp: 6;
          -webkit-box-orient: vertical;
        }
      }

      .navigator-right-icon {
        padding: 8px 12px;
        margin-left: $general-spacing;
        background-color: $eggshell;
        border-radius: 3px;
      }
    }

    .additional-actions {
      i:hover {
        color: $sonicSilver;
        background-color: $platinum;
      }

      .modal-lg-pdf {
        cursor: default;

        .toolbar-filename,
        .rpv-toolbar span {
          color: whitesmoke;
        }
      }
    }

    &-placeholder {
      &-animation {
        margin-right: 20px;
        border-radius: 3px;
        height: 20px;
        background: linear-gradient(270deg, #f9f8f8, #e7e5e5);
        background-size: 400% 400%;
        -webkit-animation: shimmer 2s ease infinite;
        -moz-animation: shimmer 2s ease infinite;
        animation: shimmer 2s ease infinite;
      }

      &:first-child {
        margin-left: 0px;
      }

      &:last-child {
        margin-right: 0px;
      }
    }

    @-webkit-keyframes shimmer {
      0% {
        background-position: 0% 50%;
      }
      50% {
        background-position: 100% 50%;
      }
      100% {
        background-position: 0% 50%;
      }
    }
    @-moz-keyframes shimmer {
      0% {
        background-position: 0% 50%;
      }
      50% {
        background-position: 100% 50%;
      }
      100% {
        background-position: 0% 50%;
      }
    }
    @keyframes shimmer {
      0% {
        background-position: 0% 50%;
      }
      50% {
        background-position: 100% 50%;
      }
      100% {
        background-position: 0% 50%;
      }
    }
  }

  &--group {
    display: block;
  }
}

.case-gen2-text,
.case-list-mobile-submission-date {
  font-weight: normal;
  font-size: 0.81em;
}

.show-on-mobile .doctor-name {
  font-style: italic;
}

@include for-size(tablet) {
  .div-table {
    &--content {
      max-height: none;
      height: auto;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      cursor: pointer;
      background-color: $pearl;
      padding: $general-spacing;
      margin-left: -$general-spacing * 2;
      margin-right: -$general-spacing * 2;
      margin-bottom: $general-spacing;
      padding-top: 0px;
      padding-bottom: 0px;
      border: 1px solid $platinum;
      border-radius: 3px;

      &:last-child {
        border-bottom: 1px solid $platinum;
      }
    }

    &--item {
      flex: 50%;

      span {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      &.show-on-mobile span {
        white-space: break-spaces;
      }

      &-0 {
        flex: 60%;
        min-width: 0;
      }

      &-1 {
        flex: 40%;
      }

      &-2 {
        padding-top: 0;
        display: flex !important;

        .clinical--icon {
          margin: 1px 0 0 8px;
        }
      }

      &:nth-child(even) {
        text-align: right;
      }

      .case-gen2 {
        float: none;
        margin: 0 0 -3px 4px;
      }

      .case-id {
        padding-top: 2px;
      }
    }
  }
}
