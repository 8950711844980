.react-autosuggest__suggestions-container--open {
  border: 1px solid $platinum;
  border-radius: $border-radius;
  background-color: $pearl;
  box-shadow: none;

  &:hover {
    border-color: $turquoise;
  }

  li {
    &:hover {
      background-color: $jade;

      .description-detail {
        color: $pearl;
      }

      .icon--primary {
        color: $pearl;
      }
    }

    &:active {
      background-color: $wave;
    }
  }
}
