.dropdown-input {
  box-shadow: none;
  min-width: 230px;
  line-height: 18px;

  &__control {
    border-radius: $border-radius;
    padding: 0px $general-spacing * 2;
    background-color: $pearl;
    box-shadow: none;

    &--is-focused,
    &:hover {
      border-color: $turquoise;
    }

    &.dropdown-input__control--is-disabled {
      cursor: not-allowed;
      pointer-events: auto;
      background-color: $platinum;
      border-color: $pearl;
    }
  }

  &__single-value {
    margin-left: 0px;
    margin-right: 0px;
  }

  &__value-container {
    border-color: $turquoise;
    padding: 0;
    box-shadow: none;
  }

  &__menu {
    border-radius: 0px 0px $border-radius $border-radius;
  }

  &__option {
    padding: $general-spacing * 0.5 $general-spacing;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &--is-focused {
      color: $pearl;
      background-color: $primary-hover;
    }
    &--is-selected {
      color: $pearl;
      background-color: $primary-default;
    }
    &:active {
      color: $pearl;
      background-color: $primary-active;
    }
  }

  &__indicator {
    margin-right: -12px;

    &.dropdown-input__loading-indicator {
      font-size: 4px;
    }
  }
}
