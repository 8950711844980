button {
  &:active {
    outline: none;
  }
  &:focus {
    outline: none;
  }
  &:hover {
    outline: none;
  }
}

.btn {
  border-radius: $border-radius;
  min-width: 120px;
  padding: 8px 16px;
  text-align: center;
  font-size: $body-font-size;
  color: $pearl;

  &:active {
    outline: none;
  }
  &:focus {
    outline: none;
  }
  &:hover {
    outline: none;
  }

  &.btn--primary {
    background-color: $primary-active;
    &:hover {
      background-color: $primary-hover;
    }
    &:active {
      background-color: $primary-active;
    }
    &:disabled {
      background-color: $primary-disabled;
    }
    &.btn--disabled {
      background-color: $primary-disabled;
      cursor: not-allowed;
    }
  }

  &.btn--secondary {
    background-color: $secondary-active;
    color: $jade;
    border: 1px $secondary-disabled solid;

    &:hover {
      background-color: $secondary-hover;
      border: 1px $jade solid;
    }
    &:active {
      background-color: $secondary-active;
      color: $wave;
      border: 1px $wave solid;
    }
    &:disabled {
      background-color: $secondary-disabled;
      border: 1px $secondary-disabled solid;
    }
  }

  &.btn--dark {
    background-color: $dark-active;
    border-color: $pearl;
    &:hover {
      background-color: $dark-hover;
    }
    &:active {
      background-color: $dark-active;
    }
    &:disabled {
      background-color: $dark-disabled;
    }
  }

  &.btn--dark2 {
    background-color: $almostBlack;
    &:hover {
      background-color: $almostBlack;
    }
    &:active {
      background-color: $almostBlack;
    }
    &:disabled {
      background-color: $almostBlack;
    }
  }

  &.btn--revise {
    background-color: $revise-default;
    &:hover {
      background-color: $revise-hover;
    }
    &:active {
      background-color: $revise-active;
    }
    &:disabled {
      background-color: $revise-disabled;
    }
  }

  &.btn--danger-outline {
    background-color: $secondary-active;
    color: $revise-active;
    border: 1px $secondary-disabled solid;

    &:hover {
      border: 1px solid $revise-hover;
    }
    &:active {
      border: 1px solid $revise-active;
    }
    &:disabled {
      border: 1px $revise-disabled solid;
    }
  }

  &:not(:last-child) {
    margin-right: $general-spacing;
  }
}


