.profile-heading {
  width: 150px;
  font-weight: bold;
  display: inline-block;
  padding-left: 15px;
}

.page-heading-solo {
  margin-bottom: 20px;
}

.page-tab-content-section {
  padding-bottom: 20px;
}

.page-tab-content-section-contact {
  padding-bottom: 40px;
}

.table-info-view {
  padding: 0px 15px;
}

// .error-view {
//   position: absolute;
// }

table > thead.table-inbrace-dark-industry-2 > tr > th {
  border: none;
}

table > thead.table-inbrace-dark-industry-2 > tr > th:first-child {
  border-radius: 10px 0px 0px 0px;
  padding-left: 16px;
}

table > thead.table-inbrace-dark-industry-2 > tr > th:nth-last-child(2) {
  border-radius: 0px 10px 0px 0px;
}

table > thead.table-inbrace-dark-industry-2 > tr > th:nth-last-child(1) {
  background: rgba(0, 0, 0, 0);
  opacity: 1;
}

table > tbody.table-inbrace-dark-industry-2 > tr:nth-child(odd) > td {
  background-color: rgba(17, 17, 17, 0.06);
  // color: #000000;
}

table > tbody.table-inbrace-dark-industry-2 > tr:nth-child(even) > td {
  background-color: rgba(17, 17, 17, 0.06);
  // color: #000000;
}

table > tbody.table-inbrace-dark-industry-2 > tr:last-child > td:nth-last-child(2) {
}

table > tbody.table-inbrace-dark-industry-2 > tr:last-child > td:first-child {
}

table > tbody.table-inbrace-dark-industry-2 > tr > td:hover {
  /* background-color: #9ea3a8; */
}

table > tbody.table-inbrace-dark-industry-2 > tr > td:last-child {
  background-color: rgba(0, 0, 0, 0);
  opacity: 1;
}

table > tbody.table-inbrace-dark-industry-2 > tr {
  height: 44px;
}

table > tbody.table-inbrace-dark-industry-2 > tr > .vertical-align-middle {
  vertical-align: middle;
}

table.table-condensed.table-industry.account-table {
  border-collapse: separate;
  border-spacing: 0 10px;
  padding-top: 0px;
  margin-bottom: 0px;
}

table.table-condensed.table-industry.address-table.address-table-condensed {
  border-collapse: collapse;
  border-spacing: 0;
  padding-top: 0px;
  margin-top: 30px;
  margin-bottom: 0px;

  tbody > tr:first-child {
    height: 40px;

    td {
      vertical-align: bottom;
    }

    td:first-child {
      border-radius: 10px 0px 0px 0px;
    }

    td:nth-child(3) {
      border-radius: 0px 10px 0px 0px;
    }
  }

  tbody > tr {
    height: 25px;

    td {
      padding-bottom: 0px;
      padding-top: 0px;
    }
  }

  tbody > tr:last-child {
    height: 40px;

    td {
      vertical-align: top;
    }

    td:first-child {
      border-radius: 0px 0px 0px 10px;
    }

    td:nth-child(3) {
      border-radius: 0px 0px 10px 0px;
    }
  }
}

.auto-width {
  width: 60%;
}

.edit-width {
  width: 200px;
}

.type-width {
  /* width: 200px; */
}

.fill-width {
  width: 16.1% !important;
}

.address-width {
  width: 15%;
}

.field-width {
  width: 8%;
}

.small-width {
  width: 5.7%;
}

.notify-width {
  width: 200px;
}

.table-add {
  display: block;
  text-align: right;
  margin-right: 225px;
}

.table-add-decor {
  text-decoration: underline;
  cursor: pointer;
  height: 16px;
  display: inline-block;
}

.table-add-decor:hover {
  font-weight: bold;
}

.general-error {
  color: #d43f3a;
}

.info-error {
  color: #d43f3a;
  padding-left: 20px;
}

.erp-error {
  position: absolute;
  width: 80%;
  top: 210px;
}

.checkbox-button {
  cursor: pointer;
}

/* For User Information on Create/Edit Account pages */

table.table-user {
  width: 41.3%;
}

table.table-user:nth-last-child(2) {
  float: left;
}

table.table-user > thead > tr > th {
  background-color: #3c3e42;
  color: #ffffff;
  border: none;
  border-radius: 10px 10px 0px 0px;
  padding-left: 20px;
}

table.table-user > tbody > tr > td {
  background-color: #c6c9cc;
  color: #000000;
}

table.table-user > tbody > tr:first-child > td {
  padding-top: 15px;
}

table.table-user > tbody > tr:last-child > td {
  padding-bottom: 15px;
}

table.table-user > tbody > tr:last-child > td {
  border-radius: 0px 0px 10px 10px;
}

table.table-user.add {
  margin-right: 50px;
}

table.table-user.edit {
  width: 46.3%;
}

.table.table-user.edit.bpp {
  width: 52%;
}

.table.table-user.edit.csi .profile-edit-window {
  overflow: visible;
}

table.table-user.edit > tbody > tr:first-child > td {
  padding-top: 18px;
}

table.table-user.edit > tbody > tr > td:last-child {
  background-color: rgba(0, 0, 0, 0);
  opacity: 1;
}

table.table-user.edit > thead > tr > th > span:last-child {
  float: right;
  margin-right: 10px;
}

table.table-user.edit > thead > tr > th:nth-last-child(1) {
  background: rgba(0, 0, 0, 0);
  opacity: 1;
}

table.table-user.edit > thead > tr > th.edit-width {
  width: 15.5%;
}

@media only screen and (min-width: 1537px) {
  table.table-user.edit > thead > tr > th.edit-width {
    width: 10.5%;
  }
}

/* End User Information on Create Account page */
