$node_size: 23;
$node_spacing: 0;
$max_nodes: 10;
$max_list_size: 500;
$last_child: 0;
$success_color: #1b8e60;
$failure_color: #e36666;

.cad-process {
  .button-panel {
    width: 500px;
    margin: 0 auto;
    text-align: center;

    button {
      width: 150px;
      margin: 10px 10px 0 10px;
    }
  }

  .cad-selection {
    width: 95px;
    display: inline-block;
    vertical-align: top;
  }
}
ul.cad-workflow {
  margin: 40px 0px 0px 20px;

  li {
    width: $node_size * 1px;
    height: $node_size * 1px;
    line-height: $node_size * 1px;
    font-size: 12px;
    font-weight: bold;
    text-align: center;
    border-radius: 50%;
    background: #fff;
    display: block;
    color: #000;
    position: relative;
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 0;
    border: 1px solid #9ea3a8;
    cursor: pointer;

    &:after {
      content: '';
      position: absolute;
      top: 50%;
      left: 23px;
      width: 12px;
      height: 1px;
      background: #9ea3a8;
    }
  }

  li p {
    position: relative;
    left: -87px;
    text-align: center;
    width: 100px;
    color: inherit;
    padding: 0px 10px 0px 10px;
    line-height: 18px;
  }

  li:last-child {
    margin-top: 30px;
  }

  li.process-failure {
    background: #e36666;
    border-color: #e36666;
  }

  li.process-success {
    background: #1b8e60;
    border-color: #1b8e60;
  }

  li.process-in-progress {
    box-shadow: 0 0 10px #00a0df;
    animation: border-pulsate 1.5s infinite;

    p {
      font-weight: bold;
    }
  }
}

.process-box {
  margin: 15px 0px 10px 0px;
  padding: 12px;
  border: 1px solid #9ea3a8;
  border-radius: 10px;
  width: calc(100% - 150px);
  display: inline-block;
  min-height: 120px;
}

.process-box-intro {
  max-width: 570px;
  text-align: center;
  margin: 0 auto 20px auto;
}

.process-box-title {
  font-weight: bold;
  margin-bottom: 15px;
  text-align: center;
}

.process-box-label-section {
  margin-top: 15px;
  margin-bottom: 15px;
}

.process-failure {
  line-height: 24px;
}

.process-retry {
  margin-bottom: 10px;
}

.process-return {
  margin-top: 20px;
  display: block;
}

input[type='checkbox'].label-checkbox {
  margin-right: 5px;
}

.return-disabled {
  pointer-events: none;
}

.margin-top-10 {
  margin-top: 10px;
}

.margin-bottom-15 {
  margin-bottom: 15px;
}

.label-selection {
  margin: 0 auto;
  width: 90%;
}

.label-output {
  margin-bottom: 20px;
  height: 70px;
}

.production-process {
  padding-top: 20px;

  .process_tracker ul.workflow li {
    &:last-child p {
      line-height: 19px;
      width: 110px !important;
      margin-left: -20px;
    }
  }

  &__gen-2 {
    margin-top: 30px;

    &__labels {
      margin-top: 30px;

      .work-ref-label {
        margin-bottom: 20px;
      }
    }

    &__wfr-in-progress {
      padding: 30px 0 20px;
      text-align: center;
    }
  }
}

.margin-top-30 {
  margin-top: 30px;
}
