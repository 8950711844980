.permission-box {
  margin-top: 10px;
  background-color: #c6c9cc;
  margin-left: 35px;
  padding: 10px 20px;
  border-radius: 10px;
  min-height: 100px;
}

.permission_indent {
  margin-left: 0px;
}

.permission_indent-inner {
  margin-left: 25px;
  margin-bottom: 2px;
}

.edit-position {
  font-size: 14px;
  float: right;
}

.page-heading-offset {
  margin-top: 29px;
}

.page-subheading-role {
  margin-left: 35px;
  font-size: 16px;
}

.page-role-content {
  background-color: rgba(17, 17, 17, 0.06);
  margin-top: 16px;
  padding: 15px 10px;
  border-radius: 10px;
}

.role_col,
.permission_col {
  display: inline-block;
  width: calc(100% - 400px);
  margin: 0px;
  vertical-align: top;
  margin-left: 25px;
  margin-right: 25px;
}

.role_col {
  width: 325px;
  margin-right: 0px;
}

.permission_col {
  border-left: 1px solid #adb0b4;
}

.page-heading-role,
.page-heading-role-2 {
  font-size: 18px;
}

.page-heading-role-2 {
  min-height: 120px;
}

.permission_col > .page-heading-role {
  margin-left: 35px;
}

.role_col > .page-heading-role,
.permission_col > .page-heading-role {
  width: calc(100% - 35px);
}

.container_role {
  background-color: #c6c9cc;
  margin-left: -5px;
  margin-right: 5px;
  padding: 10px;
}

.selected-item-row.select-role-offset {
  margin-left: -8px;
  margin-right: 8px;
}

.item-row.select-role-offset:hover {
  margin-right: 8px;
}

.item-row.select-role-offset {
  margin-left: -8px;
  margin-right: -5px;
}

.align-right-role {
  margin-right: 15px;
  margin-top: 3px;
  float: right;
}

.permission-slim {
  margin-left: 20px;
}

.button-role-container {
  margin-left: 35px;
  margin-top: 10px;
}

.permission-col-1 {
  width: 50%;
}

.edit-role {
  float: right;
  font-size: 14px;
  cursor: pointer;
}

.role-description {
  font-size: 16px;
  margin-bottom: 7px;

  label {
    float: left;
    width: 130px;
    padding-top: 5px;
    padding-bottom: 5px;
    vertical-align: top;
  }

  div {
    width: 64%;
    display: inline-block;
  }
}

.btn.btn-light.btn-closer {
  margin-left: 10px;
}

.role-text,
.role-textarea {
  border-radius: 10px;
}

.role-textarea {
  height: 150px;
  border-radius: 10px;
}

input[type='checkbox'].checkbox-button-permission {
  margin: 0px 5px 0px 0px;
  cursor: pointer;
}

.checkbox-content {
  cursor: pointer;
}

.role-title {
  font-size: 20px;
  margin-bottom: 10px;
}

.page-subheading-role-2 {
  margin-top: 40px;
}

.edit-position {
  cursor: pointer;
}
