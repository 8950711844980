
.arrow_b9bbag-o_O-arrow__direction__left_shhpn5-o_O-arrow__size__medium_9f7hgo {
  margin-left: 5%;
}

.arrow_b9bbag-o_O-arrow__direction__right_174p6a9-o_O-arrow__size__medium_9f7hgo {
  margin-right: 5%;
}

.preview-text-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-overflow: ellipsis;
  width: 90%;
  white-space: nowrap;
  overflow: hidden;
  font-size: 30px;
  text-align: center;
}

.img-hide {
  display: none;
}

.stack-on-model {
  display: block;
  color: #fff;
  position: relative;
  text-align: center;
  top: 50%;
  /* -webkit-transform: translateY(-50%); */
  /* -ms-transform: translateY(-50%); */
  /* transform: translateY(-50%); */
}

.container-image {
  position: relative;
  text-align: center;
  color: white;
}

.image-text-centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-overflow: ellipsis;
  width: 90%;
  white-space: nowrap;
  overflow: hidden;
}

.loader-center {
  top: 0;
  -webkit-transform: translateY(25%);
  -ms-transform: translateY(25%);
  transform: translateY(25%);
}

.image-new-indicator {
  position: absolute;
  bottom: 0;
  width: 100%;
  z-index: 10px;
  color: white;
  background: rgba(13, 13, 13, 0.9);
}

.stl-viewer-lightbox {
  // opacity: 0.8;
  background-color: #eaeaea;
  // cursor: auto;
  // max-height: calc(100vh - 90px);
  // position: fixed;
  // top: 50%;
  // left: 50%;
  // margin: -182px 0 0 -182px;
  // width: 400px;
  // height: 400px;
}

.remove-button {
	position: absolute;
	top: -5px;
	right: -10px;
}

.image-viewer-remove {
  position: relative;
  margin: 10px;
  margin-top: 20px;
  overflow: visible !important;
}
