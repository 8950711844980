$light-gray: #394042;
$gray: #383c3f;
$dark-gray: #303235;

.manual-wire-loop {
    display: flex;
    flex-direction: row;

    .first-item {
        min-width: 130px;
        border: 1px solid $gray;
        color: #fff;
        padding: 10px;
        background-color: $gray;
    }

    .manual-wire-loop-element {
        min-width: 130px;
        border: 1px solid $dark-gray;
        color: #fff;
        background-color: $light-gray;

        .manual-wire-loop-select {
            background-color: $light-gray;
            width: 100%;
            height: 100%;
            padding: 10px;
            color: #fff;

            &.error {
                border: 1px solid red;
            }

            svg {
                color: #fff;
            }
        }
    }

    .menu-paper {
        background-color: $gray;
        color: #fff;
    }
}
