@use "../styles/abstracts/mixins" as mixins;

$listHeight: 380px;

.itero-files-selection {
  .title {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #707070;
  }

  background-color: #f8f8f8;
  padding: 32px;
  display: flex;
  flex-direction: column;
  width: 100%;

  @include mixins.rowGap(16px);

  .ib-checkbox,
  .ib-radio {
    padding: 0;
    margin: 0;

    .form-checkbox-label {
      display: flex;
      cursor: pointer;
      margin: 0;

      .form-checkbox-input {
        margin-top: 0px;
      }
    }
  }

  .itero-files-scans-section-list-alert {
    text-align: left;
    margin: 0;
  }

  .search {
    .text-input {
      padding: 4px 40px 4px 16px;
      text-overflow: ellipsis;
    }
  }

  .list-card {
    margin: 0;
    padding: 8px 16px 14px;
    // min-height: 266px;

    .itero-loader-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: $listHeight;
    }

    .list {
      height: $listHeight;
      overflow-y: auto;

      .border-bottom {
        border-bottom: 1px solid #e1e2e2;
      }

      &__header,
      &__item {
        padding: 10px 0px;
      }

      &__header {
        display: flex;
        justify-content: space-between;

        @include mixins.columnGap(16px);

        .header {
          &__left,
          &__right {
            display: flex;
            align-items: center;
            flex: 0 1 50%;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            cursor: pointer;

            span.arrow.dsc {
              border-top: 4px solid #707070;
            }

            span.arrow.asc {
              border-bottom: 4px solid #707070;
            }
          }

          &__left {
            justify-content: flex-start;
          }
          &__right {
            justify-content: flex-start;
          }
        }
      }

      &__item {
        display: flex;
        justify-content: space-between;

        @include mixins.columnGap(16px);

        &--not-found {
          justify-content: left;
        }

        .item {
          &__left,
          &__right {
            display: flex;
            align-items: center;
            flex: 0 1 50%;

            @include mixins.columnGap(8px);
          }

          &__left {
            justify-content: flex-start;

            svg {
              filter: grayscale(1);
            }

            .ib-radio {
              display: flex;
              align-items: center;

              @include mixins.columnGap(8px);

              .form-radio-label {
                padding: 0;
                margin: 0;
                cursor: pointer;
                display: flex;
                flex-direction: column;

                .patient-name {
                  max-width: 150px;
                  word-wrap: break-word;
                }
              }
            }
            .checkmark {
              height: 20px;
            }
          }

          &__right {
            justify-content: flex-start;
          }
        }
      }
    }
  }

  .no-license-numbers-message {
    display: flex;
    flex-direction: column;

    @include mixins.rowGap(16px);

    a {
      text-decoration: underline;
    }
  }

  .show-used-field-container {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }

  .load-itero-photos-field-container {
    margin: 16px 0 0 0 !important;
  }
}

@media screen and (max-width: 1025px) {
  .itero-files-selection {
    .list-card {
      .list {
        &__header {
          .header {
            &__left {
              flex: 0 0 95%;
            }
            &__right {
              display: none;
            }
          }
        }
        &__item {
          .item {
            &__left {
              flex: 0 0 95%;
            }
            &__right {
              display: none;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .itero-files-selection {
    .list-card {
      .list {
        &__header {
          .header {
            &__left {
              flex: 0 0 50%;
            }
            &__right {
              display: flex;
            }
          }
        }
        &__item {
          .item {
            &__left {
              flex: 0 0 50%;
            }
            &__right {
              display: flex;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 599px) {
  .itero-files-selection {
    .list-card {
      .list {
        &__header {
          .header {
            &__left {
              flex: 0 0 95%;
            }
            &__right {
              display: none;
            }
          }
        }
        &__item {
          .item {
            &__left {
              flex: 0 0 95%;
            }
            &__right {
              display: none;
            }
          }
        }
      }
    }
  }
}
