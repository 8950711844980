$scrollbar_color: rgba(black, 0.1);
$scrollbar_thumb_color: rgba(black, 0.2);
$scrollbar_hover_color: rgba(black, 0.5);
$scrollbar_border_radius: 10px;
.prospect-input-label-error {
  color: $ruby;
  margin-left: 0px;
}

.prospect-note-container {
  max-height: 350px;
  overflow-y: auto;
  scrollbar-width: thin;
  @media (min-width: 768px) {
    max-width: 250px;
  }

  &::-webkit-scrollbar {
    background-color: $scrollbar_color;
    height: 6px;
    width: 6px;
    border-radius: $scrollbar_border_radius;
  }
  &::-webkit-scrollbar-thumb {
    background-color: $scrollbar_thumb_color;
    border-radius: $scrollbar_border_radius;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: $scrollbar_hover_color;
  }

  svg {
    cursor: pointer;
    margin-right: 10px;
  }

  .case-view-note-next {
    border-top: 1px solid #e1e2e2;
    padding: 5px;
    width: 98%;
  }

  .note-disabled {
    cursor: not-allowed;

    a {
      pointer-events: none;
      color: rgba(0, 0, 0, 0.1);
    }
  }

  .dropdown {
    cursor: pointer;
    height: 100%;

    &-menu {
      font-size: 1em;
      border-radius: $border-radius;

      li a {
        &:hover,
        &:focus {
          color: $pearl;
          background-color: $primary-hover;
        }

        &:active {
          color: $pearl;
          background-color: $primary-active;
        }
      }
    }
  }
}
