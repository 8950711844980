.filter {
  &__item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    margin: calc(#{$general-spacing}/2) 0;

    &:hover {
      color: $turquoise;
    }

    &--active {
      color: $turquoise;
      font-weight: bold;
    }

    &--subfilter {
      padding-bottom: calc(#{$general-spacing}/2);
      border-bottom: 1px solid $platinum;
    }
  }
}
