$dark-gray: #303235;
$light-gray: #394042;

#manual-wire-table-header {
    display: flex;
    flex-direction: row;

    .manual-wire-table-header-element {
        min-width: 130px;
        height: 40px;
        border: 1px solid $light-gray;
        color: #fff;
        padding: 10px;
        background-color: $dark-gray;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;

        &.error {
            color: red;
        }
    }
}
