.cad-window {
  max-width: 700px;
  margin: 18px auto 0px auto;

  .modal {
    text-align: left;
  }
}

.cad-window .paragraph {
  margin-bottom: 15px;
}

.cad-window .paragraph > div {
  margin-bottom: 10px;
}

.select-label.select-label-inline {
  display: inline-block;
  width: 100px;
}

.select-wire {
  width: 250px;
}

.select-wire-readonly {
  display: inline-block;
}

.ip-worksheet-upload {
  width: 125px;
  height: 140px;
  display: inline-block;
  font-size: 14px;
  padding: 10px;
  border-color: #000;

  .fileUpload-content .font-size-lg {
    font-size: 34px;
  }

  .fileUpload-content .accepted-text.font-size-lg {
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    max-height: 40px;
  }
}

.ip-worksheet-upload:hover {
  border-color: #018abe;
  color: #018abe;
  cursor: pointer;
}

.ip-worksheet-upload-disabled {
  width: 125px;
  height: 140px;
  display: inline-block;
  font-size: 14px;
  border: 1px solid #3c3e4280;
  border-radius: 10px;
  padding: 10px;
  color: #3c3e4280;
  cursor: not-allowed;
}

.ip-worksheet-zone {
  margin-right: 20px;
}

.ip-worksheet-wire {
  height: 140px;
  vertical-align: top;
}

.ip-worksheet-wire,
.ip-worksheet-zone {
  display: inline-block;
}

// NEW

.in-progress {
  margin-bottom: 20px;
}

.conversion-icon {
  font-size: 30px;
}

.btn-ip {
  width: 150px;
}

.result {
  margin-top: 30px;
  margin-bottom: 20px;
}

.result > p {
  line-height: 20px;
}

.ip-actions {
  margin-top: 20px;
}

.ip-actions button {
  width: 150px;
}

.ip-warning {
  margin-top: 5px;
  margin-left: 10px;
  text-align: left;
  color: red;
}
