#trackshipments {
  svg {
    .cls-1 {
      fill: #303235;
    }
    height: 2em;
  }
}

#trackshipments-footer {
  text-align: center !important; 
}