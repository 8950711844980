$scrollbar-color: rgba(white, 0.1);
$scrollbar-thumb-color: rgba(white, 0.2);
$scrollbar-hover-color: rgba(white, 0.5);
$scrollbar-border-radius: 10px;

div.record-viewer-popout {
  box-sizing: border-box;
  background: #383c3f;
  padding: 16px;

  .record-thumbnail-tooltip-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    max-height: unset;
    grid-gap: 4px;

    .record-thumbnail-container.thumbnail-container {
      flex-shrink: 0;
      margin: 0;
      padding: 0;

      &::-webkit-scrollbar {
        background-color: $scrollbar-color;
        height: 6px;
        width: 6px;
        border-radius: $scrollbar-border-radius;
      }
      &::-webkit-scrollbar-thumb {
        background-color: $scrollbar-thumb-color;
        border-radius: $scrollbar-border-radius;
      }

      &::-webkit-scrollbar-thumb:hover {
        background-color: $scrollbar-hover-color;
      }
    }
    .record-viewer-container {
      flex-grow: 1;
      height: calc(100vh - 120px) !important;
    }
  }
}
