$light-gray: #394042;
$dark-gray: #303235;

.empty-element {
    min-width: 65px;
    min-height: 40px;
    max-height: 60px;
    background-color: $light-gray;
    border: none;

    &.dark-bg {
        background-color: $dark-gray;
    }
}