.approval-waiting-message {
  margin-left: 130px;
}

.ship-address-label > div:first-child {
  font-weight: bold;
}

.shipping-display {
  margin-bottom: 15px !important;
}
