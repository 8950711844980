.manual-wire-custom-notes {
    width: 100%;
    margin-top: 20px;
    box-sizing: border-box;

    .custom-note-log {
        background-color: #2da4ad;
        width: 100%;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px;
        color: black;
        border-radius: 5px;
    }

    .custom-note {
        background-color: white;
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 10px;
        padding-top: 15px;
        margin-top: 10px;
        color: black;
        max-height: 200px;
        overflow-y: auto;
    }
}
  