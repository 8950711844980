@use "../../doctor/styles/abstracts/mixins" as mixins;
@import "../../bpp-variables";

$sidebar_collapse_duration: 0.5s;
$sidebar_collapse_func: ease-out;

/* The sidebar menu */
.sidenav-submenu {
  height: 100%;
  width: 285px;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 80px;
  background-color: #202125;
  padding-top: 0px;
  color: #959595;
  text-align: left;
  transition: left $sidebar_collapse_duration $sidebar_collapse_func;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.sidenav-submenu.submenu-collapse {
  left: -205px; /* sidenav-submenu - left of sidenav-submenu +  */
  transition: left $sidebar_collapse_duration $sidebar_collapse_func;
}

.inbrace-action-required-submenu,
.doctor-action-required-submenu,
.appliance-design-submenu {
  width: 285px;
  overflow-x: hidden;
  padding-top: 0px;
  color: #959595;
  text-align: left;
  transition: all 1s ease-out;
}

/* The navigation menu links */
.sidenav-submenu a {
  padding: 10px 10px 4px 10px;
  text-decoration: none;
  font-size: 25px;
  color: #999;
  display: block;
}

.sidenav-submenu a.submenu-title {
  padding: 27px 0px 15px 40px;
  font-size: 25px;
  color: #999;
  display: block;
}

.sidenav-submenu a.submenu-title:hover {
  color: #999;
}

.sidenav-submenu a.submenu-heading {
  padding: 7px 0px 7px 40px;
  font-size: 13px;
  color: #999;
  display: block;
  position: relative;

  .collapse-caret {
    position: absolute;
    height: 100%;
    top: 0;
    right: 20px;
    display: flex;
    align-items: center;

    i {
      font-size: 14px;
      padding: 0 10px;
    }

    &:hover {
      background-color: #dee1e5;
    }
  }

  &__accounts {
    padding-left: 60px;
  }

  .accounts-submenu-icon {
    float: right;
    padding-right: 40px;
    padding-top: 2px;
  }
}

.sidenav-submenu a.submenu-heading-badge {
  padding: 7px 0px 7px 28px;
  font-size: 13px;
  color: #999;
  display: block;
}

.sidenav-submenu a.submenu-heading:hover,
.sidenav-submenu a.submenu-heading-badge:hover,
.sidenav-submenu a.submenu-heading.submenu-active,
.sidenav-submenu a.submenu-heading-badge.submenu-active {
  background-color: #7a8895;
  color: #202125; /* Black */
}

.sidenav-submenu a.submenu-item {
  padding: 7px 0px 7px 60px;
  font-size: 13px;
  color: #999;
  display: block;
}

.sidenav-submenu a.submenu-item-badge {
  padding: 7px 0px 7px 48px;
  font-size: 13px;
  color: #999;
  display: block;
}

.sidenav-submenu a.submenu-inner-item-badge {
  padding: 7px 0px 7px 65px;
  font-size: 13px;
  color: #999;
  display: block;
}

.sidenav-submenu a.submenu-item:hover,
.sidenav-submenu a.submenu-item-badge:hover,
.sidenav-submenu a.submenu-item.submenu-active,
.sidenav-submenu a.submenu-item-badge.submenu-active,
.sidenav-submenu a.submenu-inner-item-badge:hover,
.sidenav-submenu a.submenu-inner-item-badge.submenu-active {
  background-color: #7a8895;
  color: #202125; /* Black */
}

/* When you mouse over the navigation links, change their color */
.sidenav-submenu a:hover {
  color: #f1f1f1;
}

/* Style page content */
.sidenav-submenu-offset {
  margin-left: 285px; /* Same as the width of the sidebar */
  padding: 0px 0px;
  transition: margin-left $sidebar_collapse_duration $sidebar_collapse_func;
}

.sidenav-submenu-offset.submenu-collapse {
  margin-left: 0px; /* Same as the width of the sidebar */
  transition: margin-left $sidebar_collapse_duration $sidebar_collapse_func;
}

.sidenav-submenu.submenu-collapse:hover,
.sidenav-submenu.submenu-collapse a.submenu-title:hover,
.sidenav-submenu.submenu-collapse a.submenu-heading:hover,
.sidenav-submenu.submenu-collapse a.submenu-heading-badge:hover,
.sidenav-submenu.submenu-collapse a.submenu-item:hover,
.sidenav-submenu.submenu-collapse
  a.submenu-item-badge:hover
  .sidenav-submenu.submenu-collapse
  a.submenu-inner-item-badge:hover {
  background-color: #202125;
  cursor: default;
}

.sidenav-collapse-btn {
  position: absolute;
  top: 50%;
  right: -25px;
  cursor: pointer;
  width: 25px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0 5px 5px 0;
  background-color: #202125;

  &:hover {
    color: #f1f1f1;
  }
}

.required-collapse-btn,
.inbrace-action-required-collapse-btn,
.doctor-action-required-collapse-btn {
  cursor: pointer;
  float: right;
  margin-right: 30px;
  z-index: 1;
}

.icon-size-md {
  font-size: $bpp-font-size-reg;
  margin-left: 10px;
  margin-right: 10px;
}

.main-content {
  padding: 10px 40px;
  background-color: #d8dce1; /* For browsers that do not support gradients */
  background-image: linear-gradient(#d8dce1, #f5f5f5); /* Standard syntax (must be last) */
  min-height: 100vh;
}

@media only screen and (max-width: 1537px) {
  .main-content {
    padding: 10px 20px;
  }
}

.context-menu-container {
  position: relative;

  @media only screen and (max-width: 767px) {
    @include mixins.rowGap(8px);
  }
}

.context-menu-container.context-menu-offset > .context-menu {
  margin-top: 10px;
}

.table-industry {
  padding-top: 20px;
  border: none;
  margin-bottom: 10px;
}

table.table-industry {
  > thead.table-inbrace-dark-industry {
    background-color: #3c3e42;
    color: #ffffff;
    border-radius: 10px 10px 0 0;

    th:first-child {
      padding-left: 20px;
      border-top-left-radius: 10px;
    }
    th:last-child {
      border-top-right-radius: 10px;
    }
  }

  > tbody.table-inbrace-dark-industry {
    border-radius: 0 0 10px 10px;
    > tr {
      background-color: #d3d6d9;
      color: #000000;

      &:nth-child(odd) {
        background-color: #c6c9cc;
      }
      td.caseid-width:first-child {
        padding-left: 20px;
      }
      &:last-child > td {
        &:first-child {
          border-bottom-left-radius: 10px;
        }
        &:last-child {
          border-bottom-right-radius: 10px;
        }
      }
      &:hover {
        background-color: #9ea3a8;
      }
    }
  }
}

.account-table > tbody > tr > td.td-industry-offset,
.address-table > tbody > tr > td.td-industry-offset {
  padding-left: 20px;
  padding-top: 12px;
}

table > tbody > tr > td.td-industry-offset {
  padding-left: 20px;
}

.input-group .form-control.search-bar-dark {
  border-radius: 10px;
}

.search-bar-dark {
  border-radius: 10px;
}

.dark-search {
  display: inline-block;
  width: 30%;
  padding-left: 20px;
  margin-left: 5px;
  margin-right: 5px;
  margin-bottom: 9px;
}

@media only screen and (max-width: 1537px) {
  .dark-search {
    width: 20% !important;
  }
}

.dash-list li {
  list-style: none;
}

.dash-list li::before {
  content: " - ";
}

.dark-filter {
  display: inline-block;
  margin-bottom: 5px;
}

.dark-filter-hide {
  display: none;
}

.badge.badge-dark {
  background-color: #c9e2fa;
  color: #202125;
}

.badge-width {
  width: 28px;
  display: inline-block;
  text-align: right;
  margin-right: 5px;
}

.badge-width.badge-width-3 {
  margin-right: 11px;
  margin-left: -6px;
}

.badge-width.badge-width-4 {
  margin-right: 18px;
  margin-left: -13px;
}

.badge-width.badge-width-5 {
  margin-right: 24px;
  margin-left: -19px;
}

.badge-width.badge-width-6 {
  margin-right: 31px;
  margin-left: -26px;
}

.badge-width.badge-width-7 {
  margin-right: 31px;
  margin-left: -26px;
}

.badge-width.badge-width-8 {
  margin-right: 31px;
  margin-left: -26px;
}

.badge-width.badge-width-9 {
  margin-right: 31px;
  margin-left: -26px;
}

.badge-width.badge-width-10 {
  margin-right: 31px;
  margin-left: -26px;
}

.page-heading {
  display: inline-block;
  font-size: 25px;
  line-height: 20px;
  vertical-align: middle;
  color: #3c3e42;
  padding-left: 8px;
}

.tag-container {
  float: right;
}

// Gen 2.0 Tag for cases
.case-gen2 {
  background: #2da4ad;
  border-radius: 5px;
  color: white;
  line-height: 12px;
  padding: 4px 5px;
  margin-left: 5px;
  font-size: 14px;
}

//EPP Tag
.case-epp {
  background: #32a1d9;
  border-radius: 5px;
  color: white;
  line-height: 12px;
  padding: 4px 5px 4px 5px;
  margin-left: 5px;
  font-size: 14px;
}

.light-gray-text {
  color: #8c8e91;
}

.caseid-width {
  width: 15% !important;
}

.shipping-caseid-width {
  width: 12%;
}

.shipping-type-width {
  width: 10%;
}

.shipping-name-width {
  width: 15%;
}

.shipping-address-width {
  width: 35%;
}

.mark-shipped-width {
  width: 5%;
}

.submission-width {
  width: 10%;
}

.manufacturing-submission-width {
  width: 11%;
}

.manufacturing-name-width {
  width: 12%;
}

.manufacturing-assigned-to-width {
  width: 9%;
}

.name-width.cozy {
  width: 14%;
}

.patient-width {
  width: 10%;
}

.name-width.ir {
  width: auto;
}

.shipping-price-width {
  width: 11%;
}

.approval-width {
  width: 19%;
}

.target-date-width {
  width: 13%;
}

.ir.approval-width {
  width: 150px;
}

.wo-width {
  width: 5%;
}

.target-ship-date-width {
  width: 150px;
  margin-right: 5px;
}

.shipping-target-ship-date-width {
  width: 12%;
}

.autosize-width {
  width: auto;
}

.result-none {
  margin-top: 15%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 40%;
}

.tx-plan-dark-container {
  background-color: #ffffff;
  padding: 0px;
  margin: 0px;
}

.modal-clean {
  margin-bottom: 0px;
}

.modal-clean-2 {
  width: 100%;
  text-align: center;
}

.modal-comments {
  /* padding: 10px 0px; */
  margin-bottom: 0px;
}

.comingSoon {
  background-size: cover;
  width: 100%;
  margin-top: -400px;
}

.font-awesome {
  font-family: Arial, FontAwesome;
}

.select-menu {
  padding: 10px 0px;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid #ccc;
}

.select-menu.row {
  margin-left: 0px;
  margin-right: 0px;
}

.selected-menu-type {
  padding: 12px 12px 12px 22px;
}

.select-filter,
.select-status {
  padding: 7px;
  border-radius: 10px;
  border: 1px solid #ccc;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -webkit-transition: border-color ease-in-out 0.15s, -webkit-box-shadow ease-in-out 0.15s;
  -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}

.select-calendar {
  font-size: 28px;
  color: #3c3e42;
}

.select-container-date {
  display: inline-block;
  margin: 0px 0px 0px 4px;
}

.case-list {
  padding: 0px 21px 0px 20px;
}

.select-date-l,
.select-date-r {
  border-radius: 10px;
  border: 1px solid #ccc;
  padding: 5px;
  width: 140px;
  margin-right: 10px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -webkit-transition: border-color ease-in-out 0.15s, -webkit-box-shadow ease-in-out 0.15s;
  -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}

.select-date-r {
  margin-left: 10px;
}

.select-container {
  margin: 5px;
  display: inline-block;
}

.search-bar-adjustment {
  padding-top: 27px;
}

.date-error {
  border-color: $bpp-subtle-warning;
}

.status-cad {
  width: 177px;
}

.status-man {
  width: 250px;
}
.expedited-icon {
  float: right;
  margin-right: 10px;
}

.case-list-settings {
  font-size: 16px;
  float: right;
  margin-top: 22px;
  margin-right: 18px;

  &__collapsed {
    margin-right: 8px;
  }
}

.case-list-settings:hover {
  cursor: pointer;
}

.tooltip.top-left {
  margin-top: -25px;
  margin-left: -85px;

  .tooltip-arrow {
    display: none;
  }
}

.no-pointer {
  cursor: default !important;
}

.filter-setting-heading {
  color: #999;
}

.filter-parent {
  margin-left: 10px;
}

.filter-child {
  overflow: hidden;
  margin-left: 25px;
  display: inline-block;
}

.inner-filter-child {
  margin-left: 25px;
  width: 100%;
  display: inline-block;
}

.filter-offset {
  margin-left: 10px;
  cursor: pointer;
}

.filter-parent-col {
  width: 45%;
}

.filter-col {
  width: 50%;
  display: inline-block;
  vertical-align: top;
  text-align: left;
}

.filter-col:first-child {
  border-right: 1px solid #e5e5e5;
}

.filter-container {
  margin-top: 20px;
}

.sidenav-submenu a.submenu-heading-badge.hide-menu-item {
  display: none;
}

.sidenav-submenu a.submenu-item-badge.hide-menu-item,
.sidenav-submenu a.submenu-inner-item-badge.hide-menu-item {
  display: none;
}

.sidebar__case_select {
  cursor: pointer;
  display: inline-block;
  height: 35px;
  position: relative;
  width: 225px;
  text-align: center;
  border-radius: 8px;
  background-color: #000000;
  font-size: 18px;
  padding: 5px;
  margin: 10px 10px 10px 11px;

  .fa.fa-chevron-down.pull-right {
    position: relative;
    top: 5px;
  }
}

.sidebar__case_select:hover {
  transition: all 0.3s;
  opacity: 0.7;
  z-index: 1;
}

.sidebar__case_select:active {
  opacity: 1;
}

.sidebar__case_select div:first-child {
  margin-top: 5px;
}

.table-shipping > tbody > tr > td.shipping-type-width {
  width: 10%;
}

.table-shipping > tbody > tr > .mark-shipped-width {
  width: 5%;
  min-width: unset;
}

.pointer-events-none {
  pointer-events: none;
}

.btn-batch-mark-shipped {
  float: right;
}

.margin-top-neg-10 {
  margin-top: -10px;
  margin-bottom: 10px;
}

.case-list-table-loading-placeholder {
  position: relative;
  mask-image: linear-gradient(black 50%, transparent);

  tr.table-placeholder-row {
    height: 30px;
    background-color: #d3d6d9;

    &:nth-child(odd) {
      background-color: #c6c9cc;
    }
    &:last-child {
      border-radius: 0 0 10px 10px;
    }

    td:first-child {
      padding-left: 20px;
    }
  }

  .table-placeholder-item {
    vertical-align: middle;

    > div {
      position: relative;
      height: 10px;
      width: 50%;
      animation-duration: 1.25s;
      animation-fill-mode: forwards;
      animation-iteration-count: infinite;
      animation-name: placeholder-shimmer;
      animation-timing-function: linear;
      background: rgba(#aaaaaa, 0.5);
      background: linear-gradient(to right, #aaaaaa 10%, #bbbbbb 18%, #aaaaaa 33%);
      background-size: 800px 10px;
      border-radius: 20px;
    }
  }

  tr.table-placeholder-row:nth-child(even) .table-placeholder-item div {
    width: 40%;
  }
}

@keyframes placeholder-shimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}

.case-list-action-button {
  overflow: visible !important;
}
