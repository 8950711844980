.label-text-ir {
  padding-left: 5px;
  cursor: pointer;
  color: #303235;
}

input[type='checkbox'].label-checkbox-ir {
  margin-top: 2px;
  margin-right: 5px;
  cursor: pointer;
}

.process-box-label-section-ir {
  margin-top: 15px;
  margin-bottom: 15px;
  float: left;
}

.process-box-label-right-section-ir {
  margin-top: 20px;
  margin-bottom: 15px;
  float: right;
}

.process-retry {
  margin-bottom: 10px;
}

.ir.process-box-title {
  margin-bottom: 15px;
  text-align: center;
}

.process-box-content {
  margin-top: 10px;
  margin-bottom: 10px;
}

.text-center {
  text-align: center;
}

.ir.process-box-label-section {
  display: inline-block;
  width: 100%;
  margin-top: 0px;
  margin-bottom: 0px;
}

.ir.process-box-intro {
  width: 55%;
  margin: 0 auto;
}

.process-return-ir {
  float: right;
}

.label-center {
  width: 350px;
  margin: 0 auto;
}

.delete-package {
  // color: rgba(226, 87, 73, 0.68);
  color: #ff6666;
  margin-left: 5px;
  cursor: pointer;
  display: inline-block;
}

.delete-package:hover {
  // color: #de5548;
  margin-left: 5px;
  cursor: pointer;
  display: inline-block;
}

.upload-label-button {
  border: 1px solid black;
  border-radius: 10px;
  cursor: pointer;
  width: 150px;
  height: 25px;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
}

.cad-container {
  .business-dark-theme {
    .business-dark-theme-content {
      min-height: 50px;
    }
  }
}

.return-disabled {
  pointer-events: none;
}

.ir-file-upload {
  padding: 10px;
  text-align: left;
  display: inline-block;
}

.upload-area {
  display: flex;
  padding: 10px;
  .upload-block {
    flex: auto;
    text-align: center;
  }
}

.file-icons__container {
  display: flex;
  align-items: flex-end;
  flex-wrap: nowrap;
  justify-content: center;
}
