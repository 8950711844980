.viewer {
  height: 99%;
  margin-bottom: 10px;
  color: unset;
  [class*='--selected'] {
    background-color: #67d4d1;
  }

  .rpv-default-layout__toolbar {
    height: 85px;
    padding: 10px 5px;
  }
  .rpv-default-layout__sidebar {
    padding-top: 82px;
  }
  .rpv-core__inner-pages {
    padding-top: 60px;
  }
  .rpv-core__popover-body {
    padding: 10px;
    border-radius: 5px;
    .rpv-search__popover-input-counter {
      width: 20rem;
    }
  }
  .rpv-core__text-layer * {
    color: rgba($color: whitesmoke, $alpha: 0.1) !important;
  }
  .rpv-core__tooltip-body * {
    color: rgba($color: whitesmoke, $alpha: 0.5) !important;
  }
  .rpv-thumbnail__item {
    &:hover,
    &:focus {
      background-color: #67d4d1;
    }
  }

  .viewer-toolbar {
    display: block;
    width: 100%;
    .toolbar-filename {
      text-align: center;
      padding: 5px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  input[type='checkbox'] {
    margin: 0px 5px 0;
  }
  input[type='text'] {
    width: 80% !important;
    padding: 5px;
    height: 30px;
  }
}

.rpv-core__viewer.rpv-core__viewer--dark *:not(.dialogStyles *) {
  color: whitesmoke;

  button[aria-label='Thumbnail']{
    margin-top: 4px;
    padding-top: 2px;
  }

  button:hover {
    background-color: #5e5e5e;
  }
  .rpv-core__icon {
    color: whitesmoke;
    font-weight: bold;
  }
  .rpv-default-layout__sidebar-headers,
  .rpv-default-layout__toolbar{
    background-color: #323539;
  }
}

.rpv-core__viewer.rpv-core__viewer--light *:not(.dialogStyles *) {

  .rpv-default-layout__sidebar-headers,
  .rpv-default-layout__toolbar{
    background-color: #F8F8F8;
  }

  button[aria-label='Thumbnail']{
    margin-top: 4px;
    padding-top: 2px;
  }
}

.dialogStyles {
  width: 500px;
  max-width: 100%;
  margin: 0 auto;
  position: relative;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 999;
  background-color: #eee;
  padding: 25px;
  border-radius: 8px;
  display: flex;
  color: #575e65 !important;
  flex-direction: column;
  .dialog-header {
    font-size: 20px;
    font-weight: 700;
  }
  .dialog-content {
    padding: 30px 0px;
    font-size: 15px;
    font-weight: 500;
  }
}


