.note-container {
  border-radius: $border-radius;
  padding: $general-spacing * 2;
  margin: $general-spacing 0;
  background-color: $mint;
  text-align: left;

  .note__subject {
    margin-bottom: $general-spacing;
  }

  .ql-editor {
    padding: 0;
  }
}
