@import "../../../../bpp-variables";

.single-tooth-idb {
  text-align: center;
  padding: 15px 30px 15px 30px !important;

  &__align-left {
    text-align: left;
    padding: 0 !important;
  }

  &__title {
    font-weight: bold;
    margin-bottom: 10px;
  }

  i.fa-trash {
    color: $bpp-subtle-warning;
  }

  &__failure {
    width: 200px;
    margin: 0 auto;
    padding: 5px 0 10px 0;
  }

  &.na {
    padding: 20px 20px !important;
    min-height: 0;
  }

  .btn-action {
    text-align: center;

    button {
      margin-top: 15px;
      width: 150px;
      text-align: center;
    }
  }

  .btn-bpp-upload {
    width: 200px;
  }

  .margin-top-12 {
    margin-top: 15px;
  }

  .process-box-title {
    margin-bottom: 8px;
  }

  a {
    &.idb-link {
      color: #303235;
    }
    &.return-idb {
      margin-top: -32px;
    }
  }

  .fa-trash {
    cursor: pointer;
  }

  .modal-header {
    text-align: left;
  }

  &__override {
    margin-top: 15px;
  }

  &__generation {
    margin-top: 10px;
  }

  &__file {
    margin-top: 10px;
  }
}
