.bpp-heading-part {
  width: 520px;
  vertical-align: top;
}

.bpp-heading-part-no {
  width: 100px;
  vertical-align: top;
}

.bpp-review-part {
  width: 200px;
  display: inline-block;
}

.bpp-review-qty {
  width: 50px;
  cursor: pointer;
}

.bpp-heading-qty {
  width: 91px;
  cursor: pointer;
  vertical-align: top;
}

.bpp-header-qty {
  width: 88px;
  vertical-align: top;
}

.bpp-review__line-divison {
  border-bottom: 1px solid #adb0b4;
  margin-top: 5px;
  margin-bottom: 5px;
}

.bpp-heading-divider {
  padding-top: 5px;
  width: 720px;
  border-bottom: 1px solid #adb0b4;
}

.bpp-item-container {
  display: inline-block;
  padding-right: 10px;
  padding-bottom: 5px;
  padding-top: 5px;
  margin-bottom: 5px;
  line-height: 30px;
  width: 730px;
  height: 30px;
}

.bpp-plus-icon {
  color: black;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.bpp-minus-icon {
  color: black;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.bpp-reason-list {
  padding: 10px 10px 10px 0px;
  border-radius: 20px;
  width: 71%;
}

#reason-arch-segments {
  display: flex;
  flex-direction: column;
}

.arch-segment-row {
  display: flex;
  justify-content: left;
}

.bpp-reason-heading {
  font-weight: bold;
  padding-bottom: 10px;
}

@media only screen and (max-width: 1537px) {
  .bpp-reason-list {
    width: 100%;
  }
}

.bpp-heading-edit-button {
  color: black;
  display: inline-block;
  top: 30px;
  right: 45px;
  text-decoration: underline;
  cursor: pointer;
  width: 5%;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-right: 1.5%;
}

.bpp-review-heading-item {
  font-weight: bold;
  padding-bottom: 5px;
}

.bpp-review-heading-quantity {
  font-weight: bold;
  padding-bottom: 5px;
}

.bpp-item-max-qty {
  background: #c6c9cc;
  border-radius: 6px;
  color: #414141;
  width: 155px;
  display: inline-block;
  padding: 5px 5px 5px 5px;
  height: 30px;
  // vertical-align: top;
  // margin-bottom: 5px;
}

.bpp-ir-cancel-text {
  color: #c9302c;
  cursor: pointer;
  padding-right: 15px;
  position: absolute;
  right: 50px;
  top: 165px;
  z-index: 10;
}

.bpp-ir-cancel-text-icon {
  padding-right: 10px;
  right: 105px;
}

.bpp-irsubmission-heading {
  display: inline-block;
  font-weight: bold;
}

.ir-case-id {
  display: inline-block;
  padding-left: 20px;
  padding-bottom: 20px;
}

.ir-case-id-label {
  display: inline-block;
  padding-bottom: 20px;
}

.ir-submission-heading {
  font-size: 18px;
  font-weight: bold;
  padding-bottom: 15px;
}

.bpp-portal-view {
  padding: 5px 65px 30px 65px;
}

.bpp-loader-header {
  padding-top: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  color: white;
}

.bpp-ir-select-caseid {
  padding: 7px;
  border-radius: 10px;
  border: 1px solid #ccc;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -webkit-transition: border-color ease-in-out 0.15s, -webkit-box-shadow ease-in-out 0.15s;
  -webkit-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  width: 300px;
}

.bpp-ir-select-caseid-disbaled {
  padding: 7px;
  border-radius: 10px;
  border: 1px solid #ccc;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -webkit-transition: border-color ease-in-out 0.15s, -webkit-box-shadow ease-in-out 0.15s;
  -webkit-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  width: 300px;
  cursor: not-allowed;
  background: #adb0b4;
}

.bpp-wizard-error {
  position: fixed;
  bottom: 55px;
  right: 50px;
  display: block;
  border-radius: 10px;
  -webkit-transition: display 0.3s;
  /* Safari */
  transition: display 0.3s;
  background-color: #ffffff;
  -webkit-transition: opacity 0.3s ease-in;
  -moz-transition: opacity 0.3s ease-in;
  -o-transition: opacity 0.3s ease-in;
  opacity: 1;
  max-width: 300px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.08), 0 2px 20px 0 rgba(0, 0, 0, 0.02);
}

.bpp-wizard-error-text {
  color: #e74b3a;
  padding-left: 15px;
}

.bpp-list-wizard-error-text {
  color: #e74b3a;
  padding-left: 36px;
}

.bpp-wizard-error-text a {
  color: #e74b3a;
}

.bpp-wizard-error-text a:hover {
  color: #ca3b27;
  text-decoration: underline;
}

.bpp-wizard-error-title {
  border-radius: 8px 8px 0px 0px;
  background-color: #e74b3ae3;
  padding: 5px;
  font-weight: bold;
  color: #ffffff;
  min-width: 200px;
  line-height: 20px;
  vertical-align: middle;
}

.bpp-ir-submission-button {
  margin-left: 0px;
  margin-top: 10px;
  background-color: #3c3e42;
  color: #ffffff;
  border-radius: 10px;
}

.bpp-ir-submission {
  height: calc(100vh - 190px);
  overflow-y: auto;
  overflow-x: hidden;
}

#update-warning-submit {
  padding-top: 10px;
  padding-bottom: 10px;
}

.bpp-update-wizard-error-text {
  color: #e74b3a;
  padding-left: 10px;
}

.bpp-reason-heading-new {
  font-weight: 700;
  font-size: 14px;
  margin-top: 10px;
}

.bpp-reason-content {
  padding-left: 0px;
  width: 85%;
}

@media only screen and (max-width: 1537px) {
  .bpp-reason-content {
    width: 100%;
  }
}

.bpp-item-reason-comment {
  width: 720px;
  border-radius: 5px;
  border: 1px solid #ccc;
  padding: 10px 10px;
}

.bpp-item-update-comment {
  width: 720px;
  border-radius: 5px;
  border: 1px solid #ccc;
  padding: 10px 10px;
  margin-bottom: 5px;
  display: inline-block;
}

.bpp-pr-reason-comment-warning {
  width: 720px;
  border-radius: 5px;
  border: 1px solid #e74c3c;
  padding: 10px 10px;
  margin-bottom: 5px;
  display: inline-block;
}

.ir-profile-edit {
  display: inline-block;
  vertical-align: 100%;
  padding-left: 10px;
}

.noleftpadding {
  padding-left: 0px !important;
}

.idb-selectors {
  padding-top: 5px;
}