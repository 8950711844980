@import '../../../bpp-variables';

.wfr {
  text-align: center;
  margin-top: 30px;

  &__container {
    width: 680px;
    margin: 0 auto;

    &__content {
      padding-left: 40px;

      .top-seperator {
        margin-top: 0px;
      }

      .bl-separator {
        border-bottom: 2.5px solid #9ea3a8;
        margin: 8px 0 8px 50px;
      }

      &__header {
        display: flex;
        margin-top: 20px;

        div {
          text-align: left;
          font-weight: bold;

          &:first-child {
            width: 56%;
          }
          &:last-child {
            width: 44%;
          }
        }
      }

      &__row {
        display: flex;
        padding: 8px 0 8px 0;

        > div {
          text-align: left;

          &:first-child {
            width: 56%;
            font-weight: bold;
            padding-top: 5px;
          }
          &:last-child {
            width: 44%;
          }

          span {
            vertical-align: sub;
            &.status-success {
              color: #1b8e60;
            }
            &.status-fail {
              color: $bpp-subtle-warning-new;
            }
          }
        }

        &__inner {
          > div {
            &:first-child {
              padding-left: 50px;
            }

            > span {
              padding-left: 40px;
            }
          }

          .break-location {
            display: flex;

            > div {
              &:nth-child(1),
              &:nth-child(4) {
                width: 25%;
                margin-right: 3px;
              }

              &:nth-child(2),
              &:nth-child(5) {
                width: 22%;
              }

              &:nth-child(3) {
                padding: 8px 5px 0 5px;
                width: 8%;
                font-weight: bold;
              }
            }

            .css-tlfecz-indicatorContainer,
            .css-1gtu0rj-indicatorContainer {
              padding: 5px;
            }

            .css-g1d714-ValueContainer {
              padding: 2px 6px;
            }
          }

          .break-location-link {
            padding-left: 27px;

            i {
              margin-left: 3px;
            }
          }
        }

        &__error {
          .css-yk16xz-control,
          .css-yk16xz-control:hover {
            border-color: $bpp-subtle-warning-new;
          }
        }
      }

      .bl-remove {
        position: absolute;
        padding-left: 6px;
        margin: 35px 5px 0 0;
        color: $bpp-subtle-warning-new;
        font-size: 18px;
        cursor: pointer;
      }
    }
  }

  .modal-title {
    text-align: left;
  }
}

.wire-failure-modal {
  .modal-body {
    max-height: 370px;
  }

  .wire-failure-summary {
    text-align: left;
    padding: 10px;

    > div {
      padding-bottom: 10px;
    }

    &__row {
      display: flex;
      margin-bottom: 10px;

      > div {
        width: 50%;

        &:first-child {
          font-weight: bold;
        }

        &.failure-label {
          text-align: center;
          color: $bpp-subtle-warning-new;
        }
      }

      &__inner {
        display: flex;
        margin-bottom: 10px;
        padding-left: 35px;

        > div:last-child {
          color: #333;
          padding-left: 94px;
          text-align: left;
        }
      }
    }

    .bl-separator-modal {
      border-bottom: 1px solid #e5e5e5;
      margin: 0 0 10px 35px;
    }

    &__log {
      padding: 10px 10px 10px 120px;

      .wire-failure-summary__row {
        > div {
          &:first-child {
            width: 40%;
          }
          &:last-child {
            width: 60%;

            &.wire-label {
              width: 100%;
            }
          }
        }

        &__inner {
          > div:last-child {
            padding-left: 50px;
          }
        }
      }
    }
  }
}

.wfr-in-progress {
  padding: 30px;
  text-align: center;
}

.small-width-break {
  display: none;
}

// Handle Element Overflow
@media (min-width: 1201px) and (max-width: 1600px), (max-width: 860px) {
  .production-process {
    .process_tracker {
      .toggle__production {
        max-width: 100%;
      }

      .toggle__production__box {
        margin-left: 0;
      }
    }
  }

  .small-width-break {
    display: block;
  }

  .wfr {
    &__container {
      max-width: 100%;

      &__content {
        &__header {
          div {
            &:first-child {
              width: 50%;
            }
            &:last-child {
              width: 50%;
            }
          }
        }

        &__row {
          &__inner {
            > div:first-child {
              padding-left: 30px;
            }
          }

          > div {
            text-align: left;

            &:first-child {
              width: 50%;
            }

            &:last-child {
              width: 50%;
            }
          }
        }
      }
    }
  }
}
