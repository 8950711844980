$col_1_width: 250px;
$col_1_width_sm: 230px;

.statuses {
  width: $col_1_width;
  transition: all 0.5s ease;
  display: inline-block;
  align-self: flex-start;
  position: sticky;
  top: 65px;
  min-width: $col_1_width;
  max-width: 350px;
  max-height: calc(100vh - 65px);
  overflow-y: auto;
  overflow-x: hidden;
  margin-right: 8px;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

.list-content {
  display: flex;
}

.statuses-result-container {
  display: inline-block;
  vertical-align: top;
  justify-content: flex-start;
  position: relative;
  width: 100%;

  .dashboard-title {
    padding: 16px 16px 10px 25px;
    display: flex;
    align-items: center;

    h3 {
      margin-right: 10px;
      margin-top: 5px;
    }

    @media only screen and (max-width: 768px) {
      border-bottom: 1px solid #e1e2e2;
    }
  }
}

.statuses-result .card {
  min-height: calc(100vh - 260px);
}

.statuses-col {
  display: flex;
  .statuses-col-25 {
    flex: 1;
  }

  .statuses-col-1 {
    flex: 0;
  }

  .statuses-col-70 {
    flex: 3;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.search {
  min-width: $col_1_width;
  transition: all 0.5s ease;
  width: 100%;
}

.search-container {
  display: block;
  padding-bottom: 8px;
  border-bottom: 1px solid #e1e2e2;
}

@media only screen and (max-width: 1198px) {
  .statuses {
    width: $col_1_width_sm;
    transition: all 0.5s ease;
  }

  .statuses-result-container {
    transition: all 0.5s ease;
  }

  .search {
    transition: all 0.5s ease;
  }
}

@media only screen and (max-width: 1198px) {
  .search {
    min-width: 200px;
    transition: all 0.5s ease;
  }
}

@media only screen and (max-width: 767px) {
  .list-content {
    display: unset;
  }

  .statuses {
    width: 100%;
    position: unset;
    max-height: unset;
    max-width: unset;
    margin-right: 0;
    overflow-y: unset;
    overflow-x: unset;

    .search-container {
      display: flex;
      justify-content: space-between;
      padding-bottom: 0px;
      border-bottom: none;
    }

    .search,
    .dropdown-input {
      flex: 50%;
    }

    .search + .dropdown-input {
      margin-top: 0px !important;
      margin-left: 16px;
    }
  }

  .statuses-result-container {
    width: 100%;
  }

  .statuses-result {
    &--dashboard {
      height: 397px;
      overflow: auto;
    }
    &--empty {
      height: auto;
    }
  }
}

@media only screen and (max-width: 576px) {
  .statuses {
    .search-container {
      flex-wrap: wrap;
    }

    .search + .dropdown-input {
      margin-top: 0px;
      margin-left: 0px;
    }
  }
}

@media only screen and (max-width: 500px) {
  .statuses {
    .search + .dropdown-input {
      margin-top: 8px !important;
      margin-left: 0px;
    }
  }
}

.no-matches-info {
  padding-top: 10px;
}

.no-matches-heading {
  display: block;
}

.welcome {
  width: 420px;
  height: 250px;
  border-radius: 15px;
  line-height: 30px;
  padding-left: 25px;
  padding-right: 25px;
  color: #ffffff;
  background-color: #7ececf; /* For browsers that do not support gradients */
  background-image: linear-gradient(to top right, #7ececf, #32a1d9); /* Standard syntax (must be last) */
  text-align: center;
  font-size: 18px;
  margin-top: 10%;
  vertical-align: middle;
  text-align: center;
  display: table;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.btn.btn-primary.btn-round.submit-case-button {
  margin: 10px;
}

.modal {
  overflow-y: auto;
}

.inlogo {
  animation: logo-spin infinite 3s linear;
  height: 18vmin;
  margin-bottom: 20px;
}

.rotate-diagonal-1 {
  height: 18vmin;
  margin-bottom: 20px;
}

.padding-left-1 {
  padding-left: 15px;
  display: inline-block;
}

.pagination {
  margin-right: 20px;
  float: right;
}

.pagination > .active > a,
.pagination > .active > a:hover,
.pagination > .active > a:active,
.pagination > .active > a:focus {
  background-color: #43a7b3;
  border-color: #43a7b3;
  color: #ffffff;
}

.pagination > li > a,
.pagination > li > span {
  color: #43a7b3;
}

.pagination > li:first-child > a,
.pagination > li:first-child > span {
  border-radius: 50% 0 0 50%;
}

.pagination > li:last-child > a,
.pagination > li:last-child > span {
  border-radius: 0 50% 50% 0;
}

.pagination-dark {
  min-height: 63px;
}

.pagination-dark > .pagination > .active > a {
  background-color: #323539;
  border-color: #323539;
  color: #ffffff;
}

.pagination-dark > .pagination > li:first-child > a,
.pagination-dark > .pagination > li:first-child > span {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.pagination-dark > .pagination > li:last-child > a,
.pagination-dark > .pagination > li:last-child > span {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.pagination-dark > .pagination > li > a,
.pagination-dark > .pagination > li > span {
  color: #323539;
}

.arrow {
  display: inline-block;
  vertical-align: middle;
  width: 0;
  height: 0;
  margin-left: 5px;
  opacity: 0.66;
}

.arrow.asc {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-bottom: 4px solid #fff;
}

.arrow.dsc {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid #fff;
}

@keyframes logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.sticky-table {
  position: -webkit-sticky;
  position: sticky;
  top: 136px;
  z-index: 10;
  background-color: #018abe;
}

.sticky-table:active {
  color: #dddddd;
}

.bold {
  font-size: 1.2em;
}

.empty-img {
  font-size: 40px;
  padding-right: 5px;
  height: 40px;
  display: inline-block;
}

ul.pagination li a:active {
  background-color: #d9d9d9;
}

.filter {
  margin: 15px 10px 15px 15px;
}

.filter-hidden {
  display: none;
}

.filter-b1 {
  margin-left: 15px;
}

.portal-view-wide-2 {
  margin: 0px 20px;
  /* border: 1px solid #018abe; */
  border-radius: 25px;
  /* -webkit-box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.2); */
  /* margin-top: 1px; */
}

.portal-view-wide-2 > table.table {
  border-radius: 25px;
  margin-bottom: 0px;
}

.portal-view-wide-2 > table > thead > tr {
  border-radius: 25px;
}

.portal-view-wide-2 > table > thead > tr > th:first-child {
  border-radius: 25px 0px 0px 0px;
}

.portal-view-wide-2 > table > thead > tr > th:last-child {
  border-radius: 0px 25px 0px 0px;
}

.offset-heading {
  padding-top: 76px;
}

.pagination-bottom {
  position: absolute;
  bottom: 10px;
  right: 330px;
  -webkit-transition: all 1s linear; /* Safari */
  transition: all 1s linear;
}

@media screen and (max-width: 768px) {
  .pagination-bottom {
    position: absolute;
    bottom: 10px;
    right: 30px;
    -webkit-transition: all 1s linear; /* Safari */
    transition: all 1s linear;
  }
}

.pulse::before {
  /* margin: 100px; */
  display: inline-block;
  width: 17px;
  height: 15px;
  border-radius: 50%;
  margin-right: 5px;
  /* background: #cca92c; */
  cursor: pointer;
  box-shadow: 0 0 0 rgba(80, 187, 186, 0.4);
  animation: pulse 1.5s infinite;
}

.fa-bell-yellow {
  color: #575e65;
}

@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(80, 187, 186, 0.7);
  }
  70% {
    -webkit-box-shadow: 0 0 0 10px rgba(80, 187, 186, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(80, 187, 186, 0);
  }
}

@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(80, 187, 186, 0.7);
    box-shadow: 0 0 0 0 rgba(80, 187, 186, 0.7);
  }
  70% {
    -moz-box-shadow: 0 0 0 10px rgba(80, 187, 186, 0);
    box-shadow: 0 0 0 10px rgba(80, 187, 186, 0);
  }
  100% {
    -moz-box-shadow: 0 0 0 0 rgba(80, 187, 186, 0);
    box-shadow: 0 0 0 0 rgba(80, 187, 186, 0);
  }
}

.dropdown-menu > .disabled > a,
.dropdown-menu > .disabled > a:focus,
.dropdown-menu > .disabled > a:hover {
  color: #999;
}

.beige-table,
.notso-beige-table {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.red-color {
  color: #e34558;
}

.alert--icon {
  color: #e34558;
  float: right;
  font-size: 1.5em !important;
  margin-right: 4px;

  &__no-float {
    float: none;
    vertical-align: bottom;
  }
}

.snooze--icon {
  color: #707070;
  float: right;
  font-size: 1.5em !important;
  margin-right: 4px;

  &__no-float {
    float: none;
    vertical-align: bottom;
  }
}

.fa-info-circle {
  color: #211b57;
  font-size: 1.2em;
}

h4.modal-title {
  color: #575e65;
}

.pulse-red::before {
  display: inline-block;
  width: 14px;
  height: 15px;
  border-radius: 50%;
  margin-right: 5px;
  cursor: pointer;
  box-shadow: 0 0 0 rgba(226, 87, 73, 0.68);
  animation: pulse-red 1.5s infinite;
}

.fa-bell-yellow {
  color: #575e65;
}

@-webkit-keyframes pulse-red {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(226, 87, 73, 0.7);
  }
  70% {
    -webkit-box-shadow: 0 0 0 10px rgba(226, 87, 73, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(226, 87, 73, 0);
  }
}

@keyframes pulse-red {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(226, 87, 73, 0.7);
    box-shadow: 0 0 0 0 rgba(226, 87, 73, 0.7);
  }
  70% {
    -moz-box-shadow: 0 0 0 10px rgba(226, 87, 73, 0);
    box-shadow: 0 0 0 10px rgba(226, 87, 73, 0);
  }
  100% {
    -moz-box-shadow: 0 0 0 0 rgba(226, 87, 73, 0);
    box-shadow: 0 0 0 0 rgba(226, 87, 73, 0);
  }
}

.fa-bell-red {
  color: rgba(226, 87, 73, 0.68);
}

.ipp-light {
  .alert--icon {
    width: 20px;
    max-height: 20px;
    &.warning--icon .icon_status {
      stroke: #ed9b40;
    }
    .icon_status {
      stroke-width: 1.5px;
      stroke: #e34558;
    }
  }

  .case-gen2 {
    color: white !important;
    border-radius: 3px;
    padding: 2px 4px 0 4px;
    font-weight: 500px;
    font-size: 0.81em;
    line-height: 1em;
    float: none;
    margin: 0;
  }
}
