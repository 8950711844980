.base-modal {
  backdrop-filter: blur(10px);

  .modal-content {
    border-radius: $border-radius;
    border: 1px solid $platinum;
    border-top: 5px solid $turquoise;
    padding: 0px 32px;
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.4);
    background-color: $pearl;
    height: fit-content;

    &-warning {
      border-top: 5px solid $ruby;
    }
  }

  .modal-header {
    border-bottom: none;
    padding: 32px 0px;
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 100;

    button.close {
      color: $almostBlack;
      opacity: 0.5;

      &:hover,
      &:focus {
        opacity: 0.8;
      }
      &:active {
        opacity: 1;
      }

      > span {
        font-size: 1.5em;
      }
    }
  }

  .modal-body {
    padding: 0px 0px 32px 1px;
  }

  .modal-footer {
    padding: 0px 0px 32px 0px;
    border-top: none;
    text-align: right;
    background-color: white;

    #modal_close_btn,
    #modal_confirm_btn {
      margin-top: 1em;
    }

    &.ipp-smile-design-preferences-ipr {
      text-align: center;
    }
  }
}

@media (min-width: 992px) {
  .base-modal {
    .modal-dialog {
      &.ipp-smile-design-preferences-ipr {
        width: 980px;
      }
    }
  }
}
