.lightbox-spinner-div {
  background-color: #fefefe;
  width: 650px;
  height: 650px;
  position: relative;
}

.lightbox-spinner-div img {
  margin-left: auto;
  margin-right: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
