.case-reversal {
    padding: 20px 50px;

    h3 {
        color: #303235;
    }

    &__details {
        padding: 20px 0;
    }

    p {
        color: #303235;
        font-weight: bold;
    }
}

.revert-button {
    margin: 10px 0;
}

.reversal-input {
    display: inline;
    width: unset;
    margin-right: 10px;
    margin-bottom: 10px;
}
