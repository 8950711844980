.badge {
  display: inline-block;
  text-align: center;
  padding: 2px 8px 5px 8px;
  pointer-events: none;
  border-radius: 3px;

  &--status {
    font-weight: normal !important;
    border-radius: 4px;
    padding: 4px 8px 6px 8px;
    overflow: hidden;
    text-overflow: ellipsis;

    span {
      font-family: $badge-font-family;
    }
  }

  &--default {
    background-color: $lightcyan;
    color: $turquoise;
    font-family: $badge-font-family;

    a,
    span {
      color: $turquoise;
    }
  }

  &--nav {
    background-color: #fff;
    font-weight: 500;
    color: #2da4ad;
    padding: 0px 8px;
  }

  &--primary {
    background-color: $linen;
    color: $tangerine;
    font-family: $badge-font-family;

    a,
    span {
      color: $tangerine;
    }
  }

  &--secondary {
    background-color: transparent;
  }

  &--midnight {
    background-color: $nightblue;
    color: $pearl;
  }

  &--action {
    background-color: $amour;
    color: #e13146;
    padding: 3px 7px;
  }
}

.navbar__menu__item:hover {
  .badge {
    &--nav {
      color: #74b9be;
    }
  }
}

.navbar__menu__item--active {
  &:hover {
    .badge {
      &--nav {
        color: #489599;
      }
    }
  }

  .badge {
    &--nav {
      color: #489599;
    }
  }
}
