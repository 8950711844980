.browser-warning-doctor {
  align-items: center;
  display: flex;

  .top-overlay-message {
      color: white;
      font-size: 16px;
      height: 100%;
      padding: 10% 25px 10px 25px;
      text-align: center;
      width: 100%;
      @media (min-width: 768px) {
        padding: 0;
      }
    }

    .top-overlay-message > a {
      color: #43a7b3;
    }

    .top-overlay-message > a:hover {
      color: #23527c;
    }

  .right-overlay-message {
    position: fixed;
    top: 0;
    right: 0;
    width: 35px;
    height: 100vh;
    color: #303235;
    background-color: rgba(216, 250, 101, 0.3);
    z-index: 10000;
    padding: 10px;
    text-align: center;
    font-size: 16px;
    overflow: hidden;
    vertical-align: middle;

    .environment-banner{
      position: absolute;
      top: 50%;
      right: 50%;
      transform: translate(50%,-50%) rotate(90deg);
      width: 300px;
    }

  }
}
