.wire-selection-output {
  position: relative;

  .redo-wire-btn {
    position: absolute;
    top: 0px;
    right: 0px;

    button[disabled] {
      pointer-events: none;
    }
  }

  .ws-manual {
    margin-left: 5px;
  }

  .wsg-replace {
    font-size: 12px;
    border-radius: 5px !important;
    padding: 2px 6px;
  }

  .abmf-file-icon {
    margin-left: 5px;
  }
}
