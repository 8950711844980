/* Inbrace Official Color Palette */
$inbrace-blue: #32a1da;
$inbrace-pink: #f8c9dd;
$inbrace-light-pink: #fce5ef;
$inbrace-red: #f7b3b5;
$inbrace-salmon: #fde2cd;
$inbrace-turquoise: #7ececf;

/* Inbrace Unofficial Color Palette */
$inbrace-grey: #f7f7f7;

/* Standard Colors */
$warning: #e74c3c;
$text-grey: #999999;

/* Sizes */
$spaceamounts: (
  0,
  5,
  10,
  15,
  20,
  25,
  30,
  35,
  40,
  45,
  50,
  75,
  100
); // Adjust this to include the pixel amounts you need.
$sides: (top, bottom, left, right); // Leave this variable alone

@each $space in $spaceamounts {
  @each $side in $sides {
    .m#{str-slice($side, 0, 1)}-#{$space} {
      margin-#{$side}: #{$space}px !important;
    }

    .p#{str-slice($side, 0, 1)}-#{$space} {
      padding-#{$side}: #{$space}px !important;
    }
  }
}

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  color: #303235;
  overscroll-behavior: none;

  &.no-scroll {
    position: relative;
    overflow: hidden;
    height: 100%;
    width: 100%;

    touch-action: none;
  }
}

.hide-element {
  display: none;
}

input[type="checkbox"], input[type="radio"] {
  accent-color: #0075FF;
}

input[type='date'].form-control {
  line-height: 20px;
}