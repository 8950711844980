.teeth-selector {
  .row {
    display: flex;
    justify-content: center;

    .col-md-6 {
      width: auto;
      padding: 0;

      &:first-child {
        padding-right: 16px;
      }
    }
  }

  .btn-ib {
    // width: 54px;
    // height: 54px;
    padding: 4px;
    margin: 2px;
    font-size: 0.8em;
  }
}

// smaller padding in mobile