$background_color: #2a3033;
$turquoise: #2da4ad;
$padding: 20px;
$danger: #e34558;

.setup-viewer-backdrop {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(10px);
  transition: all 0.3s ease;
}

.setup-viewer-modal {
  position: fixed;
  z-index: 500;
  background-color: $background_color;
  width: 70%;
  border-top: 5px solid $turquoise;
  border-radius: 3px;
  padding: $padding;
  left: 50%;
  top: 50%;
  transition: all 0.3s ease;

  &.setup-viewer-modal--danger {
    border-top-color: $danger;
  }

  i {
    position: absolute;
    top: $padding;
    right: $padding;
    color: white;

    &:hover {
      cursor: pointer;
      color: rgba(white, 0.5);
    }
  }

  .modal-title {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 20px;
  }

  .modal-body-paragraph {
    font-size: 16px;
    margin-bottom: 20px;

    .bold {
      font-size: 16px;
      font-weight: bold;
    }
  }

  .modal-button-group {
    text-align: right;

    button {
      margin-right: 20px;
      font-size: 16px;
      height: 40px;
      width: 100px;

      &:last-child {
        margin-right: 0px;
      }
    }
  }
}

.modal-pdf.smartwire-label.modal-body {
  display: flex;
  flex-direction: column;

  .p-slim.grey-text {
    margin: 0;
  }

  .uploader-container {
    flex: 1 0 100%;

    form,
    .fileUpload-container,
    .fileUpload-box-full {
      height: 100%;
    }
  }
}

.modal-dialog {
  &.modal-dialog--setup-ipr {
    width: 90vw;
    margin: auto;
  }
  .uploader-container {
    .fileUpload-container {
      .record-thumbnail-tooltip-container {
        padding-top: 20px;
      }
    }
  }
}

.modal.production-log {
  .modal-body {
    max-height: 600px;
    overflow-y: scroll;
  }
}

@media (min-width: 720px) {
  .setup-viewer-modal {
    width: 700px;
  }
}

@media (min-width: 992px) {
  .modal-dialog {
    &.modal-dialog--setup-ipr {
      width: 960px;
    }
  }
}

.bpp-modal {
  .modal-dialog:not(.modal-lg) {
    max-height: calc(100vh - 200px);
    margin-top: 100px;
  }

  .modal-content {
    height: 100%;
    .comments-area {
      min-height: 100%;
      height: fit-content;
    }
  }

  .modal-body {
    max-height: calc(100vh - 330px);
    overflow-y: auto;
    overflow-x: hidden;
  }

  .modal-pdf .viewer {
    margin-bottom: 0;
    max-height: 100%;
    overflow: hidden;
  }
}
