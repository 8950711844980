// style from pdf => html conver
#page_1 {
  position: relative;
  overflow: hidden;
  margin: 10px 0px 95px 96px;
  padding: 0px;
  border: none;
  width: 720px;
}

#page_2 {
  position: relative;
  overflow: hidden;
  margin: 10px 0px 64px 96px;
  padding: 0px;
  border: none;
  width: 720px;
}
#page_2 #id2_1 {
  border: none;
  margin: 0px 0px 0px 0px;
  padding: 0px;
  border: none;
  width: 720px;
  overflow: hidden;
  width: 100% !important;
  padding-right: 0px !important;
}
#page_2 #id2_2 {
  border: none;
  margin: 44px 0px 0px 327px;
  padding: 0px;
  border: none;
  width: 393px;
  overflow: hidden;
}

#page_3 {
  position: relative;
  overflow: hidden;
  margin: 10px 0px 64px 96px;
  padding: 0px;
  border: none;
  width: 720px;
}
#page_3 #id3_1 {
  border: none;
  margin: 0px 0px 0px 0px;
  padding: 0px;
  border: none;
  width: 720px;
  overflow: hidden;
  width: 100% !important;
}
#page_3 #id3_2 {
  border: none;
  margin: 10px 0px 0px 327px;
  padding: 0px;
  border: none;
  width: 393px;
  overflow: hidden;
}

#page_4 {
  position: relative;
  overflow: hidden;
  margin: 10px 0px 64px 96px;
  padding: 0px;
  border: none;
  width: 720px;
}

#page_5 {
  position: relative;
  overflow: hidden;
  margin: 10px 0px 64px 96px;
  padding: 0px;
  border: none;
  width: 720px;
}

#page_6 {
  position: relative;
  overflow: hidden;
  margin: 10px 0px 64px 96px;
  padding: 0px;
  border: none;
  width: 720px;
}
#page_6 #id6_1 {
  border: none;
  margin: 0px 0px 0px 0px;
  padding: 0px;
  border: none;
  width: 624px;
  overflow: hidden;
  width: 100% !important;
}
#page_6 #id6_2 {
  border: none;
  margin: 10px 0px 0px 327px;
  padding: 0px;
  border: none;
  width: 393px;
  overflow: hidden;
  width: 100% !important;
}

#page_7 {
  position: relative;
  overflow: hidden;
  margin: 10px 0px 64px 96px;
  padding: 0px;
  border: none;
  width: 720px;
}

#page_8 {
  position: relative;
  overflow: hidden;
  margin: 10px 0px 64px 183px;
  padding: 0px;
  border: none;
  width: 633px;
}

#page_9 {
  position: relative;
  overflow: hidden;
  margin: 10px 0px 64px 96px;
  padding: 0px;
  border: none;
  width: 720px;
}

#page_10 {
  position: relative;
  overflow: hidden;
  margin: 10px 0px 64px 144px;
  padding: 0px;
  border: none;
  width: 672px;
}

#page_11 {
  position: relative;
  overflow: hidden;
  margin: 10px 0px 64px 144px;
  padding: 0px;
  border: none;
  width: 672px;
}

#page_12 {
  position: relative;
  overflow: hidden;
  margin: 10px 0px 64px 144px;
  padding: 0px;
  border: none;
  width: 672px;
}

#page_13 {
  position: relative;
  overflow: hidden;
  margin: 10px 0px 64px 144px;
  padding: 0px;
  border: none;
  width: 672px;
}

#page_14 {
  position: relative;
  overflow: hidden;
  margin: 10px 0px 64px 192px;
  padding: 0px;
  border: none;
  width: 624px;
}

#page_15 {
  position: relative;
  overflow: hidden;
  margin: 10px 0px 64px 120px;
  padding: 0px;
  border: none;
  width: 696px;
}

.ft0 {
  font: bold 16px 'Roboto, sans-serif' !important;
  line-height: 19px;
  padding-left: 0px !important;
  text-align: center !important;
}
.ft1 {
  font: 16px 'Roboto, sans-serif';
  line-height: 19px;
  padding-right: 0px !important;
  padding-left: 0px !important;
}
.ft2 {
  font: 16px 'Roboto, sans-serif';
  text-decoration: underline;
  margin-left: 4px;
  line-height: 19px;
}
.ft3 {
  font: 16px 'Roboto, sans-serif';
  margin-left: 4px;
  line-height: 19px;
}
.ft4 {
  font: bold 16px 'Roboto, sans-serif';
  line-height: 20px;
  padding-right: 0px !important;
  padding-left: 0px !important;
}
.ft5 {
  font: 16px 'Roboto, sans-serif';
  line-height: 20px;
  padding-right: 0px !important;
  padding-left: 0px !important;
}
.ft6 {
  font: 16px 'Roboto, sans-serif';
  margin-left: 4px;
  line-height: 20px;
}
.ft7 {
  font: 16px 'Roboto, sans-serif';
  line-height: 18px;
  padding-right: 0px !important;
  padding-left: 0px !important;
}
.ft8 {
  font: 16px 'Roboto, sans-serif';
  text-decoration: underline;
  margin-left: 4px;
  line-height: 18px;
}
.ft9 {
  font: 16px 'Roboto, sans-serif';
  text-decoration: underline;
  line-height: 19px;
}
.ft10 {
  font: 1px 'Roboto, sans-serif';
  line-height: 1px;
}
.ft11 {
  font: 1px 'Roboto, sans-serif';
  line-height: 18px;
}
.ft12 {
  font: 16px 'Roboto, sans-serif';
  text-decoration: underline;
  margin-left: 20px;
  line-height: 19px;
}
.ft13 {
  font: 16px 'Roboto, sans-serif';
  text-decoration: underline;
  margin-left: 20px;
  line-height: 20px;
}
.ft14 {
  font: 16px 'Roboto, sans-serif';
  margin-left: 17px;
  line-height: 19px;
}
.ft15 {
  font: 16px 'Roboto, sans-serif';
  margin-left: 17px;
  line-height: 20px;
}
.ft16 {
  font: 16px 'Roboto, sans-serif';
  margin-left: 17px;
  line-height: 18px;
}
.ft17 {
  font: bold 16px 'Roboto, sans-serif';
  line-height: 18px;
}
.ft18 {
  font: 16px 'Courier New';
  line-height: 18px;
}
.ft19 {
  font: 15px 'Roboto, sans-serif';
  color: #222222;
  line-height: 17px;
}
.ft20 {
  font: 15px 'Roboto, sans-serif';
  text-decoration: underline;
  color: #1155cc;
  line-height: 17px;
}
.ft21 {
  font: 15px 'Roboto, sans-serif';
  color: #1155cc;
  line-height: 17px;
  text-align: center !important;
}
.ft22 {
  font: bold 16px 'Roboto, sans-serif';
  text-decoration: underline;
  margin-left: 36px;
  line-height: 19px;
}
.ft23 {
  font: 16px 'Roboto, sans-serif';
  margin-left: 37px;
  line-height: 19px;
}
.ft24 {
  font: 16px 'Roboto, sans-serif';
  margin-left: 36px;
  line-height: 19px;
}
.ft25 {
  font: 16px 'Roboto, sans-serif';
  text-decoration: underline;
  margin-left: 24px;
  line-height: 19px;
}
.ft26 {
  font: 16px 'Roboto, sans-serif';
  margin-left: 39px;
  line-height: 19px;
}
.ft27 {
  font: 16px 'Roboto, sans-serif';
  margin-left: 36px;
  line-height: 20px;
}
.ft28 {
  font: 16px 'Roboto, sans-serif';
  margin-left: 24px;
  line-height: 19px;
}
.ft29 {
  font: 16px 'Roboto, sans-serif';
  margin-left: 40px;
  line-height: 19px;
}
.ft30 {
  font: 16px 'Roboto, sans-serif';
  margin-left: 37px;
  line-height: 20px;
}
.ft31 {
  font: 16px 'Roboto, sans-serif';
  margin-left: 24px;
  line-height: 20px;
}
.ft32 {
  font: bold 16px 'Roboto, sans-serif';
  text-decoration: underline;
  line-height: 19px;
}
.ft33 {
  font: 16px 'Roboto, sans-serif';
  text-decoration: underline;
  margin-left: 37px;
  line-height: 19px;
}
.ft34 {
  font: 16px 'Roboto, sans-serif';
  text-decoration: underline;
  margin-left: 36px;
  line-height: 19px;
}
.ft35 {
  font: 16px 'Roboto, sans-serif';
  margin-left: 37px;
  line-height: 18px;
}
.ft36 {
  font: 16px 'Roboto, sans-serif';
  text-decoration: underline;
  margin-left: 36px;
  line-height: 20px;
}
.ft37 {
  font: 16px 'Roboto, sans-serif';
  text-decoration: underline;
  margin-left: 39px;
  line-height: 20px;
}

.p0 {
  text-align: left;
  padding-left: 21px;
  margin-top: 0px;
  margin-bottom: 0px;
}
.p1 {
  text-align: justify;
  padding-right: 96px;
  margin-top: 15px;
  margin-bottom: 0px;
  text-indent: 48px;
}
.p2 {
  text-align: justify;
  padding-right: 96px;
  margin-top: 14px;
  margin-bottom: 0px;
  text-indent: 48px;
}
.p3 {
  text-align: justify;
  padding-right: 96px;
  margin-top: 11px;
  margin-bottom: 0px;
  text-indent: 48px;
}
.p4 {
  text-align: justify;
  padding-right: 96px;
  margin-top: 13px;
  margin-bottom: 0px;
  text-indent: 48px;
}
.p5 {
  text-align: left;
  padding-left: 48px;
  margin-top: 14px;
  margin-bottom: 0px;
}
.p6 {
  text-align: justify;
  padding-right: 96px;
  margin-top: 16px;
  margin-bottom: 0px;
  text-indent: 128px;
}
.p7 {
  text-align: justify;
  padding-right: 96px;
  margin-top: 0px;
  margin-bottom: 0px;
}
.p8 {
  text-align: justify;
  padding-right: 96px;
  margin-top: 11px;
  margin-bottom: 0px;
  text-indent: 128px;
}
.p9 {
  text-align: justify;
  padding-right: 96px;
  margin-top: 31px;
  margin-bottom: 0px;
  text-indent: 128px;
}
.p10 {
  text-align: left;
  padding-right: 96px;
  margin-top: 28px;
  margin-bottom: 0px;
  text-indent: 128px;
}
.p11 {
  text-align: left;
  padding-left: 48px;
  margin-top: 13px;
  margin-bottom: 0px;
}
.p12 {
  text-align: justify;
  padding-right: 96px;
  margin-top: 15px;
  margin-bottom: 0px;
  text-indent: 128px;
}
.p13 {
  text-align: justify;
  padding-right: 96px;
  margin-top: 10px;
  margin-bottom: 0px;
  text-indent: 128px;
}
.p14 {
  text-align: left;
  margin-top: 0px;
  margin-bottom: 0px;
}
.p15 {
  text-align: left;
  padding-left: 48px;
  margin-top: 0px;
  margin-bottom: 0px;
}
.p16 {
  text-align: left;
  padding-right: 96px;
  margin-top: 14px;
  margin-bottom: 0px;
  text-indent: 128px;
}
.p17 {
  text-align: justify;
  padding-right: 96px;
  margin-top: 13px;
  margin-bottom: 0px;
  text-indent: 128px;
}
.p18 {
  text-align: left;
  padding-left: 128px;
  margin-top: 11px;
  margin-bottom: 0px;
}
.p19 {
  text-align: justify;
  padding-right: 96px;
  margin-top: 2px;
  margin-bottom: 0px;
}
.p20 {
  text-align: justify;
  padding-right: 96px;
  margin-top: 12px;
  margin-bottom: 0px;
  text-indent: 128px;
}
.p21 {
  text-align: justify;
  padding-right: 96px;
  margin-top: 7px;
  margin-bottom: 0px;
}
.p22 {
  text-align: justify;
  padding-right: 96px;
  margin-top: 9px;
  margin-bottom: 0px;
  text-indent: 128px;
}
.p23 {
  text-align: left;
  padding-right: 96px;
  margin-top: 0px;
  margin-bottom: 0px;
}
.p24 {
  text-align: justify;
  padding-right: 96px;
  margin-top: 14px;
  margin-bottom: 0px;
  text-indent: 128px;
}
.p25 {
  text-align: left;
  padding-left: 48px;
  margin-top: 10px;
  margin-bottom: 0px;
}
.p26 {
  text-align: left;
  padding-left: 327px;
  margin-top: 11px;
  margin-bottom: 0px;
}
.p27 {
  text-align: left;
  padding-right: 98px;
  margin-top: 0px;
  margin-bottom: 0px;
  text-indent: 48px;
}
.p28 {
  text-align: justify;
  padding-right: 96px;
  margin-top: 24px;
  margin-bottom: 0px;
  text-indent: 48px;
}
.p29 {
  text-align: justify;
  padding-right: 96px;
  margin-top: 32px;
  margin-bottom: 0px;
  text-indent: 48px;
}
.p30 {
  text-align: left;
  padding-left: 48px;
  margin-top: 24px;
  margin-bottom: 0px;
}
.p31 {
  text-align: left;
  margin-top: 0px;
  margin-bottom: 0px;
  white-space: nowrap;
}
.p32 {
  text-align: justify;
  padding-right: 96px;
  margin-top: 31px;
  margin-bottom: 0px;
  text-indent: 48px;
}
.p33 {
  text-align: left;
  padding-left: 327px;
  margin-top: 15px;
  margin-bottom: 0px;
}
.p34 {
  text-align: justify;
  margin-top: 13px;
  margin-bottom: 0px;
  text-indent: 48px;
}
.p35 {
  text-align: left;
  margin-top: 14px;
  margin-bottom: 0px;
  text-indent: 48px;
}
.p36 {
  text-align: justify;
  margin-top: 14px;
  margin-bottom: 0px;
  text-indent: 48px;
}
.p37 {
  text-align: left;
  padding-right: 9px;
  margin-top: 13px;
  margin-bottom: 0px;
  text-indent: 48px;
}
.p38 {
  text-align: justify;
  margin-top: 12px;
  margin-bottom: 0px;
  text-indent: 48px;
}
.p39 {
  text-align: left;
  padding-left: 270px;
  margin-top: 0px;
  margin-bottom: 0px;
}
.p40 {
  text-align: left;
  padding-left: 214px;
  margin-top: 15px;
  margin-bottom: 0px;
}
.p41 {
  text-align: justify;
  padding-right: 129px;
  margin-top: 16px;
  margin-bottom: 0px;
}
.p42 {
  text-align: left;
  padding-left: 24px;
  padding-right: 119px;
  margin-top: 10px;
  margin-bottom: 0px;
  text-indent: -24px;
}
.p43 {
  text-align: left;
  padding-left: 24px;
  padding-right: 107px;
  margin-top: 0px;
  margin-bottom: 0px;
  text-indent: -24px;
}
.p44 {
  text-align: left;
  padding-left: 24px;
  padding-right: 148px;
  margin-top: 1px;
  margin-bottom: 0px;
  text-indent: -24px;
}
.p45 {
  text-align: left;
  padding-right: 121px;
  margin-top: 13px;
  margin-bottom: 0px;
}
.p46 {
  text-align: left;
  padding-left: 24px;
  padding-right: 125px;
  margin-top: 12px;
  margin-bottom: 0px;
  text-indent: -24px;
}
.p47 {
  text-align: left;
  padding-left: 24px;
  padding-right: 132px;
  margin-top: 0px;
  margin-bottom: 0px;
  text-indent: -24px;
}
.p48 {
  text-align: left;
  margin-top: 3px;
  margin-bottom: 0px;
}
.p49 {
  text-align: left;
  padding-left: 183px;
  margin-top: 0px;
  margin-bottom: 0px;
}
.p50 {
  text-align: left;
  padding-left: 124px;
  margin-top: 15px;
  margin-bottom: 0px;
}
.p51 {
  text-align: left;
  margin-top: 18px;
  margin-bottom: 0px;
}
.p52 {
  text-align: left;
  padding-left: 240px;
  margin-top: 791px;
  margin-bottom: 0px;
}
.p53 {
  text-align: left;
  padding-left: 209px;
  margin-top: 11px;
  margin-bottom: 0px;
}
.p54 {
  text-align: left;
  padding-right: 97px;
  margin-top: 16px;
  margin-bottom: 0px;
  text-indent: 48px;
}
.p55 {
  text-align: left;
  padding-left: 48px;
  margin-top: 8px;
  margin-bottom: 0px;
}
.p56 {
  text-align: left;
  padding-right: 99px;
  margin-top: 3px;
  margin-bottom: 0px;
}
.p57 {
  text-align: left;
  padding-right: 105px;
  margin-top: 11px;
  margin-bottom: 0px;
  text-indent: 48px;
}
.p58 {
  text-align: left;
  padding-right: 109px;
  margin-top: 11px;
  margin-bottom: 0px;
  text-indent: 48px;
}
.p59 {
  text-align: left;
  padding-right: 108px;
  margin-top: 13px;
  margin-bottom: 0px;
  text-indent: 48px;
}
.p60 {
  text-align: left;
  padding-left: 96px;
  padding-right: 131px;
  margin-top: 16px;
  margin-bottom: 0px;
  text-indent: 48px;
}
.p61 {
  text-align: left;
  padding-left: 144px;
  margin-top: 14px;
  margin-bottom: 0px;
}
.p62 {
  text-align: left;
  padding-left: 144px;
  padding-right: 102px;
  margin-top: 16px;
  margin-bottom: 0px;
  text-indent: 112px;
}
.p63 {
  text-align: left;
  padding-left: 144px;
  padding-right: 101px;
  margin-top: 14px;
  margin-bottom: 0px;
  text-indent: 107px;
}
.p64 {
  text-align: left;
  padding-left: 144px;
  padding-right: 97px;
  margin-top: 14px;
  margin-bottom: 0px;
  text-indent: 103px;
}
.p65 {
  text-align: left;
  padding-left: 327px;
  margin-top: 25px;
  margin-bottom: 0px;
}
.p66 {
  text-align: left;
  padding-left: 96px;
  padding-right: 125px;
  margin-top: 0px;
  margin-bottom: 0px;
  text-indent: 104px;
}
.p67 {
  text-align: left;
  margin-top: 31px;
  margin-bottom: 0px;
}
.p68 {
  text-align: left;
  padding-left: 48px;
  padding-right: 109px;
  margin-top: 16px;
  margin-bottom: 0px;
  text-indent: 48px;
}
.p69 {
  text-align: left;
  padding-left: 48px;
  padding-right: 109px;
  margin-top: 14px;
  margin-bottom: 0px;
  text-indent: 48px;
}
.p70 {
  text-align: left;
  padding-left: 48px;
  padding-right: 98px;
  margin-top: 14px;
  margin-bottom: 0px;
  text-indent: 48px;
}
.p71 {
  text-align: left;
  padding-left: 48px;
  padding-right: 106px;
  margin-top: 30px;
  margin-bottom: 0px;
  text-indent: 48px;
}
.p72 {
  text-align: justify;
  padding-left: 48px;
  padding-right: 128px;
  margin-top: 9px;
  margin-bottom: 0px;
  text-indent: 48px;
}
.p73 {
  text-align: left;
  padding-left: 48px;
  margin-top: 1px;
  margin-bottom: 0px;
}
.p74 {
  text-align: left;
  padding-left: 48px;
  padding-right: 135px;
  margin-top: 15px;
  margin-bottom: 0px;
  text-indent: 48px;
}
.p75 {
  text-align: left;
  padding-left: 48px;
  padding-right: 104px;
  margin-top: 13px;
  margin-bottom: 0px;
  text-indent: 48px;
}
.p76 {
  text-align: left;
  padding-left: 279px;
  margin-top: 12px;
  margin-bottom: 0px;
}
.p77 {
  text-align: left;
  padding-left: 48px;
  padding-right: 114px;
  margin-top: 0px;
  margin-bottom: 0px;
}
.p78 {
  text-align: left;
  padding-left: 96px;
  padding-right: 99px;
  margin-top: 13px;
  margin-bottom: 0px;
  text-indent: 112px;
}
.p79 {
  text-align: left;
  padding-left: 48px;
  padding-right: 98px;
  margin-top: 31px;
  margin-bottom: 0px;
  text-indent: 48px;
}
.p80 {
  text-align: left;
  padding-left: 48px;
  padding-right: 106px;
  margin-top: 14px;
  margin-bottom: 0px;
  text-indent: 48px;
}
.p81 {
  text-align: left;
  margin-top: 13px;
  margin-bottom: 0px;
}
.p82 {
  text-align: left;
  padding-left: 48px;
  padding-right: 106px;
  margin-top: 16px;
  margin-bottom: 0px;
  text-indent: 48px;
}
.p83 {
  text-align: left;
  padding-left: 96px;
  margin-top: 13px;
  margin-bottom: 0px;
}
.p84 {
  text-align: left;
  padding-left: 208px;
  margin-top: 12px;
  margin-bottom: 0px;
}
.p85 {
  text-align: left;
  padding-left: 96px;
  margin-top: 3px;
  margin-bottom: 0px;
}
.p86 {
  text-align: left;
  padding-left: 96px;
  padding-right: 105px;
  margin-top: 15px;
  margin-bottom: 0px;
  text-indent: 107px;
}
.p87 {
  text-align: left;
  padding-left: 96px;
  padding-right: 114px;
  margin-top: 13px;
  margin-bottom: 0px;
  text-indent: 103px;
}
.p88 {
  text-align: left;
  padding-left: 200px;
  margin-top: 11px;
  margin-bottom: 0px;
}
.p89 {
  text-align: left;
  padding-left: 96px;
  margin-top: 2px;
  margin-bottom: 0px;
}
.p90 {
  text-align: left;
  padding-left: 96px;
  padding-right: 98px;
  margin-top: 16px;
  margin-bottom: 0px;
  text-indent: 108px;
}
.p91 {
  text-align: left;
  padding-left: 48px;
  padding-right: 104px;
  margin-top: 14px;
  margin-bottom: 0px;
  text-indent: 48px;
}
.p92 {
  text-align: left;
  padding-left: 279px;
  margin-top: 60px;
  margin-bottom: 0px;
}
.p93 {
  text-align: left;
  padding-left: 96px;
  margin-top: 16px;
  margin-bottom: 0px;
}
.p94 {
  text-align: left;
  padding-left: 96px;
  padding-right: 140px;
  margin-top: 15px;
  margin-bottom: 0px;
  text-indent: 112px;
}
.p95 {
  text-align: justify;
  padding-left: 96px;
  padding-right: 111px;
  margin-top: 13px;
  margin-bottom: 0px;
  text-indent: 107px;
}
.p96 {
  text-align: left;
  padding-left: 96px;
  padding-right: 109px;
  margin-top: 14px;
  margin-bottom: 0px;
  text-indent: 103px;
}
.p97 {
  text-align: left;
  padding-left: 96px;
  padding-right: 99px;
  margin-top: 14px;
  margin-bottom: 0px;
  text-indent: 104px;
}
.p98 {
  text-align: left;
  padding-left: 204px;
  margin-top: 10px;
  margin-bottom: 0px;
}
.p99 {
  text-align: left;
  margin-top: 15px;
  margin-bottom: 0px;
}
.p100 {
  text-align: left;
  padding-left: 48px;
  padding-right: 107px;
  margin-top: 15px;
  margin-bottom: 0px;
  text-indent: 48px;
}
.p101 {
  text-align: left;
  padding-left: 48px;
  padding-right: 110px;
  margin-top: 13px;
  margin-bottom: 0px;
  text-indent: 48px;
}
.p102 {
  text-align: left;
  padding-left: 48px;
  padding-right: 111px;
  margin-top: 31px;
  margin-bottom: 0px;
  text-indent: 48px;
}
.p103 {
  text-align: left;
  padding-left: 279px;
  margin-top: 34px;
  margin-bottom: 0px;
}
.p104 {
  text-align: left;
  padding-left: 48px;
  padding-right: 111px;
  margin-top: 0px;
  margin-bottom: 0px;
  text-indent: 48px;
}
.p105 {
  text-align: left;
  padding-left: 96px;
  padding-right: 103px;
  margin-top: 14px;
  margin-bottom: 0px;
  text-indent: 112px;
}
.p106 {
  text-align: left;
  padding-left: 96px;
  padding-right: 110px;
  margin-top: 14px;
  margin-bottom: 0px;
  text-indent: 107px;
}
.p107 {
  text-align: justify;
  padding-left: 96px;
  padding-right: 100px;
  margin-top: 14px;
  margin-bottom: 0px;
  text-indent: 103px;
}
.p108 {
  text-align: left;
  padding-left: 96px;
  padding-right: 98px;
  margin-top: 14px;
  margin-bottom: 0px;
  text-indent: 104px;
}
.p109 {
  text-align: left;
  padding-left: 96px;
  padding-right: 99px;
  margin-top: 13px;
  margin-bottom: 0px;
  text-indent: 108px;
}
.p110 {
  text-align: left;
  margin-top: 14px;
  margin-bottom: 0px;
}
.p111 {
  text-align: left;
  padding-left: 48px;
  padding-right: 104px;
  margin-top: 11px;
  margin-bottom: 0px;
  text-indent: 48px;
}
.p112 {
  text-align: left;
  padding-left: 48px;
  padding-right: 116px;
  margin-top: 7px;
  margin-bottom: 0px;
}
.p113 {
  text-align: left;
  padding-left: 48px;
  padding-right: 120px;
  margin-top: 14px;
  margin-bottom: 0px;
  text-indent: 48px;
}
.p114 {
  text-align: justify;
  padding-left: 48px;
  padding-right: 104px;
  margin-top: 13px;
  margin-bottom: 0px;
  text-indent: 48px;
}
.p115 {
  text-align: left;
  padding-right: 114px;
  margin-top: 0px;
  margin-bottom: 0px;
}
.p116 {
  text-align: left;
  padding-right: 105px;
  margin-top: 13px;
  margin-bottom: 0px;
  text-indent: 48px;
}
.p117 {
  text-align: left;
  padding-right: 96px;
  margin-top: 13px;
  margin-bottom: 0px;
  text-indent: 48px;
}
.p118 {
  text-align: left;
  padding-right: 102px;
  margin-top: 14px;
  margin-bottom: 0px;
  text-indent: 48px;
}
.p119 {
  text-align: left;
  padding-left: 85px;
  margin-top: 47px;
  margin-bottom: 0px;
}
.p120 {
  text-align: left;
  padding-left: 231px;
  margin-top: 596px;
  margin-bottom: 0px;
}
.p121 {
  text-align: left;
  padding-left: 238px;
  margin-top: 0px;
  margin-bottom: 0px;
}
.p122 {
  text-align: left;
  padding-left: 263px;
  margin-top: 11px;
  margin-bottom: 0px;
}
.p123 {
  text-align: left;
  padding-left: 24px;
  padding-right: 111px;
  margin-top: 36px;
  margin-bottom: 0px;
  text-indent: -24px;
}
.p124 {
  text-align: left;
  padding-left: 303px;
  margin-top: 756px;
  margin-bottom: 0px;
}

.td0 {
  padding: 0px;
  margin: 0px;
  width: 192px;
  vertical-align: bottom;
}
.td1 {
  padding: 0px;
  margin: 0px;
  width: 175px;
  vertical-align: bottom;
}

.tr0 {
  height: 25px;
}
.tr1 {
  height: 19px;
}
.tr2 {
  height: 18px;
}
.tr3 {
  height: 21px;
}

.t0 {
  width: 367px;
  margin-left: 48px;
  margin-top: 28px;
  font: 16px 'Roboto, sans-serif';
}
.page {
  width: 100% !important;
  margin-left: 0px !important;
  margin-bottom: 10px !important;
  text-align: center !important;
}

#page_5 tr {
  font-size: 1em;
}
#page_5 table {
  margin-left: 0vw;
}
#page_5 td:first-child {
  width: 0px;
  min-width: 0px;
}

//
.checkbox1 {
  margin-bottom: 20px;
}
.agreement {
  overflow-x: auto !important;
  max-height: 37vh;
  overflow: scroll;
  white-space: pre-wrap;
  word-break: break-word;
  margin-bottom: 20px;
  width: 100%;
  padding-right: 10px;
  text-align: center;
}

.agreement_content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px 20px;
}

.agreement-form {
  overflow: auto;
}

.red_input_boarder {
  border: 1px solid #e34558 !important;
}

.email_input_container {
  margin-top: 20px;
  text-align: start;
}

.pg_content_light_header {
  font-weight: bold !important;
  margin-top: 20px;
}

.agreement_content .email_input_container .ipp_referral_email_input {
  width: 300px;
}

.email_input_container .error-message {
  opacity: 1;
  position: absolute;
  color: #e34558 !important;
}
.hide {
  display: none !important;
}

@media (max-width: 730px) {
  .checkbox1 {
    text-align: start;
  }
  .checkbox2 {
    text-align: start;
  }
  .email_input_container .error-message {
    position: absolute;
  }
  .agreement_container .base-modal {
    overflow-y: hidden !important;
  }
  .agreement_content .email_input_container .ipp_referral_email_input {
    width: 200px;
  }
}

@media (max-width: 992px) {
  .agreement {
    max-height: 30vh;
  }
}

@media (max-height: 700px) {
  .ipp-light .base-modal .modal-content {
    margin-top: -20px !important;
  }
}

@media (max-height: 800px) {
  .agreement-form {
    height: 25vh;
    padding-bottom: 32px;
  }
}

@media (max-height: 650px) {
  .agreement-form {
    height: 15vh;
  }
}
