@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

$dark-gray: #303235;
$light-gray: #394042;
$gray: #383c3f;

.manual-wire-table-length {
    .first-item {
        min-width: 130px;
        border: 1px solid $light-gray;
        color: #fff;
        padding: 10px;
        background-color: $gray;
        display: flex;
        align-items: center;
    }

    .manual-wire-table-length-element {
        &.error {
            border: 1px solid red;
        }

        min-width: 130px;
        border: 1px solid $light-gray;
        color: #fff;
        padding: 10px;
        background-color: $light-gray;
        display: flex;
        align-items: center;
        justify-content: center;

        .input-number {
            font-family: 'Roboto', sans-serif;
            font-weight: 400;
            font-size: 1rem;
            line-height: 1.5;
            letter-spacing: 0.00938em;
            color: #000;
            height: 100%;
            padding: 5px;
            background-color: #fff;
            width: 50px;
            text-align: center;
            border-radius: 5px;

            &.disabled {
                background-color: #d3d3d3;
            }
        }

        label {
            font-weight: lighter;
            color: #fff;
            font-size: small;
            margin-left: 20px;
        }
    }
}
