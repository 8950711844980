$announcement-image: "../../../../public/static/img/smile_design_preferences.png";
$announcement-sm-image: "../../../../public/static/img/smile_design_preferences_sm.png";

.feature-announcement-modal.simple-modal {
  .modal-dialog {
    .modal-content {
      .modal-body {
        .modal-content-body {
          &#announcement-image {
            background-image: url($announcement-image);
            background-size: cover;
            background-origin: border-box;
            background-repeat: no-repeat;
            min-height: 365px;
          }

          @media screen and (pointer: coarse) {
            @media (max-width: 767px) {
              &#announcement-image {
                background-image: url($announcement-sm-image);
                min-height: 320px;
              }
            }
            @media (width: 540px) {
              &#announcement-image {
                background-image: url($announcement-image);
              }
            }
          }
        }
      }
    }
  }
}
