.content {
  min-height: 95vh !important;

  .content__header {
    padding: $general_spacing * 2 0px 0px 0px;
  }

  h2 {
    margin: 0;
    margin-bottom: 10px;
  }

  &--fullscreen {
    display: block;
    min-height: 100vh;
    padding: 20px 15px 15px;
    position: relative;
    height: 100vh;
  }
}

.content {
  .content--fullscreen {
    margin-top: -120px;
  }
}

@media screen and (max-width: 1024px) {
  .content {
    min-height: auto !important;
  }
}
