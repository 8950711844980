@import "src/doctor/styles/base/_variables";

.notification {
  display: flex;
  align-items: center;
  align-self: center;
  margin-top: 8px;
  padding: 8px 16px;
  font-weight: $notification-font-weight;
  font-size: 14px;
  border-radius: 5px;
  opacity: 1;
  transition-property: opacity;
  transition-timing-function: ease;

  &.error {
    color: $notification-error-color;
    background: $notification-error-bg;

    svg path {
      stroke: $notification-error-color;
    }
  }
  &.warn {
    color: $notification-warn-color;
    background: $notification-warn-bg;

    svg path {
      stroke: $notification-warn-color;
    }
  }

  .icon {
    display: flex;
    margin-right: 8px;
  }

  .dismiss-btn {
    font-weight: 400;
    font-style: italic;
    text-decoration: underline;
    margin-left: 16px;
    cursor: pointer;
  }

  &.isDisappearing {
    opacity: 0;
  }
}
