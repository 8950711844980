.question-box {
  margin-bottom: 15px;

  textarea {
    min-height: 100px;
  }
}

.question-box .title {
  padding-left: 15px;
  padding-bottom: 4px;
  font-size: 1em;
  display: flex;
  column-gap: 4px;
  padding-right: 4px;

  &.inline {
    display: inline;
  }
}
.question-box .title.warning-text .title-index {
  color: #e34558;
}

.question > .form-group {
  margin: 5px 15px;
}

.ib-arch.ib-arch-sidebyside {
  margin-left: 23px;
}

.question {
  background-color: rgb(242, 249, 251);
  border-radius: 15px;
  padding: 0px;
  margin: 0px;
  padding-bottom: 5px;
  padding-bottom: 8px;
  padding-top: 3px;
}

.question .teeth-selector {
  padding-bottom: 0px;
}

.opposing-arch {
  margin-left: 1.5em;
  display: inline-block;
}

.opposing-arch.flex-column {
  display: flex;
  flex-direction: column;
}

.opposing-arch div {
  display: inline-block;
}

.ib-radio input.mm-size {
  margin-left: 5px;
}

.ib-radio input.mm-size:disabled {
  cursor: text;
}

.ib-radio > :last-child {
  margin-right: 20px;
}

.ib-radio {
  .form-check-label {
    display: flex;
    align-items: flex-start;
  }
}

.ib-checkbox {
  margin-left: 15px;
  .form-check-label {
    display: flex;
    align-items: flex-start;
  }
}

.btn-ib {
  position: relative;
  margin: 5px 5px 0px 5px;
  padding: 5px;
  padding-top: 7px;
  padding-bottom: 7px;
  border-radius: 10px;
  border-style: solid;
  border-width: 0px;
  border: 1px solid rgb(220, 220, 220);
  background-color: white;
  // box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);
  z-index: 2;
}

.btn-ib:active {
  left: 1px;
  top: 1px;
  // box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.btn-ib.btn-ib--active {
  background-color: rgb(144, 204, 206);
  border-color: rgb(144, 204, 206);
  color: white;
}

.btn-ib.btn-ib--disabled {
  opacity: 0.4;
  pointer-events: none;
}

.btn-ib.btn-ib--active.btn-ib--disabled,
.btn-ib.btn-ib--active.disabled {
  color: white;
}

.teeth-selector {
  display: block;
  margin: 5px;
  padding: 8px 8px 8px 8px;
  margin-top: 0px;
}

.teeth-selector .btn-ib {
  font-size: 9pt;
  width: 35px;
  margin-left: 2px;
  margin-right: 2px;
}

#bracket-presets button {
  width: 75px;
}

.btn-bar {
  display: inline-block;
}

textarea {
  border-radius: 15px;
}

#restrict-reason {
  margin-top: 10px;
}

label {
  font-weight: normal;
}

.opposing-arch div.ib-radio {
  padding-top: 0px;
}

.ib-checkbox input.form-check-input {
  margin-right: 6px;
}

.tooth-viewer {
  text-align: center;
  width: 95%;
  position: relative;
  width: inherit;
}

.tooth-loader {
  padding: 20px;
  height: 55vh;
  vertical-align: middle;
  text-align: center;
  color: #fff;
  font-size: 20px;
  width: 100%;
  padding-top: 50%;
  position: absolute;
  top: 70px;
}

.modal-content .tooth-viewer-inner-frame {
  margin-top: 30px;
}
.tooth-viewer-inner-frame {
  width: 100% !important;
}

.tooth-viewer svg {
  margin-left: 15px;
  height: 37vh;
  display: inline-block;
}

.tx-heading {
  color: #43a7b3;
  font-size: 20px;
  margin-top: 25px;
  margin-bottom: 5px;
}

.tx-heading:first-child {
  margin-top: 0px;
}

#improve-crowding .btn-ib {
  padding: 5px;
  width: 100px;
}

.label-right {
  text-align: right;
  min-height: 32px;
  vertical-align: middle;
  display: table-cell;
}

#spacing-box .mm-size {
  width: 500px;
}

.info-box {
  padding: 8px;
  border-radius: 3px;
  opacity: 0.9;
}

.ib-warning {
  padding: 10px;
  border-radius: 10px;
  background-color: #ffebe4;
  margin: 10px 15px 8px 15px;
}

.ib-warning-heading {
  border-radius: 10px;
  background-color: #f2f9fb;
  padding: 10px;
  margin-left: -10px;
  margin-bottom: 15px;
}

.overjet .ib-checkbox {
  padding-left: 2em;
}

.form-check-label.disabled {
  color: rgb(150, 150, 150);
}

.spacing-chart-line {
  padding-left: 10px;
  padding-right: 10px;
  background-color: rgb(245, 245, 245);
  border-radius: 7px;
  margin-bottom: 5px;
}

.spacing-chart-tooth {
  display: inline-block;
  width: 32px;
  padding: 2px;
  border-radius: 5px;
  background-color: rgb(230, 230, 230);
}

.spacing-center > table.spacing-chart-input-U,
.spacing-center > table.spacing-chart-input-L,
.spacing-center > table {
  margin-left: auto;
  margin-right: auto;
}

.spacing-center {
  &.upper {
    &.mb {
      margin-bottom: 32px;
    }
  }

  &.lower {
    &.mt {
      margin-top: 32px;
    }
  }
}

a.anchor-offset {
  position: relative;
  display: block;
  visibility: hidden;
  top: -150px;
}

table.spacing-chart > tbody > tr > td.spacing-chart-upper-label,
table.spacing-chart > tbody > tr > td.spacing-chart-upper-label-hidden {
  width: 44px;
  min-width: 44px;
  border-bottom: 2.5px solid #aaa;
  border-right: 1px solid #ccc;
  text-align: center;
  height: 28px;
  vertical-align: bottom;
}

table.spacing-chart > tbody > tr > td.spacing-chart-lower-label.spacing-chart-lower-label-border {
  border-top: 1px solid #ccc;
}

table.spacing-chart > tbody > tr > td.spacing-chart-lower-label,
table.spacing-chart > tbody > tr > td.spacing-chart-lower-label-hidden {
  width: 44px;
  min-width: 44px;
  border-right: 1px solid #ccc;
  text-align: center;
  height: 28px;
  vertical-align: top;
}

table.spacing-chart > tbody > tr > td.spacing-chart-upper-label:nth-child(8),
table.spacing-chart > tbody > tr > td.spacing-chart-lower-label:nth-child(8) {
  border-right: 2.5px solid #aaa;
}

.spacing-strike {
  text-decoration: line-through;
}

table.spacing-chart > tbody > tr > td.spacing-chart-upper-label:last-child,
table.spacing-chart > tbody > tr > td.spacing-chart-lower-label:last-child,
table.spacing-chart > tbody > tr > td.spacing-chart-upper-label-hidden:last-child,
table.spacing-chart > tbody > tr > td.spacing-chart-lower-label-hidden:last-child {
  border-right: None;
}

table.spacing-chart > tbody > tr > td.spacing-chart-upper-label-hidden,
table.spacing-chart > tbody > tr > td.spacing-chart-lower-label-hidden {
  border-right: None;
}

table.spacing-chart-input-U,
table.spacing-chart-input-L {
  margin-left: 24px;
  max-height: 60px;
  height: 60px;
}

.awb {
  table.spacing-chart-input-U,
  table.spacing-chart-input-L {
    margin-left: 68px;
  }
}

table.spacing-chart-input-U > tbody > tr > td {
  width: 44px;
  min-width: 44px;
  text-align: center;
  vertical-align: bottom;
  height: 32px;
}

table.spacing-chart-input-L > tbody > tr > td {
  width: 44px;
  min-width: 44px;
  text-align: center;
  vertical-align: top;
  height: 32px;
}

.spacing-chart-input-U > tbody > tr.tx-instruction,
.spacing-chart-input-L > tbody > tr.tx-instruction {
  padding-left: 44px;
}

.spacing-chart-input-upper {
  width: 22px;
  height: 20px;
  border-radius: 5px;
  border: 1px solid #ccc;
  outline: none;
}

.spacing-chart-input-upper::placeholder,
.spacing-chart-input-lower::placeholder {
  color: white;
}

.spacing-chart-input-upper:hover {
  border-color: #43a7b3;
}

.spacing-chart-input-upper:active,
.spacing-chart-input-upper:focus {
  width: 32px;
  height: 32px;
  padding: 4px;
}

.spacing-chart-input-upper:active::placeholder,
.spacing-chart-input-upper:focus::placeholder,
.spacing-chart-input-lower:active::placeholder,
.spacing-chart-input-lower:focus::placeholder {
  color: #ccc;
}

.spacing-chart-input-upper.spacing-chart-input-upper-lrg,
.spacing-chart-input-lower.spacing-chart-input-lower-lrg {
  width: 32px;
  height: 32px;
  padding: 4px;
  background-color: rgb(144, 204, 206);
  color: #fff;
}

.spacing-chart-input-upper.spacing-chart-input-upper-lrg:active,
.spacing-chart-input-lower.spacing-chart-input-lower-lrg:active,
.spacing-chart-input-upper.spacing-chart-input-upper-lrg:focus,
.spacing-chart-input-lower.spacing-chart-input-lower-lrg:focus {
  background-color: #fff;
  color: #000;
}

.spacing-chart-input-upper.spacing-chart-input-upper-lrg:disabled,
.spacing-chart-input-lower.spacing-chart-input-lower-lrg:disabled {
  width: 32px;
  height: 32px;
  background-color: rgb(202, 199, 199);
  color: #000;
}

.spacing-chart-input-lower {
  width: 22px;
  height: 20px;
  border-radius: 5px;
  border: 1px solid #ccc;
  vertical-align: top;
  outline: none;
}

.spacing-chart-input-lower:hover {
  border-color: #43a7b3;
}

.spacing-chart-input-lower:active,
.spacing-chart-input-lower:focus {
  width: 32px;
  height: 32px;
  padding: 4px;
}

.spacing-chart-input-lower:focus,
.spacing-chart-input-upper:focus {
  border-color: #43a7b3;
}

.spacing-chart-x-upper {
  display: inline-block;
  font-size: 12px;
  cursor: pointer;
  position: relative;
  top: 11px;
  left: -15px;
  background-color: rgba(255, 255, 255, 0.7);
  border: 1px solid #ccc;
  border-radius: 50%;
  width: 19px;
  height: 25px;
  line-height: 18px;
  color: #aaa;
}

.spacing-chart-x-lower {
  display: inline-block;
  font-size: 12px;
  cursor: pointer;
  position: relative;
  top: -11px;
  left: -15px;
  background-color: rgba(255, 255, 255, 0.7);
  border: 1px solid #ccc;
  border-radius: 50%;
  width: 19px;
  height: 25px;
  line-height: 18px;
  color: #aaa;
}

.spacing-chart-x-upper:hover,
.spacing-chart-x-lower:hover {
  border: 1px solid #43a7b3;
  color: #43a7b3;
}

.spacing-chart-input-upper:disabled,
.spacing-chart-input-lower:disabled {
  width: 22px;
  height: 20px;
  border-color: #ccc;
  background-color: rgb(202, 199, 199);
}

.spacing-chart-zone {
  margin-top: -20px;
  margin-bottom: -20px;
}

.spacing-chart-zone.upper-only {
  margin-bottom: 0px;
}

.upper-only > div.spacing-center:first-child {
  padding-bottom: 20px;
}

.upper-only > div.spacing-center:nth-child(2) {
  margin-top: -20px;
}

.spacing-chart-zone.lower-only {
  margin-top: 0px;
}

.lower-only > div.spacing-center:first-child {
  padding-top: 20px;
}

.tx_heading {
  color: #43a7b3;
}

.bracket-preset {
  padding: 15px 0px 0px 15px;
}

.form-group.aux-box {
  margin: 3px 15px 0px 15px;
}

.btn-bar.btn-bar-spaced {
  margin-bottom: 5px;
}

.tx-heading-h4 {
  margin-bottom: 5px;
  font-size: 1em;
}

.form-check-label {
  cursor: pointer;

  input {
    margin-right: 6px;
  }
}

#spacing-box .question-box .question {
  padding-top: 0px;
}

.selection-green {
  .disabled.active {
    background-color: rgb(144, 204, 206);
    border-color: rgb(144, 204, 206);
    color: white;
  }
}

#fullscreen_header {
  position: fixed;
  right: 35px;
  top: 5%;
  z-index: 2;
}

#fullscreen_footer {
  position: fixed;
  bottom: 10px;
  width: 80%;
  left: 10%;
}

#lightboxBackdrop {
  .tooth-viewer-inner-frame svg {
    background-color: white;
    border-radius: 3px;
  }

  #fullscreen_header button span svg {
    fill: white;
    @media (max-width: 754px) and (max-height: 780px) {
      fill: #337ab7;
    }
  }

  #fullscreen_footer > div {
    color: rgba(255, 255, 255, 0.75);
    @media (max-width: 880px) and (max-height: 732px) {
      color: #337ab7;
    }
  }

  button.arrow-direction span svg {
    fill: white;
    @media (max-width: 845px) {
      fill: #337ab7;
    }
  }
}

.footer_download {
  color: #337ab7 !important;
}

.anterior-posterior {
  display: flex;
  flex-direction: column;
  row-gap: 16px;

  &__radios {
    display: flex;
    flex-direction: column;
    row-gap: 16px;

    * {
      display: flex;
      flex-direction: row;
      column-gap: 26px;
    }
    .ib-radio {
      margin: 0;
      padding: 0;
    }
    .ib-radio > :last-child {
      margin: 0;
      padding: 0;
    }
    .form-check-label input {
      margin: 0;
      padding: 0;
    }
    .header {
      &__c1 {
        flex-basis: 167px;
      }
      &__c2 {
        flex-basis: 16px;
        font-weight: 500;
      }
      &__c3 {
        flex-basis: 16px;
        font-weight: 500;
      }
    }
    .row1 {
      &__c1 {
        flex-basis: 167px;
        font-weight: 500;
      }
      &__c2 {
        flex-basis: 16px;
      }
      &__c3 {
        flex-basis: 16px;
      }
    }
    .row2 {
      &__c1 {
        flex-basis: 167px;
        font-weight: 500;
      }
      &__c2 {
        flex-basis: 16px;
      }
      &__c3 {
        flex-basis: 16px;
      }
    }
  }

  &__corrections-checkboxes {
    margin-left: 1.5em;
    display: flex;
    flex-direction: column;
  }
}
//restore spacing table display properties with bigger screens
@media (min-width: 880px) {
  .spacing-chart-input-U tbody tr {
    padding-left: 21px;
    display: none;
    display: table-cell;
    vertical-align: bottom;
  }

  .spacing-chart-input-L tbody tr {
    padding-top: 1px;
    padding-left: 21px;
    display: none;
    display: table-cell;
    vertical-align: baseline;
  }

  table.spacing-chart-input-U > tbody > tr > td:first-child,
  table.spacing-chart-input-L > tbody > tr > td:first-child {
    width: 44px;
  }
}

//adjust spacing checkbox padding
@media (max-width: 990px) {
  .spacing-chart-input-U tbody tr,
  .spacing-chart-input-L tbody tr {
    padding-left: 44px;
  }
}

//spacing table collasp on smaller screens
@media (max-width: 1024px) {
  tr {
    display: block;
  }

  //upper
  .spacing-chart td:nth-of-type(n + 9) {
    float: left;
  }
  .spacing-chart-input-U {
    margin-top: 30px;
  }
  .spacing-chart-input-U tbody tr {
    padding-left: 42px;
  }
  .spacing-chart-input-U td:nth-of-type(n + 9) {
    float: left;
    margin-top: 43px;
  }
  .spacing-chart td {
    margin-top: 40px;
    border-bottom: 2.5px solid #aaa;
  }
  .spacing-chart:nth-of-type(2) {
    margin-top: -77px;
  }
  .spacing-chart-x-upper {
    display: none;
  }

  table.spacing-chart-input-U > tbody > tr > td:first-child,
  table.spacing-chart-input-L > tbody > tr > td:first-child {
    width: 44px;
  }

  //lower
  .spacing-chart-input-L tbody tr {
    padding-left: 42px;
  }
  .spacing-chart-input-L td:nth-of-type(n + 9) {
    float: left;
    margin-top: 40px;
  }
  .spacing-chart:nth-of-type(1) {
    margin-top: 40px;
  }
  .spacing-chart-input-L {
    margin-top: -117px;
    margin-bottom: 50px;
  }
  .spacing-chart-x-lower {
    display: none;
  }

  .spacing-chart-input-upper.spacing-chart-input-upper-lrg,
  .spacing-chart-input-lower.spacing-chart-input-lower-lrg,
  .spacing-chart-input-lower:focus,
  .spacing-chart-input-upper:focus {
    margin-top: -15px;
  }

  .spacing-chart-input-upper,
  .spacing-chart-input-lower {
    position: relative;
  }
}

//adjust fonts and checkbox size with smaller screen
@media (max-width: 1024px) {
  tr {
    font-size: 10px;
  }

  table.spacing-chart > tbody > tr > td.spacing-chart-upper-label,
  table.spacing-chart > tbody > tr > td.spacing-chart-upper-label-hidden,
  table.spacing-chart > tbody > tr > td.spacing-chart-lower-label,
  table.spacing-chart > tbody > tr > td.spacing-chart-lower-label-hidden {
    width: 25px;
    min-width: 25px;
  }

  table.spacing-chart-input-U > tbody > tr > td:first-child,
  table.spacing-chart-input-L > tbody > tr > td:first-child {
    width: 25px;
  }

  .spacing-chart-input-upper {
    width: 20px;
    height: 20px;
    border-radius: 3px;
    border: 1px solid #ccc;
    outline: none;
  }

  .spacing-chart-input-upper:active,
  .spacing-chart-input-upper:focus {
    width: 20px;
    height: 20px;
    padding: 4px;
  }

  .spacing-chart-input-lower {
    width: 20px;
    height: 20px;
    border-radius: 3px;
    border: 1px solid #ccc;
    outline: none;
    margin-top: -10px;
  }

  .spacing-chart-input-lower:active,
  .spacing-chart-input-lower:focus {
    width: 20px;
    height: 20px;
    padding: 4px;
  }

  table.spacing-chart-input-U > tbody > tr > td {
    width: 25px;
    min-width: 25px;
  }

  table.spacing-chart-input-L > tbody > tr > td {
    width: 25px;
    min-width: 25px;
  }

  .spacing-chart-input-U tbody tr,
  .spacing-chart-input-L tbody tr {
    padding-left: 23px;
  }

  .spacing-chart-input-upper.spacing-chart-input-upper-lrg,
  .spacing-chart-input-lower.spacing-chart-input-lower-lrg {
    width: 20px;
    height: 20px;
    padding: 3px;
    font-size: 9px;
    background-color: #90ccce;
    color: #fff;
  }

  .spacing-chart-input-L {
    margin-top: -107.5px;
    margin-bottom: 50px;
  }
  .spacing-chart:nth-of-type(2) {
    margin-top: -83.2px;
  }

  .spacing-chart-input-upper.spacing-chart-input-upper-lrg,
  .spacing-chart-input-lower.spacing-chart-input-lower-lrg,
  .spacing-chart-input-lower:focus,
  .spacing-chart-input-upper:focus {
    margin-top: -10px;
  }
}

//adjust fonts and checkbox size with smaller screen
@media (max-width: 300px) {
  .spacing-chart-input-U tbody tr,
  .spacing-chart-input-L tbody tr {
    padding-left: 3vw;
  }
  .ipp-light .card.card--form {
    padding: 10px;
  }
}
