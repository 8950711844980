@use '../styles/abstracts/mixins' as mixins;

.account__itero-settings {
  $errorBorder: 1px solid #e74b3a;

  * {
    margin: 0px !important;
  }

  display: flex;
  flex-direction: column;

  @include mixins.rowGap(16px);

  .error-message {
    color: #e74b3a;
    opacity: 1;
  }

  .loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .itero-settings {
    &__title {
      font-family: Roboto, san-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 20.8px;
      line-height: 23px;
      color: #404041;
    }

    &__instructions {
      display: flex;
      flex-direction: column;

      @include mixins.rowGap(16px);

      &__list {
        display: flex;
        flex-direction: column;

        @include mixins.rowGap(16px);

        .itero-link {
          text-decoration: underline;
        }

        .itero-bold {
          font-weight: 600;
        }
      }
    }

    &__add-form {
      display: flex;
      flex-direction: column;

      @include mixins.rowGap(8px);

      .input-container-label {
        font-family: Roboto, san-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #707070;
      }

      .input-container {
        display: flex;

        @include mixins.columnGap(6px);

        &__input {
          flex: 0 1 300px;

          &.text-input.error {
            border: $errorBorder;
          }
        }
      }
    }

    &__license-numbers {
      $tableBorder: 1px solid #e1e2e2;
      $tableBorderRadius: 3px;

      display: flex;

      &.hide {
        display: none;
      }

      .list-container {
        $rowDefaultPadding: 8px;

        display: flex;
        flex-direction: column;
        flex: 0 1 500px;
        border-top: 5px solid #2da4ad;
        border-right: $tableBorder;
        border-left: $tableBorder;
        border-bottom: $tableBorder;
        border-radius: $tableBorderRadius;

        .border-bottom {
          border-bottom: $tableBorder;
        }

        .margin-bottom {
          margin-bottom: 6px !important;
        }

        .padding-top {
          padding-top: $rowDefaultPadding;
        }

        .padding-bottom {
          padding-bottom: $rowDefaultPadding;
        }

        .title {
          font-family: sofia-pro, sans-serif;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          color: #707070;
        }

        &__title {
          padding: $rowDefaultPadding $rowDefaultPadding 0 $rowDefaultPadding;

          &.with-scroll {
            padding-right: 25px;
          }
        }

        .list {
          display: flex;
          flex-direction: column;
          padding-left: $rowDefaultPadding;
          padding-right: $rowDefaultPadding;
          max-height: 204px;
          overflow-y: auto;

          &__item {
            display: flex;
            @include mixins.columnGap(8px);
          }

          .item__license-number {
            flex: 0 1 368px;

            .license-number {
              font-family: Roboto, san-serif;
              font-style: normal;
              font-weight: 400;
              font-size: 16px;
              line-height: 24px;
              color: #707070;
              cursor: pointer;

              &:hover {
                color: #2da4ad;
              }

              &.selected {
                color: #2da4ad;
              }
            }

            .input-container__input.text-input.error {
              border: $errorBorder;
            }
          }

          .item__actions {
            flex: 0 1 88px;
            display: flex;
            align-items: center;
            justify-content: center;

            &.hide {
              opacity: 0;
            }

            .actions {
              display: flex;
              justify-content: center;
              align-items: center;

              @include mixins.columnGap(8px);

              .action-button {
                background-color: #e0e0e0;
                padding: 0px 5px;
                border-radius: 3px;
                cursor: pointer;
                height: 24px;
                width: 24px;
                display: flex;
                justify-content: center;
                align-items: center;

                &:hover {
                  opacity: 0.6;
                  transition: all 500ms ease;
                }

                &.disabled {
                  opacity: 0.3;
                  cursor: not-allowed;
                }

                &.cancel svg {
                  color: #cf424b;
                }

                &.save i {
                  color: #489599;
                }

                &.edit i {
                  color: #2da4ad;
                }
              }
            }
          }
        }
      }
    }
  }
}

.MuiTooltip-tooltip.itero-settings-action__tooltip {
  background-color: #303235;
  color: white;
  font-size: 0.9em;
  font-weight: 400;

  .MuiTooltip-arrow {
    color: #303235;
  }
}

.MuiTooltip-popper.itero-settings-action__popper {
  z-index: 98;
  opacity: 0.9;
  top: 24px;
}

@media only screen and (max-width: 768px) {
  .MuiTooltip-popper.itero-settings-action__popper.mobile-hidden {
    display: none;
  }
}
