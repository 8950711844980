.api-list-container {
  width: 100%;
  padding-left: 15px;
}
.api-list-section {
  width: 50%;
  display: inline-block;
}

.name-heading {
  font-weight: bold;
  display: inline-block;
  padding-left: 15px;
}

.api-list-container > div.api-list-section:first-child {
  vertical-align: middle;
}

.error-message {
  color: #ff0000;
  opacity: 0.5;
  font-size: 16px;
  margin-top: 5px;
  margin-right: 49px;
  margin-bottom: 5px;
}

.api-heading {
  width: 100px;
  display: inline-block;
  line-height: 30px;
  vertical-align: middle;
  font-weight: bold;
}

.api-section {
  display: inline-block;
  width: calc(100% - 100px);

  input {
    border-radius: 10px;
  }
}

.api-button {
  height: 40px;

  button {
    margin-top: 10px;
    margin-bottom: 5px;
    margin-right: 1px;
  }
}

.table.table-marginless {
  margin-bottom: 0px;
}

.page-top-content.page-top-content-extra {
  padding-bottom: 20px;
}
