.bpp-alert {
  border-radius: 3px;
  padding: 8px 16px;
  font-size: 1em;
  font-weight: 500;
  margin: 0;

  & .bpp-alert-dismiss {
    cursor: pointer;
    float: right;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    margin-left: 8px;
  }

  &.bpp-alert--error {
    background-color: #e75f6f;
    border: 1px solid #e13146;
    color: #ffffff;
  }

  &.bpp-alert--success {
    background-color: #ecf5f5;
    border: 1px solid #2DA4AD;
    color: #707070;
  }


  & .bpp-alert-text {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
  }
}
