@use '../styles/abstracts/mixins' as mixins;

.impression-padding-b1 {
  padding-bottom: 60px;
}

.case-form-container {
  &.scans-case-submission {

    .itero-files-scans-section {
      display: flex;

      @include mixins.columnGap(24px);

      &__left {
        width: 50%;
      }

      &__right {
        width: 50%;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .case-form-container {
    &.scans-case-submission {
      .itero-files-scans-section {
        flex-wrap: wrap;

        @include mixins.columnGap(0px);
        @include mixins.rowGap(16px);

        &__left {
          width: 100%;
        }
        &__right {
          width: 100%;
        }
      }
    }
  }
}
