.wall {
  &-right {
    display: inline-block;
    margin-right: $general-spacing;
    padding-right: $general-spacing;
    border-right: 1px solid $platinum;

    & + button {
      margin-top: 0px;
    }
  }

  &-left {
    display: inline-block;
    margin-left: $general-spacing;
    padding-left: $general-spacing;
    border-left: 1px solid $platinum;

    & + button {
      margin-top: 0px;
    }
  }

  &-vertical {
    margin-right: $general-spacing;
    border-right: 1px solid $platinum;
  }
}

@include for-size(phone) {
  .wall {
    &-right {
      display: block;
      margin-right: 0px;
      border: none;
      padding-right: 0px;

      & + button {
        margin-top: $general-spacing;
      }
      & + button + button {
        margin-top: $general-spacing;
      }
      & + button + button + button {
        margin-top: $general-spacing;
      }
    }
  }
}
