$dark-gray: #303235;
$light-gray: #394042;
$gray: #383c3f;

.manual-wire-table-offset {
    display: flex;
    flex-direction: row;

    .first-item {
        min-width: 130px;
        min-height: 40px;
        max-height: 60px;
        border: 1px solid $light-gray;
        color: #fff;
        padding: 10px;
        background-color: $dark-gray;
        align-items: center;
        justify-content: center;
        display: flex;
    }

    .manual-wire-table-offset-element {
        &.error {
            border: 1px solid red;
        }
        
        background-color: $dark-gray;
        min-width: 130px;
        max-width: 130px;
        min-height: 40px;
        max-height: 60px;
        overflow-y: auto;
        border: 1px solid $light-gray;
        color: #fff;
        padding: 5px;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
    
        .input-number {
            width: 50px;
            text-align: center;
            background-color: #fff;
            color: #000;
            border-radius: 5px;

            &.disabled {
                background-color: #d3d3d3;
            }
        }
    
        .open-bracket {
            margin-right: 2px;

            &.show-label {
                margin-top: 10px;
            }
        }
    
        .separator {
            margin-left: 2px;
            margin-right: 2px;
            
            &.show-label {
                margin-top: 10px;
            }
        }
    
        .close-bracket {
            margin-left: 2px;

            &.show-label {
                margin-top: 10px;
            }
        }

        .offset-x {
            label {
                font-weight: lighter;
                color: #fff;
                font-size: small;
                width: 100%;
                box-sizing: border-box;
                overflow: visible;
                white-space: nowrap;
                text-align: right;
                margin-left: 10px;
            }
        }

        .offset-y {
            label {
                font-weight: lighter;
                color: #fff;
                font-size: small;
                width: 100%;
                box-sizing: border-box;
                overflow: visible;
                white-space: nowrap;
            }
        }
    }
}
