.case-submission-btns {
  display: flex;
  flex-direction: column;
  align-items: center;

  .prospect-lost-btn {
    margin-top: 10px;
    padding: 8px !important;
    width: 140px;
  }

  .submit-case-btn {
    margin-top: 10px;
    width: 140px;
    margin-right: 0px !important;
  }
}

.progress-bar-status {
  white-space: pre-wrap;
}
