.loader-3 {
  position: absolute;
  top: calc(50% - 32px);
  left: calc(50% - 32px);
  width: 64px;
  height: 64px;
  border-radius: 50%;
  perspective: 800px;
}

.loader-4 {
  position: absolute;
  top: calc(50% + 30px);
  left: calc(50% - 32px);
  width: 64px;
  height: 64px;
  border-radius: 50%;
  perspective: 800px;
}

.comments-area .loader-window-small .loader-header {
  min-height: 158px;
  -webkit-transition: all 2s;
  -o-transition: all 2s;
  transition: all 2s;
}

.loader-window-delete .loader-header {
  min-height: 158px;
  -webkit-transition: all 2s;
  -o-transition: all 2s;
  transition: all 2s;
}

.loader-window-filled .loader-header {
  min-height: 560px;
}

.loader-window-ir .loader-header {
  min-height: calc(100vh - 220px);
}

.loader-window-video .loader-header {
  min-height: 360px;
}

.loader-window-form .loader-header {
  min-height: calc(100vh - 230px);
}

.inner {
  position: absolute;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  // border-radius: 50%;
  z-index: 1;
}

.inner.one {
  left: 0%;
  top: 0%;
  animation: rotate-one 1s linear infinite;
  border-bottom: 3px solid #494d51;
  z-index: 1;
}

.inner.two {
  right: 0%;
  top: 0%;
  animation: rotate-two 1s linear infinite;
  border-right: 3px solid #494d51;
  z-index: 1;
}

.inner.three {
  right: 0%;
  bottom: 0%;
  animation: rotate-three 1s linear infinite;
  border-top: 3px solid #494d51;
  z-index: 1;
}

@keyframes rotate-one {
  0% {
    transform: rotateX(35deg) rotateY(-45deg) rotateZ(0deg);
  }
  100% {
    transform: rotateX(35deg) rotateY(-45deg) rotateZ(360deg);
  }
}

@keyframes rotate-two {
  0% {
    transform: rotateX(50deg) rotateY(10deg) rotateZ(0deg);
  }
  100% {
    transform: rotateX(50deg) rotateY(10deg) rotateZ(360deg);
  }
}

@keyframes rotate-three {
  0% {
    transform: rotateX(35deg) rotateY(55deg) rotateZ(0deg);
  }
  100% {
    transform: rotateX(35deg) rotateY(55deg) rotateZ(360deg);
  }
}
