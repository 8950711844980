@import '../../bpp-variables';

.ip-loop-measurements {
  table.spacing-chart>tbody>tr>td.ip-spacing-chart-upper-label {
    width: 44px;
    min-width: 44px;
    border-bottom: 2.5px solid #aaa;
    border-right: 1px solid #ccc;
    text-align: center;
    height: 28px;
    vertical-align: bottom;
  }

  table.spacing-chart>tbody>tr>td.ip-spacing-chart-lower-label {
    width: 44px;
    min-width: 44px;
    border-right: 1px solid #ccc;
    text-align: center;
    height: 28px;
    vertical-align: top;
  }

  table.spacing-chart>tbody>tr>td.spacing-chart-lower-label,
  table.spacing-chart>tbody>tr>td.spacing-chart-lower-st-label-hidden {
    border-top: 2.5px solid #aaa !important;
  }

  .border-right-bold {
    border-right: 2.5px solid #aaa !important;
  }

  .border-top-bold {
    border-top: 2.5px solid #aaa !important;
  }

  .spacing-chart-lower-st-label-hidden {
    border-right: None !important;
  }

  .spacing-chart-upper-label-hidden {
    border-right: None !important;
  }

  .spacing-lower-st-width-middle {
    width: 88px !important;
  }

  .spacing-chart-lower-st-label-border {
    border-top: 2.5px solid #aaa !important;
  }

  .spacing-lower-border-right-none {
    border-right: none !important;
  }

  .spacing-lower-border-top-none {
    width: 44px;
    min-width: 44px;
    border-right: 1px solid #ccc;
    text-align: center;
    height: 28px;
    vertical-align: top;
    border-top: none !important;
  }

  .spacing-chart-lower-st-label-border-empty {
    border-top: 2.5px solid #aaa !important;
    min-width: 44px;
    text-align: center;
    height: 28px;
    vertical-align: top;

    &:after {
      content: '';
      background-color: #ccc;
      position: absolute;
      width: 1px;
      height: 17px;
      top: 65px;
      left: 49.9%;
      display: block;
    }
  }

  @for $i from 1 to 15 {
    .tooth-number-box-#{$i} {
      width: #{44 * $i}px !important;
    }
  }

  @for $i from 0 to 15 {
    .input-box-#{$i} {
      @if $i==0 {
        position: relative;
        width: 132px !important;
      }

      @else if $i==1 {
        position: relative;
        width: 88px !important;
      }

      @else {
        $add: 43 * ($i - 1);
        position: relative;
        width: $add + 88px !important;
      }
    }
  }

  .lower-horizontal-line {
    margin-top: 10px;
    margin-bottom: 0px;
    border-top: 1px solid #ccc;
  }

  .upper-horizontal-line {
    margin-bottom: 10px;
    border-top: 1px solid #ccc;
    margin-top: 0px;
  }

  .vertical-line {
    content: '';
    background-color: #ccc;
    width: 1px;
    height: 17px;
    position: absolute;
    left: 50%;
  }

  .left {
    left: 65%;
  }

  .bottom {
    bottom: 100%;
  }

  .spacing-hidden {
    width: 44px !important;
  }

  .ip-spacing-chart-x-lower {
    display: inline-block;
    font-size: 12px;
    cursor: pointer;
    position: absolute;
    bottom: -10px;
    background-color: rgba(255, 255, 255, 0.7);
    border: 1px solid #ccc;
    border-radius: 50%;
    width: 18px;
    height: 18px;
    line-height: 18px;
    color: #aaa;
  }

  .ip-spacing-chart-x-upper {
    display: inline-block;
    font-size: 12px;
    cursor: pointer;
    position: absolute;
    top: -10px;
    background-color: rgba(255, 255, 255, 0.7);
    border: 1px solid #ccc;
    border-radius: 50%;
    width: 18px;
    height: 18px;
    line-height: 18px;
    color: #aaa;
  }

  @for $i from 0 to 15 {
    .x-position-#{$i} {
      @if $i==0 {
        right: 25px;
      }

      @else {
        $add: 22 * ($i);
        right: $add + 25px;
      }
    }
  }

  .ip-spacing-chart-input {
    position: relative;
  }

  .input-error {
    border-color: $bpp-subtle-warning;
  }

  .spacing-chart-input-U tbody tr.ip-loop,
  .spacing-chart-input-L tbody tr.ip-loop {
    padding-left: 11px;
  }

  .ip-input:active {
    text-indent: 1px;
  }

  .ip-input {
    text-align: left;
  }
}