.content__header__restrict + .content__header__container {
  margin-left: 16px;
}

.content__header {
  &__container {
    display: inline-flex;
    justify-content: space-between;
    align-items: flex-end;
    width: calc(100vw - 469px);
    margin-bottom: 10px;
    height: 37px;
    vertical-align: top;
  }

  &__restrict {
    display: inline-flex;
  }

  &__user-profile {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    svg {
      width: 37px;
      height: 37px;
    }

    p {
      margin: 0 10px;
    }
  }
}
