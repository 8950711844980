.scrollbar--dark {
  &::-webkit-scrollbar {
    background-color: $scrollbar-color;
    height: 6px;
    width: 6px;
    border-radius: $border-radius;
  }
  &::-webkit-scrollbar-thumb {
    background-color: $scrollbar-thumb-color;
    border-radius: $border-radius;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: $scrollbar-hover-color;
  }
}
