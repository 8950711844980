.header-back {
  background: linear-gradient(-65deg, RGB(50, 161, 218), RGB(128, 207, 207));
}

.kb-welcome {
  color: white;
  display: black;
  margin-top: 27px;
  font-size: 22pt;
  font-weight: lighter;
}

.kb.navbar-brand {
  position: absolute;
  left: 10px;
}

.backportal {
  float: right;
  color: #ffffff;
  padding-right: 10px;
  font-weight: normal;
}

a.backportal:hover {
  color: #ffffff;
}

.sub-cat.col-md-12 {
  padding-left: 0px;
  padding-right: 0px;

  .line-division--limited{
    border-bottom: none;
    border-top: 1px solid #e1e2e2;
    width: 100%;
    margin-top: 20px;
    padding-top: 20px;
  }
}

/* html {
	overflow-y: overlay;
} */

.container-normalize,
.container-fluid.container-normalize,
.container-normalize .row {
  padding-left: 0px;
  padding-right: 0px;
  margin-left: 0px;
  margin-right: 0px;
}

.container-fluid.container-normalize {
  /* min-height: 95vh; */
}

.kb.category {
  box-sizing: content-box;
  /* border: 3px solid; */
  /* border-color: white; */
  display: block;
  width: 100%;
  height: 0px;
  padding: 0px;
  padding-top: 50%;
  padding-bottom: 50%;
  border-radius: 50%;
  opacity: 0.8;
  margin: 0px;
  -webkit-transition: all 0.25s; /* Safari */
  transition: all 0.25s;
}

.kb.category:hover {
  border-color: #3de1e5;
  z-index: 1;
}

.kb.header-container {
  width: 85%;
  margin-left: auto;
  margin-right: auto;
}

.kb.category.select_bar {
  /* border-color: #fff; */
  opacity: 1;
}

.kb.category.select_bar img {
  border: none;
  /* border: 3px solid #fff; */
  opacity: 1;
}

.kb.category.select_bar:hover {
  /* border-color: #3de1e5; */
}

.kb.category.select_bar:active {
  /* border-color: #3de1e5; */
}

.kb.category img {
  position: relative;
  width: 100%;
  background-position: center;
  border-radius: 50%;
  top: 0;
  padding: 0;
  margin: 0;
  transform: translateY(-50%);
  opacity: 0.7;
  /* border: 3px solid #fff; */
}

.kb.category img:hover {
  position: relative;
  width: 100%;
  border-radius: 50%;
  top: 0;
  padding: 0;
  margin: 0;
  transform: translateY(-50%);
  opacity: 1;
  border: 3px solid #3de1e5;
}

.kb.category-sizer {
  width: 85%;
  max-width: 300px;
}

.category-name {
  color: white;
  font-size: 15pt;
  margin-top: 20px;
  margin-bottom: 20px;
  min-width: 200px;
  font-weight: lighter;
}

.databox {
  margin-top: 30px;
  /* min-width: 220px; */
}
.databox a {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.titlefont {
  font-size: 18px;
}

.searchfont {
  font-size: 14px;
  line-height: 1.7em;
  color: #000000;
}

p.subfont {
  font-size: 14px;
  line-height: 1.7em;
  color: #000000;
  text-indent: -8px;
  padding-bottom: 0px;
  padding-left: 8px;
  margin-bottom: 0px;
}
p.subfont a {
  color: #000000;
}

p.subfont:hover a {
  color: #32a1d9;
}

.titlefont-subcat {
  text-align: left;
  font-size: 18px;
  margin-top: 40px;
  padding-left: 60px;
  padding-right: 60px;
}

p.subfont-subcat {
  line-height: 1.7em;
  color: #000000;
  margin-top: 0px;
  padding-bottom: 0px;
  margin-bottom: 0px;
  white-space: initial;

  > a {
    display: flex;
    align-items: baseline;
  }

  svg {
    margin-right: 8px;
  }
}

p.subfont-subcat a {
  color: #000000;
}

p.subfont-subcat:hover a {
  color: #43a7b3;
}

.hrline {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  border-style: inset;
  border-width: 1px;
  width: 51%;
}

.morefont {
  font-size: 14px;
  margin-top: 200px;
}

.description {
  margin-top: 20px;
}

.description-detail {
  margin-top: 5px;
}

.description-sub {
  margin-top: 10px;
}

.border-sub {
  color: #43a7b3;
}

.border-title {
  font-size: 24px;
  color: #43a7b3;
}

.border-sub-2 {
  border-bottom: 1px solid #43a7b3;
  min-height: 1px;
  margin: 15px 0px 15px 0px;
}

.border-header {
  background-image: linear-gradient(to right, #61bdd4, #35a3da);
  min-height: 6px;
  margin-left: 0px;
  margin-right: 0px;
  margin-top: 2px;
  margin-bottom: 5px;
  opacity: 0.9;
}

.container.container__margin--no-top,
.container__margin--no-top {
  margin-top: 0px;
}

body {
  font-family: Helvetica, sans-serif;
}

.react-autosuggest__container {
  position: relative;
}

.react-autosuggest__input {
  width: 100%;
  height: 30px;
  padding: 10px 20px;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 14px;
  border: 1px solid #aaa;
  border-radius: 4px;
}

.react-autosuggest__input--focused {
  outline: none;
}

.react-autosuggest__input--open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
  display: none;
}

.react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  width: 100%;
  border: 1px solid #aaa;
  background-color: #fff;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 14px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  max-height: 300px;
  overflow-y: auto;
  z-index: 2;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 1px 20px;
}

.react-autosuggest__suggestion--highlighted {
  background-color: #ddd;
}

#videoViewer .loader-header,
#pdfViewer .loader-header {
  min-height: 30vh;
}

.kb-content {
  position: relative;
}

.kb-footer {
  /* background: linear-gradient(-65deg, RGBa(50, 161, 218, 0.35), RGBa(128, 207, 207, 0.35)); */
  margin-top: 30px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.container-min {
  min-height: -moz-calc(100vh - (98px));
  min-height: -webkit-calc(100vh - (98px));
  min-height: calc(100vh - (98px));
}

.external-link {
  color: #ffffff;
}
