@import '../../bpp-variables';

.mark-shipped {
  .form-group {
    margin-top: 15px;
    text-align: left;
    padding: 0 10px;
    max-height: 45vh;

    &.many-items {
      overflow: auto;
    }
  }

  .ship-date {
    margin-top: 20px;
  }

  .ship-date-label {
    margin-right: 30px;
  }

  .table-failed {
    margin: 20px auto 0;
    width: 55%;
    color: #3c3e42;

    .id-width {
      width: 33%;
    }

    tr {
      text-align: left;
    }
  }

  table {
    width: 100%;
    margin-top: 15px;
    margin-bottom: 5px;
    border-collapse: separate;

    th {
      border-top: 1px solid #ddd;
      padding-top: 5px;
      padding-bottom: 5px;
      background-color: #f1f1f1;
      cursor: default;
    }

    th:first-child {
      border-left: 1px solid #ddd;
      border-top-left-radius: 10px;
      padding-left: 10px;
    }

    th:last-child {
      border-right: 1px solid #ddd;
      border-top-right-radius: 10px;
    }

    td {
      padding-top: 10px;
      padding-bottom: 10px;

      input {
        margin-left: 33px;
      }

      &:first-child {
        border-left: 1px solid #ddd;
        padding-left: 10px;
        width: 100px;
        vertical-align: top;
        padding-top: 15px;
        min-width: 140px;
      }

      &:last-child {
        border-right: 1px solid #ddd;
      }
    }

    tr:last-child td:first-child {
      border-bottom-left-radius: 10px;
    }

    tr:last-child td:last-child {
      border-bottom-right-radius: 10px;
    }

    tr:last-child td {
      border-bottom: 1px solid #ddd;
    }
  }

  .delivery {
    &-selection {
      display: inline-block;
      border: 1px solid #ddd;
      border-radius: 10px;
      width: calc(100% - 8px);
      padding: 5px;
      margin-left: -12px;
      cursor: pointer;

      &--borderless {
        border: none;
        padding: 5px 7px;
      }

      &-window {
        position: relative;
        overflow: visible;
      }

      &-options {
        &-window {
          margin-left: -12px;
          margin-right: 0px;
          width: calc(100% - 8px);
          padding-right: -8px;
          position: absolute;
          max-height: 250px;
          overflow-y: scroll;
          background-color: #fff;
          border-radius: 10px;
          border: 1px solid #ddd;
          z-index: 1;
        }

        overflow-x: hidden;
        display: block;
        padding: 5px 0px 5px 5px;
        margin-right: 0px;
        width: calc(100%);
        &:hover {
          background-color: #ddd;
          cursor: pointer;
        }
      }
    }

    &-item {
      display: inline-block;
      vertical-align: top;
      margin-left: 5px;

      &:first-child {
        width: 200px;
      }

      &:nth-child(2) {
        width: 260px;
        margin-right: 10px;
      }

      &--full {
        display: inline-block;
        width: calc(100% - 210px);
      }

      .shipping-badge {
        color: white;
        font-weight: bold;
        border-radius: 5px;
        padding-left: 5px;
        padding-right: 2px;

        &__preferred {
          background-color: #4799f9;
          width: 127px;
        }
        &__fastest {
          margin-top: 2px;
          background-color: #707070;
          width: 61px;
        }
        &__best-value-cheapest {
          margin-top: 2px;
          background-color: #707070;
          width: 152px;
        }
        &__best-value {
          margin-top: 2px;
          background-color: #707070;
          width: 81px;
        }
        &__cheapest {
          margin-top: 2px;
          background-color: #707070;
          width: 74px;
        }
      }

      .clock {
        margin-left: 5px;

        &__before-date {
          color: green;
        }
        &__after-date {
          color: red;
        }
      }
    }

    &-expander {
      float: right;
      padding: 0px 5px;
    }

    &-options {
      text-align: center;
      float: right;
      font-size: 16px;
      width: 20px;
      padding: 5px;
      position: relative;
      z-index: 2;

      &-menu {
        cursor: pointer;
      }

      &-list {
        width: 160px;
        position: absolute;
        top: -0px;
        left: -160px;
        padding: 0px 0px;
        background-color: #fff;
        font-size: 14px;
        text-align: left;
        -webkit-box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.35);
        -moz-box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.35);
        box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.35);

        &-item {
          padding: 5px 0px 5px 15px;

          &:hover {
            background-color: #69bbf1;
            color: #fff;
            cursor: pointer;
          }
        }
      }
    }
  }
}

.form-control.tracking-input {
  display: inline-block;
  width: calc(100% - 135px);
  margin: 0px;
  margin-left: 10px;
  border-radius: 10px;
}

.form-control.tracking-url-input {
  margin-top: 5px;
  width: calc(100% - 267px);
}

.tracking-url-label {
  margin-top: 5px;
}

.overflow-show {
  overflow: visible !important;
}
