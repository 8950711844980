//------------------------------------------------------------------------------
// Default Colors
//------------------------------------------------------------------------------
@use 'sass:math';

$onyx: #01131d;

$turquoise: #2da4ad;
$turquoise_50: rgba($turquoise, 0.5);
$turquoise_light: rgba($turquoise, 0.15);
$inbrace_ifs_bg_color: #96b8e1;
$inbrace_ifs_title_color: #211b57;
$heading_color: #c9e63f;
$text_color: white;
$wave: #42adb5;
$jade: #57b6bd;
$sonicSilver: #707070;

$background_color: #383c3f;
$foreground_color: #2a3033;
$wasm-foreground-color: #303235;
$selected_background_color: #252a2d;
$border_color: rgba(white, 0.2);

$nominal_spacing: 10;
$standard_gap: 4;

$nominal_spacing_size: $nominal_spacing + px;
$nominal_spacing_double_size: ($nominal_spacing * 2) + px;
$nominal_spacing_half_size: ($nominal_spacing * 0.5) + px;
$standard_gap_size: $standard_gap + px;

$scrollbar_color: rgba(white, 0.1);
$scrollbar_thumb_color: rgba(white, 0.2);
$scrollbar_hover_color: rgba(white, 0.5);
$scrollbar_border_radius: 10px;

$super_purple: #a293ff;
$super_blue: #78adeb;

$text_font_size: 14px;

$transition_duration: 0.35s;
$transition_func: ease;

//------------------------------------------------------------------------------
// Menu
//------------------------------------------------------------------------------
$panel_size: 250px;
$panel_collapse_size: 80px;
$vertical_font_size: 26px;

$active_split_switch_color: white;
$inactive_split_switch_color: rgba(white, 0.5);

.panel-menu {
  &--dark {
    position: relative;
    display: inline-block;
    width: $panel_size;
    background-color: $foreground_color;
    color: $text_color;
    height: 100vh;
    transition: all $transition_duration $transition_func;
    padding: $nominal_spacing_size;
    border-radius: 0 3px 3px 0;

    &.collapse {
      width: $panel_collapse_size;
    }

    .logo-container {
      width: 190px;

      .panel-menu-logo {
        cursor: pointer;
        max-width: 100%;
        max-height: 100%;
      }

      svg:hover {
        cursor: pointer;
      }
    }
  }

  &-surrounding {
    margin: $nominal_spacing_double_size $nominal_spacing_size 0 $nominal_spacing_size;
    overflow: hidden;

    &--no-top {
      margin: $nominal_spacing_double_size $nominal_spacing_size 0 $nominal_spacing_size;
      margin-top: 0;
    }
  }

  &-accordian-btn {
    z-index: 1;
    font-size: 25px;
    position: absolute;
    right: -30px;
    top: 50%;
    background-color: $foreground_color;
    width: 30px;
    text-align: center;
    border-radius: 0 10px 10px 0;
    cursor: pointer;

    &.wasm {
      background-color: $wasm-foreground-color;
    }
  }

  &-split {
    z-index: 1;
    font-size: $text_font_size;
    position: absolute;
    top: 0;
    right: -$panel_size;
    height: 30px;
    width: 200px;
    margin-top: $nominal_spacing_size;
    padding-left: $nominal_spacing_size;
    transition: all $transition_duration $transition_func;

    &-title {
      display: inline-block;
      margin-left: $nominal_spacing_size;
      margin-right: $nominal_spacing_size;
      line-height: 30px;
      vertical-align: middle;
    }

    label {
      &.switch {
        margin: 0;

        .slider {
          background-color: $onyx;
          border: 1px $inactive_split_switch_color solid;

          &:before {
            left: 3px;
          }

          &.round {
            height: 24px;
            width: 53px;

            &:before {
              height: 15px;
              width: 15px;
              background-color: $inactive_split_switch_color;
            }
          }
        }
      }

      &.switch.switch-color-default {
        .slider.round:before {
          background-color: $active_split_switch_color;
        }
      }
    }

    .switch-label-right {
      color: $inactive_split_switch_color;
      position: relative;
      left: -34px;
      top: 2px;
      z-index: 0;
      cursor: pointer;
    }
  }
}

.compareBtnContainer {
  position: absolute;
  bottom: 15px;
  display: flex;
  left: calc(50% - 100px);

  .compare {
    border-style: none;
    width: 194px;
    height: 40px;
    border-radius: 20px;
    font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 20px;
    font-weight: bold;
    margin: auto;
    color: white;
    background-color: #2a3033;
    transition: bottom $transition_duration $transition_func;
  }

  .compare:hover {
    color: #489599;
  }

  .compare_active {
    border-style: none;
    width: 194px;
    height: 40px;
    border-radius: 20px;
    font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 20px;
    font-weight: bold;
    margin: auto;
    background-color: #2a3033;
    transition: bottom $transition_duration $transition_func;
    color: #489599;
  }

  .compare_active:hover {
    color: white;
  }
}

.reminder-modal {
  transition: all $transition_duration $transition_func;
  white-space: nowrap;
  position: fixed;
  z-index: 500;
  background-color: $background_color;
  width: 376px;
  border-top: 5px solid $turquoise;
  border-radius: 3px;
  padding: 20px;
  right: 5.7%;
  overflow: hidden;
  bottom: 85px;

  i {
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 10px;
    color: white;
  }

  .modal-body-paragraph {
    font-size: 14px;
  }
}

.patient-info {
  padding-top: $nominal_spacing_size;
  padding-bottom: $nominal_spacing_size;

  &-heading {
    font-size: $text_font_size;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 20px;

    &-title {
      display: inline-block;
      font-weight: bold;
      width: 55px;

      &-extend {
        width: 60px;
      }
    }

    &-content {
      display: inline-block;
    }

    &--vertical {
      line-height: 26px;
      font-size: $vertical_font_size;
      font-weight: bold;
      writing-mode: vertical-lr;
      transform: rotate(180deg);
      margin: auto;
      margin-top: $nominal_spacing_size;
    }
  }

  &-bar {
    min-height: 10px;
    border-bottom: 2px $border_color solid;

    &-top {
      min-height: 0px;
      border-bottom: 2px $border_color solid;
    }
  }
}

.history {
  &-area {
    height: calc(100vh - #{$nominal_spacing_double_size} - 215px);
    overflow-y: auto;
    padding-left: $nominal_spacing_half_size;
    scrollbar-width: thin;

    &::-webkit-scrollbar {
      background-color: $scrollbar_color;
      height: 6px;
      width: 6px;
      border-radius: $scrollbar_border_radius;
    }
    &::-webkit-scrollbar-thumb {
      background-color: $scrollbar_thumb_color;
      border-radius: $scrollbar_border_radius;
    }

    &::-webkit-scrollbar-thumb:hover {
      background-color: $scrollbar_hover_color;
    }
  }

  &-list {
    &-heading {
      margin: $nominal_spacing_size 0;
      font-size: $text_font_size;
      font-weight: bold;
    }

    &-item {
      padding: $nominal_spacing_size;
      margin-bottom: $nominal_spacing_size;
      margin-right: 3px;
      border-radius: 3px;
      background-color: rgba(white, 0.05);
      cursor: pointer;
      transition: background-color 0.25s ease;

      &:hover {
        background-color: rgba(white, 0.1);
      }

      &.history-list-item--active {
        z-index: 2;
        background-color: $turquoise_light;
        cursor: default;
      }

      &-title {
        display: inline-block;
        color: $turquoise;
        font-weight: bold;
      }

      &-status {
        display: inline-block;
        margin-left: $nominal_spacing_half_size;
        margin-bottom: $nominal_spacing_half_size;
      }

      &-comments {
        &:last-child {
          margin-top: 5px;
        }
      }

      &-name {
        display: block;
        color: $heading_color;
      }

      &-note {
        display: block;

        hr {
          margin: 10px 0;
          border-top: solid 1px rgba(white, 0.25);
        }

        p {
          color: white;
          font-size: $text_font_size;
          line-height: 20px;
        }

        &-text {
          font-weight: bold;
        }
      }

      &-revise-container {
        .history-list-item-header {
          text-align: center;
          margin-top: 20px;
          font-weight: bold;
        }

        textarea {
          margin-top: 10px;
          padding: 10px;
          height: 10em;
          background-color: inherit;
          width: 100%;
          border-radius: 3px;

          &::-webkit-scrollbar {
            background-color: $scrollbar_color;
            height: 6px;
            width: 6px;
            border-radius: $scrollbar_border_radius;
            cursor: default;
          }
          &::-webkit-scrollbar-thumb {
            background-color: $scrollbar_thumb_color;
            border-radius: $scrollbar_border_radius;
            background-clip: padding-box;
          }

          &::-webkit-scrollbar-thumb:hover {
            background-color: $scrollbar_hover_color;
          }
        }
      }
    }

    &-button-area {
      padding-top: $nominal_spacing_size;
      text-align: center;

      > .tooltip-wrapper {
        display: inline-block;
        margin-right: 10px;
        text-align: left;

        > .button[disabled] {
          pointer-events: none;
        }

        &.disabled {
          cursor: not-allowed;
        }
      }
    }
  }
}

.quill-formatted {
  font-size: $text_font_size;
  overflow-wrap: break-word;

  &.ql-text p {
    color: $sonicSilver;
  }

  p {
    font-family: inherit;
    font-weight: normal;
    color: $text_color;
    line-height: 1em;
    font-size: 1em;
  }

  ul {
    padding-left: 0px;

    li.ql-indent-1 {
      padding-left: 3.5em !important;
    }

    li.ql-indent-2 {
      padding-left: 5.5em !important;
    }

    li.ql-indent-3 {
      padding-left: 7.5em !important;
    }
  }
}

@media (max-width: 1350px) {
  .panel {
    &-menu {
      &-split {
        right: -200px;
        transition: all $transition_duration $transition_func;
      }
    }
  }
}

//------------------------------------------------------------------------------
// Selection Bars
//------------------------------------------------------------------------------
$bar_item_max: 5;
$bar_width: 700;
$bar_half_width: $bar_width * 0.5;
$bar_height: 80;
$bar_half_height: $bar_height * 0.5;
$bar_font_height: 20;
$bar_radius: $bar_height * 0.5;
$bar_item_width: math.div($bar_width - ($bar_radius * 2), $bar_item_max);
$bar_width--small: $bar_item_width + ($bar_radius * 2);
$bar_half_width--small: ($bar_width--small * 0.5);
$line_height: 20;
$bar_position_size: $bar_font_height * 2.5 + px;
$bar_item: 45;

$bar_width_size: $bar_width + px;
$bar_half_width_size: $bar_half_width + px;
$bar_height_size: $bar_height + px;
$bar_half_height_size: $bar_half_height + px;
$bar_font_height_size: $bar_font_height + px;
$bar_radius_size: $bar_radius + px;
$bar_item_width_size: $bar_item_width + px;
$bar_width--small_size: $bar_width--small + px;
$bar_half_width--small_size: $bar_half_width--small + px;
$line_height_size: $line_height + px;
$bar_item_padding: 45px;

.panel-viewer--dark {
  position: relative;
  display: inline-block;
  vertical-align: top;
  margin: 0;
  padding: 0;
  width: calc(100% - #{$panel_size});
  height: 100vh;
  background: $background_color;
  background-color: $background_color;
  color: white;
  transition: width $transition_duration $transition_func;

  &--collapse {
    transition: width $transition_duration $transition_func;
    width: calc(100% - #{$panel_collapse_size});
  }

  .selection-bar-top {
    z-index: 1;
    position: absolute;
    top: 0;
    left: calc(50% - #{$bar_half_width_size});
    background-color: $foreground_color;
    background: $foreground_color;
    height: $bar_height_size;
    width: $bar_width_size;
    margin: auto;
    border-radius: 0 0 $bar_radius_size $bar_radius_size;
    margin-top: calc(#{$nominal_spacing_double_size} + #{$bar_font_height_size} - #{$bar_height_size});
    transition: top $transition_duration $transition_func;

    &.selection-bar-top-oov {
      transition: top $transition_duration $transition_func;
      top: calc(-1 * (#{$nominal_spacing_double_size} + #{$bar_font_height_size}));
    }

    &-page-left {
      position: absolute;
      bottom: calc(#{$nominal_spacing_size} - #{$nominal_spacing_size}* 0.25);
      left: $nominal_spacing_double_size;

      i {
        margin: calc(#{$nominal_spacing_size}* 0.5);
      }

      &:hover {
        transition: color 0.15s ease;
        cursor: pointer;
        color: rgba(white, 0.75);

        &:active {
          color: $turquoise;
        }
      }
    }

    &-page-right {
      position: absolute;
      bottom: calc(#{$nominal_spacing_size} - #{$nominal_spacing_size}* 0.25);
      right: $nominal_spacing_double_size;

      i {
        margin: calc(#{$nominal_spacing_size}* 0.5);
      }

      &:hover {
        transition: color 0.15s ease;
        cursor: pointer;
        color: rgba(white, 0.75);

        &:active {
          color: $turquoise;
        }
      }
    }

    &-page-indicator {
      width: $bar_width_size;
      text-align: center;
      font-size: 12px;

      &.fade-out {
        i:hover {
          cursor: default;
        }
      }

      i {
        margin: calc(#{$nominal_spacing_size}) calc(math.div($nominal_spacing, 3));
        transition: color 0.45s ease;
        color: rgba(white, 0.25);

        &:hover {
          transition: color 0.15s ease;
          cursor: pointer;
          color: rgba(white, 0.5);
        }

        &.active {
          color: white;
        }
      }
    }

    .selection-bar-item {
      // Done this way to preserve clickable area instead of table cell
      padding-top: calc(#{$bar_height_size} - #{$nominal_spacing_size} - #{$bar_font_height_size});
      padding-bottom: $nominal_spacing_size;
    }
  }

  .selection-bar-top--small {
    z-index: 4;
    position: absolute;
    top: 0;
    left: calc(50% - #{$bar_half_width--small_size});
    background-color: $foreground_color;
    background: $foreground_color;
    height: $bar_height_size;
    width: $bar_width--small_size;
    margin: auto;
    border-radius: 0 0 $bar_radius_size $bar_radius_size;
    margin-top: calc(#{$nominal_spacing_double_size} + #{$bar_font_height_size} - #{$bar_height_size});
    transition: top $transition_duration $transition_func;

    &.selection-bar-top-oov {
      z-index: 0;
      opacity: 0;
      transition: top $transition_duration $transition_func;
      top: calc(-1 * (#{$nominal_spacing_double_size} + #{$bar_font_height_size}));
    }

    .selection-bar-item {
      padding-top: $nominal_spacing_size;
      padding-bottom: $nominal_spacing_size;
      margin-left: $bar_radius_size;

      &:first-child {
        padding-top: calc(#{$bar_height_size} - #{$nominal_spacing_size} - #{$bar_font_height_size});
        padding-bottom: $nominal_spacing_size;
      }
    }

    i {
      transition: color 0.15s ease, transform 0.45s ease;
      margin: calc(#{$nominal_spacing_size}) calc(math.div($nominal_spacing, 3));
    }

    i:hover {
      transition: color 0.15s ease, transform 0.45s ease;
      cursor: pointer;
      color: rgba(white, 0.5);
    }

    @for $i from 1 through 20 {
      &-height-#{$i} {
        // first item + (each item * (num of item -1)) + offset
        // (bar height + ((padding-top + padding-bottom + line-height)* num line - 1)) + offset
        z-index: 0;
        height: calc(
          #{$bar_height_size} + ((#{$nominal_spacing_size} + #{$nominal_spacing_size} + #{$line_height_size}) *#{$i - 1}) + #{$nominal_spacing_size}
        );
      }

      &-offset-#{$i} {
        top: calc(-1 * (#{$nominal_spacing_size} + #{$nominal_spacing_size} + #{$line_height_size}) *#{$i});
      }
    }

    &-expand {
      z-index: 3;
      top: calc(#{$nominal_spacing_size} + #{$nominal_spacing_size} + #{$line_height_size});
      border: 1px solid $border_color;
    }

    &-hr {
      z-index: 3;
      position: absolute;
      top: calc(#{$nominal_spacing_size} + #{$nominal_spacing_size} + #{$line_height_size});
      left: calc(50% - #{$bar_half_width--small_size});
      height: 1px;
      width: $bar_width--small_size;
      background-color: $border_color;

      &.fade-in {
        animation: fade-in $transition_duration $transition_func forwards;
      }

      &.fade-out {
        animation: fade-out $transition_duration $transition_func forwards;
      }
    }
  }

  .selection-bar-bottom {
    z-index: 3;
    position: absolute;
    left: 50%;
    bottom: -$bar_position_size;
    transform: translateX(-50%);
    background-color: $foreground_color;
    background: $foreground_color;
    margin: auto;
    border-radius: $bar_radius_size;
    user-select: none;
    white-space: nowrap;

    .selection-bar-item {
      // Done this way to preserve clickable area instead of table cell
      padding: $nominal_spacing_size $bar_item_padding;
      width: auto;
      border-right: 1px $border_color solid;
      transition: color 0.1s ease;

      &:first-child {
        border-radius: $bar_radius_size 0 0 $bar_radius_size;
      }

      &:nth-child(4) {
        border-right: none;
        border-radius: 0 $bar_radius_size $bar_radius_size 0;
      }

      &:hover {
        background: rgba($selected_background_color, 0.5);
      }
      &.selection-bar-item--active {
        font-weight: bold;
        background: $selected_background_color;
      }
    }
  }

  .selection-bar-item {
    cursor: pointer;
    display: inline-block;
    width: $bar_item_width_size;
    text-align: center;
    transition: color 0.1s ease;

    &.selection-bar-item--disabled:hover {
      cursor: not-allowed;
    }

    &.selection-bar-item--active {
      font-weight: bold;
      color: $turquoise;
    }
    &:hover {
      color: $turquoise;
    }

    &.selection-bar-item--ifs {
      margin-right: 70px;
    }
  }

  .pane-section-2 {
    .selection-bar-top--small:first-child {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      .selection-bar-item.selection-bar-item--active {
        margin: 0px;
      }

      i {
        position: absolute;
        top: 65%;
        right: 10%;
      }
    }
  }

  .selection-bar-top--small-expand:has(.selection-bar-item--ifs) {
    height: auto;
  }

  .selection-bar-scroll-zone {
    position: relative;
    height: $bar_height_size;
    overflow: hidden;
    white-space: nowrap;

    @for $i from 0 through 9 {
      &-area-#{$i} {
        position: relative;
        display: flex;
        justify-content: center;
        transition: left $transition_duration $transition_func;
      }
    }
  }

  .smile-selection-bar-scroll-zone {
    display: flex;
    justify-content: center;
    position: relative;
    width: calc(100% - #{$bar_radius_size} * 2);
    height: $bar_height_size;
    margin-left: $bar_radius_size;
    overflow: hidden;
    white-space: nowrap;

    @for $i from 0 through 9 {
      &-area-#{$i} {
        position: absolute;
        left: calc(-1 * (#{$bar_item_width_size} * #{$bar_item_max}) * #{$i});
        transition: left $transition_duration $transition_func;
      }
    }
  }

  .smile-selection-bar-scroll-zone-area-0 {
    position: relative;
  }

  .btn--primary {
    background-color: $jade;
    &:hover {
      background-color: $wave;
      color: $text_color;
    }
  }
}

.superimpose {
  &-anchor {
    position: relative;
  }

  &-window {
    z-index: 4;
    position: absolute;
    left: calc(50% + 182px);
    bottom: 40px;
    transform: translateX(-50%);
    background-color: $foreground_color;
    background: $foreground_color;
    margin: auto;
    border-radius: 20px;
    width: calc(100px + (#{$bar_item_padding} * 2));
    height: calc(#{$nominal_spacing_size} * 5);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

    .records-zoom-control-window {
      bottom: 15px;
    }
  }
}

.selection-bar-item.selection-bar-item--active:hover + .superimpose-window.hide {
  display: block !important;
}

.superimpose-window.hide:hover {
  display: block !important;
}

@media (max-width: 1500px) {
  .panel {
    &-viewer--dark {
      .selection-bar-bottom {
        .selection-bar-item {
          padding: $nominal_spacing_size calc((100vw / 2400) * #{$bar_item});
        }
      }
    }
  }

  .superimpose {
    &-window {
      left: calc(50% + 182px - ((100vw / 1500) * #{$bar_item}));
      width: calc(100px + ((100vw / 1500) * #{$bar_item} * 2));
      min-width: 150px;
    }
  }
}

@media (max-width: 1350px) {
  .superimpose {
    &-window {
      left: calc(50% + 140px - ((100vw / 1350) * 20));
    }
  }
}

@media (max-width: 1100px) {
  .superimpose {
    &-window {
      left: calc(50% + 120px - ((100vw / 1100) * 20));
    }
  }
}

@media (max-width: 1000px) {
  .superimpose {
    &-window {
      left: calc(50% + 100px - ((100vw / 1100) * 20));
    }
  }
}

//------------------------------------------------------------------------------
// Panes
//------------------------------------------------------------------------------
$panel_margin: 60px;

.panel-viewer--dark {
  .pane-section {
    margin-left: $panel_margin;
    width: calc(100vw - #{$panel_size} - #{$panel_margin * 2});
  }
  .pane-section-2 {
    &:first-child {
      margin-left: $panel_margin;
    }
    width: calc(50vw - #{$panel_size * 0.5} - #{$panel_margin});
  }
}
.panel-viewer--dark--collapse {
  .pane-section {
    width: calc(100vw - #{$panel_collapse_size} - #{$panel_margin * 2});
  }
  .pane-section-2 {
    width: calc(50vw - #{$panel_collapse_size * 0.5} - #{$panel_margin});
  }
}

.pane {
  width: 200vw;
  height: 100vh;
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  background: $background_color;
  transition: all $transition_duration $transition_func;

  &-section {
    transition: all $transition_duration $transition_func;
    height: 100%;
    overflow: hidden;
    margin-left: $panel_margin;

    &:last-child {
      opacity: 0;
    }
  }

  &-section-2 {
    position: relative;
    height: 100%;
    transition: all $transition_duration $transition_func;

    #pane-viewer-2 {
      margin-left: $panel_margin * 0.5;
    }

    #smile-pane-viewer-1 {
      border-radius: 20px 00px 00px 20px;
      border-right: none !important;
      margin-left: auto;
      margin-right: 2px;
    }
    #smile-pane-viewer-2 {
      border-radius: 00px 20px 20px 00px;
      border-left: none !important;
      margin-left: 2px;
      margin-right: auto;
    }
  }

  &-viewer {
    position: relative;
    transition: all $transition_duration $transition_func;
    width: 100%;
    height: calc(100vh - 150px);
    background: $foreground_color;
    border-radius: 20px;
    margin: auto;
    margin-top: 80px;

    &-records {
      width: 100%;
      height: 100%;
      padding: 10px;

      .pane-view-pdfobject {
        height: 100%;
        width: 100%;
        border-radius: 3px;
      }
    }

    .viewer-wrapper {
      width: 100%;
      height: 100%;
      outline: none;
    }

    .viewer-3d > canvas {
      outline: none;
      border-radius: 20px;
    }

    .viewer-controls-container {
      position: absolute;
      top: 10px;
      right: 10px;
      display: flex;
      flex-direction: column;
      pointer-events: none;
    }

    &-navigation-controls {
      .MuiSvgIcon-root {
        cursor: pointer;
        font-size: 3em;
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%) scaleX(-1);

        &:hover {
          color: rgba(white, 0.5);
        }

        &:active {
          color: $turquoise;
        }

        &.pane-viewer-navigation--prev {
          left: 0;
          transform: translateY(-50%);
        }
      }
    }
  }
}

$text_border_color: rgba(black, 0.5);

.pane-viewer .viewer-controls-container {
  .viewer-controls-lower-container {
    margin-top: 5px;
    padding: 15px 17px 0px 20px;
    background-color: rgba($foreground_color, 0.5);
    border-radius: 20px;
  }

  .step-selection-container {
    .step-selection-item {
      display: flex;
      justify-content: flex-end;
      position: relative;
      padding-top: 0;
      border-right: 1px white solid;
      font-size: $text_font_size;
      user-select: none;
      line-height: 15px;

      &:last-child {
        border-right: 1px transparent solid;
        padding-bottom: 1em;
      }

      > div {
        margin-top: -0.75em;
        margin-bottom: 0.75em;
        padding: 0.75em 1.5em 0.75em 1.5em;
        cursor: pointer;
        color: white;
        pointer-events: auto;
        transition: color $transition_duration $transition_func;
        text-shadow: -1.3px 0 $text_border_color, 0 1.3px $text_border_color, 1.3px 0 $text_border_color, 0 -1.3px $text_border_color;

        &::after {
          content: '';
          position: absolute;
          right: 0;
          top: 0;
          transform: translateX(50%);
          border-radius: 50%;
          background: white;
          width: 1em;
          height: 1em;
          cursor: pointer;
          transition: background $transition_duration $transition_func;
        }

        &:hover {
          color: #959899;

          &::after {
            background: #959899;
          }
        }
      }

      &.active > div {
        color: $turquoise;
        font-weight: bold;

        &::after {
          background: $turquoise;
        }
      }

      &.super-purple > div {
        color: $super_purple;
        font-weight: bold;

        &::after {
          background: $super_purple;
        }
      }

      &.super-blue > div {
        color: $super_blue;
        font-weight: bold;

        &::after {
          background: $super_blue;
        }
      }
    }
  }
}

.pane-viewer .grid-size {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 10px;
  background: $foreground_color;
  padding: 6px 10px;
  border-radius: 10px;
  border: 2px rgba($turquoise, 0.5) solid;
  user-select: none;
}

// DAT GUI
.dg.ac {
  .slider:before {
    display: none;
  }
  input {
    line-height: normal;
    font: 400 13.3333px Arial;
  }
  select {
    color: black;
  }
}

//------------------------------------------------------------------------------
// Record Slider and Image
//------------------------------------------------------------------------------
$thumbnail_height: 70;
$thumbnail_btn_area: 70;
$track_area: 100;
$btn_area: 22;

$thumbnail_height_size: $thumbnail_height + px;
$thumbnail_btn_area_size: $thumbnail_btn_area + px;
$track_area_size: $track_area + px;
$btn_area_size: $btn_area + px;

.records-slider {
  text-align: center;

  &-container {
    max-width: calc(#{$bar_width_size} - #{$thumbnail_btn_area_size} * 2);
    text-align: left;
    margin: auto;
    overflow: hidden;
    white-space: nowrap;
    overflow-x: auto;
    padding-top: 25px;

    // scroll bar customization
    &::-webkit-scrollbar {
      background-color: $scrollbar_color;
      height: 6px;
      width: 5px;
      border-radius: $scrollbar_border_radius;
    }
    &::-webkit-scrollbar-thumb {
      background-color: $scrollbar_thumb_color;
      border-radius: $scrollbar_border_radius;
    }

    &::-webkit-scrollbar-thumb:hover {
      background-color: $scrollbar_hover_color;
    }
  }

  &-thumbnail {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin: $nominal_spacing_size calc(#{$nominal_spacing_size}/ 2);
    height: $thumbnail_height_size;
    width: $thumbnail_height_size;
    border: 2px $turquoise_light solid;
    transition: border-color 0.1s ease;
    text-align: center;
    border-radius: 3px;
    cursor: pointer;
    vertical-align: middle;
    overflow: hidden;

    &:hover {
      border-color: $turquoise_50;
    }

    &.active {
      border: 2px $turquoise solid;
    }

    &:first-child {
      margin-left: 0px;
    }

    &:last-child {
      margin-right: 0px;
    }

    img,
    svg {
      width: 100%;
    }
  }
}

.records-image {
  display: flex;
  text-align: center;
  margin: calc(#{$nominal_spacing_double_size} * 2);
  margin-top: 0px;
  height: calc(100% - #{$nominal_spacing_double_size} * 8);

  &-content {
    display: inline-block;
    transition: transform $transition_duration $transition_func;
    margin: auto;
    height: 100%;
  }

  &.pdf-record {
    display: block;
    height: calc(100% - #{$nominal_spacing_double_size} * 6);
    margin-bottom: 0;

    #rpv-core__popover-body-inner-zoom,
    #rpv-core__popover-body-inner-toolbar-more-actions {
      max-height: unset !important;
    }
  }
}

.records-zoom {
  &-control-window {
    position: absolute;
    left: calc(50% - #{$track_area_size}/ 2);
    text-align: center;
    width: 100px;
  }

  &-controller {
    -webkit-appearance: none;
    background: rgba(white, 0.5);
    height: 1px;
    width: $track_area_size;

    &.vertical-controller {
      pointer-events: all;
      position: absolute;
      top: 54px;
      right: -7px;
      transform: rotate(270deg);
      width: 49px;
    }

    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      height: 11px;
      width: 11px;
      border-radius: 50%;
      background: white;
      cursor: pointer;
    }

    &::-moz-range-thumb {
      height: 11px;
      width: 11px;
      border-radius: 50%;
      background: white;
      cursor: pointer;
    }

    &::-ms-thumb {
      height: 11px;
      width: 11px;
      border-radius: 3px;
      background: white;
      cursor: pointer;
    }

    &-left-btn {
      left: -$btn_area_size;
      top: -11px;
    }

    &-right-btn {
      right: -$btn_area_size;
      top: -11px;
    }

    &-value {
      display: inline-block;
      position: absolute;
      top: -25px;
      left: 50%;
      transform: translateX(-50%);

      &.super-blue {
        color: $super_blue;
      }

      &.super-purple {
        color: $super_purple;
      }
    }

    &.fifty-percent-bar::before {
      content: '|';
      position: absolute;
      left: calc(50% - 2px);
      top: -10px;
      z-index: -1;
    }

    &:disabled {
      pointer-events: none;
      color: rgba(white, 0.2);
      background: rgba(white, 0.2);

      &::-webkit-slider-thumb {
        background: rgba(white, 0.2);
      }
    }
  }
}

.records-controller-btn {
  position: absolute;
  padding: 5px;

  &:hover {
    color: rgba(white, 0.5);
    cursor: pointer;

    &:active {
      color: $turquoise;
    }
  }

  &.MuiSvgIcon-root {
    height: 30px;
    width: 30px;
  }

  &#records-rotate-controller-right-btn {
    right: calc(-1 * #{$btn_area_size} * 2.3);
    top: -15px;
  }

  &#records-flip-u-d-controller-right-btn {
    right: calc(-1 * #{$btn_area_size} * 3.4);
    top: -15px;
    transform: rotate(90deg);
  }

  &#records-flip-l-r-controller-right-btn {
    right: calc(-1 * #{$btn_area_size} * 4.5);
    top: -15px;
  }

  &.records-reset-controller-btn {
    left: calc(-1 * #{$btn_area_size} * 2);
    top: -11px;
  }
}

//------------------------------------------------------------------------------
// IPR Chart
//------------------------------------------------------------------------------
$chart_height: 120;
$chart_width: 608;
$chart_offset: 65;
$chart_item_height: 20;
$bubble: 38;
$bubble_padding: 3;
$bubble_height_offset: 10;

$chart_height_size: $chart_height + px;
$chart_width_size: $chart_width + px;
$chart_offset_size: $chart_offset + px;
$chart_item_height_size: $chart_item_height + px;
$bubble_size: $bubble + px;
$bubble_padding_size: $bubble_padding + px;
$bubble_x_offset_size: ($bubble * 0.5) + px;
$bubble_height_offset_size: $bubble_height_offset + px;

.ipr {
  &-window {
    position: absolute;
    height: $chart_height_size;
    width: $chart_width_size;
    bottom: 60px;
    left: calc(50% - #{$chart_width_size}/ 2);
    text-align: center;

    &.ipr-none {
      height: auto;
    }

    &-area {
      position: relative;

      .loader-window-ipr > .loader-header {
        min-height: 0;
      }
    }
  }

  &-title {
    text-align: center;
    font-weight: bold;
    height: 35px;
    line-height: 25px;
    padding: 5px;
  }

  &-bubble {
    position: absolute;
    display: inline-block;
    height: calc(#{$bubble_size} - #{$bubble_padding_size} * 2);
    width: calc(#{$bubble_size} - #{$bubble_padding_size} * 2);
    padding: $bubble_padding_size;
    border-radius: calc(#{$bubble_size}/ 2);
    background-color: rgba(white, 0.25);
    text-align: center;
    line-height: 26px;

    @for $i from 1 through 29 {
      &-x-#{$i} {
        left: calc(#{$bubble_x_offset_size} * #{$i} + #{$bubble_padding_size});

        &:after {
          content: '';
          left: calc(#{$bubble_size}/ 2 - #{$bubble_padding_size});
          position: absolute;
          width: 1px;
          background-color: rgba(white, 0.25);
        }

        &.ipr-bubble-y-0 {
          &:after {
            @if $i%2 == 1 {
              height: calc(#{$chart_item_height_size} + #{$bubble_height_offset_size} + #{$bubble_padding_size} * 2);
              bottom: calc(-1 * (#{$chart_item_height_size} + #{$bubble_height_offset_size} + #{$bubble_padding_size} * 2));
            } @else {
              height: calc(#{$bubble_height_offset_size} + #{$bubble_padding_size} * 2);
              bottom: calc(-1 * (#{$bubble_height_offset_size} + #{$bubble_padding_size} * 2));
            }
          }
        }

        &.ipr-bubble-y-1 {
          top: calc(#{$bubble_height_offset_size} + (#{$bubble_padding_size} * 2));

          &:after {
            @if $i%2 == 1 {
              height: calc(#{$chart_item_height_size} + #{$bubble_height_offset_size} + (#{$bubble_padding_size} * 2));
              top: calc(-1 * (#{$chart_item_height_size} + #{$bubble_height_offset_size} + (#{$bubble_padding_size} * 2)));
            } @else {
              height: calc(#{$bubble_height_offset_size} + #{$bubble_padding_size} * 2);
              top: calc(-1 * (#{$bubble_height_offset_size} + #{$bubble_padding_size} * 2));
            }
          }
        }
      }
    }

    &-x-15::after {
      background-color: white;
    }

    &-area {
      display: block;
      position: relative;
      height: calc(#{$bubble_size} + #{$bubble_height_offset_size});
      width: 100%;
    }
  }

  &-chart {
    table {
      tr {
        td {
          font-size: $text_font_size;
          min-width: $bubble_size;
          width: $bubble_size;
          text-align: center;
          line-height: 20px;

          &.ipr-chart-top-teeth {
            border-bottom: rgba(white, 0.25) 1px solid;
          }
        }
      }
    }

    &-missing-teeth {
      text-decoration: line-through;
      color: rgba(white, 0.25);
    }
  }
}

@media (max-width: 1500px) {
  .ipr-window {
    table {
      margin-left: calc(#{$bubble_size});
    }

    tr > td:first-child {
      display: none;
    }

    tr > td:last-child {
      display: none;
    }
  }
}

@media (max-width: 1350px) {
  .ipr-window {
    display: none;
  }
}

//------------------------------------------------------------------------------
// Helper CSS
//------------------------------------------------------------------------------

.background--dark {
  .fill {
    width: 100%;
    height: 100%;
  }

  .center {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

.invisible {
  display: none;
}

.underline {
  text-decoration: underline;
  text-underline-offset: 5px;
}

.flip-180 {
  transition: transform $transition_duration $transition_func;
  transform: rotate(180deg);
}

.fade-in {
  animation: fade-in 0.5s ease forwards;
}

.fade-out {
  animation: fade-out 0.5s ease forwards;
  cursor: default;
}

.flip_l_r {
  transform: scaleX(-1);
}

.flip_u_d {
  transform: scaleY(-1);
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.layer-hidden {
  z-index: -1 !important;
  position: relative;
}

//------------------------------------------------------------------------------
// Background
//------------------------------------------------------------------------------

$tooltip_color: #64686b;

.background--dark {
  background: $background_color;
  background-color: $background_color;
  padding: 0px;
  position: fixed;
  width: 100vw;
  height: 100vh;
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

  .tooltip {
    &.top .tooltip-arrow {
      border-top-color: $tooltip_color;
    }
    &.right .tooltip-arrow {
      border-right-color: $tooltip_color;
    }
    &.bottom .tooltip-arrow {
      border-bottom-color: $tooltip_color;
    }
    &.left .tooltip-arrow {
      border-left-color: $tooltip_color;
    }
    .tooltip-inner {
      background-color: $tooltip_color;
      text-align: left;
    }
  }

  a {
    color: #4dadff;
    text-decoration: underline;

    &:focus {
      color: #94ceff;
    }
    &:hover {
      color: #0089ff;
    }
  }
}

//------------------------------------------------------------------------------
// Viewer Controls
//------------------------------------------------------------------------------

.view-selection {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  .nav-table {
    pointer-events: none;

    button,
    img {
      pointer-events: all;
    }

    tr > td:first-child {
      min-width: 0;
    }

    td {
      text-align: right;
    }

    .btn.btn-setup {
      padding: 3px 6px;
      margin: 0;
      color: white;
      background: transparent;
      transition: all 0.3s;
      box-shadow: none;

      &.active {
        .teeth-group {
          opacity: 1;
        }
        .mask {
          opacity: 0;
        }
      }

      &:not(.active) {
        .teeth-group {
          opacity: 0;
        }
        .mask {
          .fill {
            opacity: 0.4;
          }
          opacity: 1;
        }
        &:hover {
          path.border {
            opacity: 0.5;
          }
        }
      }
    }
  }

  .jaw-view {
    margin-left: $nominal_spacing_size;
    margin-right: 5px;
    pointer-events: auto;
    position: relative;

    .view-btn {
      cursor: pointer;
      user-select: none;
      background: rgba($foreground_color, 0.5);
      padding: 2px 0 0 1px;
      color: $turquoise_light;
      border: 2px rgba(white, 0.2) solid;
      font-weight: bold;
      transition: color 0.2s ease;
      width: 26px;
      height: 26px;
      border-radius: 50%;

      > div {
        text-align: center;
      }

      &.active {
        color: $turquoise;
        background: rgba($selected_background_color, 0.5);
        border-color: $turquoise_50;
      }

      &#show-upper {
        margin-bottom: 50px;
      }
    }
  }
}

.ifs-history-list {
  .history-list-item--active {
    background-color: $inbrace_ifs_bg_color !important;
    .history-list-item-title,
    .history-list-item-name,
    .quill-formatted p {
      color: $inbrace_ifs_title_color !important;
    }
  }
}
//------------------------------------------------------------------------------
// Redirect Btn
//------------------------------------------------------------------------------
.redirect-btn {
  z-index: 3;
  position: absolute;
  right: 60px;
  top: 20px;
  transition: color 0.1s ease;
  width: auto;

  &:hover {
    background: rgba($selected_background_color, 0.5);
  }
}
