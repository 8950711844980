.label-dropdown-container {
  display: flex;
  flex-wrap: wrap;

  > span {
    align-self: center;
    margin-right: 4px;
  }

  .label-dropdown-popup {
    margin: 0 4px;
  }

  .dropdown-container {
    display: flex;

    .dropdown-input {
      min-width: 200px;
    }
    .dropdown-action-container {
      align-self: center;
    }
  }
}
