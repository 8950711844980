.manual-wire-error {
    width: 100%;
    margin-top: 20px;
    box-sizing: border-box;
  
    .error-log {
      background-color: red;
      width: 100%;
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px;
      color: black;
    }
  
    .error-message {
      background-color: white;
      width: 100%;
      height: auto;
      display: flex;
      align-items: flex-start;
      justify-content: space-around;
      flex-wrap: wrap;
      padding: 10px;
      margin-top: 10px;
      color: black;
    }
  }
  