.smile {
    &-radio{
        &-heading{
            font-weight: 500;
        }

        &-subheading{
            font-weight: 500;
        }

        &-response {
            background-color: #f8f8f8;
            padding: $general-spacing*2;
            margin-top: $general-spacing;
            margin-bottom: $general-spacing;
        }
    }
}