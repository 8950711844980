.ipr_teeth_chart {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  .ipr_char_tooth {
    border: solid #e1e2e2 1px;
    display: inline-block;
    width: auto;
    min-width: 48px;
    height: 32px;
    margin-bottom: 0px;
    color: #000;
    padding-top: 4px;
  }
  .ipr_char_tooth_missing {
    color: #e1e2e2;
    text-decoration: line-through;
  }

  .ipr_teeth_chart_bubble {
    display: flex;
    flex-direction: column;
    margin-top: 0px;
  }

  .left_margin_offset {
    margin-left: -3px;
  }

  .ipr_teeth_chart_checkbox {
    margin-left: -5px;
    margin-top: 0px;
    margin-bottom: 0px;
    &.left_margin_offset {
      margin-left: -45px;
    }
  }
  .ipr_teeth_chart_checkbox_label {
    color: #e13146;
    font-size: 16px;
    line-height: 24px;
    margin: 0px;
    font-weight: 400;
    text-align: start;
    margin-left: -9px;
    margin-top: 4px;
    margin-bottom: 4px;
  }

  .vl {
    border-left: 2px solid #e13146;
    height: 30px;
  }

  .UR8_row {
    align-items: end;
  }

  .UL1_row {
    align-items: end;
    margin-bottom: 6px;
  }

  .ipr_teeth_row {
    min-width: 40%;
    display: flex;
  }
  .UR8_row {
    margin-bottom: 6px;
    margin-right: 5px;
  }

  .LR8_row {
    margin-right: 5px;
  }

  .ipr_notes {
    border: solid #e1e2e2 1px;
    padding: 12px 15px;
    width: 100%;
    height: 80px;
    outline: none;
  }
}

@media screen and (min-height: 992px) {
  .ipr_teeth_chart {
    margin-left: 20px;
  }
}

@media screen and (max-width: 992px) {
  .ipr_teeth_chart {
    display: unset;
  }
  .ipr_teeth_chart .ipr_teeth_row {
    justify-content: center;
  }
}

@media screen and (max-width: 500px) {
  .ipr_teeth_chart .ipr_char_tooth {
    min-width: 30px;
    font-size: 0.7em;
  }
}

.lower_checkboxes {
  margin-top: 0px !important;
}
.upper_checkboxes {
  margin-bottom: -5px;
}
