.fileupload-progress-container {
  max-height: calc(50vh - 140px);
  overflow: auto;
}

.fileupload-preview-container {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  align-content: flex-start;
  width: 613px;

  .fileupload-preview-thumbnail {
    width: 100px;
    height: 80px;
    border-radius: 3px;
    object-fit: cover;
    margin: 0 10px 10px 0;
  }
}
