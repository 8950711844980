$loader-color: #2DA4AD;
$background-color: #f8f8f8;
$loader-color-dark: #c9e2e4;
$size: 40px;

@keyframes scaleDelay {
  0%,
  80%,
  100% {
    transform: scale(0.2);
  }
  40% {
    transform: scale(1);
  }
}

.fullview {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 100000;
}

.with-background {
  background-color: $background-color;
}

.spinner-container {
  width: $size;
  height: $size;
  position: relative;
  margin: 0 auto;

  &--fullscreen{
    margin: calc((100vh - #{$size})/2 - #{$size}) auto;
    vertical-align: middle;
  }

  &--small{
    width: 1.5em;
    height: 1.5em;
  }

  .circle {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    &:before {
      content: '';
      display: block;
      margin: 0 auto;
      width: 18%;
      height: 18%;
      background-color: $loader-color;
      border-radius: 50%;
      animation: scaleDelay 1.2s infinite ease-in-out both;
    }
  }

  &--dark {
    .circle::before {
      background-color: $loader-color-dark;
    }
  }

  .circle2 {
    transform: rotate(45deg);
    &:before {
      animation-delay: -1.05s;
    }
  }
  .circle3 {
    transform: rotate(90deg);
    &:before {
      animation-delay: -0.9s;
    }
  }
  .circle4 {
    transform: rotate(135deg);
    &:before {
      animation-delay: -0.75s;
    }
  }
  .circle5 {
    transform: rotate(180deg);
    &:before {
      animation-delay: -0.6s;
    }
  }
  .circle6 {
    transform: rotate(225deg);
    &:before {
      animation-delay: -0.45s;
    }
  }
  .circle7 {
    transform: rotate(270deg);
    &:before {
      animation-delay: -0.3s;
    }
  }
  .circle8 {
    transform: rotate(315deg);
    &:before {
      animation-delay: -0.15s;
    }
  }
}

.loader-spacing {
  padding: 15px 5px 5px 5px;
}
