.simple-modal {
  .modal-dialog {
    .modal-content.pdf-modal-content {
      margin: 0 10px 0 10px;
      padding: 45px;

      @media (max-width: 768px) {
        padding: 25px;
      }

      .modal-body {
        .modal-header {
          padding: 0 0 32px 0;
          @media (max-width: 768px) {
            padding: 0 0 20px 0;
          }
        }
        .modal-content-body {
          height: calc(100vh - 250px);
          max-height: 900px;
        }

        .viewer {
          .dialogStyles {
            width: auto;
          }
        }
      }
    }
  }
}
