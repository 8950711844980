.login-window {
  width: 450px;
  margin: auto;
  padding-top: calc((100vh - 400px) / 2);
  height: 100vh;
}

.warning-login {
  display: none;
  text-align: center;
}

.warning-login.warning-display {
  display: block;
}

.footer-display {
  font-size: 0.9em !important;
  position: absolute;
  bottom: 0px;
  width: 50%;
  margin-bottom: 10px;

  &.footer-display-100{
    width: 100%;
    text-align: center;
  }
}

.footer-center{
  font-size: 0.9em !important;
  text-align: center;
}

.left-align-bullet {
  text-align: left;
  padding: 0px 10px 1px 15px;
  ul {
    margin-top: 5px;
    margin-bottom: 0px;
    padding-left: 20px;

    li {
      line-height: 24px;
      padding-left: 5px;
    }
  }
}

a.block{
  display: block;
}

.split-50 {
  width: 50%;
  margin-left: 0px;
  margin-right: 0px;
  height: 100vh;
}

.login-side{
  background-image: url('../../../public/static/img/login_side.jpg');
  background-size: cover;
  background-origin: border-box;
  background-repeat: no-repeat;
  background-position-x: 0px;
  background-position-y: 0px;
}

@media only screen and (max-width: 1100px) {
  .split-50 {
    width: 100%;

    &+.split-50{
      display: none;
      width: 0%;
    }
  }

  .footer-display {
    width: 100%;
  }
}

@media only screen and (max-width: 500px) {
  .login-window {
    width: 90%;
  }
}
