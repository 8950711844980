.not-available {
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 14% auto;
  text-align: center;

  .not-available-container {
    background-color: #111111;
    border-radius: 20px;
    padding: 20px;

    .not-available-image {
      width: 60%;
    }

    .not-available-text {
      color: #ffffff;
      font-size: 18px;
      margin-top: 20px;
    }
  }
}
