.case-breadcrumb {
  font-size: $body-font-size;
  color: $almostBlack;
  cursor: pointer;
  display: block;
  margin: 0;

  &:hover {
    color: $turquoise;
  }

  &.case-breadcrumb--active {
    color: $turquoise;
    font-weight: bold;
  }
}
