$divider-style: 2px dashed $platinum;
$circle-size: 2em;
$mobile-circle-size: 4em;

.stepper {
  &__counter {
    position: relative;
    color: $almostBlack;
    border-radius: 50%;
    border: 2px solid $platinum;
    z-index: 1;
    overflow: hidden;
    transition: border 0.3s ease-out;

    &--white {
      color: $pearl;
    }

    &--completed {
      border-color: $turquoise;
    }

    &__background {
      background: $turquoise;
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 100%;
      z-index: -1;
      transition: max-height 0.3s ease-out;
    }

    &::before,
    &::after {
      content: '\200B';
      display: inline-block;
      line-height: 0px;
      padding-top: 50%;
      padding-bottom: 50%;
    }
    &::before {
      padding-left: $general-spacing;
    }
    &::after {
      padding-right: $general-spacing;
    }
  }

  &__info {
    text-align: right;
  }
}

@include for-size(tablet) {
  .stepper.show-on-mobile {
    display: flex;
    justify-content: space-between;
  }
}

.stepper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
  padding: 10px;

  &__item {
    flex-grow: 1;
    color: $almostBlack;
    cursor: pointer;
    position: relative;
    text-align: center;
    align-self: flex-end;
    z-index: 0;

    &:first-child::before {
      width: 50%;
      transform: none;
    }

    &:last-child::before {
      width: 50%;
      transform: translateX(-100%);
    }

    &::before {
      content: '';
      position: absolute;
      width: 100%;
      bottom: calc(#{$circle-size} / 2);
      left: 50%;
      transform: translateX(-50%);
      border-top: $divider-style;
      z-index: -1;
    }

    &::after {
      content: '';
      display: block;
      border-radius: 50%;
      width: $circle-size;
      height: $circle-size;
      margin: $general-spacing auto 0;
      background-color: $soot;
    }

    &:hover {
      color: $turquoise;

      &::after {
        background-color: $wave;
      }
    }

    &--active {
      color: $turquoise;
      font-weight: bold;
    }

    &.stepper__item--disabled {
      color: $soot;
      cursor: not-allowed;

      &:active::after, &::after {
        background-color: $platinum;
      }
    }

    &--completed {
      &::after {
        background-color: $turquoise;
      }
    }

    &--blinking {
      &::after {
        animation: colorTransition 0.5s infinite alternate ease;
      }
    }

    &:active::after {
      background-color: $jade;
    }

    &__divider {
      flex-grow: 1;
      border-top: $divider-style;
      align-self: flex-end;
      margin: 0 3px calc(#{$circle-size} / 2);
    }
  }
}

@keyframes colorTransition {
  from {
    background-color: $soot;
  }
  to {
    background-color: $turquoise;
  }
}
