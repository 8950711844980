.content--left {
  display: block;
  text-align: left;
}

.heading {
  &--light {
    color: $pearl;

    a {
      color: $hyperlink-white-active;
      &:hover {
        color: $hyperlink-white-hover;
      }
      &:active {
        color: $hyperlink-white-active;
      }
      &:disabled {
        color: $hyperlink-white-disabled;
      }
    }
  }

  &--warning {
    color: rgba($ruby, 0.75);
  }
}

.block--right {
  text-align: right;
}

.float--right {
  float: right;
}

.break-text {
  word-break: break-all;
}
