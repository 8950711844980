$light-red: #cf424b;
$brand: #489599;
$light-grey: #e0e0e0;

input[type='radio'].text-input {
  margin-right: 8px;
  width: 13px;
}

.text-input {
  border: 1px solid $platinum;
  border-radius: 3px;
  padding: $general-spacing * 0.5 $general-spacing * 2;
  background-color: $pearl;
  width: 100%;

  &::placeholder {
    color: $soot;
  }

  &:focus {
    border: 1px solid $turquoise;
    outline: none;
  }

  &:hover {
    border: 1px solid $turquoise;
  }

  &:disabled {
    background-color: $platinum;
    cursor: not-allowed;
    border: 1px solid $platinum;
  }

  &.has-error {
    border: 1px solid $light-red;
  }
}

.text-input__error {
  line-height: 1em;
  position: absolute;
  left: 0px;
  color: $light-red;
}

.text-area__count {
  position: absolute;
  top: 177px;
  right: 20px;
  text-align: right;
  background-color: transparent;

  span{
    color: #cccccc;
  }

  &--bottom {
    top: auto;
  }
}

.text-input__count {
  background-color: transparent;
  padding: 0px 5px;
  position: absolute;
  right: 5px;
  top: 15px;

  span{
    color: #cccccc;
  }
}

.text-input__count--error {
  span {
    color: $light-red;
  }
}

.action-buttons {
  display: inline-block;
  cursor: pointer;
  margin: 5px;

  &:last-child{
    margin: 5px 0px;
  }
}

.svg-button {
  float: right;
  > div {
    background-color: $light-grey;
    padding: 0px 5px;
    border-radius: 3px;
  }

  > div.tooltip {
    background-color: white;
  }

  .cancel-svg-button {
    min-width: 26px;
    text-align: center;
    &:hover {
      opacity: 0.6;
      -webkit-transition: all 500ms ease;
      -moz-transition: all 500ms ease;
      -ms-transition: all 500ms ease;
      -o-transition: all 500ms ease;
      transition: all 500ms ease;
    }
    span {
      svg {
        color: $light-red;
      }
    }
  }
  .save-svg-button {
    &:hover {
      opacity: 0.6;
      -webkit-transition: all 500ms ease;
      -moz-transition: all 500ms ease;
      -ms-transition: all 500ms ease;
      -o-transition: all 500ms ease;
      transition: all 500ms ease;
    }
    span {
      i {
        color: $brand;
      }
    }
  }
}

.warning-border {
  border: 1px solid $ruby !important;
}

.warning-text {
  color: $ruby;
}

.input-container {
  position: relative;

  & .input-icon {
    position: absolute;
    padding: $general-spacing;
    right: $general-spacing;
    cursor: pointer;

    &:hover {
      color: $turquoise;
    }
  }
}

.p-slim {
  text-align: left;
}

textarea {
  border-radius: 3px;
  font-size: 1em;
}
