.reposition {
  font-family: sans-serif;
  text-align: center;
  width: 75%;
  margin: 0 auto;
}

.ReactCrop__crop-selection {
  pointer-events: initial;
}

.ReactCrop {
  cursor: default;
  pointer-events: none;
}

.ReactCrop__image {  
  max-height: 500px;
}

.delete-picture canvas {
  margin-top: 10px;
}
