$scrollbar_color: rgba(black, 0.1);
$scrollbar_thumb_color: #707070;
$scrollbar_hover_color: rgba(black, 0.5);
$scrollbar_border_radius: 10px;

.modal-dialog ::-webkit-scrollbar {
  height: 6px;
  width: 6px;
  border-radius: $scrollbar_border_radius;
}
.modal-dialog ::-webkit-scrollbar-thumb {
  background-color: $scrollbar_thumb_color;
  border-radius: $scrollbar_border_radius;
}

.modal-dialog ::-webkit-scrollbar {
  background-color: rgba(64, 64, 65, 0.137);
  height: 6px;
  width: 6px;
  border-radius: 3px;
}

.modal-dialog ::-webkit-scrollbar-thumb:hover {
  background-color: $scrollbar_hover_color;
}

.info-box--up {
  margin-bottom: 10px;
}

.table-shadow {
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.08), 0 2px 20px 0 rgba(0, 0, 0, 0.02);
  color: #575e65;
}

.fa.fa-search {
  color: #575e65;
}

.pull-right {
  float: right;
}

.pull-left {
  float: left;
}

.btn.btn-round-6.view-plan-button:nth-child(2) {
  margin-right: 10px;
}

.logo-img {
  height: 18px;
  width: 100px;
}

.table.treatment,
.tr,
.td {
  border: 1px solid black;
  text-align: center;
  height: 25px;
  width: 300px;
  margin-left: 30px;
}

.table.patients td {
  border: none;
  text-align: left;
  margin-left: 30px;
}

.table.patients tr td.columnsize,
.table.patients th.columnsize {
  width: 10px;
  min-width: 0px;
}

.treatment-delimiter {
  padding-left: 20px;
}

.btn.approved {
  margin-left: 110px;
  margin-top: -50px;
}

.btn.revise {
  margin-left: 5px;
  margin-top: -50px;
}

.dropdown-menu > li.disabled-link > a {
  color: #ddd;
  cursor: not-allowed;
}

[data-toggle='collapse'] {
  cursor: pointer;
}

.txplan-left.txplan-force-left {
  padding-left: 0px;
}

.modal__approval--size {
  min-width: 800px;
}

.modal__approval--dialog {
  padding: 15px 25px 15px 25px;
}

.modal_centered {
  position: absolute !important;
  left: 50% !important;
  top: 40% !important;
  transform: translate(-50%, -50%) !important;
}

.modal_crunch {
  .modal-content {
    .modal-header {
      padding-bottom: 8px !important;
    }

    .modal-body {
      padding: 0px !important;

      .record-thumbnail-tooltip-container {
        max-height: 40vh !important;
        padding-top: 0px !important;

        .record-thumbnail-container.thumbnail-container {
          margin-top: -20px;
          padding-top: 42px;
        }
      }
    }

    .uploader_div {
      margin-top: 8px;
    }

    .title {
      margin-bottom: 8px;
    }
  }
}

.page__heading {
  display: inline-block;
  width: 280px;
}

.page__heading--full {
  width: 100%;
}

.page__heading--long {
  width: 65%;
}

.page__name {
  color: #43a7b3;
  font-size: 1.8em;
  margin: 0px 0px 0px 0px;
  padding: 0px 0px 0px 30px;
}

.page__name--default {
  margin: 16px 0px 0px 0px;
  padding-left: 31px;
  padding-bottom: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.page__name.page__name--long {
  width: 100%;
}

.page__name--tab {
  position: relative;
  top: -20px;
}

.page__name--w-search {
  display: inline-block;
  width: 180px;
}

.page__number {
  float: right;
  position: relative;
  top: 27px;
  color: #7a7e80;
  margin-right: 5px;
  width: 145px;
  max-width: 145px;
}

.casedetails__line-divison {
  border-bottom: 1px solid #d4d4d4;
  margin-bottom: 5px;
  width: 40%;
}

.casedetails-item {
  display: inline-block;
  width: 30%;
  font-size: 1.1em;
}

.casedetails-quantity {
  display: inline-block;
  width: 10%;
  font-size: 1.1em;
}

.casedetails-reasons {
  padding-bottom: 15px;
  padding-left: 10px;
}

.cozy-text-headlines {
  padding-bottom: 10px;
  margin: 0;
  color: #575e65;
}

.case-details-welcome {
  border-radius: 15px;
  width: 450px;
  height: 250px;
  padding-left: 25px;
  padding-right: 25px;
  color: #ffffff;
  background-color: #7ececf;
  line-height: 30px;
  background-image: -webkit-gradient(linear, left bottom, right top, from(#7ececf), to(#32a1d9));
  background-image: -webkit-linear-gradient(bottom left, #7ececf, #32a1d9);
  background-image: linear-gradient(to top right, #7ececf, #32a1d9);
  text-align: center;
  font-size: 18px;
  vertical-align: middle;
  text-align: center;
  display: table;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.case-details-welcome-btn {
  margin: 15px 0 0 0;
  background-color: #9cfffe3b;
  padding: 10px 50px;
  border-radius: 20px;
  color: #ffffff;
}

.case-details-welcome-btn:hover {
  -webkit-transition: all 0.6s; /* Safari */
  transition: all 0.6s;
  background-color: #fff;
  color: #43a7b3;
}

.case-details-welcome-btn:active {
  background-color: #fff;
  color: #656b6d;
}

.casedetail-welcome-message {
  height: 250px;
  display: table-cell;
  vertical-align: middle;
  min-width: 450px;
}

.new-font-size {
  font-size: 1.1em;
}

.modal-content {
  margin: 50px 10px 0 10px;
}

.modal-content-scroll {
  margin: 10vh 10px 0 10px;
  max-height: 80vh;
  overflow-y: scroll;
}

.ipp-ir-link {
  color: #718790;
  margin-right: 5px;

  &.clickable {
    cursor: pointer;

    &:hover {
      color: #43a7b3;
    }
  }
}

.modal-lg {
  .modal-footer {
    width: auto;
  }
}

.modal-xlg.modal-lg-pdf.modal-dialog {
  width: 1100px;
}

.modal-lg.modal-lg-pdf.modal-dialog .modal-content {
  margin-top: 0px;
}

.modal-lg.modal-lg-pdf.modal-dialog .modal-pdf .pdfobject-container {
  height: calc(100vh - 280px);
}

.modal-lg.modal-lg-pdf.modal-dialog .modal-pdf {
  height: calc(100vh - 250px);
  max-height: 900px;
}

.modal-lg.modal-dialog .modal-pdf .pdfobject-container {
  height: calc(100vh - 280px);
}

.modal-pdf .grey-text {
  margin-bottom: 5px;
}

.modal-pdf.modal-body {
  padding: 0px 15px;
  overflow-y: hidden;

  .p-slim.grey-text {
    margin-bottom: 0px;
  }
}

.modal-lg.modal-lg-form.modal-dialog {
  width: auto;
  @media (min-width: 768px) {
    width: 80%;
  }

  > .modal-content {
    margin-top: 20px;
  }
  .modal-body {
    max-height: calc(100vh - 200px);

    > .portal-view {
      padding-left: 0;
      padding-right: 0;

      .row {
        margin-left: 0;
        margin-right: 0;
      }
    }
  }
}

.cozy-paragraph-wrapped {
  word-break: break-all;
}

.setup-comment {
  display: block;
  margin-top: 10px;
  margin-bottom: 10px;
}

.setup-comment-box {
  background-color: #eaf6f8;
  border: 1px solid #eaf6f8;
  border-radius: 3px;
  display: inline-block;
  margin-left: 15px;
  min-height: 30px;
  padding: 15px 15px 5px 15px;

  p {
    font-family: sans-serif;
    font-size: 1em;
    font-weight: 300;
    line-height: 1.2em;
    color: #575e65;
  }
}

.patient-ref {
  word-wrap: break-word;
}

.encap--button-height {
  display: inline-block;
  height: 36px;
  line-height: 36px;
  vertical-align: middle;
}

.smile_preferences {
  padding-left: 0px;
  border-right: 1px solid #e1e2e2;
}

.smile_scans {
  padding-left: 24px;
}

.case_attribute_checkbox {
  margin-right: 8px !important;
}

.case_detail_title_div {
  display: flex;
  justify-content: space-between;
}

.addtional_action_div {
  display: flex;
  justify-content: flex-end;
  .additional_action_btn {
    height: 40px;
    min-width: 184px;
    margin: auto;
    margin-right: 0px !important;
  }

  .additional_action_icon {
    margin-left: 5px;
  }

  .lock_icon {
    margin-right: 5px;
  }

  .navigator_dropdown_list_hide {
    display: none;
  }

  .navigator_dropdown_lock {
    z-index: 5;
    background-color: white;
    color: rgb(163, 162, 162);
    position: absolute;
    margin-top: 57px;
    border-radius: 4px;
    border: solid 1px #dfdada;
    padding: 10px 0px;

    li {
      list-style-type: none;
      padding: 7px;
      font-size: 16px;
      font-weight: 400;
      border-radius: 4px;
      text-align: left;
      padding-left: 10px;
      pointer-events: none;
    }
  }

  .navigator_dropdown_list {
    z-index: 5;
    overflow: hidden;
    position: absolute;
    margin-top: 57px;
    background: white;
    border-radius: 4px;
    border: solid 1px #dfdada;
    padding: 10px 0px;
    min-width: 160px;

    li {
      list-style-type: none;
      padding: 7px;
      color: #303235;
      font-size: 16px;
      font-weight: 400;
      border-radius: 4px;
      text-align: left;
      padding-left: 10px;
    }

    li:hover {
      cursor: pointer;
      background-color: #74b9be;
      color: white;
      border: none;
      border-radius: 0px;
    }
  }
}

.patient_details {
  .linked_id {
    font-weight: 700;
    color: #211b57;
    cursor: pointer;
  }
}

.text-wrappable {
  word-break: break-all;
}

.patient-notes {
  .btn {
    margin-top: -8px;
  }
  display: flex;
  justify-content: space-between;

  &__message {
    border-bottom: 1px solid #e1e2e2;
    padding-bottom: 10px;
    margin-bottom: 10px;
  }
}

@media only screen and (max-width: 991px) {
  .smile_preferences {
    padding-bottom: 16px;
    padding-left: 0px;
    border-bottom: 1px solid #e1e2e2;
    border-right: none;
  }

  .smile_scans {
    padding-top: 16px;
    padding-left: 0;
  }
}

@media only screen and (max-width: 500px) {
  .modal-lg.modal-lg-pdf.modal-dialog .modal-pdf {
    height: calc(100vh - 250px);
    max-height: 56vh;
  }
}

@media screen and (max-width: 768px) {
  .modal_centered.modal-dialog {
    width: 100%;
    left: 48% !important;
  }
}

@media screen and (max-width: 450px) {
  .modal_centered.modal-dialog {
    left: 47% !important;
  }
}
