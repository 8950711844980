.csq-label {
  padding-left: 15px;
  margin-bottom: 10px;
}

.csq-radio-label {
  padding-left: 10px;
  display: inline-block;
  vertical-align: top;
}

.csq-gray-background {
  padding: 15px 15px 15px 15px;
  // background-color: #f7f7f7;
  background-color: #f2f9fb;
  border-radius: 15px;
  width: 100%;
}

.pr-upload-date-label {
  display: inline-block;
  color: #999999;
}

.pr-upload-date {
  display: inline-block;
  margin-left: 10px;
}

.csq-teeth-selector {
  width: 70%;
  padding-left: 25px;
}

.csq-radio-area {
  cursor: pointer;
}

.csq-radio-date-label {
  padding-left: 23px;
  display: inline-block;
}

.csq-radio-date-field {
  margin-left: 10px;
  display: inline-block;
  margin-bottom: 5px;
}

.csq-upload-heading {
  margin-left: 15px;
}

.csq-mq-section {
  width: 50%;
  display: inline-block;
}

.box-offset {
  margin-left: 21px;
}