.invalid-address {
  padding: 0 20px 0 20px;

  .invalid-message {
    margin-bottom: 15px;
  }

  .invalid-address-row {
    text-align: left;
    padding: 0 10px 0 10px;
  }

  input {
    margin-right: 10px;
    vertical-align: top;
  }

  .address-suggestion {
    margin-bottom: 20px;
  }

  .address-suggestion:nth-child(odd) {
    float: left;
  }

  .address-suggestion:nth-child(even) {
    float: right;
  }
}
