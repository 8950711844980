.collapsible-container .collapsible-toggle button {
  border: none;
  border-radius: 0;
  padding: $general-spacing;
  width: 100%;
  background-color: $platinum;

  &:hover {
    background-color: $aluminum;
  }
  &:active {
    background-color: $soot;
  }
}
