.tooth-popup-menu {
  position: absolute;
  box-shadow: 0 4px 10px 0 #00000040;
  border: 1px solid #CCCCCC;
  border-radius: 3px;
  padding: 8px 0;
  background: #FFFFFF;
}

.tooth-popup-menu-item {
  cursor: pointer;
  color: #303235;
  padding: 4px 8px;
  line-height: 20px;
  font-size: 14px;

  &:hover {
    background: #e9ecef;
  }
}
