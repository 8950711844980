.banner {
  display: flex;
  flex-direction: row;
  border-radius: 3px;
  overflow: hidden;
  .treatmentBanner {
    width: 100%;
    height: fit-content;
    background-color: #211b57;
    padding: 32px;
    max-height: 400px;
    color: #ffffff;

    .text_conents p,
    h1 {
      color: #ffffff !important;
      letter-spacing: 1px;
    }
    .navigator_content {
      margin: 20px 0 20px 0;
      font-size: 12px;
    }

    .btn--close {
      background-color: transparent;
      border: none;
      text-decoration: underline;
      font-size: 0.8em !important;
    }
    .btn--close:hover {
      text-decoration: none;
    }
    .lock_icon {
      margin-right: 5px;
    }
  }
  .right_img {
    width: 154px;
    object-fit: cover;
  }
}

.navigator-right-icon {
  margin: auto;
  margin-right: 9px;
}

.navigator_icon {
  min-width: 35px;
  .icon_status {
    stroke-width: 2.5px !important;
  }
}

.status_card:hover {
  border-left: 5px #489599 solid !important;
}

.status_card:hover .navigator-right-icon {
  color: #489599;
}

.partial_complete_border {
  border-left: 5px #ed9b40 solid !important;
}

.complete_border {
  border-left: 5px #2da4ad solid !important;
}

.navigator_section {
  .navigator_status_section {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .status_column {
      width: 32%;
      background-color: #e1e2e2;
      border-radius: 3px;
      min-height: 100px;
      .status_column_title {
        color: #707070;
        font-weight: bold;
        font-size: 16px;
        margin-left: 7px;

        .label-dropdown-popup {
          margin-left: 7px;
        }
      }
      .status_card_section {
        .status_card {
          cursor: pointer;
          border-left: 5px #707070 solid;
          border-radius: 3px;
          width: inherit;
          height: 85px;
          background-color: #ffffff;
          display: flex;
          flex-direction: row;
          margin: 7px;
          -webkit-box-shadow: -1px 1px 2px 2px rgba(179, 175, 175, 0.75);
          -moz-box-shadow: -1px 1px 2px 2px rgba(179, 175, 175, 0.75);
          box-shadow: -1px 1px 2px 2px rgba(179, 175, 175, 0.75);

          .status_card_content {
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding-right: 5px;

            .status_card_title {
              color: #303235;
              font-size: 16px;
              font-weight: 500;
              margin: 0px;
            }

            .status_card_status_text {
              margin: 0px;
            }
            .status_card_status_text p {
              color: #707070;
              font-size: 16px;
              font-weight: 400;
              margin: 0px;
            }
          }

          .case-detail__status__icon {
            width: 35px;
            margin: 16px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1028px) {
  .navigator_section .navigator_status_section {
    flex-direction: column;
  }
  .navigator_section .navigator_status_section .status_column {
    width: 100%;
    margin-top: 10px;
  }
}

@media only screen and (max-width: 516px) {
  .right_img {
    display: none;
  }
}

@media only screen and (max-width: 330px) {
  .navigator_section .navigator_status_section .status_column .status_card_section .status_card .status_card_content .status_card_status_text {
    display: none;
  }
}

@media only screen and (max-width: 330px) {
  .navigator_section .navigator_status_section .status_column .status_card_section .status_card .status_card_content .status_card_status_text {
    display: none;
  }
}
