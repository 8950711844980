@use '../styles/abstracts/mixins' as mixins;

.upload-method-header {
  .title {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #707070;
  }

  margin: 0;
  display: flex;
  flex-direction: column;

  @include mixins.rowGap(16px);

  .upload-method-field {
    display: flex;

    .ib-radio {
      .form-radio-label {
        cursor: pointer;
      }
    }
  }
}
