.autocomplete {
  position: relative;
  display: inline-block;
  width: 100%;
  max-width: 100%;
  margin-bottom: 0;
  vertical-align: top;

  .autocomplete__list {
    position: absolute;
    box-sizing: border-box;
    top: 100%;
    left: 0;
    z-index: 1;
    width: 100%;
    margin: 0;
    padding: 0;
    list-style: none;
    background-color: $pearl;
    border: 1px solid $platinum;
    border-radius: 3px;
    box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
    max-height: 33vh;
    overflow-y: scroll;
    .autocomplete__item {
      padding: 10px 15px;
      cursor: pointer;
      &:hover {
        color: $pearl;
        background-color: $turquoise;
      }
    }
  }
}