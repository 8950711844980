@import '../../../_bpp-variables';

.align-bottom {
  vertical-align: bottom !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-super {
  vertical-align: super;
}

.form-input.select-disabled > div > button {
  background-color: #eee;
  cursor: not-allowed;

  .filter-option-inner-inner {
    color: #999;
  }
}

.form-input{
  .dropdown{
    margin: 0px;
  }
}

.datalist {
  margin-left: -1px;
  margin-right: -1px;

  button.datalist-select-option {
    border: 1px solid #ccc;
    background-color: #fff;
    border-radius: 10px;
    min-height: 36px;
  }

  .select-border {
    border: 1px solid #cccccc !important;
  }

  .datalist-select-option div.dropdown-menu.open {
    max-height: 350px !important;
    min-height: 250px !important;
    overflow: hidden;
    // min-width: 300px !important;
  }
  p .datalist-select-option ul.dropdown-menu.inner {
    max-height: 160px !important;
    overflow-y: auto;
  }

  .datalist-select-option.dropup div.dropdown-menu.open {
    max-height: 350px !important;
    min-height: 350px !important;
    overflow: hidden;
  }

  .datalist-select-option.dropup ul.dropdown-menu.inner {
    max-height: 290px !important;
    overflow-y: auto;
  }

  .datalist-select-option-medium {
    .caret,
    .filter-option {
      color: rgb(85, 85, 85);
    }

    .hide {
      display: none;
    }

    .dropdown-menu.open {
      width: 300px;
    }

    @media only screen and (max-width: 1537px) {
      .dropdown-menu.open {
        width: 100%;
      }
    }
  }
}

.datalist.datalist-error {
  button {
    border: 1px solid $bpp-subtle-warning;
  }
}

.css-1fhf3k1-control {
  cursor: not-allowed;
  border-radius: 10px !important;
}
