.csq-label {
  padding-left: 15px;
  margin-bottom: 10px;
  font-size: 16px;
}
.csr-label-radio {
  padding-left: 15px;
  margin-bottom: 14px;
  font-size: 16px;
}

.bpp-csq-label {
  margin-bottom: 10px;
  font-size: 16px;
}

.csq-radio-label {
  padding-left: 10px;
  display: inline-block;
  vertical-align: top;
}

.csq-gray-background {
  padding: 15px 15px 15px 15px;
  // background-color: #f7f7f7;
  background-color: #f2f9fb;
  border-radius: 15px;
  width: 100%;
}

.bpp-csq-gray-background{
  .csq-label{
    font-size: 14px;
  }

  .csq-label.csq-radio-area{
    font-size: 14px;
  }
}

.pr-upload-date-label {
  display: inline-block;
  color: #999999;
}

.pr-upload-date {
  display: inline-block;
  margin-left: 10px;
}

.csq-teeth-selector {
  width: 70%;
  padding-left: 25px;
}

.csq-radio-area {
  cursor: pointer;
}

.csq-radio-date-label {
  padding-left: 23px;
  display: inline-block;
}

.csq-radio-date-field {
  margin-left: 10px;
  display: inline-block;
  margin-bottom: 5px;
}

.csq-upload-heading {
  margin-left: 15px;
}

.csq-mq-section {
  width: 50%;
  display: inline-block;
}

.nopadding {
  padding-left: 0 !important;
  margin-left: 0 !important;
}

.question-padding {
  padding-bottom: 10px;
}

.csr-question {
  padding-bottom: 5px;
}

.csr-answer {
  padding-bottom: 15px;
}
.question-size {
  width: 70%;
}

.csr-confirm-color {
  color: red;
}

.csr-submission {
  .bpp-submit__back {
    left: 124px;
  }
}
.extra-padding-headline-bpp {
  margin-bottom: 20px;
}
