.smile-design-preferences-modal.simple-modal {
  .modal-dialog {
    .modal-content {
      .modal-body {
        .modal-content-body {
          .preferences-content {
            a,
            span,
            .checkbox-response {
              font-weight: bold;
            }

            a {
              text-decoration: underline;
            }

            ol {
              padding-inline-start: 24px;
            }
          }
        }
      }
    }
  }
}

.smile-design-preferences-ipr {
  .modal-content {
    padding: 0 22px;
  }

  #modal_close_btn {
    color: $jade;
    &:hover {
      border: 1px $jade solid;
    }
  }
}
