.review-headers {
  padding-bottom: 5px;
}

.review-headers>h4 {
  margin-top: 0;
  font-size: 16px;
}

.heading-quantity {
  padding-left: 5px;
  padding-right: 5px;
  display: inline-block;
}

.icon-disabled {
  color: gray;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 5px;
  padding-bottom: 5px;
  cursor: default;
}

.main-heading {
  color: #43a7b3;
  // padding-top: 15px;
  padding-bottom: 5px;
}

.main-headline {
  // padding-bottom: 5px;
  font-size: 16px;
}

.item-section {
  padding-bottom: 20px;
}

.noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                        supported by Chrome and Opera */
}

.row.remove-margin {
  margin: 0;
}

.gray-background {
  padding: 15px 15px 15px 15px;
  // background-color: #f7f7f7;
  background-color: #f2f9fb;
  border-radius: 15px;
  width: 65%;
}

.reason-input-data-bpp {
  padding-left: 15px;
}

.reason-checkbox-lebel {
  padding-left: 5px;
  cursor: pointer;
}

.reason-teeth-container {
  padding-left: 4px;
}

.reason-checkbox {
  padding-left: 5px;
  cursor: pointer;
}

.item-reason-comment {
  width: 100%;
  border-radius: 10px;
  border: 1px solid #ccc;
  padding: 10px 10px;
  min-height: 100px;
}

.item-reason-comment-warning {
  width: 100%;
  border-radius: 10px;
  border: 1px solid #e74c3c;
  padding: 10px 10px;
}

.item-reason-headline {
  padding-bottom: 5px;
  // padding-top: 5px;
}

@media screen and (max-height: 768px) {
  .patient__record {
    max-height: 600px;
  }
}

@media only screen and (max-width: 1537px) {
  .item-reason-comment {
    width: 100%;
  }

  .gray-background {
    width: 100%;
  }
}

.review-heading {
  display: inline-block;
  margin-right: 0.5%;
  padding-top: 10px;
  padding-bottom: 5px;
}

.review-item {
  display: inline-block;
  width: 30%;
  padding-bottom: 5px;
}

.review-quantity {
  display: inline-block;
  width: 10%;
  padding-bottom: 5px;
}

.review-reasons {
  padding-bottom: 5px;
  padding-left: 10px;
  font-size: 1.1em;
}

.review-radio {
  display: inline-table;
  vertical-align: top;
}

.review__line-divison {
  border-bottom: 1px solid #d4d4d4;
  margin-top: 5px;
  margin-bottom: 5px;
}

.ir__submit__content {
  padding-top: 10px;
  padding-bottom: 20px;
}

.ir__submit__windows {
  min-height: calc(100vh - 140px);
}

.review-address-format {
  padding-left: 180px;
  padding-bottom: 10px;
}

.review-address-label {
  margin-bottom: 0;
}

.extra-padding-headline {
  margin-bottom: 10px;
}

.extra-padding {
  padding-top: 5px;
}


.main-heading>h4 {
  font-size: 1.6em;
}

.question .teeth-selector .row .col-md-6 {
  padding-left: 15px;
}

.teeth-selector .row .col-md-6 {
  padding-left: 0px;
}
