.disable-account {
  .form-group {
    margin-top: 15px;
    text-align: left;

    .assign-label {
      margin-right: 30px;
    }

    .heading-item-container {
      width: 300px;
      border-radius: 3px;
    }

    .error {
      border-color: red;
    }
  }

  div.case-table {
    overflow-y: scroll;
    max-height: 250px !important;
  }

  table {
    width: 100%;
    padding-left: 16px;
    padding-right: 16px;
    border-collapse: separate;

    thead, tr {
      width: 100%;
    }

    th {
      font-weight: bold;
      padding: 16px 8px 8px 8px;
      border-bottom: 1px solid #e1e2e2;
    }
    
    th:last-child {
      width: 30%;
    }
    th:nth-child(2) {
      width: 30%;
    }

    td {
      text-align: left;
      border-bottom: 1px solid #e1e2e2;
      padding: 6px 15px 6px 15px;
      margin-bottom: 20px;
    }
  }

  .smiles-unassigned-message {
    text-align: left;
  }
}
