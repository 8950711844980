@import "src/doctor/styles/base/_variables";

.wasm-view-initial-stage {
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  bottom: 10px;
  height: 40px;
  width: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $pearl;
  background: $almostBlack;
  border: 1px solid $almostBlack;
  border-radius: 5px;
  user-select: none;

  &:hover {
    border-color: $turquoise;
  }

  &.with-tmt {
    bottom: 5px;
  }

  transition: bottom 1s;
}
