.inline-date-container {
  margin-left: $general-spacing * 2;
  > label,
  span {
    margin-left: 5px;
    margin-right: $general-spacing * 0.5;
  }
  > .input-container {
    display: inline;
    input {
      width: auto;
    }
  }

  &--warning {
    color: $ruby;
  }
}

.header-edit-container {
  margin-top: $general-spacing * 2;

  .header-edit__heading {
    display: inline;
  }
  .header-edit__button {
    text-decoration: underline;
    cursor: pointer;
  }
}

.submission-loader-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-top: 20%;
}

.submission-loader-container .close{
  display: none;
}

#modalContentContainer{
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
 

  #header{    
    color: #8a8a8a;
    font-size: 1.1em;
    margin-top: 0.5em;
    margin-bottom: 1em;
  }
}

.row.equal {
  display: flex;
  flex-wrap: wrap;

  & > [class*='col-'] {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: auto;
  }
}
