@import "../../bpp-variables";

.account-info {
  padding-right: 0;
  margin: 1rem;
}

.form-horizontal .form-group {
  margin: 0;
}

.form-horizontal .form-inline:first-of-type div {
  margin-bottom: 1rem !important;
}

.form-control:focus {
  border-color: #cccccc;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.modal {
  display: block;
}

.modal-footer {
  text-align: center;
}

.account-info .form-inline div {
  padding-right: 18px;
}

.account-info .form-inline:first-of-type {
  margin: 1rem 0;
}

.account-info .form-inline input {
  width: 100%;
}

.account-info .form-inline label {
  padding-left: 0;
  justify-content: flex-start;
  text-align: left;
}

table.info {
  border: 1px solid #e3e3e3;
  border-right: none;
}

table.table-bordered {
  border-right: none;
  border-bottom: none;
}

tr > td:first-child {
  width: 22%;
  min-width: 120px;
}

table.info tr > td:last-child {
  background-color: white;
  border: none;
}

.action-icon {
  cursor: pointer;
  height: 24px;
  margin-right: 0.6rem;
  transform: scale(0.9);
  transition: all 0.1s linear;
}

.action-icon:hover {
  transform: scale(1);
}

.margin-top-1 {
  margin-top: 1rem;
}

.move-left {
  width: auto;
  box-shadow: none;
}

.form-control.select-option {
  display: inline-block;
  width: 100%;
  vertical-align: middle;
}

.page-top-content.page-top-content-bottom-space {
  margin-bottom: 10px;
}

.profile-w-button {
  line-height: 20px;
  vertical-align: middle;
}

.profile-w-button + .profile-info {
  margin-left: 8px;
}

.switch-label {
  position: relative;
  left: -54px;
  top: 2px;
  z-index: 0;
  cursor: pointer;
}

.profile-icons {
  margin-right: 10px;
  cursor: pointer;
  font-size: 18px;
}

.btn.btn-light.btn-link-light-style {
  margin-left: 10px;
}

.modal {
  display: block !important;
  /* I added this to see the modal, you don't need this */
}

/* Important part */
.modal-dialog {
  overflow-y: initial !important;
}

.modal-body {
  overflow-y: auto;
}

.account-logo {
  font-size: 100px;
  background: -webkit-linear-gradient(top, #3ea8d8, #7fcfce 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.account-type-logo {
  font-size: 125px;
  background: -webkit-linear-gradient(top, #3ea8d8, #7fcfce 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  opacity: 0.75;
  cursor: pointer;
  padding-bottom: 5px;
}

.account-type-logo:hover {
  opacity: 1;
}

.account-type-logo-dso {
  font-size: 125px;
  background: -webkit-linear-gradient(top, #28a5ab, #28a5ab 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  opacity: 0.75;
  cursor: pointer;
  padding-bottom: 5px;
}

.account-type-logo-dso:hover {
  opacity: 1;
}

.account-type-logo-dark {
  font-size: 125px;
  background: -webkit-linear-gradient(top, #303131, #d2d7da 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  opacity: 0.75;
  cursor: pointer;
  padding-bottom: 5px;
}

.account-type-logo-dark:hover {
  opacity: 1;
}

#confirmmodal {
  border-radius: 15px;
}

#accountconfirmmodal {
  padding-top: 15px;
  padding-bottom: 15px;
}

#selectaccounttypemmodal {
  padding-bottom: 15px;
}

/* style the create account modal with flexbox
  and add border to each except last one
*/
.account-type-list {
  display: flex;
  justify-content: space-evenly;
  box-sizing: border-box;

  .account-type {
    position: relative;
    text-align: center;
    padding: 20px 0;
    flex: 1 1 auto;
    box-sizing: border-box;

    &:not(:last-child) {
      border-right: 2px solid #e5e5e5;
    }
  }
}

/* Split the screen in half */
.split {
  width: 33%;
  text-align: center;
  padding-bottom: 20px;
  padding-top: 20px;
}

/* Control the left side */
.left {
  float: left;
}

/* Control the right side */
.right {
  float: right;
}

.split.center {
  width: 100%;
}

#selectAccountRoleModal .modal-dialog .modal-content {
  margin-top: 50%;
}

.form-control.datalist-select-option-medium div.inner.open {
  height: calc(100% - 47px);
}

/* Add address table */
table.table-condensed.table-industry.address-table {
  border-collapse: collapse;
  border-spacing: 0;
  margin-bottom: 0px;
}

table.table-condensed.table-industry.address-table-condensed {
  min-height: 245px;
}

table.table-industry.address-table td {
  word-break: break-word;
}
.feature-table {
  border-collapse: separate;
  border-spacing: 0 10px;
  padding: 0px;
}
@media only screen and (min-width: 1537px) {
  .user-error-view {
    margin-top: 5px;
    margin-bottom: 25px;

    .profile-error {
      padding-left: 15px;
    }
  }
}

@media only screen and (max-width: 1537px) {
  .user-error-view {
    margin-top: 5px;
    margin-bottom: 25px;

    .profile-error {
      padding-left: 15px;
    }
  }
}

.social-error-view {
  margin-top: 5px;
  margin-bottom: 25px;

  .profile-error {
    padding-left: 15px;
  }
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.account-heading {
  margin-top: 5px;
  margin-left: 16px;
  margin-bottom: 5px;
  font-weight: bold;
  font-size: 16px;
}

.practice-heading {
  margin-left: 32px;

  &__margin-bottom {
    margin-bottom: -25px;
  }
}

.account-body {
  width: calc(100% - 100px);
  background-color: rgba(17, 17, 17, 0.06);
  border-radius: 10px;
  padding-top: 20px;
  padding-bottom: 20px;
}

.account-error {
  margin-top: 10px;
}

.account-add {
  width: calc(100% - 115px);
  text-align: right;
  min-height: 22px;
}

.account-add-last {
  margin-top: 15px;
}

.account-add:hover {
  cursor: pointer;
  color: #6b7079;
}

.break-word {
  word-break: break-all;
}

@media only screen and (max-width: 1200px) {
  .account-add {
    width: calc(100% - 115px);
  }
}

.btn-clear {
  display: inline-block;
  width: 23px;
  font-size: 18px;
  margin-right: 1px;
  text-align: center;
}

.btn-clear:hover {
  cursor: pointer;
  color: #6b7079;
}

.account-table-start {
  border-radius: 10px 0px 0px 10px;
}

.account-table-end {
  border-radius: 0px 10px 10px 0px;
}

.account-table-round {
  border-radius: 10px 10px 10px 10px;
}

.table > tbody > tr > td.account-table-first {
  padding-left: 16px;
}

.type-width-tb > select.select-filter {
  width: 94px;
}

.account-city {
  display: inline-block;
  
  margin-right: 5px;
  &--us {
    width: calc(100% - 75px);
  }
  &--ous {
    width: 100%;
  }
}

.account-state {
  display: inline-block;
}

tr > td.fourth-width-tb {
  width: calc(25%);
}

.account-label {
  font-weight: bold;
}

table > tbody.table-inbrace-dark-industry-2 > tr.account-start-height {
  height: 50px;
}

table > tbody.table-inbrace-dark-industry-2 > tr.account-start-height > td:nth-child(2) {
  vertical-align: bottom;
}

.account-curve-top-left {
  border-radius: 10px 0px 0px 0px;
  padding-top: 10px !important;
}

.account-curve-top-right {
  border-radius: 0px 10px 0px 0px;
}

.account-curve-bottom-right {
  border-radius: 0px 0px 10px 0px;
}

.account-curve-bottom-left {
  border-radius: 0px 0px 0px 10px;
}

table > tbody.table-inbrace-dark-industry-2 > tr:nth-child(odd) > td.account-table-dark,
table > tbody.table-inbrace-dark-industry-2 > tr:nth-child(even) > td.account-table-dark {
  background-color: #494d51;
  color: #fff;
  text-align: right;
  // padding-right: 10px;
}

.account-checkbox-label {
  display: inline-block;
  vertical-align: top;
}

input[type="checkbox"][disabled].account-checkbox {
  margin-top: 4px;
  margin-left: 10px;
}

input[type="checkbox"].feature-checkbox,
input[type="checkbox"].program__checkbox,
input[type="checkbox"].program-checkbox {
  margin-top: 0px;
  margin-left: 5px;
  vertical-align: middle;
}

.account-plain-text {
  margin-left: 14px;
  display: inline-block;
  line-height: 30px;
  overflow-wrap: anywhere;
}

input.form-control.input-error {
  border: 1px solid #e74b3ae3;
}

.select-edit-input-error {
  border: 1px solid #e74b3ae3;
  border-radius: 10px;
}

.erp-input {
  input {
    border-radius: 10px;
    float: left;
    background-color: $bpp-text-input-disabled-color !important;
    pointer-events: none;
  }

  div:first-child input {
    width: 30%;
    margin-right: 5px;
  }

  div:last-child input {
    width: 68%;
  }
}

.css-g1d714-ValueContainer {
  height: 36px;
}

.feature-table .table-inbrace-dark-industry-2 .opted_in_tr {
  height: 88px;
  width: 100%;

  td {
    vertical-align: initial;
    padding-top: 10px;
  }
}

.feature-table .table-inbrace-dark-industry-2 .opted_in_email_tr {
  position: absolute;
  top: 75px;
  width: calc(100% - 145px);
  td {
    padding-left: 15px;
    background: none;
  }
}

.feature-table .table-inbrace-dark-industry-2 .opted_in_email_tr_with_icon {
  position: absolute;
  top: 70px;
  width: 50%;
  #referral_email {
    position: absolute;
    line-height: 20px;
  }
  td {
    padding: 0 0 0 15px;
    background: none;
  }
}

.feature-table .table-inbrace-dark-industry-2 .opted_in_email_tr td {
  display: inline-block;
}

.referral_email_input_td {
  position: absolute;
  right: 0px;
  display: inline-block;
  width: 65% !important;
  margin-right: 100px;
}

.opted_in_email_td {
  display: inline-table;
}

.referral_email_input {
  font-weight: normal;
  margin-left: 10px;
}

@media screen and (max-width: 1024px) {
  .type-width-tb {
    width: 70% !important;
  }
  .feature-table .table-inbrace-dark-industry-2 .opted_in_tr {
    height: 100px !important;

    td {
      padding-bottom: 40px;
    }
  }
}
