.work-ref-order {
  display: inline-block !important;
  width: 100%;
  text-align: center;
  margin: 0 0 15px 0;

  &--ws {
    margin: 10px 0 15px 25px;
  }

  .work-ref-label {
    display: inline-block;
    width: 210px;
    text-align: right;
    padding-right: 10px;
  }

  .work-ref-input {
    display: inline-block;

    .work-order-ref-textbox {
      &__ws {
        width: 216px !important;
      }

      display: inline-block;
      width: 250px !important;
      border-radius: 10px;
      color: rgb(51, 51, 51);
      height: 38px;
    }

    .work-order-ref-textbox.profile-edit-input-error {
      border: 1px solid #e74b3ae3;
    }
  }

  .work-ref-button {
    position: absolute;
    display: inline-block;
    text-align: right;
    padding: 0px 5px;
  }

  .edit-confirm {
    margin-left: 10px;
  }

  &__read-only {
    padding-right: 144px;
  }
}

.work-ref-order-ir {
  display: inline-block !important;
  text-align: left;
  margin: 0 0 10px 0;

  .work-ref-label {
    display: inline-block;
    width: 145.25px;
    text-align: right;
    padding-left: 10px;
    padding-right: 10px;
  }

  .work-ref-input {
    display: inline-block;
    .work-order-ref-textbox {
      display: inline-block;
      width: 170px !important;
      border-radius: 10px;
      color: rgb(51, 51, 51);
      height: 38px;
    }

    .work-order-ref-textbox.profile-edit-input-error {
      display: inline-block;
      width: 170px !important;
      border-radius: 10px;
      color: rgb(51, 51, 51);
      height: 38px;
      border: 1px solid #e74b3ae3;
    }
  }

  .work-ref-button {
    position: absolute;
    display: inline-block;
    text-align: right;
    padding: 0px 5px;
  }

  .edit-confirm {
    margin-left: 10px;
  }

  .wo-ref-edit-section {
    display: inline-block;
  }

  .wo-ref-edit-window {
    border-radius: 10px;
    width: 170px;
    display: inline-block;
    padding-left: 13px;
    padding-right: 10px;
    min-height: 36px;
    line-height: 36px;
    vertical-align: middle;
    margin-bottom: 1px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    background-color: #afb2b7;
    cursor: pointer;
  }
  .wo-ref-edit-window-disabled {
    border-radius: 10px;
    width: 170px;
    display: inline-block;
    padding-left: 13px;
    padding-right: 10px;
    min-height: 36px;
    line-height: 36px;
    vertical-align: middle;
    margin-bottom: 1px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    background-color: #afb2b7;
    cursor: not-allowed;
  }
}

.unassigned-width {
  display: inline-block;
  width: 250px;
  text-align: left;
}

.production-label > .work-ref-label {
  width: 245px;
}
