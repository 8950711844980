$dark-gray: #303235;
$light-gray: #394042;
$gray: #383c3f;

.manual-wire-table {     
    display: grid;
    grid-template-columns: auto 1fr;
    border: 1px solid $dark-gray;
    width: 100%;
    max-width: 100%;

    &::-webkit-scrollbar {
        width: 12px;
        height: 12px;
        background: #f1f1f1;
    }

    &::-webkit-scrollbar-track {
        background: #f1f1f1;
    }

    &::-webkit-scrollbar-thumb {
        background: #888;
        border-radius: 6px;
    }

    &::-webkit-scrollbar-thumb:hover {
        background: #555;
    }

    .manual-wire-table-header {
        grid-column: 1 / 2;
        display: grid;
        grid-auto-flow: row;
        background: $dark-gray;
        padding: 0px;
        margin: 0px;
        width: 100%;
        max-width: 100%;
        height: 100%;
        grid-template-rows: 40px 40px 40px 60px 65px;
        box-sizing: border-box;
        &.with-generated-length {
          grid-template-rows: 40px 40px 40px 60px 45px 65px;
        }
      }

      .header-item,
      .manual-wire-table-header-element {
        box-sizing: border-box;
        padding: 10px;
      }
      
      .header-item {
        border: 1px solid $light-gray;
        display: flex;
        flex-direction: row;
        .header-offset-item {
            align-self: center;
        }
      }

      .loop-item,
      .length-item {
        background-color: $light-gray;
      }
      
      .manual-wire-table-content {
        grid-column: 2 / 2;
        display: grid;
        grid-auto-flow: row;
        overflow-x: auto;
        grid-template-rows: 40px 40px 40px 60px 45px;
        &.with-generated-length {
          grid-template-rows: 40px 40px 40px 60px 45px 45px;
        }
      }      
}
