// =============================================================================
// Anchors Followed by
// =============================================================================
a + a {
  margin-bottom: $general-spacing;
}
// =============================================================================
// Button Followed by
// =============================================================================
button + div {
  margin-top: $general-spacing;
}

button + a > button {
  vertical-align: bottom;
}

// =============================================================================
// Division Followed by
// =============================================================================
div + button {
  margin-top: $general-spacing;
}

div + .footer-center {
  margin-top: $general-spacing;
}

div + label {
  margin-top: $general-spacing;
}

// =============================================================================
// Form Followed by
// =============================================================================
form + p.heading--warning {
  margin-top: $general-spacing * 2;
}

// =============================================================================
// Heading Followed by
// =============================================================================

@media (max-width: 770px) {
  h1 + button {
    margin-bottom: 8px;
  }
  .smile_name {
    font-weight: bold;
  }
}

h3 + label,
h3 + div {
  margin-top: $general-spacing * 2;
}

h3 + div.edit-table, h3 + div.address, h3 + div.col-md-6 {
  margin-top: 0px;
}

h3 + p {
  margin-top: $general-spacing*0.5;
  margin-bottom: 0px;
}

h3 + .alert {
  margin-top: $general-spacing*2;
}
// =============================================================================
// Inputs Followed by
// =============================================================================
.indent {
  margin-left: $general-spacing;
}

input + input,
.input-container + .input-container {
  margin-top: $general-spacing;
}

input + a,
input + button,
.input-container + a {
  margin-top: $general-spacing;
  margin-bottom: $general-spacing * 2;
}

.input-container + button {
  margin-top: $general-spacing * 2;
  margin-bottom: $general-spacing;
}

input + label,
.input-container + label {
  margin-top: $general-spacing;
}
// =============================================================================
// Label Followed by
// =============================================================================
label + div {
  select {
    margin-bottom: $general-spacing;
  }
}

// =============================================================================
// Paragraph Followed by
// =============================================================================
p + button {
  margin-top: $general-spacing;
}

p + div {
  margin-top: $general-spacing;
}

p + label {
  margin-top: calc(#{$general-spacing} * 2);
}

p + p {
  margin-top: $general-spacing*0.5;
  margin-bottom: 0px;
}

// =============================================================================
// Paragraph Followed by
// =============================================================================
svg[data-icon='file-archive'],  svg[data-icon='file-pdf'], svg[data-icon='file-video']{
  margin-right: $general-spacing;
}

// =============================================================================
// Specials Classes Followed by
// =============================================================================
.badge + button {
  margin-top: $general-spacing;
}

.card + .card {
  margin-top: $general-spacing;
}

.description-detail + .sub-cat {
  margin-top: $general-spacing;
}

.dso-account-profile > .account__section:last-child{
  padding-bottom: 0px;
}

.dso-role + a {
  margin-top: $general-spacing;
  display: block;
}

.edit-table--item {
  & + button {
    margin: 0px;
    margin-top: -$general-spacing;

    & + button {
      margin-top: -$general-spacing;

      & + button {
        margin-top: -$general-spacing;
      }
    }
  }

  .dropdown.heading-value-dropdown {
    margin-top: -1px;
    margin-left: 0px;
  }

  .input-container {
    //Custom adjustment
    margin-left: -17px;
    margin-top: -6px;
    margin-right: 8px;
  }
}

.input-container + .dso-role {
  margin-top: $general-spacing;
}

.block--right > button {
  margin-top: $general-spacing;
}

.stretch {
  margin-top: $general-spacing*2;
}

.navbar__menu__item__label > .badge {
  margin-left: $general-spacing;
}

.prospect-detail-heading-item > h3 {
  margin-left: 8px;
}

.react-autosuggest__container + .card {
  margin-top: $general-spacing;
}

.search + .dropdown-input {
  margin-top: $general-spacing;
}

.search-container + .card {
  margin-top: $general-spacing;
}

.spinner-container + p {
  margin-top: $general-spacing;
}

.statuses + .statuses-result {
  margin-left: calc(#{$general-spacing} * 2);
}

.statuses-result {
  .col-md-4,
  .col-md-6,
  .col-md-8 {
    padding-left: 0px;
    padding-right: 0px;
  }
}

.statuses-result + .pagination__container {
  margin-top: $general-spacing;
}

.statuses-result > .card.card--top-border {
  margin-top: 0px;
  width: 100%;
}


@include for-size(tablet) {
  .statuses + .statuses-result {
    margin-left: 0px;
    margin-top: $general-spacing;
  }

  .statuses-result {
    width: 100%;
  }
}

@include for-size(phone) {
  .statuses-result + .pagination__container {
    margin-left: -$general-spacing;
    margin-right: -$general-spacing;
  }
}
