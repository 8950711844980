@import '../../bpp-variables';

.expedite-details:first-child {
  margin-top: 20px;
  margin-left: 15%;
}
.expedite-details {
  margin-left: 2%;
  text-align: left;

  .inline-link {
    &__smile_design {
      float: none;
      margin-left: 35px;
    }
  }
  .inline-input {
    .target-date-input {
      display: inline-block;
    }

    &__error {
      border-color: $bpp-subtle-warning-new;
    }

    span {
      i {
        margin-left: 8px;
        cursor: pointer;
      }
    }

    textarea {
      width: 275px;
    }

    .shipping-methods-input {
      width: 173px;
    }
  }

  &__shipping-address {
    width: 250px;
  }
}

.modal-error {
  padding-left: 15px;
  padding-right: 15px;
}

.expedite-details:last-child {
  margin-bottom: 0px;
}

.expedite-details > label {
  text-align: right;
}

.expedite-details select {
  padding: 5px;
  border-radius: 4px;
  border: 1px solid #ccc;
  color: #303235;
}

.expedite-details textarea {
  width: 275px;
}

.expedite-details .inline-label {
  width: 220px !important;
}

.text-top {
  vertical-align: top;
}

.expedite-approval-header {
  background-color: #ededed;
  padding: 10px 0 10px 0;
  border-radius: 8px;
  margin-bottom: 15px;
}

.cancel-expedite {
  float: right;
  text-decoration: underline;
  color: #303235;
  margin-right: 5px;
}

.cancel-expedite-disabled {
  cursor: not-allowed;
  float: right;
  text-decoration: underline;
  color: #303235;
  margin-right: 5px;
}

.additional {
  margin-top: 6px;
}

.inline-link {
  float: right;
  vertical-align: top;
}

.update-shipping-anchor {
  text-decoration: underline;
  cursor: pointer;
  color: #303235;
}
