@use "sass:math";

$node_size: 23;
$node_spacing: 0;
$max_nodes: 10;
$max_list_size: 400;
$last_child: 0;
$success_color: #1b8e60;
$failure_color: #e36666;

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

@keyframes border-pulsate {
  0% {
    border-color: rgba(0, 160, 223, 1);
  }
  50% {
    border-color: rgba(0, 160, 223, 0);
  }
  100% {
    border-color: rgba(0, 160, 223, 1);
  }
}

.process_tracker {
  .margin-top-20 {
    margin-top: 20px;
  }

  .hidden {
    display: none;
  }

  hr {
    border-top: 0.7px solid rgb(158, 163, 168);
    padding: 0 10px;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  ul.workflow {
    width: $max_list_size * 1px;
    margin: 0 auto;
    padding: 0;
    padding-top: 54px;
    li {
      width: $node_size * 1px;
      height: $node_size * 1px;
      line-height: $node_size * 1px;
      font-size: 12px;
      font-weight: bold;
      text-align: center;
      border-radius: 50%;
      background: #fff;
      display: inline-block;
      color: #000;
      position: relative;
      margin-top: 0;
      margin-bottom: 0;
      margin-left: 0;
      border: 1px solid #9ea3a8;

      &:before {
        content: '';
        position: absolute;
        top: 50%;
        left: -100%;
        width: 100%;
        height: 1px;
        background: #9ea3a8;
      }

      &.process-toggle {
        cursor: pointer;

        &__disabled {
          cursor: default;
          pointer-events: none;
        }
      }

      p.process-toggle__label {
        width: 55px !important;
        left: -18px !important;
        padding: 0px !important;
      }
    }

    @for $i from 1 through $max_nodes {
      $node_factor: if($i - 1 > 0, $i - 1, 1);
      $node_spacing: math.div($max_list_size - ($i * $node_size), $node_factor);
      li:first-child:nth-last-child(#{$i}),
      li:first-child:nth-last-child(#{$i}) ~ li:not(:last-child) {
        margin-right: $node_spacing + 0px;
      }

      li:first-child:nth-last-child(#{$i}),
      li:first-child:nth-last-child(#{$i}) ~ li {
        &:before {
          left: -$node_spacing - 1px;
          width: $node_spacing + 0px;
        }
      }
    }

    li:first-child {
      &:before {
        display: none;
      }
    }

    li p {
      position: relative;
      top: -50px;
      left: -37px;
      text-align: center;
      width: 100px;
      color: inherit;
      padding: 0px 10px 0px 10px;
      line-height: 18px;
    }

    .production {
      line-height: 50px;
    }

    li.process-failure,
    li.process-halted {
      background: #e36666;
      border-color: #e36666;
    }

    li.process-success,
    li.process-done {
      background: #1b8e60;
      border-color: #1b8e60;
    }

    li.process-in-progress {
      box-shadow: 0 0 10px #00a0df;
      animation: border-pulsate 1.5s infinite;
    }

    li.process-disabled {
      background: #9ea3a8;
      border-color: #9ea3a8;
    }

    li.process-in-progress,
    li.process-success,
    li.process-failure {
      p {
        font-weight: bold;

        &.font-weight-normal {
          font-weight: normal !important;
        }
      }
    }

    li.process,
    li.process-next {
      background: #fff;
    }
  }

  p {
    color: #000;
    text-align: center;
    line-height: 12px;
  }

  .rotate {
    animation: rotation 3s infinite linear;
  }

  .center {
    margin: 0 auto;
    display: block;
  }

  .loader {
    margin-top: 20px;
    margin-bottom: 10px;
  }

  button.download-link {
    background: none !important;
    border: none;
    padding: 0 !important;
    color: #337ab7;
    text-decoration: none;
    cursor: pointer;
  }

  button.next-process-btn {
    font-size: 40px;
    color: #fff;
    border-radius: 15px;
    border: none;
    background-color: #000;
    padding: 0px 50px;
    margin: 10px;
  }

  .toggle {
    &__cad {
      width: 400px;
      margin: 0 auto;

      &__box {
        width: 165px;
        height: 100px;
        border: 1px dashed;
        margin-top: -70px;
        margin-left: 41%;

        button {
          color: white;
          background-color: #323539;
          width: 94px;
          font-size: 11px;
          padding: 0 5px 0 5px;
          margin-top: 40px;
          margin-left: 36px;
          border-radius: 7px;
          border: none;
          cursor: pointer;
          &.disabled {
            opacity: 0.65;
          }
        }

        i.fa-exchange {
          margin-left: 70px;
          margin-top: 10px;
          font-size: 20px;
          cursor: pointer;
        }

        &__no-toggle {
          margin-top: 73px !important;
        }
      }
    }

    &__production {
      width: 560px;
      margin: 0 auto;

      &__box {
        height: 135px;
        border: 1px dashed;
        border-radius: 10px;
        margin-top: -90px;
        margin-left: 30px;

        i.fa-exchange {
          margin-left: 48%;
          margin-top: 27px;
          font-size: 20px;
          cursor: pointer;

          &.toggle-disabled {
            cursor: default;
            pointer-events: none;
          }
        }

        button {
          color: white;
          background-color: #323539;
          width: 55px;
          font-size: 14px;
          padding: 0 5px 0 5px;
          margin-top: 47px;
          margin-left: 78%;
          border-radius: 7px;
          border: none;
          cursor: pointer;
          height: 27px;
        }
      }
    }

    &__hide {
      display: none;
    }
  }
}
