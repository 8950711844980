$scrollbar_color: rgba(black, 0.1);
$scrollbar_thumb_color: #707070;
$scrollbar_hover_color: rgba(black, 0.5);
$scrollbar_border_radius: 10px;

.agreement_container ::-webkit-scrollbar {
  height: 6px;
  width: 6px;
  border-radius: $scrollbar_border_radius;
}
.agreement_container ::-webkit-scrollbar-thumb {
  background-color: $scrollbar_thumb_color;
  border-radius: $scrollbar_border_radius;
}

.agreement_container ::-webkit-scrollbar {
  background-color: rgba(64, 64, 65, 0.137);
  height: 6px;
  width: 6px;
  border-radius: 3px;
}

.agreement_container ::-webkit-scrollbar-thumb:hover {
  background-color: $scrollbar_hover_color;
}

.program_container {
  width: 100%;
  display: inline-flex;
  vertical-align: top;
  justify-content: flex-start;
  position: relative;

  .background--wires--yellow {
    background-image: url('../../../public/static/img/wiresYellow.png');
    background-position: top right;
    background-repeat: no-repeat;
  }

  a {
    text-decoration: underline;
  }

  input:checked + .slider {
    background-color: #489599;
  }

  .card-container {
    display: flex;

    .program_selections {
      width: 30%;
      max-width: 350px;
      border-right: solid #7070703d;
      .switch {
        margin-top: 5px;
      }
      .pg_selection_status {
        color: white;
      }

      .selection-area--primary {
        box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.05), 0 4px 4px 0 rgba(0, 0, 0, 0.05);
      }
    }

    .program_content {
      border-left: 2px solid #80808021;
      padding-left: 20px;
      max-width: 100vw;

      .line-division--limited {
        margin-right: 10px;
        border-bottom: 4px solid #d6e264;
      }
      .pg_content_light_header {
        font-weight: bold;
        font-family: Roboto, sans-serif;
        font-size: 1em;
        color: #707070;
        line-height: 24px;
      }

      .pg_content_header {
        font-weight: bold;
        color: #303235;
        font-family: Roboto, sans-serif;
        font-size: 1em;
        line-height: 24px;
      }

      .input-container {
        width: 300px;
      }
      .workflow_container {
        margin-bottom: 10px;
        display: inline-block;
      }

      .red_input_boarder {
        border: 1px solid #e34558;
      }

      .box {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 140px;
        height: 120px;
        background-color: black;
        color: #fff;
        text-align: center;
        line-height: 100px;
        position: relative;
        margin-left: 25px;
        padding: 0px 10px;
        border: none;
        float: left;
      }

      .rectangle {
        width: 15px;
        height: 15px;
        position: absolute;
        background: black;
        top: 52px;
        right: -14px;
      }
      .triangle-right {
        position: absolute;
        width: 15px;
        height: 20px;
        right: -28px;
        top: 45px;
        background-color: transparent !important;
        border-top: 15px solid transparent;
        border-right: none;
        border-left: 15px solid black;
        border-bottom: 15px solid transparent;
      }
      .workflow {
        span {
          font-size: 17px;
        }
        div:nth-of-type(1) {
          background-color: #C9E63F;
          span {
            color: black;
            font-weight: bold;
          }
          z-index: 5;
          .rectangle {
            background-color: #C9E63F;
          }
          .triangle-right {
            border-left: 15px solid #C9E63F;
          }
        }
        div:nth-of-type(2) {
          background-color: #2DA4AD;
          span {
            color: white;
            font-weight: bold;
          }
          z-index: 4;
          .rectangle {
            background-color: #2DA4AD;
          }
          .triangle-right {
            border-left: 15px solid #2DA4AD;
          }
        }
        div:nth-of-type(3) {
          background-color: #211b57;
          span {
            color: white;
            font-weight: bold;
          }
          z-index: 3;
          .rectangle {
            background-color: #211b57;
          }
          .triangle-right {
            border-left: 15px solid #211b57;
          }
        }
        div:nth-of-type(4) {
          background-color: #303235;
          span {
            color: white;
            font-weight: bold;
          }
          z-index: 2;
          .rectangle {
            background-color: #303235;
          }
          .triangle-right {
            border-left: 15px solid #303235;
          }
        }
        div:nth-of-type(5) {
          background-color: #e13146;
          span {
            color: white;
            font-weight: bold;
          }
          z-index: 1;
          .rectangle {
            background-color: #e13146;
          }
          .triangle-right {
            border-left: 15px solid #e13146;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1160px) {
  main {
    width: 100%;
  }

  .content {
    width: fit-content;
  }
  .program_container {
    width: fit-content;
  }
  .workflow {
    width: 600px;

    div:nth-of-type(4) {
      margin-top: 20px;
    }

    div:nth-of-type(5) {
      margin-top: 20px;
    }
  }
}

@media screen and (max-width: 800px) {
  .workflow {
    width: auto;

    .box {
      left: 10px;
    }

    .rectangle {
      display: none;
    }
    .triangle-right {
      display: none;
    }

    div:nth-of-type(4) {
      margin-top: 0px;
    }

    div:nth-of-type(5) {
      margin-top: 0px;
    }
  }

  .selection-area--icon {
    display: none;
  }
}
