.inputfile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.inputfile + label {
  font-size: 1.25em;
  font-weight: 700;
  color: white;
  background-color: #32a1d9;
  display: inline-block;
  padding: 15px 40px;
  border-radius: 40px;
}

.inputfile:focus + label,
.inputfile + label:hover {
  background-color: #018abe;
}

.inputfile + label {
  cursor: pointer;
}

.fileUpload-button {
  font-size: 1.25em;
  font-weight: 700;
  color: white;
  background-color: #32a1d9;
  display: inline-block;
  padding: 15px 40px;
  border-radius: 40px;
  border: none;
}

.fileUpload-button:hover {
  background-color: #018abe;
  cursor: pointer;
}

.fileUpload-box {
  height: 380px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 10px;
  text-align: center;
  /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.19); */
  cursor: pointer;
}

.fileUpload-box:hover {
  border: 1px solid #43a7b3;
  transition: all 0.3s;
}

.bpp-fileUpload-box {
  height: 380px;
  border: 1px solid #adb0b4;
  border-radius: 10px;
  text-align: center;
  /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.19); */
  cursor: pointer;
}

.bpp-fileUpload-box:hover {
  border: 1px solid #43a7b3;
  transition: all 0.3s;
}

.bpp-hr {
  border-color: #adb0b4;
}

.fileUpload-box-sm {
  height: 200px;
  border: 1px solid #999;
  border-radius: 10px;
  text-align: center;
}

.fileUpload-box-full {
  height: 60vh;
  width: 100%;
  border: 1px solid #999;
  border-radius: 10px;
  text-align: center;
}

.content-middle {
  position: relative;
  top: 50%;
  -webkit-transform: translateY(50%);
  -ms-transform: translateY(50%);
  transform: translateY(50%);
}

.content-middle {
  position: relative;
  top: 50%;
  -webkit-transform: translateY(50%);
  -ms-transform: translateY(50%);
  transform: translateY(50%);
}

.fileUpload-content-left {
  text-align: left;
  padding: 20px 40px 0px 40px;
}

.fileUpload-content-condense {
  margin-bottom: 2px;
}

.upload-button {
  font-size: 26px;
  color: #32a1d9;
}

.upload-button:hover {
  color: #018abe;
  cursor: pointer;
}

.dashed-box {
  border: 1px dashed #999999;
  padding: 20px 10px;
  margin: 10px 0px;
  height: 180px;
}

.fileUpload-previews {
  display: flex;
  flex-wrap: wrap;
}

.centered {
  width: auto;
  margin: auto;
}

.image-box {
  position: relative;
  margin: 10px;
  width: fit-content;
}

.img-name-position {
  display: block;
  text-align: center;
  margin-top: -75px;
  max-width: 280px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0 13.5%;
}

.img-name-text {
  color: #999999;
  transition: all 0.25s;
}

.img-name-text:hover {
  color: #ffffff;
  transition: all 0.25s;
}

.dashed-box-2 {
  border: 1px dashed #999999;
  padding: 20px 10px;
  margin: 10px 0px;
  height: 120px;
}

.remove-location {
  position: absolute;
  top: 5px;
  right: 1px;
}

.remove-location-ifu {
  position: absolute;
  bottom: 20px;
  right: 20px;
}

.file-upload-remove {
  position: absolute;
  left: -8px;
  top: -4px;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  cursor: pointer;
}

.fa.fa-trash-o.rejected-text.font-size-lg:hover {
  font-size: 24px;
}

.ip-worksheet-zone > .font-size-lg {
  font-size: 34px;
}

.fileUpload-warning {
  color: #e74c3c;
  text-align: center;
}

.fileUpload-warning.hide {
  display: none;
}

.img-preview {
  max-width: 100%;
  max-height: 128px;
}

.img-preview.img-sm {
  max-height: 128px;
}

.img-preview-tiny {
  width: 100%;
  max-height: 90px;
  max-width: 100%;
}

.img-excel {
  height: 128px;
  width: 125px;
  background-color: rgb(219, 238, 252);
  border-radius: 10px;
  font-size: 100px;

  svg {
    margin: 12px 10px 10px 10px;
    color: #000000;
  }
}

.font-size-40 {
  font-size: 40px;
}
.ifu-upload-button {
  width: 127px;
  border: 1px solid #000 !important;
  text-align: center;
  border-radius: 10px;
  color: #000000;
  font-size: 24px;
  height: 145px;
  padding: 0px;
  margin-left: auto;
  margin-right: auto;
  cursor: pointer;
}

.img-ifu {
  height: 150px;
  width: 128px;
  background-color: rgb(219, 238, 252);
  border-radius: 10px;
  font-size: 100px;
  color: #000000;
  padding-top: 25px;
}

.loading_text {
  display: contents;
  width: 400px;
}

.loading-file-name {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 400px;
  max-width: 400px;
  margin: 0px auto;
}

.upload-button {
  .cls-1 {
    fill: #383c3f;
  }
}

.navigator_upload {
  margin-right: 15px;
}

.file-upload-zone-container {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: stretch;
}

@media screen and (max-width: 400px) {
  .loading_text {
    display: flex;
    justify-content: center;
    width: auto;
    max-width: 100%;
  }
  .loading-file-name {
    width: 50%;
  }
}

.idb-error {
  margin-top:10px;
  color: #e74b3ae3 !important;
}