.disabled-save {
  cursor: not-allowed;
}

.portal-view .multi-step .btn.btn-prev.btn-primary.btn-lg.pull-right {
  width: 80px;
  height: 80px;
  background-color: #32a1d9;
  color: #ffffff;
  border-radius: 40px;
  border: 1px solid #32a1d9;
  font-size: 40px;
  float: none !important;
  margin-left: 20px;
}

.portal-view .footer-buttons .btn.btn-next.btn-primary.btn-lg.pull-left {
  width: 80px;
  height: 80px;
  background-color: #32a1d9;
  color: #ffffff;
  border-radius: 40px;
  border: 1px solid #32a1d9;
  font-size: 40px;
  float: none !important;
  margin-right: 20px;
}

.portal-view .footer-buttons {
  text-align: center;
}

.portal-view.portal-view-offset {
  padding-top: 136px;
}

.portal-view.portal-view-offset-2 {
  padding-top: 118px;
}

ol.progtrckr {
  list-style-type: none;
  padding: 0;
}

ol.progtrckr li {
  display: inline-block;
  text-align: center;
  line-height: 4.5rem;
  cursor: pointer;
}

ol.progtrckr li span {
  padding: 0 1.5rem;
}

.tx-de-3-auxiliaries-title {
  max-width: 680px;
}

@media (max-width: 650px) {
  .progtrckr li span {
    display: none;
  }

  .nav-tabs.wizard li {
    border-radius: 20px;
  }
}
.progtrckr em {
  display: none;
  font-weight: 700;
  padding-left: 1rem;
}

@media (max-width: 650px) {
  .progtrckr em {
    display: inline;
  }
}

ol.progtrckr li.progtrckr-todo {
  color: silver;
  border-bottom: 4px solid silver;
}

ol.progtrckr li.progtrckr-doing {
  color: black;
  border-bottom: 4px solid #cccccc;
}

ol.progtrckr li.progtrckr-done {
  color: black;
  border-bottom: 4px solid #5cb85c;
}

ol.progtrckr li:after {
  content: '\00a0\00a0';
}

ol.progtrckr li:before {
  position: relative;
  bottom: -3.7rem;
  float: left;
  left: 50%;
}

ol.progtrckr li.progtrckr-todo:before {
  content: '\039F';
  color: silver;
  background-color: white;
  width: 1.2em;
  line-height: 1.4em;
}

ol.progtrckr li.progtrckr-todo:hover:before {
  color: #ff4500;
}

ol.progtrckr li.progtrckr-doing:before {
  content: '\2022';
  color: white;
  background-color: #cccccc;
  width: 1.2em;
  line-height: 1.2em;
  border-radius: 1.2em;
}

ol.progtrckr li.progtrckr-doing:hover:before {
  color: #ff4500;
}

ol.progtrckr li.progtrckr-done:before {
  content: '\2713';
  color: white;
  background-color: #5cb85c;
  width: 1.2em;
  line-height: 1.2em;
  border-radius: 1.2em;
}

ol.progtrckr li.progtrckr-done:hover:before {
  color: #303235;
}

.btn.btn-xs.btn-save {
  float: right;
  margin-right: 80px;
  margin-top: -25px;
  padding-left: 12px;
  padding-right: 12px;
  border-radius: 10px;
  background-color: #e8f6f6;
  color: #32a1d9;
}

.btn.btn-xs.btn-save:hover {
  cursor: pointer;
}

/* Warning Display */
#warning-submit {
  padding-top: 10px;
}

.warning-submit {
  display: none;
  color: #e74c3c;
  text-align: center;
  padding: 5px;
  border-radius: 10px;
}

.warning-submit.warning-display,
.warning-submit.warning-display {
  display: block;
}

.warning-border,
.dxtx-comment.warning-border,
.dxtx-group-box.warning-border,
.address-area.warning-border,
.item-reason-comment.warning-border {
  border: 1px solid #e74c3c;
}

.checkbox_warning-border {
  box-shadow: inset 0 0 0 1px #e34558 !important;
}

.wizard-error {
  position: fixed;
  bottom: 55px;
  right: 35px;
  border: 1px solid #ca3b27;
  border-radius: 10px;
  display: block;
  -webkit-transition: display 0.3s; /* Safari */
  transition: display 0.3s;
  background-color: #ffffff;
  -webkit-transition: opacity 0.3s ease-in;
  -moz-transition: opacity 0.3s ease-in;
  -o-transition: opacity 0.3s ease-in;
  opacity: 1;
  max-width: 300px;
}

.wizard-error-text {
  color: #e74b3a;
  padding-left: 25px;
}

.wizard-error-text a {
  color: #e74b3a;
}

.wizard-error-text a:hover {
  color: #ca3b27;
  text-decoration: underline;
}

.dxtx-heading.warning-text,
.warning-text,
.name-warning-text {
  color: #e74c3c;
}

.name-warning-text {
  padding-left: 10px;
}

.wizard-bottom {
  position: relative;
  bottom: 0px;
  width: 100%;
  text-align: center;
  border-top: 1px solid #d4d4d4;
}

.wizard-bottom-btn {
  padding: 10px 20px;
  font-size: 15px;
  cursor: pointer;
  display: inline-block;
}

.wizard-bottom-v-center {
  line-height: 36px;
  vertical-align: middle;
}

.wizard-bottom-btn-primary {
  color: #32a1d9;
}

.wizard-bottom-btn-danger {
  color: #e74b3a;
}

.wizard-bottom-btn-primary:hover {
  color: #018abe;
  text-decoration: underline;
}

.wizard-bottom-btn-danger:hover {
  color: #ca3b27;
  text-decoration: underline;
}

.wizard-bottom-btn-back {
  position: absolute;
  bottom: -60px;
  left: 41.25%;
}

.wizard-bottom-btn-next {
  position: absolute;
  bottom: -60px;
  left: 51.25%;
}

.wizard-next {
  padding: 0px 20px;
}

.wizard-back {
  padding: 0px 20px;
}

.wizard-error-title-main {
  margin-left: 10px;
  color: #ffffff !important;
}

.wizard-error-title {
  border-radius: 8px 8px 0px 0px;
  background-color: #e74b3a;
  padding: 5px;
  font-weight: bold;
  color: #ffffff !important;
  min-width: 200px;
  line-height: 20px;
  vertical-align: middle;
}

.wizard-hidden {
  display: none;
  opacity: 0;
}

.wizard-center {
  bottom: 300px;
  width: calc(100%);
  text-align: center;
}

.submit__content {
  color: #575e65;
  /* margin: 0px 15px 10px 15px; */
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.08), 0 2px 20px 0 rgba(0, 0, 0, 0.02);
  min-height: calc(100vh - 106px);
}

.submit__content.row {
  margin-left: 0px;
  margin-right: 0px;
}

.submit__next,
.submit__back {
  font-size: 2.4em;
  position: absolute;
  top: 50%;
  z-index: 13;
  cursor: pointer;
}

.bpp-submit__next,
.bpp-submit__back {
  font-size: 2.4em;
  position: absolute;
  top: 50%;
  z-index: 13;
  cursor: pointer;
}

.submit__back {
  left: 35px;
}

.bpp-submit__back {
  left: 144px;
}

.submit__next {
  right: 35px;
}

.bpp-submit__next {
  right: 62px;
}

@media only screen and (max-width: 1537px) {
  .bpp-submit__back {
    left: 124px;
  }

  .bpp-submit__next{
    right: 52px;
  }
}

.submit__next > i,
.submit__back > i {
  color: #43a7b3;
  background: #43a7b3;
  background: linear-gradient(45deg, #7fcfce, #3ea8d8);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  cursor: pointer;
}

.bpp-submit__next > i,
.bpp-submit__back > i {
  color: #303235;
  background: #303235;
  -webkit-background-clip: text;
  cursor: pointer;
}

.submit__remove {
  position: absolute;
  right: 0;
  bottom: 0;
  margin-right: 20px;
  z-index: 1;
}

.submit__remove > div > i {
  display: inline-block;
}

.submit__remove > div > div {
  display: inline-block;
  padding-left: 5px;
}

.submit__remove > div > div:hover {
  text-decoration: underline;
}

.submit__window {
  height: calc(100vh - 225px);
  overflow-y: auto;
}

.submit__window::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background-color: #f5f5f5;
}

.submit__window::-webkit-scrollbar {
  width: 12px;
  background-color: #f5f5f5;
}

.submit__window::-webkit-scrollbar-thumb {
  background-color: #d4d4d4;
  border-radius: 10px;
}

.submit__window > .portal-view {
  padding-top: 5px;
}

.list-spaced > ul {
  margin-top: 8px;
  // margin-left: 10px;
}

.list-spaced > ul > li {
  line-height: 20px;
}

.guildline-heading {
  font-size: 18px;
}

.modal__recommendation--size {
  margin-top: 80px;
}

.modal-grey {
  color: #575e65;
}

.next-back-buttons {
  font-size: 14px;
  color: #43a7b3;
  cursor: pointer;
}

.next-back-buttons:hover {
  color: #656b6d;
}

.bpp-next-back-buttons {
  font-size: 14px;
  color: #303235;
  cursor: pointer;
  text-decoration: underline;
}

.bpp-next-back-buttons:hover {
  color: #656b6d;
}

.not-allowed {
  cursor: not-allowed;
}

.attribute_header{
  margin-top: 15px;
}
