%row-item-hover-color {
  color: $pearl;
}

%row-item-hover-background-color {
  background-color: $wave;
}

%row-item-padding-x {
  padding-left: 24px;
  padding-right: 24px;
}

%row-item-padding-y {
  padding-top: 4px;
  padding-bottom: 4px;
}

%row-item-padding {
  @extend %row-item-padding-x;
  @extend %row-item-padding-y;
}

.help-sidebar {
  transition-property: transform, opacity, width, z-index;
  transition-duration: 0.3s;
  transition-timing-function: ease;

  h2 {
    color: $almostBlack;
  }

  align-items: flex-end;
  backdrop-filter: blur(1px);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  position: fixed;
  right: 0;
  top: 0;

  &__aside {
    background: $pearl;
    box-shadow: -4px 0px 5px rgb(0 0 0 / 10%);
    box-sizing: border-box;
    min-height: 100%;
    overflow: scroll;
    padding-bottom: 24px;
    padding-top: 24px;
    transition-duration: 0.3s;
    transition-property: transform, opacity, width, z-index;
    transition-timing-function: ease;

    .aside__row {
      border-bottom: 1px solid $platinum;

      &--last {
        border-bottom: none;
      }

      &--header-fullwidth {
        border-bottom: none;
        padding-bottom: 16px;
      }

      &--last {
        padding-bottom: 32px;
      }

      .row {
        &__header {
          .header-text {
            @extend %row-item-padding-x;

            align-items: baseline;
            color: $almostBlack;
            display: flex;
            font-size: 16px;
            font-weight: 700;
            justify-content: space-between;
            line-height: 24px;
            color: $sonicSilver;

            .header__close-icon {
              font-size: 16px;
              color: $sonicSilver;
              cursor: pointer;

              &:hover {
                color: $almostBlack;
              }
            }

            &:hover {
              color: $sonicSilver;
            }
          }

          .header-text--fullwidth {
            column-gap: 10px;
            cursor: pointer;
            font-weight: 400;
            justify-content: flex-start;
            align-items: center;
            padding-left: 14px;
            padding-right: 14px;

            .header__go-back-icon {
              font-size: 24px;
            }
          }
        }

        &__content {
          display: flex;
          flex-direction: column;
          color: $sonicSilver;
          padding-bottom: 6px;
          padding-top: 6px;

          * {
            margin: 0;
            transition: color, background-color 0.3s ease;
          }

          .give-your-feedback-action {
            @extend %row-item-padding;

            display: flex;
            column-gap: 4px;
            align-items: baseline;
            cursor: pointer;

            &--disabled {
              p {
                cursor: not-allowed;
                color: rgba(112, 112, 112, 0.7);
              }
            }

            &:not(.give-your-feedback-action--disabled):hover {
              @extend %row-item-hover-background-color;
              i,
              p {
                @extend %row-item-hover-color;
              }
            }
          }

          .content {
            &__title {
              @extend %row-item-padding;

              font-size: 16px;
              font-weight: 700;
              line-height: 24px;
            }

            &__phone {
              @extend %row-item-padding;

              color: #404041;
              font-size: 16px;
              font-weight: 400;
              line-height: 24px;

              &:hover {
                @extend %row-item-hover-background-color;
                @extend %row-item-hover-color;
              }
            }

            &__email {
              @extend %row-item-padding;

              font-size: 16px;
              font-weight: 400;
              line-height: 22px;

              &:hover {
                @extend %row-item-hover-background-color;
                @extend %row-item-hover-color;
              }
            }

            &__action {
              font-size: 16px;
              font-weight: 400;
              line-height: 22px;
            }

            &__text {
              font-size: 16px;
              font-weight: 400;
              line-height: 22px;
            }

            &__link {
              @extend %row-item-padding;

              align-items: baseline;
              display: flex;
              font-size: 16px;
              font-weight: 400;
              justify-content: space-between;
              line-height: 22px;

              &:hover {
                @extend %row-item-hover-background-color;

                i,
                a,
                span,
                svg {
                  @extend %row-item-hover-color;
                }
              }
            }

            &__specialist {
              @extend %row-item-padding;

              display: flex;
              padding-left: 24px;
              padding-top: 4px;

              img {
                width: 36px;
              }

              .content__specialist-info {
                &.left-padding {
                  padding-left: 10px;
                }
              }

              .content__specialist-title,
              .content__specialist-name {
                font-size: 14px;
                font-weight: 700;
                line-height: 20px;
                padding-bottom: 4px;
              }
            }
          }
        }
      }
    }
  }
}
