@import "../../bpp-variables";

.settings {
  .target-date {
    &__display {
      margin-bottom: 10px;
    }

    .page-heading {
      display: block;
    }

    .dark-search {
      margin-top: 20px;
      padding-left: 0px;
      width: 27%;
    }

    .page-tab-content {
      margin-top: 15px;
      border-radius: 10px;

      .settings-heading {
        &__case {
          margin-top: 8px;
          margin-bottom: 15px;
        }

        &__de {
          margin-top: 20px;
          margin-bottom: 15px;
        }
      }

      .settings-label {
        margin-bottom: 5px;
        font-size: 12px;
      }

      .content-heading {
        font-weight: bold;
        border-bottom: 2px solid #adb0b4;
        padding-bottom: 10px;
        padding-left: 10px;
      }

      .tiers,
      .programs {
        margin-left: 15px;
      }

      table {
        width: 100%;

        th {
          padding: 5px;
          cursor: default;

          &:first-child {
            width: 12%;
            padding-left: 10px;
          }
        }

        tbody {
          cursor: pointer;

          tr {
            &:hover {
              background-color: rgba(17, 17, 17, 0.06);
            }

            &.tier-selected,
            &.program-selected {
              background-color: rgba(17, 17, 17, 0.06);
            }

            &.tier-disabled,
            &.program-disabled {
              pointer-events: none;
            }

            td {
              padding: 5px;

              &:first-child {
                border-top-left-radius: 20px;
                border-bottom-left-radius: 20px;
                width: auto;
                min-width: auto;
                padding-left: 10px;
              }

              &:last-child {
                border-top-right-radius: 20px;
                border-bottom-right-radius: 20px;
              }

              i {
                float: right;
                margin-top: 4px;
                margin-right: 20px;
              }
            }
          }
        }
      }

      .target-date {
        // Base Internal
        margin-top: 8px;

        &__label {
          float: left;
          font-size: 12px;

          span {
            width: 250px;
            display: inline-block;
            vertical-align: -webkit-baseline-middle;
          }
        }

        &__display {
          border-radius: 10px;
          width: 40%;
          display: inline-block;
          padding-left: 13px;
          padding-top: 7px;
          vertical-align: middle;
          background-color: #afb2b7;
          height: 35px !important;
          margin-left: 10px;
        }

        &__input {
          .days-input {
            width: 7%;
            display: inline-block;
            margin-right: 5px;
            height: 35px;
            border-radius: 9px;
            padding: 0px;
            text-align: center;

            &__error {
              border-color: $bpp-subtle-warning-new;
            }
          }

          .datalist {
            .select-border {
              border-radius: 9px;
              width: 201px;

              &.preferred-shipping-case {
                margin-top: -1px;
              }

              .btn {
                width: 201px;
                margin-left: -1px;
                margin-top: -1px;
                outline: none !important;
              }

              &.datalist-select-option div.dropdown-menu.open {
                min-height: 160px !important;
              }
            }
          }
        }

        //External
        &.external {
          width: 100%;

          .target-date__display {
            margin-left: 0;
            width: 40%;
          }
        }
      }
    }

    .btn-edit-settings {
      width: 40%;
      display: inline-block;
      padding-top: 7px;
      vertical-align: middle;
      height: 50px !important;
      margin-left: 260px;

      .btn-edit {
        float: right;
      }
    }

    .btn-edit-settings.external {
      margin: 0 auto;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      .btn-edit {
        float: none;
      }
    }

    .btn-save-cancel {
      margin: 0 auto;
      width: 50%;
      text-align: center;
      margin-top: 15px;
    }

    .btn {
      width: 100px;
    }

    .btn-upgrade {
      width: auto;
    }

    .target-date-error {
      text-align: center;
      margin-top: 5px;
      color: $bpp-subtle-warning-new;
    }
  }
}

.external-settings {
  .dark-options {
    position: relative;
  }

  .create-new {
    position: absolute;
    right: 0;
    bottom: 9px;
  }

  .target-date__input {
    width: 100%;
    margin: 10px 0;
    text-align: left;

    label {
      display: inline-block;
      text-align: right;
      width: 50%;
    }
    .days-input {
      width: 7%;
      display: inline-block;
      margin: 0 10px;
      padding: 5px;
      text-align: center;
    }
    span {
      display: inline-block;
    }

    .days-input__error {
      border-color: $bpp-subtle-warning-new;
    }
  }

  .modal-body {
    overflow: visible;
  }
  .create-error-message {
    color: $bpp-subtle-warning-new;
  }
}

.provider-select-modal {
  justify-content: center;
  align-items: center;

  .datalist {
    text-align: center;
    .form-label {
      text-align: right;
    }
    .form-input {
      padding-left: 10px;
      text-align: left;
    }
    .form-label,
    .form-input {
      width: 50%;
      display: inline-block;
    }
  }

  .btn.dropdown-toggle {
    width: 100%;
  }

  .inner.open {
    height: calc(100% - 47px);
    overflow-x: hidden;
  }
}

.edit-details {
  text-align: left;
  width: fit-content;
  margin: 10px auto 0;

  .bold-text.heading {
    margin-bottom: 10px;
    display: block;
  }

  .fa-arrow-right-long {
    margin: 0 10px;
  }

  &__type {
    margin-bottom: 20px;
  }

  &__change {
    margin-top: 5px;
  }
}

.disabled-badge {
  font-weight: bold;
  color: #999;
}

.tiers.external {
  max-height: 600px;
  padding-right: 20px;
  overflow: auto;

  &::-webkit-scrollbar {
    width: 10px;
    background-color: rgba(64, 64, 65, 0.1);
    border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: rgba(64, 64, 65, 0.5);
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: rgba(#7e7e7e, 1);
  }
}

@mixin option-container {
  label {
    [type="checkbox"],
    [type="radio"] {
      margin: 0px 5px 0px 0px;
    }

    margin: 0px 5px 0px 0px;
    display: block;
    white-space: nowrap;
    cursor: pointer;
  }
}

.option-container {
  @include option-container;
  align-items: start;
  label {
    display: flex;
    width: 100%;
  }
}

@mixin error-message {
  color: $bpp-subtle-warning-new;
  font-size: 12px;
  font-style: italic;
  font-weight: bold;
  .error-exclamation-icon {
    margin-right: 5px !important;
  }
}

.error-message-container {
  @include error-message;
  color: #E74B3A;
}

.create-title-box {
  text-align: center;
  padding-bottom: 10px;
}

.color-grey {
  color: gray;
}

.program-list {
  max-height: calc(100vh - 150px);
  overflow-y: auto;
}

.program-details {
  width: 100%;
  margin-bottom: 10px;

  .dark-options {
    position: relative;
  }

  .settings-label {
    margin-bottom: 5px;
    font-size: 12px;
  }

  label {
    display: inline-block;
    text-align: left;
    width: 50%;
  }

  .option-container {
    @include option-container;
  }

  .error-message {
    @include error-message;
  }

  .input {
    width: 90%;
    display: inline-block;
    height: auto !important;
    border-radius: 9px;
    text-align: left;

    &__error {
      width: 90%;
      border-radius: 9px;
      border-color: $bpp-subtle-warning-new;
    }
  }

  .error-message {
    color: $bpp-subtle-warning-new;
    font-size: 12px;
    .error-exclamation-icon {
      margin-right: 5px !important;
    }
  }

  .option-container {
    @include option-container;
  }
  .display_text_area {
    overflow-wrap: break-word;
  }
}

.action-bar {
  display: flex;
  align-items: center;
  justify-content: stretch;
  margin-top: 20px;
  flex-grow: 1;
  .search-bar {
    display: flex;
    align-items: center;
    width: 500px;
    gap: 5px;

    .program-search-input {
      width: 30%;
    }
  }
}

.program-details-create {
  display: flex;
  width: 100%;
  gap: 10px;
  margin-bottom: 10px;

  .settings-label {
    margin-bottom: 5px;
    font-size: 12px;
  }

  label {
    display: inline-block;
    flex: 0 0 30%;
    text-align: left;
    width: 100%;
  }

  [type="checkbox"],
  [type="radio"] {
    flex: 1;
    margin: 0px 5px 0px 0px;
  }

  .input {
    flex: 1 1 70%;
    width: 100%;
    display: inline-block;
    height: auto !important;
    border-radius: 9px;
    text-align: left;

    &__error {
      border-color: $bpp-subtle-warning-new;
    }
  }

  .option-container {
    @include option-container;
  }

  .error-message {
    @include error-message;
  }
}

.btn-container {
  display: flex;
  justify-content: center;
}

.modal-body-create {
  overflow-y: visible;
  overflow-x: visible !important;
}

.display-tags {
  display: flex;
  flex-wrap: nowrap;
  .display-tag {
    background-color: rgba(255, 163, 119, 0.75);
    border-radius: 15%;
    margin-right: 8px;
    padding: 0px 5px;
  }
}

.disable-program-question {
  margin: 0px;
  white-space: "pre-line";
  font-size: 13.5px;
}
