@import "../../../bpp-variables";

$node_size: 23;
$node_spacing: 0;
$max_nodes: 10;
$max_list_size: 500;
$last_child: 0;
$success_color: #1b8e60;
$failure_color: #e36666;

.cad-process {
  .button-panel {
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
    text-align: center;

    button {
      width: 150px;
      margin: 10px 10px 0 10px;
    }
  }

  .cad-selection {
    width: 95px;
    display: inline-block;
    vertical-align: top;
  }

  .appliance-design-assigned {
    text-align: left;
    margin-top: 35px;
    margin-bottom: 20px;

    &__wo {
      margin-right: 103px;
      text-align: center;
    }

    .qc-assigned-to {
      margin-left: 285px;
    }

    .qc-wo-label {
      padding-right: 5px;
    }

    .qc-wo {
      margin-bottom: 10px;
    }
  }

  .process-box {
    &.ddm {
      margin-left: auto;
      margin-right: auto;
      width: 80%;
      display: block;
      padding: 10px 30px 10px 30px;

      .process-block {
        display: inline-block;
      }
      .process-box-heading {
        font-weight: bold;
        padding: 20px 0px 5px 0px;
      }
      .file-list {
        text-align: left;
      }
      .button-area.ddm {
        margin: 20px 10px 10px 10px;
        .btn {
          margin-top: 10px;
          width: 125px;
        }
        .btn-light:last-child {
          margin-left: 10px;
        }
        .btn-light:nth-child(2) {
          margin-left: 10px;
        }
      }

      .element-spacing {
        padding-top: 10px;
      }
      .modal-header {
        text-align: left;
      }

      .grid {
        display: grid;
        grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
        grid-template-rows: max-content max-content;
        gap: 10px;
        padding: 10px;
        grid-auto-flow: column;
        grid-template-areas:
          "s1 s2"
          "s3 s4";

        a {
          color: #303235;
        }
      }
      .s1 {
        grid-area: s1;
        padding: 15px 10px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
      }
      .s2 {
        grid-area: s2;
        padding: 15px 10px;
      }
      .s3 {
        grid-area: s3;
        padding: 15px 10px;
      }

      .ddm-upload {
        margin-top: 20px;
        height: 170px;
        display: flex;
        justify-content: center;
        padding: 10px;

        .ddm-zip {
          margin: 0 10px;
        }

        .ddm-options {
          width: auto;
          margin: 0 10px;
        }
        .process-box-heading {
          padding: 35px 0px 5px 0px;
        }
      }
    }

    &.ifu-gen-1 {
      margin-left: auto;
      margin-right: auto;
      width: 80%;
      display: block;
      padding: 10px 30px 10px 30px;
    }
  }

  &.cad-gen-2 {
    .process-box {
      margin-left: auto;
      margin-right: auto;
      width: 80%;
      display: block;
      padding: 10px 30px 10px 30px;
    }
    .cad-stage-1 {
      .stage-1-upload {
        flex: 1 1 50%;

        .zip-label {
          font-weight: bold;
          text-align: center;
        }

        &__error {
          color: $bpp-subtle-warning-new;
          text-align: center;
        }
      }

      &__upload-container {
        display: flex;
        flex-flow: row wrap;
        width: 92%;
        margin: 0 auto 20px;
        border-bottom: 1px solid #9ea3a8;
      }

      &__file-upload-labels {
        display: flex;
      }

      &__file-upload-type {
        display: flex;
        margin: 10px 20px 10px 0;
        cursor: pointer;

        input {
          margin: 0 8px 0 0;
        }
      }

      &__file-upload-input {
        margin-bottom: 30px;
      }
    }

    .cad-stage-3 {
      .process-box {
        text-align: center;
      }
    }
  }

  .cad-stage-3 {
    .process-box {
      margin-left: auto;
      margin-right: auto;
      width: 80%;
      display: block;
      padding: 10px 30px 10px 30px;

      input {
        margin-right: 5px;
      }

      .afg {
        margin-right: 18px;
        margin-top: 5px;

        &__margin-right {
          margin-right: 33px;
        }
      }

      .instructions {
        margin-left: 50px;
      }

      .ifu-options.ifu {
        margin-bottom: 5px;
        width: 250px;
        text-align: left;
        margin-left: 15px;
      }

      .ifu-row__option__label {
        margin-bottom: 5px;
      }
    }

    &__row {
      margin-bottom: 10px;
    }

    .stage-1-info {
      .process-box {
        text-align: left;
      }

      &__option {
        margin-bottom: 15px;
      }

      .guide-item-row {
        margin-bottom: 5px;

        .wire-label {
          font-weight: bold;
          margin-bottom: 10px;
          margin-top: 10px;
          float: left;
        }
      }
    }
    .modal-header {
      text-align: left;
    }
  }
}

.ifu-blocked {
  height: 110px;
  margin-top: 50px;
}

.process-box-ifu {
  max-width: 570px;
  text-align: center;
  margin-top: 50px;
}

.modal-content .ifu.fileUpload-content {
  .accepted-text {
    width: auto;
    font-size: 20px !important;
  }
}

.modal-content .uploader-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  form,
  .fileUpload-container,
  .fileUpload-box-full {
    height: 100%;
  }
}

.modal-content .fileUpload-content {
  .grey-text {
    margin-bottom: 5px;
  }
}

.ifu.fileUpload-content {
  padding: 15px;

  .font-size-xlg {
    font-size: 34px;
  }

  .fa-cloud-upload {
    padding: 0px;
    margin: 0px;
  }

  .accepted-text {
    margin: 0 auto;
    color: #43a7b3;
    font-size: 13px !important;
    overflow: hidden;
    white-space: pre-wrap;
    width: 95px;
  }
}

.ifu-container {
  .ifu-row {
    width: 70%;
    margin: 0 auto;

    .ifu-label {
      display: inline-block;
    }

    .ifu-options {
      display: inline-block;
      vertical-align: top;
    }

    .ifu-label.ifu {
      width: 250px;
      margin-bottom: 10px;
      text-align: left;
    }

    .ifu-options.ifu {
      margin-bottom: 5px;
      width: 250px;
      text-align: left;
    }

    .ifu-label.brackets {
      width: 295px;
    }

    .ifu-label.brackets-de {
      width: 200px;
    }

    .ifu-options.brackets {
      width: calc(100% - 295px);
    }

    .ifu-options.brackets-de {
      width: calc(100% - 200px);
    }

    .ifu-label.collisions {
      width: 100px;
    }

    .ifu-options.collisions {
      width: calc(100% - 100px);
    }

    .ifu-label.new-brackets {
      width: 198px;
    }

    .ifu-options.new-brackets {
      width: calc(100% - 198px);
    }

    .ifu-label.wire {
      width: 90px;
    }

    .ifu-options.wire {
      width: calc(100% - 90px);
      vertical-align: middle;
    }

    input[type="checkbox"] {
      margin-right: 12px;
    }

    select {
      width: 230px;
    }

    .bracket-link {
      display: inline-block;
      text-decoration: underline;
      color: #303235;
      cursor: pointer;
    }

    .bracket-link.disabled {
      pointer-events: none;
    }

    &__option {
      margin-bottom: 15px;

      &__selection {
        width: 45%;
        padding-left: 12px;
      }

      &__label {
        margin-bottom: 10px;
      }
    }

    .process-box {
      width: 100%;
      margin-bottom: 20px;
      padding-left: 30px;
      padding-right: 30px;
    }
  }

  .margin-bottom-20 {
    margin-bottom: 20px;
  }

  .fa-refresh {
    font-size: 34px;
  }
}

.three-matic-upload-error,
.file-type-error {
  text-align: center;
  color: $bpp-subtle-warning-new;
}

.zip-label {
  font-weight: bold;
  text-align: center;
}

.align-left {
  text-align: left !important;
}

.case-upload-button {
  margin-top: 10px;
}

.label-failed-retry {
  margin-top: 10px;
  margin-left: 0px !important;
  width: 80px;
}

.return-ifu-upload {
  padding-top: 10px;
  display: block;
  text-align: center;
}

.failed-error-message {
  padding-top: 15px;
  padding-bottom: 15px;

  .tooltip-inner {
    max-width: 400px;
    white-space: normal;
    word-wrap: break-word;
  }
}

.margin-top-27 {
  margin-top: 27px !important;
}

.content-center {
  margin-top: 35px;
}

.uploader-row {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px 20px 0px;
  flex-wrap: wrap;
  > div {
    flex: 0 0 auto;
  }
  .ifu-row__option {
    width: 200px;
    padding-left: 0;
  }
  .ifu-upload-details {
    text-align: center;
    margin-left: 10px;
  }
  .fileUpload-container {
    margin-right: 10px;
    margin-bottom: 10px;
  }
}

.ifu-upload-container {
  text-align: center;
}

.upload-error-container {
  position: relative;
  margin-top: 20px;
  min-height: 5px;
  &.ifu {
    margin-top: 10px;
    padding-bottom: 20px;
  }
}

.upload-error {
  position: absolute;
  top: 0;
  right: 0;
  text-align: center;
  width: 100%;
}

.process-box-intro .btn-transparent {
  border-color: #000;
  color: #000;
  background-color: transparent;
}

.ifu_pdf {
  display: flex;
  justify-content: center;
  align-items: baseline;
}

.tiny-label {
  font-size: 0.9em;
  font-style: italic;
}