.wire-selection-assignment {
  display: flex;
  padding-bottom: 20px;
  align-items: center;
  justify-content: center;

  div {
    margin-bottom: 5px;
  }
}

.assignment-label {
  margin-right: 10px;
}

.select-assign-to {
  width: 300px;
}

.item-request-text {
  text-align: center;
  padding-bottom: 10px;
}

.table-items-edit {
  padding-bottom: 20px;
  table {
    table-layout: auto;
    width: 100%;
  }

  .wrapper {
    overflow: auto;
    overflow-x: auto;
  }

  thead {
    tr:first-child {
      border-bottom: 1px solid #adb0b4;
      width: 98%;
    }
  }

  td,
  th {
    text-align: left;
    font-size: 14px;
    padding: 3px 3px;
  }

  th {
    padding-bottom: 0px;
  }

  td:first-child {
    padding-top: 0px;
  }

  .item-col {
    vertical-align: left;
    white-space: nowrap;
    min-width: 160px;
    max-width: 160px;
    width: 160px;
  }

  .item-col:first-child {
    padding-left: 0px;
    white-space: nowrap;
    min-width: 250px;
    max-width: 250px;
    width: 250px;
  }

  .item-col {
    vertical-align: left;
    white-space: nowrap;
    // min-width: 100px;
    // max-width: 150px;
    // width: 100px;
  }

  .item-col:nth-child(2) {
    min-width: 180px;
    max-width: 180px;
    width: 140px;
  }

  .item-col:nth-child(3) {
    min-width: 130px;
    max-width: 130px;
    width: 100px;
  }

  .item-col:nth-child(4) {
    min-width: 180px;
    max-width: 180px;
    width: 140px;
  }
}

.table-items-disabled {
  table {
    table-layout: auto;
    width: 100%;
  }

  .wrapper {
    overflow: auto;
    overflow-x: auto;
  }

  thead {
    tr:first-child {
      border-bottom: 1px solid #adb0b4;
      width: 98%;
    }
  }

  td,
  th {
    text-align: left;
    font-size: 13px;
    padding: 3px 3px;
  }

  th {
    padding-bottom: 0px;
  }

  td:first-child {
    padding-top: 0px;
  }

  .item-disabled-col {
    vertical-align: left;
    white-space: nowrap;
  }

  .item-disabled-col:first-child {
    padding-left: 0px;
    white-space: nowrap;
    min-width: 225px;
    max-width: 225px;
    width: 225px;
  }

  .item-disabled-col:nth-child(2) {
    white-space: nowrap;
    min-width: 130px;
    max-width: 130px;
    width: 130px;
  }

  .item-disabled-col:nth-child(3) {
    white-space: nowrap;
    min-width: 75px;
    max-width: 75px;
    width: 75px;
  }
}

.notes-disabled-container {
  padding: 10px 0px;
  font-size: 14px;
}

.text-left {
  text-align: left;
}

.notes-edit-container {
  width: 100%;
  font-size: 14px;
  padding-bottom: 10px;
}

.wire-selection-notes-edit {
  margin: 8px;
  height: 100px;
  width: 100%;
}

.transparent-button {
  opacity: 80%;
  border-radius: 10px;
  background-color: transparent;
  padding: 2px 10px;
  font-size: 13px;
  border: 1px solid black;
  opacity: 1;
}

.centered-button {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.transparent-button.lg {
  border-radius: 10px;
  font-size: 14px;
  padding: 5px 80px;
}

.remove-icon {
  color: red;
  cursor: pointer;
}

.table-items-edit .remove-icon {
  margin-top: 5px;
}

.download-link {
  text-decoration: underline;
  cursor: pointer;
  align-items: center;
}

.download-link > *:last-child {
  margin-left: 5px;
}

.file-container {
  display: flex;
  gap: 10px;
}

.file-container.centered {
  align-items: center;
  justify-content: center;
}

.document-icon {
  background-color: transparent;
  cursor: pointer;
}

.custom-wo-and-fabrication-container {
  position: relative;
  padding-bottom: 20px;
}

.top-right-button {
  text-align: right;
  position: absolute;
  top: 0;
  right: 0;
}

.custom-wo-box {
  border: 1px solid #adb0b4;
  border-radius: 10px;
}

.mt-10 {
  margin-top: 10px;
}

.return-link {
  margin-bottom: 10px;
}

.fabrication-log-modal {
  .modal-footer {
    text-align: right;
  }
}

.ir-ws-subtitle {
  width: 70%;
  margin: 0 auto;
}
