.radio-spacing {
  padding-right: 5px;

  .input-container {
    display: inline-block;
  }
}

select.patient-field {
  width: 300px;
  padding-left: 9px;
  overflow: hidden;
}

[type='text'].patient-field {
  width: 300px;
}

[type='text']:disabled {
  cursor: not-allowed !important;
}

.patient-field {
  width: 200px;
}

.patient-img-window {
  border: 2px dashed #999999;
  width: 200px;
  height: 150px;
  text-align: center;
}

.fa.fa-large {
  font-size: 36px;
  padding-bottom: 10px;
  margin-top: 30%;
}

.patients-info:nth-child(2) {
  margin-top: 40px;
}

.inline-label-patient {
  display: inline-block;
  width: 139px;
}

.label-delete-referral-code {
  margin-left: 10px;
  cursor: pointer;
}

select.form-control.patient-field {
  border: 1px solid #e1e2e2;
}

.display-inline {
  display: inline-block !important;
}

#referralCodeLabel {
  display: block;
}

button.display-inline {
  margin-top: 0px !important;
  margin-left: 16px;
}

#other_input {
  margin-left: 21px;
  max-width: 96%;
}

@media only screen and (min-width: 1200px) {
  .info-border-left {
    border-left: 1px solid #e1e2e2;
  }
}
