.text-xxxl {
  font-size: 60px;
}

.text-xxl {
  font-size: 54px;
}

.text-xl {
  font-size: 28px;
}

.error-middle {
  margin: 30vh;
  color: #fff;
}
