$card-space-small: $general-spacing;
$card-space-medium: $general-spacing * 2;
$card-space-large: $general-spacing * 3;
$size: 40px;

.card {
  border: 1px solid $platinum;
  border-radius: $border-radius;
  background-color: $pearl;

  &--x {
    font-size: 1.5em;
  }

  &--no-padding {
    padding: 0px;
  }

  &.card--fullwidth{
    min-width: 100%;
  }

  &.card--stepper {
    margin-bottom: $card-space-small;
    padding: $card-space-medium 0;
  }

  &.card--workflow {
    padding: $card-space-large;
  }

  &.card--top-border {
    border-top: 5px solid $turquoise;
    margin-top: $card-space-medium;
  }

  &.card--message {
    border-top: 5px solid $turquoise;
    max-width: 500px;
    padding: $card-space-large;
    margin: calc((100vh - #{$size})/2 - 100px) auto;
    vertical-align: middle;
  }

  &.card--no-top-border {
    border-top: none;
  }

  &.card--error-message {
    border: none;
    border-top: 5px solid $ruby;
    color: $pearl;
    background-color: $dark-active;
    padding: $card-space-medium;

    a, span {
      color: $pearl;
    }
  }

  &.card--error {
    border-top-color: $ruby;
  }

  &.card--form {
    padding: $card-space-medium;
    background-color: $eggshell;
    border: none;
  }

  &.card--padding {
    padding: calc(#{$general-spacing}/2) $general-spacing;
  }

  &.clear-after::after {
    content: '';
    clear: both;
    display: block;
  }

  &.mt-none {
    margin-top: 0;
  }

  &.mt-sm {
    margin-top: $card-space-small;
  }
  &.mt-md {
    margin-top: $card-space-medium;
  }

  &.mb-sm {
    margin-bottom: $card-space-small;
    padding-right: $card-space-medium;
    padding-left: $card-space-medium;
  }

  &.ml-sm {
    margin-left: $card-space-small;
  }

  &.pad-sm {
    padding: $card-space-small;
  }
  
  &.pad-md {
    padding: $card-space-medium;

    &--no-top{
      padding-top: 0px;
    }

    &--no-bottom {
      padding-bottom: 0px;
    }
  }
  &.pad-lg {
    padding: $card-space-large;
  }

  &--smile {
    div.col-md-6 {
      margin-bottom: $general-spacing;
      min-height: calc(63px + #{$general-spacing});
    }
  }
}
