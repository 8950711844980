div,
p,
span {
  font-family: $base-font-family;
  font-size: $body-font-size;
  font-weight: $body-font-weight;
  color: $body-font-color;
  line-height: $body-font-line-height;
}

h1 {
  font-size: $h1-font-size;
  font-weight: $h1-font-weight;
  color: $h1-font-color;
}

h2 {
  font-size: $h2-font-size;
  font-weight: $h2-font-weight;
  color: $h2-font-color;
}

h3 {
  font-size: $h3-font-size;
  font-weight: $h3-font-weight;
  color: $h3-font-color;
}

h1,
h2,
h3 {
  margin-top: 0px;
}

.alert {
  font-size: $alert-font-size;
  font-weight: $alert-font-weight;
  color: $alert-font-color;

  span {
    font-weight: $alert-font-weight;
  }
}

.center {
  text-align: center;
}


.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.emphasis {
  font-weight: bold;

  &--colored {
    color: $turquoise;
  }
}

.medium {
  font-weight: 600;
  
  &--colored {
    color: $turquoise;
  }
}

.tiny {
  font-size: 0.9em;
}

.underlined {
  text-decoration: underline;
}

.wrap-text {
  white-space: normal;
}

@include for-size(desktop-lg) {
  h1 {
    font-size: 1.9em;
  }
}

@include for-size(tablet) {
  h1 {
    font-size: $h2-font-size;
  }

  h2, h3 {
    font-size: $h3-font-size;
  }
}
