$small: 576px; // mobile
$medium: 768px; // tablet
$large: 992px; // desktop
$xlarge: 1200px; // desktop large

$sizes: (
  phone: $small - 1,
  tablet: $medium - 1,
  desktop: $large - 1,
  desktop-lg: $xlarge - 1,
);

// max-width is <=
// min-width is >=
@mixin for-size($size) {
  @if not map-has-key($sizes, $size) {
    @error "#{$size} is not a valid size";
  } @else {
    @media only screen and (max-width: map-get($sizes, $size)) {
      @content;
    }
  }
}

@mixin rowGap($gap) {
  & > *:not(:last-child) {
    margin-bottom: $gap !important;
  }
}

@mixin columnGap($gap) {
  & > *:not(:last-child) {
    margin-right: $gap !important;
  }
}
