$almostBlack: #303235;
$scrollbar_border_radius: 10px;
$scrollbar-color: rgba($almostBlack, 0.1);
$scrollbar-thumb-color: rgba($almostBlack, 0.5);
$scrollbar-hover-color: rgba($almostBlack, 0.7);

.breadcrumb-link,
.breadcrumb-link:hover {
  color: #ffffff;
  cursor: pointer;
}

.breadcrumb-link:active {
  color: #dddddd;
}

.fullview {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 100000;
}

.navbar-default .navbar-nav > li > a.banner-text {
  color: #ffffff;
}

.navbar-default .navbar-nav > li > a.banner-text:hover {
  color: #777777;
}

.dropdown-menu.inbrace-color > li > a:focus,
.dropdown-menu.inbrace-color > li > a:hover {
  color: #262626;
  text-decoration: none;
  background-color: #f5f5f5;
}

@media (min-width: 768px) {
  .dropdown-menu.inbrace-color li a {
    background: #ffffff;
  }
  .dropdown-menu.inbrace-color {
    top: 105%;
  }
}

#topnav ul li ul.dropdown-menu.inbrace-color li {
  padding: 0px;
}

.dropdown-menu.inbrace-color > li > a {
  width: 215px;
}

.navbar.navbar-simple {
  margin-bottom: 0px;
}

.nav li.show-sm {
  display: none;
}

.nav li.hide-sm {
  display: block;
}

@media screen and (max-width: 768px) {
  .nav li.show-sm {
    display: block;
  }

  .nav li.hide-sm {
    display: none;
  }
}

#topnav ul li ul li {
  padding-left: 45px;
}

.dropdown-submenu {
  position: relative;
}

.dropdown-submenu > .dropdown-menu {
  top: 0;
  left: 50%;
  margin-top: -6px;
  margin-left: -1px;
  -webkit-border-radius: 0 6px 6px 6px;
  -moz-border-radius: 0 6px 6px;
  border-radius: 0 6px 6px 6px;
}

.dropdown-submenu:hover > .dropdown-menu {
  display: block;
}

.dropdown-submenu > a:after {
  display: block;
  content: ' ';
  float: right;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
  border-width: 5px 0 5px 5px;
  border-left-color: #ccc;
  margin-top: 5px;
  margin-right: -10px;
}

.dropdown-submenu:hover > a:after {
  border-left-color: #fff;
}

.dropdown-submenu.pull-left {
  float: none;
}

.dropdown-submenu.pull-left > .dropdown-menu {
  left: -100%;
  margin-left: 10px;
  -webkit-border-radius: 6px 0 6px 6px;
  -moz-border-radius: 6px 0 6px 6px;
  border-radius: 6px 0 6px 6px;
}

.dropdown-menu.dropdown-menu-round {
  color: #43a7b3;
  border-color: #9cd7df;
  border-radius: 0px 0px 10px 10px;
  padding-bottom: 0px;
  padding-top: 0px;
  right: 95px;
  left: 38px;
  top: 54px;
  width: 202px;
  opacity: 0.96;
}

.version-tag {
  display: inline;
  position: absolute;
  font-weight: bold;
  font-size: 10px;
  color: #32a1d9;
  left: 332px;
  margin-top: 39px;
}

.inline-search {
  width: 30%;
  display: inline-block;
  margin-top: 10px;
  float: left;
}

nav#sidebar {
  /* padding-top: 70px !important; */
  position: sticky !important;
  top: 0;
  z-index: 12;
}

.fixed-header {
  position: fixed;
  padding-top: 68px;
  width: 100%;
  z-index: 11;
}

.white-header {
  position: fixed;
  padding-top: 176px;
  background-color: white;
  width: 100%;
  z-index: 10;
  height: 20px;
}

.submit-spacing {
  margin-top: 100px;
}

div.tend.tend-menu {
  position: relative;
  margin-bottom: 8px;
  padding-right: 16px;
  padding-left: 16px;

  &:hover {
    border-color: #2da4ad;
  }

  a {
    color: #707070;
    &:hover {
      color: #fff;
      background-color: #2da4ad;
      transition: all 0s;
      span,
      .fa.fa-chevron-right.pull-right {
        color: #fff;
      }
    }

    &:focus {
      color: #fff;
      background-color: #2da4ad;
    }
  }

  li {
    &:hover {
      color: #fff;
      background-color: #2da4ad;

      i {
        color: #fff;
      }
    }

    &:focus {
      color: #fff;
      background-color: #2da4ad;
    }
  }

  i {
    line-height: 24px;
  }
}

.tend {
  .item {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    position: relative;
  }

  .fa.fa-chevron-down.pull-right {
    color: #707070;
    top: 0px;
    font-size: 13.2px;
    right: 0px;
    position: absolute;
    border-radius: 50%;

    &:hover {
      color: hsl(0, 0%, 60%);
    }
  }

  .fa.fa-chevron-right.pull-right {
    min-height: 100%;
    color: #707070;
    font-size: 13.2px;
    vertical-align: middle;
  }
}

.dropdown-menu.tend-dropdown-menu-round {
  color: #707070 !important;
  border-color: #e1e2e2;
  padding-bottom: 0px;
  padding-top: 0px;
  left: -1px;
  top: auto;
  width: 250px;
  opacity: 0.96;
  font-size: 1em;

  li > a {
    padding: 3px 16px;
  }
}

.tend-doctor-popover,
.tend-location-popover {
  padding: 0px !important;
  border-color: #e1e2e2;
  border-radius: 3px;
  color: #707070 !important;
}

.aa-popover {
  left: -112px !important;

  &__border {
    border-bottom: 1px solid #e1e2e2;
    margin: 5px 0;
  }

  &__label {
    font-weight: bold;
    font-size: 0.75em;
    padding: 6px 5px 3px 15px;
    font-family: sofia-pro, sans-serif;

    &__disabled {
      pointer-events: none;
      color: rgb(163, 162, 162) !important;
    }
  }

  .lock_icon {
    margin-right: 10px;
  }
}

.tend-submenu {
  padding-bottom: 0px;
  padding-top: 0px;
  width: 250px;
  opacity: 0.96;
  z-index: 1000;
  min-width: 160px;
  padding: 0;
  margin: 0;
  font-size: 16px;
  text-align: left;
  list-style: none;
  background-color: #fff;
  overflow-y: auto;
  max-height: 170px;
  &::-webkit-scrollbar {
    background-color: $scrollbar_color;
    height: 6px;
    width: 6px;
    border-radius: $scrollbar_border_radius;
  }
  &::-webkit-scrollbar-thumb {
    background-color: $scrollbar_thumb_color;
    border-radius: $scrollbar_border_radius;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: $scrollbar_hover_color;
  }

  &.aa-submenu {
    max-height: 350px;
    width: 255px;
  }

  a {
    text-decoration: none;
    padding: 3px 3px;
    display: block;
    padding-left: 15px;
    width: 100%;
    line-height: 32px;
    height: 34px;
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow-y: hidden;
    color: #707070;

    .badge.badge-hollow {
      margin: 0px;
      margin-right: 4px;
      width: 35px;
      text-align: right;
    }
  }
  a:not(.no-doctors):hover {
    color: #fff;
    background-color: #2da4ad;
  }

  a.no-doctors {
    cursor: default;
  }
}
