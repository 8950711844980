.context-menu {
  position: absolute;
  margin-left: -55px;
  margin-top: -10px;
  background-color: #fff;
  border: 1px solid grey;
  z-index: 500;
  text-align: center;
  width: auto !important;
}

.context-menu-item {
  display: block;
  padding: 5px 10px;
  background-color: #fff;
  color: #000;
  a {
    color: #000;
    text-decoration: none;
  }
}

.context-menu-item:hover {
  background-color: #c9e2fa;
}
