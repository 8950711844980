.inbrace-alert {
  background-color: #eaf6f6;
  padding: 10px 35px;
  border-radius: 30px;
}

.address-area {
  border-radius: 10px;
  border: 1px solid #999999;
  padding: 5px 15px;
  width: 400px;
}

.treatment-dropdown {
  width: 200px;
  border-radius: 10px;
  border: 1px solid #ddd;
}

.textAreaStyle {
  height: 50px;
  margin: 10px;
  resize: none;
}

.addressbox {
  display: inline-flex;
  margin-left: 14px;
}

@media only screen and (max-width: 768px) {
  .address-area {
    width: auto;
  }

  .addressbox {
    display: inline-table;
    margin-left: 14px;
  }
}

textarea {
  resize: none; /* Disables resizability */
}

.label-middle {
  display: inline-table;
  vertical-align: middle;
  min-height: 55px;
}

.tab-space {
  margin-left: 3px;
}

.inline-label {
  display: inline-block;
}

.address-format {
  padding-left: 180px;
  padding-bottom: 12px;
}

.address-format-container {
  padding-top: 10px;
}

input[type='radio'].address-format-radio {
  margin-right: 10px;
}

/* input[type='radio'].address-format-radio:first {
  padding-left: 10px;
  padding-right: 10px;
} */
