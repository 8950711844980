// =============================================================================
// Color
// =============================================================================
$almostBlack: #303235;
$amour:#FCE8EB;
$onyx: #000000;
$azure: #32a1d9;
$nightblue: #211b57;
$eggshell: #f8f8f8;
$mint: #ecf5f5;
$pearl: #ffffff;
$light-gray-10: #f2f2f2;
$platinum: #e1e2e2;
$sonicSilver: #707070;
$soot: #c4c4c4;
$turquoise: #2da4ad;
$wave: #42adb5;
$jade: #57b6bd;
$seafoam: #c9e2e4;
$aluminum: #d6d6d6;
$titanium: #b8b8b8;
$platinum: #e1e2e2;
$ruby: #e34558;
$licorice: #e75f6f;
$vermillion: #e13146;
$cottonCandy: #f6c6cc;
$electricGreen: #c9e63f;
$orange: #ed9b40;
$lightcyan: #d5edef;
$tangerine: #e87d07;
$linen: #fdf2e6;

// Primary Button
$primary-default: $turquoise;
$primary-hover: $wave;
$primary-active: $jade;
$primary-disabled: $seafoam;

// Secondary Button
$secondary-default: $pearl;
$secondary-hover: $pearl;
$secondary-active: $pearl;
$secondary-disabled: $platinum;

// Dark Button
$dark-default: $onyx;
$dark-hover: rgba($onyx, 0.5);
$dark-active: rgba($onyx, 0.3);
$dark-disabled: rgba($onyx, 0.1);

// Revise Button
$revise-default: $ruby;
$revise-hover: $licorice;
$revise-active: $vermillion;
$revise-disabled: $cottonCandy;

// Notifications
$notification-font-weight: 500;
$notification-error-bg: $ruby;
$notification-error-color: $light-gray-10;
$notification-warn-bg: $linen;
$notification-warn-color: $tangerine;

// =============================================================================
// font
// =============================================================================
$base-font-family: Roboto, sans-serif;
$badge-font-family: sofia-pro, sans-serif;

// body
$body-font-size: 1em;
$body-font-weight: normal;
$body-font-color: $sonicSilver;
$body-font-line-height: 24px;

// H1
$h1-font-size: 2.3em;
$h1-font-weight: bold;
$h1-font-color: $almostBlack;

// H2
$h2-font-size: 1.3em;
$h2-font-weight: bold;
$h2-font-color: $turquoise;

// H3
$h3-font-size: 1.2em;
$h3-font-weight: bold;
$h3-font-color: $almostBlack;

// Alert
$alert-font-size: 1em;
$alert-font-weight: 500;
$alert-font-color: $sonicSilver;

// =============================================================================
// Border
// =============================================================================
$border-radius: 3px;

// =============================================================================
// Spacing
// =============================================================================
$general-spacing: 8px;

// =============================================================================
// Hyperlinks
// =============================================================================
// Dark
$hyperlink-dark-default: $sonicSilver;
$hyperlink-dark-hover: rgba($sonicSilver, 0.7);
$hyperlink-dark-active: $sonicSilver;
$hyperlink-dark-disabled: rgba($sonicSilver, 0.5);

// White
$hyperlink-white-default: $pearl;
$hyperlink-white-hover: rgba($pearl, 0.7);
$hyperlink-white-active: $pearl;
$hyperlink-white-disabled: rgba($pearl, 0.5);

// Blue
$hyperlink-blue-default: $azure;
$hyperlink-blue-hover: rgba($azure, 0.7);
$hyperlink-blue-active: $azure;
$hyperlink-blue-disabled: rgba($azure, 0.5);

// Night-Blue
$hyperlink-nightblue-default: $nightblue;
$hyperlink-nightblue-hover: rgba($nightblue, 0.7);
$hyperlink-nightblue-active: $nightblue;
$hyperlink-nightblue-disabled: rgba($nightblue, 0.5);
