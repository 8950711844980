.submission-summary {
  padding-bottom: 1em;

  &__header {
    p {
      margin: 20px 0 20px 0;
    }

    .no-records {
      margin-bottom: 0px;
    }
  }

  &__container {
    padding: 10px 10px 0 10px;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;

    &__section {
      width: 50%;

      .tooth-viewer {
        text-align: left;
        svg {
          height: 600px;
        }
      }
    }
  }

  &__ol {
    padding-inline-start: 24px;
    & li::marker {
      font-weight: 500;
    }
  }

  &__field {
    padding: 0.5em 0;

    &__value {
      padding: 5px 0px;
      word-break: break-word;
      white-space: pre-wrap;

      p {
        margin: 0;

        &:nth-child(2) {
          margin-top: 10px;
        }
      }
    }
  }

  .emphasis {
    font-weight: 500;
  }

  .small-img-window {
    pointer-events: none;
  }

  .record-thumbnail-tooltip-container {
    all: unset;
  }
}

@media (max-width: 768px) {
  .submission-summary {
    &__container {
      &__section {
        width: 100%;
      }
    }
  }
}
