.text-editor {
  background-color: white;
  text-align: left;
  border-radius: 10px;

  .ql-container {
    font-size: 15.4px;
    color: rgb(0, 0, 0);
  }
}
.quill .ql-editor {
  min-height: 200px;
  max-height: 200px;
}
.setup-comment-box .user-title {
  padding-left: 15px;
}
.setup-comment-box .ql-editor {
  overflow-y: inherit;
  padding-left: 0px;
}
td .ql-editor {
  p {
    text-align: left !important;
    line-height: 20px;
    text-indent: 0px;
    font-size: 15.4px;
    font-family: sans-serif;
    font-weight: 300;
    color: #303235;
  }
}
.ql-editor {
  text-align: left;

  p {
    text-align: left !important;
    line-height: 20px;
    text-indent: 0px;
    font-size: 15.4px;
  }
}
.ql-toolbar {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.ql-container {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.process_tracker .ql-editor {
  p {
    line-height: 18px;
  }
}
.ql-tooltip {
  z-index: 1;
}
.richtext {
  margin-left: 16px;
  p {
    margin: 0 0 0 0;
  }
  ol {
    text-indent: 0px;
  }
  ul {
    text-indent: 0px;
  }
}
