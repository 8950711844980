@import '../../../bpp-variables';

.wire-manufacturing-container {
  .process_tracker ul.workflow li {
    &:first-child p {
      width: 92px;
    }
    &:last-child p {
      left: -53px;
      width: 120px;
    }
  }

  .wire-manufacturing-process {
    text-align: center;
    margin-top: 10px;

    .button-panel {
      margin-top: 20px;
    }
  }
}
