.fileUpload-box {
  overflow: hidden;
  border: 1px dashed $platinum;
  border-radius: $border-radius;
  background-color: $eggshell;
  padding: $general-spacing * 3;
  color: $sonicSilver;
  min-height: 200px;
  height: 500px;
  max-height: 53.5vh;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    border-color: $turquoise;
  }

  .fileUpload-content {
    align-self: center;
  }

  .accepted-text.font-size-lg {
    margin-bottom: $general-spacing;
  }
}
