@import '../../bpp-variables';

.csv-download-link {
  padding-right: 25px;
  padding-left: 25px;
}

.spinner {
  color: #337ab7;
}

.report-data-table {
  height: 850px;
}

.no-record-container {
  width: 100%;
  border-radius: 20px;
  background-color: #303235;
}

@media only screen and (max-width: 1537px) {
  .report-data-table {
    height: 850px;
  }
}
@media screen and (max-height: 768px) {
  .report-data-table {
    max-height: 630px;
  }
}

.reports-heading {
  padding-top: 25px;
  padding-bottom: 15px;
}

.reports-data-heading {
  padding-top: 15px;
  padding-bottom: 15px;
}

.reports-page {
  padding-bottom: 15px;

  .date-label {
    font-size: 14px;
    margin-right: 18px;
  }
  .select-date {
    font-size: 14px;
    border-radius: 10px;
    border: 1px solid #ccc;
    padding: 5px;
    width: 140px;
    margin-right: 10px;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  }

  .btn-light-3 {
    margin: 1px 0px -4px 0px;
  }

  .error {
    border-color: $bpp-subtle-warning;
  }

  .error-message {
    font-size: 14px;
    color: $bpp-subtle-warning;
    margin-left: 20px;
  }
}
