.collapsible-triangle-container {
  position: relative;

  .MuiCollapse-container {
    box-shadow: inset 0 -10px 10px -10px rgba($onyx, 0.5);
    position: relative;
    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      pointer-events: none;
      background-image: linear-gradient(rgba($pearl, 0), rgba($pearl, 0.8) 100%);
      width: 100%;
      height: 2em;
    }

    &.MuiCollapse-entered {
      &::after {
        content: none;
      }
      box-shadow: none;
      border-bottom: 1px solid $platinum;
    }
  }

  .triangle-toggle {
    position: absolute;
    left: 50%;
    transform: translate(-50%, -100%);
    border-bottom: 1.5em solid $primary-default;
    border-left: 2em solid transparent;
    border-right: 2em solid transparent;
    padding: 0;
    height: 0;
    width: 6em;
    cursor: pointer;

    &:hover {
      border-bottom-color: $primary-hover;
    }

    &:active {
      border-bottom-color: $primary-active;
    }

    > i {
      position: absolute;
      left: 50%;
      top: 0.2em;
      color: $pearl;
      transform: translate(-50%, 0);
    }

    &.triangle-toggle--down {
      border-bottom: none;
      border-top: 1.5em solid $turquoise;
      transform: translate(-50%, 0);

      &:hover {
        border-top-color: $primary-hover;
      }

      &:active {
        border-top-color: $primary-active;
      }

      > i {
        top: -1.2em;
      }
    }
  }
}
