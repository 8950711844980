.feedback-form-modal.simple-modal {
  .modal-dialog {
    .modal-content {
      .modal-body {
        .modal-content-body {
          padding-top: 16px;
          margin-bottom: 16px;

          .content-comment-container {
            .comment-label {
              font-size: 16px;
              font-weight: 500;
              color: $sonicSilver;
              margin-top: 0;
              margin-bottom: 10px;
            }

            .text-input__count--error span {
              color: $vermillion;
            }

            .text-input__error {
              position: relative;
              color: $vermillion;
              width: 100%;
              text-align: center;
              margin-top: 32px;
            }

            .text-input.has-error {
              border-color: $vermillion;
            }
          }

          .submitted-message {
            margin: 0;
          }
        }

        .error--alert {
          margin-top: 16px;
        }
      }
    }
  }
}
