.banner-message-critical {
  width: 100%;
  height: 100%;
  background-color: #ffb300;
  padding: 20px 100px 20px 100px;
  text-align: center;
}

.banner-message-info {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #c9e63f;
  text-align: center;

  & .banner-message-info__text {
    color: #211b57;
    margin: 0;
  }

  & .banner-message-info__link {
    color: #211b57;
    text-decoration: underline;
  }

  & .bold {
    font-weight: bold;
  }
}

.banner-message-header {
  font-weight: bold;
  font-size: 22px;
  padding-bottom: 10px;
}
