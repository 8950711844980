.progress-ring {
  display: block;
  max-width: 100%;

  .progress-ring__circle-background {
    fill: none;
    stroke: $platinum;
  }

  .progress-ring__circle {
    transform: rotate(-90deg);
    transform-origin: 50% 50%;
    fill: none;
    stroke: $turquoise;
    transition: stroke-dashoffset 0.3s ease-out;
  }

  .progress-ring__text {
    text-anchor: middle;
    font-weight: bold;
  }
}
