/* The sidebar menu */
.sidenav {
  height: 100%; /* Full-height: remove this if you want "auto" height */
  width: 80px; /* Set the width of the sidebar */
  position: fixed; /* Fixed Sidebar (stay in place on scroll) */
  z-index: 2; /* Stay on top */
  top: 0; /* Stay at the top */
  left: 0;
  background-color: #111; /* Black */
  overflow-x: hidden; /* Disable horizontal scroll */
  padding-top: 0px;
  color: #959595;
  text-align: center;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

  &::-webkit-scrollbar {
    background: transparent;
    width: 12px;
    background-color: rgba(255,255,255,.2)
  }
  &::-webkit-scrollbar-thumb {
    background-clip: padding-box;
    background-color: rgba(#7e7e7e, 0.8);
    border-radius: 8px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background-color: rgba(#7e7e7e, 1);
  }
}

/* The navigation menu links */
.sidenav a {
  padding: 10px 10px 4px 10px;
  text-decoration: none;
  font-size: 25px;
  color: #818181;
  display: block;

  .cls-1 {
    fill: #ffffff;
    width: 40px;
  }

  svg {
    margin-top: 5px;
    width: 35px;
  }
}

/* When you mouse over the navigation links, change their color */
.sidenav a:hover {
  color: #f1f1f1;
}

/* Style page content */
.sidenav-offset {
  margin-left: 80px; /* Same as the width of the sidebar */
  padding: 0px 0px;
}

.inlogo-m {
  width: 60px;
}

.sidebar-text {
  font-size: 12px;
  display: block;
}

.sidebar-bottom {
  bottom: 0px;
  left: 10px;
}

.icon-s {
  font-size: 16px;
}
