.account-search-navbar {
  width: 350px;
  display: inline-block;
  vertical-align: bottom;
  margin-bottom: 10px;

  .search-nav-search {
    width: 100%;
  }
}

.new-account {
  color: #43a7b3;
  cursor: pointer;
  display: inline-block;
  margin: 0px 0px 20px 30px;
  vertical-align: bottom;

  &:hover {
    color: #7a7e80;
  }
}
