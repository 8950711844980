.bpp-csq-gray-background {
  // padding: 15px 15px 15px 15px;
  width: 100%;
}
.btn.btn-light.btn-bpp-pr {
  margin-left: 0px;
  margin-top: 10px;
}

.bpp-pr-upload-date-label {
  display: inline-block;
  font-size: 16px;
  font-weight: 700;
  color: #303235;
}
