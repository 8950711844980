.banner-carousel {
  align-items: center;
  background: transparent;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  display: flex;
  gap: 8px;
  justify-content: center;
  transition: transform 400ms ease-in-out 0s;
  width: 100%;

  .banner-container {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    margin-top: 0;
    overflow: hidden;
    width: 100%;

    .banner-track {
      display: flex;
      flex-direction: row;
      height: 100%;
      transform-style: preserve-3d;
      transition: transform 400ms ease-in-out 0s;
      width: 100%;
      will-change: transform, transition;
      .banner {
        flex: 1 0 100%;
        position: relative;
        transform-style: preserve-3d;
        > * {
          padding: 8px 32px;
          @media (min-width: 768px) {
            padding: 10px 32px;
          }
        }
        a {
          color: #337ab7;
        }
      }
    }
  }

  .banner-control {
    background: transparent;
    border: none;
    display: none;
    height: 32px;
    margin-top: 0;
    position: absolute;
    width: 32px;
    z-index: 10;
    
    @media (min-width: 1025px) {
      display: block;
    }

    &.banner-previous {
      left: 0;
    }

    &.banner-next {
      margin-right: 16px;
      right: 0;
    }

    &:hover {
      g {
        opacity: 0.75;
      }
    }

    g {
      opacity: 0.25;
      transition: .5s all;
    }
  }

  

  .carousel-navigation {
    align-items: flex-start;
    display: flex;
    gap: 4px;
    padding-bottom: 10px;
    position: absolute;

    .carousel-indicator {
      cursor: pointer;
      height: 5px;
      width: 50px;
    }
  }

}
